import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { useAPI } from 'components/lib'
import { successToast } from 'helpers'
import { downloadImage } from 'helpers/image'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import useWebSocket from 'react-use-websocket'
import {
  selectClientId,
  selectPending,
  setQueue,
  setClientId,
  setFinished,
  setPending,
} from 'store/generations'
import { v4 as uuidv4 } from 'uuid'

export default function WsClient() {
  const dispatchAction = useDispatch()
  const { t } = useTranslate()
  const navigate = useNavigate()
  const location = useLocation()
  const comfyUrl = useAPI('/api/image')

  const pending = useSelector(selectPending)
  const clientId = useSelector(selectClientId)

  // Set clientId
  useEffect(() => {
    dispatchAction(setClientId(uuidv4()))
  }, [dispatchAction])

  const { lastJsonMessage } = useWebSocket(
    comfyUrl.data,
    {
      queryParams: { clientId: clientId },
      share: true,
      shouldReconnect: () => true,
      reconnectInterval: (lastAttemptNumber) => (lastAttemptNumber < 3 ? 0 : 1000),
    },
    clientId && !comfyUrl.loading
  )

  const getUpscaledImage = useCallback(
    async (el) => {
      console.log('Fetching upscaled image...')
      try {
        const res = await axios.get(`/api/ai/comfy/${el.id}`)

        const images = res.data
        for (const image of images) {
          downloadImage(image, el.name)
        }
        console.log('Done')
        successToast(t('eleo-dowload-complete', 'Image download complete'), 'upscale', 3000)
      } catch (err) {
        console.log(err)
      }
    },
    [t]
  )

  const showSuccessToast = useCallback(
    async (category) => {
      const redirects = {
        generate: '/image',
        modify: '/image/modify',
      }
      const content = t(
        'eleo-image-generation-done',
        'Your images are ready. Click here to see them or navigate to the Image tab'
      )

      const options = {
        onClick: () => navigate(redirects[category]),
        autoClose: 8000,
        position: 'bottom-right',
        theme: 'colored',
      }
      toast.success(content, options)
    },
    [navigate, t]
  )

  useEffect(() => {
    const getRequest = (id) => {
      let category, el
      for (let key in pending) {
        el = pending[key]
        if (el?.id === id) {
          category = key
          break
        }
      }

      return { category, el }
    }

    if (!lastJsonMessage) return
    // console.log(JSON.stringify(lastJsonMessage))
    // console.log(lastJsonMessage)

    // Update queue
    if (lastJsonMessage.type === 'queue') {
      const { category } = getRequest(lastJsonMessage.data.prompt_id)
      dispatchAction(setQueue({ [category]: lastJsonMessage.data.remaining }))
    }
    if (lastJsonMessage.type === 'execution_start') {
      const { category } = getRequest(lastJsonMessage.data.prompt_id)
      dispatchAction(setQueue({ [category]: 0 }))
    }

    // Image generation done
    if (lastJsonMessage.type === 'executing' && !lastJsonMessage.data.node) {
      const { category, el } = getRequest(lastJsonMessage.data.prompt_id)
      if (!category) return

      if (category === 'upscale') getUpscaledImage(el)
      else dispatchAction(setFinished({ [category]: el }))

      dispatchAction(
        setPending({
          [category]: {},
        })
      )

      // Create a redirect toast
      if (!location.pathname.includes('/image')) showSuccessToast(category)
    }
  }, [
    lastJsonMessage,
    pending,
    dispatchAction,
    getUpscaledImage,
    navigate,
    t,
    showSuccessToast,
    location.pathname,
  ])

  return
}
