import classNames from 'classnames'
import { useTranslate } from '@tolgee/react'

export default function ToolSelector({ activeTool, dispatch }) {
  const { t } = useTranslate()
  const tools = [
    { value: 'modify', label: t('eleo-image-modify-tool-modify', 'Modify') },
    { value: 'inpaint', label: t('eleo-image-modify-tool-inpaint', 'Inpaint') },
    { value: 'background', label: t('eleo-image-modify-tool-bg', 'Background') },
  ]

  return (
    <div className='flex'>
      <div className='box-border w-12 border-b' />
      <div id='tool-selector' className='flex'>
        {tools.map((tool) => (
          <div
            id={tool.value}
            key={tool.value}
            className={classNames(
              'border-1 box-border rounded-t-2xl px-2 py-1 uppercase',
              tool.value === activeTool
                ? 'border border-b-0'
                : 'hover:bg-brand-violet cursor-pointer border-b transition-opacity duration-200 hover:bg-opacity-10'
            )}
            onClick={() =>
              dispatch({
                type: 'SET_TOOL',
                payload: tool.value,
              })
            }
          >
            {tool.label}
          </div>
        ))}
      </div>
      <div className='flex-1 border-b'></div>
    </div>
  )
}
