/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useState, useEffect } from 'react'
import { useAPI } from 'components/lib'

export function usePlans() {
  const [state, setState] = useState({ data: null, loading: false })
  const plans = useAPI('/api/account/plans')

  useEffect(() => {
    setState({ loading: true })

    // format plans
    if (plans?.data?.plans) {
      let formatted = []
      plans.data.plans.map((plan) => {
        let label = `${plan.name} (${plan.currency.symbol}${plan.price}/${plan.interval})`
        return formatted.push({ value: plan.id, label: label })
      })

      let periods = { month: [], year: [] }
      plans.data.plans.map((plan) => {
        let labelMonthly =
          plan.id === 'free'
            ? 'Free'
            : `${plan.name} (${plan.currency.symbol}${plan.pricing.month.price}/month)`
        periods.month.push({ value: plan.pricing.month.id, label: labelMonthly, planId: plan.id })

        let labelYearly =
          plan.id === 'free'
            ? 'Free'
            : `${plan.name} (${plan.currency.symbol}${plan.pricing.year.price}/year)`

        return periods.year.push({
          value: plan.pricing.year.id, // Price id
          label: labelYearly,
          planId: plan.id, // Global plan id
        })
      })

      setState({
        data: {
          list: formatted,
          active: plans.data.active,
          raw: plans.data,
          periods: periods,
        },
        loading: false,
      })
    }
  }, [plans])

  return state
}
