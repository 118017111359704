import { Animate } from 'components/lib'
import WriterLayout from 'toolComponents/writer/write-text/WriterLayout'

export function Writer(props) {
  return (
    <Animate type='pop'>
      <WriterLayout />
    </Animate>
  )
}
