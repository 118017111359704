import { createSlice } from '@reduxjs/toolkit'

export const contextInitialState = {
  chat: [],
  'chat-message': [], // Single message context in chat
  advisor: [],
  ideas: [],
  image: [],
  'image-modify': [],
  translator: [],
  writer: [],
  'writer-modify': [],
  'writer-reply': [],
  'writer-check': [],
}

export const documentContextSlice = createSlice({
  name: 'documentContext',
  initialState: contextInitialState,
  reducers: {
    setContext: (state, action) => {
      state[action.payload.tool] = action.payload.data
    },
  },
})

// Action creators are generated for each case reducer function
export const { setContext } = documentContextSlice.actions

//selectors
export const selectContext = (state) => state.documentContext

export default documentContextSlice.reducer
