import React from 'react'
import { T } from '@tolgee/react'
const TextLength = ({ numWords, characters, limit, className }) => {
  return (
    <>
      <div
        className={`bg-brand-grey-bg pointer-events-none absolute bottom-0 right-3 border-b px-2 py-1 text-right text-[12px] text-gray-500 ${className}`}
      >
        {/* <T keyName="eleo-writer-result-text-length">Text length</T>: */}
        {numWords} <T keyName='eleo-length-option-words'>words</T> / {characters}{' '}
        <T keyName='eleo-length-option-characters'>characters</T>
        {limit && (
          <>
            {' '}
            / <T keyName='eleo-length-option-limit'>words limit</T>: {limit}
          </>
        )}
      </div>
    </>
  )
}

export default TextLength
