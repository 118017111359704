/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from 'react'
import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  Button,
  useNavigate,
  Animate,
  useAPI,
  Event,
  Message,
} from 'components/lib'
import { useLocation } from 'react-router-dom'

import { T } from '@tolgee/react'

export function Profile(props) {
  const navigate = useNavigate()
  const location = useLocation()

  // context
  const authContext = useContext(AuthContext)
  const viewContext = useContext(ViewContext)

  // fetch
  const user = useAPI('/api/user')

  function closeAccount() {
    viewContext.modal.show(
      {
        title: <T keyName='eleo-profile-close-account-header'>Close Your Account</T>,
        form: {},
        buttonText: <T keyName='eleo-profile-close-account-button-text'>Close Account</T>,
        btnClasses: '!px-1 mt-[15px] h-[50px] whitespace-nowrap',
        cancelBtnClasses: 'px-1 mt-[15px]',
        url: authContext.permission.owner ? '/api/account' : '/api/user',
        method: 'DELETE',
        destructive: true,
        text: (
          <T keyName='eleo-profile-close-account-alert-message'>
            Are you sure you want to delete your account? You will lose all of your data and this
            can not be undone.
          </T>
        ),
      },
      () => {
        // destory user
        Event('closed_account')
        localStorage.removeItem('user')
        localStorage.removeItem('registered')
        navigate('/signup')
      }
    )
  }

  return (
    <Fragment>
      {/* <AccountNav /> */}
      <Animate>
        {location.state?.message === 'PAYMENT_REQUIRES_ACTION' && (
          <div className='p-2'>
            <Message type='warning' title={<T keyName='eleo-payment-past-due'>Payment past due</T>}>
              <T keyName='eleo-message-contact-owner'>
                Please contact your account owner to complete the payment
              </T>
            </Message>
          </div>
        )}
        <Card
          title={<T keyName='eleo-profile-edit-your-profile'>Edit Your Profile</T>}
          loading={user.loading}
          restrictWidth
        >
          {user?.data && (
            <Form
              buttonText={<T keyName='eleo-profile-edit-profile-save-button'>Save</T>}
              url='/api/user'
              method='PATCH'
              inputs={{
                name: {
                  label: <T keyName='eleo-profile-your-name'>Your Name</T>,
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: <T keyName='eleo-profile-info-error-1'>Please enter your name</T>,
                },
                email: {
                  label: <T keyName='eleo-profile-email-address'>Email address</T>,
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: (
                    <T keyName='eleo-profile-info-error-2'>Please enter your email address</T>
                  ),
                },
                avatar: {
                  label: <T keyName='eleo-profile-profile-picture'>Profile Picture</T>,
                  type: 'file',
                  required: false,
                  max: 1,
                },
                ...(user.data.permission === 'owner' && {
                  account_name: {
                    type: 'text',
                    label: <T keyName='eleo-profile-account-name'>Account Name</T>,
                    value: user.data.account_name,
                  },
                }),
                ...(user.data.accounts?.length > 1 && {
                  default_account: {
                    label: <T keyName='eleo-profile-default-account'>Default Account</T>,
                    type: 'select',
                    default: user.data.default_account,
                    options: user.data.accounts.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      }
                    }),
                  },
                }),
              }}
              callback={(res) => {
                // update the account name
                if (authContext.user?.accounts?.length > 0) {
                  const accounts = [...authContext.user.accounts]
                  accounts[accounts.findIndex((x) => x.id === authContext.user.account_id)].name =
                    res.data.data.account_name
                  authContext.update({ accounts: accounts })
                }

                // update the user name and avatar
                authContext.update({
                  name: res.data.data?.name,
                  avatar: res.data.data?.avatar || user.data?.avatar,
                })
              }}
            />
          )}

          <Fragment>
            <br />
            <Button
              textOnly
              action={closeAccount}
              text={<T keyName='eleo-profile-close-account-button'>Close Your Account</T>}
            />
          </Fragment>
        </Card>
      </Animate>
    </Fragment>
  )
}
