import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { useWindowDimensions } from 'components/hooks/window'
import { transformReadyMadeTemplatesData, transformSelectOptionValue } from 'helpers'
import Guru from 'toolComponents/guru'
import MoreOptions from 'toolComponents/more-options'
import WriterLengthContainer from 'toolComponents/writer/WriterLengthContainer'
import { Input, ReactSelect } from 'ui'
import { Author } from './advisor-options-data'
import { advisorReadyMadeTemplates } from './advisor-options-data/ready-made-templates'

export default function AdvisorOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  templateOptions,
  optionsData,
  setKeyWords,
  setTopic,
  setAdvisorContext,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  includeSources,
  setIncludeSources,
  allowStandard,
  showUpgradePlanModal,
  setTemplateId,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  contextPlaceHolder,
  topicPlaceHolder,
  handleClearTemplate,
  documentContext,
}) {
  const { height } = useWindowDimensions()
  const { t } = useTranslate()
  const isPanelLarge = usePanelSize('advisor')
  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const readyMadeTemplatesData = transformReadyMadeTemplatesData(advisorReadyMadeTemplates, t)

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  const handleSelectTemplate = (e) => {
    if (!allowStandard) return showUpgradePlanModal()

    setTemplateId(e)
  }

  return (
    visible && (
      <>
        <div
          className={classNames(
            'flex flex-col break-words',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
          style={{ gap: height <= 768 ? '14px' : '16px' }}
        >
          <div className='!w-full'>
            <ContextField documentContext={documentContext} />
          </div>
          <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
            <div
              id='template'
              className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
              data-tooltip-id='advisor-template'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-template'>Template</T>
              </FieldDescription>
              <ReactSelect
                options={templateOptions}
                isClearable
                clearLabel={t('eleo-clear-button-label-none', 'None')}
                customOptions={readyMadeTemplatesData}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                // defaultValue={options[0]}
                value={templateId.value ? templateId : null}
                showDeleteIcon
                deleteAction={(templateId) => handleDeleteTemplate(templateId)}
                setSelectedValue={handleSelectTemplate}
                name='TEMPLATE'
                onClear={handleClearTemplate}
              />
            </div>
            {/* <div
            id='story'
            className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='advisor-myStory'
          >
            <FieldDescription>
              <T keyName='eleo-your-story'>Your story</T>
            </FieldDescription>
            <ReactSelect
              options={storyTemplateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // disabled={!allowStandard}
              // defaultValue={options[0]}
              setSelectedValue={setStoryId}
              value={storyId.value ? storyId : null}
              name='STORY'
              menuAlignment='right'
              action={() => navigate('/account/your-story')}
              actionLabel={t('eleo-add', 'Add')}
              menuMinWidth={200}
            />
          </div> */}
          </div>

          <div
            id='topic'
            className='flex w-full items-center gap-[12px]'
            data-tooltip-id='advisor-topic'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-topic'>Topic</T>
            </FieldDescription>
            <Input
              placeholder={
                topicPlaceHolder.value
                  ? t(topicPlaceHolder.tolgee_id, topicPlaceHolder.value)
                  : t(
                      'eleo-tool-advisor-topic-placeholder',
                      'For Example:  How to improve my time management skills?'
                    )
              }
              className='placeholder-font-regular w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              onChange={setTopic}
              value={optionsData.topic}
            />
          </div>
          <div className='flex w-full items-start gap-[12px]' data-tooltip-id='advisor-context'>
            <FieldDescription>
              <T keyName='eleo-write-text-context'>Context</T>
            </FieldDescription>
            <Input
              placeholder={
                contextPlaceHolder.value
                  ? t(contextPlaceHolder.tolgee_id, contextPlaceHolder.value)
                  : t('eleo-write-text-context-placeholder', 'Provide more details about your text')
              }
              className='placeholder-font-regular min-h-[85px] w-full rounded-[8px] text-[13px] placeholder-[#36363680] min-[1370px]:min-h-[100px]'
              onChange={setAdvisorContext}
              value={optionsData.advisorContext}
              isTextArea
            />
          </div>

          {/* Show advanced button */}
          <div className='flex items-center justify-center text-[14px] '>
            <button
              className={classNames(
                'flex w-auto items-center justify-center rounded-[4px] text-white',
                isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
              )}
              onClick={() => setIsShowAdvanced((prev) => !prev)}
            >
              <div className='ml-[5px]'>
                <MoreOptions isShowAdvanced={isShowAdvanced} />
              </div>
              {isShowAdvanced ? (
                <ChevronLeft className='rotate-90' />
              ) : (
                <ChevronRight className='rotate-90' />
              )}
            </button>
          </div>
          {/* Advanced section */}
          <div
            className={classNames(
              'flex flex-col',
              !isShowAdvanced && 'hidden',
              isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
            )}
            style={{ gap: height <= 768 ? '14px' : '16px' }}
          >
            <div
              id='advanced'
              className={classNames(
                'flex flex-col',
                isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
              )}
              style={{ gap: height <= 768 ? '14px' : '16px' }}
            >
              <div
                className='flex w-full items-center gap-[12px]'
                data-tooltip-id='advisor-keywords'
              >
                <FieldDescription>
                  <T keyName='eleo-write-text-keywords'>keywords</T>
                </FieldDescription>
                <Input
                  placeholder={t(
                    'eleo-write-text-keywords-placeholder',
                    "What's most important in your text?"
                  )}
                  className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
                  value={optionsData.keyWords}
                  onChange={setKeyWords}
                />
              </div>
              <div className='flex w-full items-center gap-[12px]' data-tooltip-id='advisor-length'>
                <FieldDescription>
                  <T keyName='eleo-write-text-length'>Length</T>
                </FieldDescription>
                <WriterLengthContainer
                  handleRadioButtonCheck={handleRadioButtonCheck}
                  selectedWordLength={wordLength}
                  setWriterLengthOption={setWriterLengthOption}
                  writerLengthOption={writerLengthOption}
                  forceMobile={!isPanelLarge}
                />
              </div>
              <div className='flex flex-wrap items-center justify-between gap-[12px] min-[1200px]:flex-nowrap'>
                <div
                  className='flex w-full items-center gap-[10px] min-[1200px]:w-1/2'
                  data-tooltip-id='advisor-author'
                >
                  <FieldDescription>
                    <T keyName='eleo-write-text-author'>Author</T>
                  </FieldDescription>
                  <ReactSelect
                    options={Author}
                    // defaultValue={options[0]}
                    setSelectedValue={handleTopicChange}
                    placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                    value={transformSelectOptionValue(Author, optionsData.state.author, Author[0])}
                    name='AUTHOR'
                    menuPlacement='top'
                  />
                </div>
                <div
                  className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
                  data-tooltip-id='advisor-language'
                >
                  <FieldDescription>
                    <T keyName='eleo-write-text-language'>Language</T>
                  </FieldDescription>
                  <ReactSelect
                    options={languages}
                    setSelectedValue={handleTopicChange}
                    value={transformSelectOptionValue(
                      languages,
                      optionsData.state.language,
                      defaultToolOutPutLanguage
                    )}
                    name='LANGUAGE'
                    menuPlacement='top'
                    menuAlignment='right'
                  />
                </div>
              </div>
            </div>

            <div className='flex !w-full flex-wrap items-center gap-[12px]'>
              {/* <div
                className={classNames(
                  'flex !w-full items-center gap-[12px]',
                  !isPanelLarge && 'justify-between'
                )}
              >
                <FieldDescription>
                  <T keyName='eleo-checkbox-sources'>Sources</T>
                </FieldDescription>
                <Toggle isChecked={includeSources} setIsChecked={setIncludeSources} />
              </div> */}
              <Guru
                tool='advisor-guru'
                className='!w-full'
                place='top'
                setIsGuruModeEnabled={setIsGuruModeEnabled}
                isGuruModeEnabled={isGuruModeEnabled}
              />
            </div>
          </div>
        </div>
      </>
    )
  )
}
