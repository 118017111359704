import { ChevronLeft, ChevronRight, HelpOutline } from '@mui/icons-material'
import { T, useTolgee, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { useWindowDimensions } from 'components/hooks/window'
import { transformBotName, transformSelectOptionValue } from 'helpers'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import {
  SelectChatBotInputValues,
  SelectChatBotListData,
  SelectChatBotListOptions,
  setChatBotInputValues,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import ImageDropZone from 'toolComponents/image/ImageDropZone'
import MoreOptions from 'toolComponents/more-options'
import { ToneInputData } from 'toolComponents/writer/writer-options-data'
import { Input, ReactSelect, Toggle } from 'ui'

const introValueByLang = {
  'fr-FR': 'Bonjour, comment puis-je vous aider?',
  pl: 'Dzień dobry, jak mogę pomóc?',
  es: 'Hola, ¿cómo puedo ayudarle?',
  en: 'Hello, How can I help you?',
}

export default function ChatOptions({
  isShowAdvanced,
  setIsShowAdvanced,
  storyTemplateOptions,
  handleDeleteChatBot,
  handleFileUpload,
}) {
  const { height } = useWindowDimensions()
  const tolgee = useTolgee()
  const pendingLang = tolgee.getPendingLanguage()
  const { t } = useTranslate()
  const isPanelLarge = usePanelSize('chatbot')

  const dispatch = useDispatch()
  const chatBotSettings = useSelector(SelectChatBotInputValues)

  const chatBotListOptions = useSelector(SelectChatBotListOptions)

  const chatBotList = useSelector(SelectChatBotListData)
  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const [baseImage, setBaseImage] = useState(chatBotSettings.avatar)

  const handleDispatchFieldValue = (params) => {
    dispatch(setChatBotInputValues(params))
  }

  const handleSetAvatar = (image) => {
    handleDispatchFieldValue({ field: 'avatar', value: image })
  }

  const handleSelectBot = (data) => {
    const selectedChatBot = chatBotList.find((bot) => bot.id === data.value)

    dispatch(
      setSelectedChatBotValues({
        avatar: selectedChatBot.avatar,
        isGuruEnabled: selectedChatBot.isGuruEnabled,
        language: selectedChatBot.language,
        intro: selectedChatBot.intro,
        monthlyLimit: selectedChatBot.monthlyLimit,
        name: selectedChatBot.name,
        responseLength: selectedChatBot.responseLength,
        storyId: selectedChatBot.storyId,
        tone: selectedChatBot.tone,
        wordsConsumed: selectedChatBot.wordsConsumed,
      })
    )

    dispatch(
      setSelectedChatBotSettings({
        ...selectedChatBot.settings,
      })
    )

    handleDispatchFieldValue({ field: 'selectedChatBot', value: data.value })
  }

  useEffect(() => {
    setBaseImage(chatBotSettings.avatar)
  }, [chatBotSettings.avatar])

  useEffect(() => {
    if (!chatBotSettings.intro.length) {
      handleDispatchFieldValue({
        field: 'intro',
        value: introValueByLang[pendingLang],
      })
    }
  }, [pendingLang])

  const renderMonthlyLimitAlert = useCallback(() => {
    const calculatedValue = Number(chatBotSettings.monthlyLimit) - chatBotSettings.wordsConsumed

    if (chatBotSettings.wordsConsumed > 0 && calculatedValue > 0) {
      return (
        <p className='bg-brand-green rounded-[10px] px-[10px] py-[5px] text-[14px] leading-[1.2em] text-[#ffffff]'>
          {calculatedValue} -{' '}
          <T keyName='eleo-chat-bot-monthly-limit-exceeded-error'>
            words left until the end of the limit
          </T>
        </p>
      )
    }

    if (chatBotSettings.wordsConsumed > 0 && calculatedValue < 0) {
      return (
        <p className='bg-brand-pink rounded-[10px] px-[10px] py-[5px] text-[14px] leading-[1.2em] text-[#ffffff]'>
          <T
            keyName='eleo-chat-bot-monthly-limit-exceeded-error-secondary'
            params={{ calculatedValue: Math.abs(calculatedValue) }}
            defaultValue='monthly word limit exceeded by - {calculatedValue} words'
          ></T>
        </p>
      )
    }
  }, [chatBotSettings])

  return (
    <div
      className={classNames(
        'relative flex flex-col break-words  pb-6 pt-[20px]',
        isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
      )}
      style={{ gap: height <= 768 ? '14px' : '16px' }}
    >
      <div
        id='selector'
        className='flex w-full items-center gap-[10px]'
        data-tooltip-id='chatBotPanel-activeChatBot'
      >
        <FieldDescription>
          <T keyName='eleo-chat-bot-select-label'>Chat Bot</T>
        </FieldDescription>
        <ReactSelect
          options={chatBotListOptions}
          // defaultValue={options[0]}
          setSelectedValue={({ data }) => handleSelectBot(data)}
          placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
          CSSClassNames={{ singleValue: '!lowercase' }}
          value={transformSelectOptionValue(
            chatBotListOptions,
            chatBotSettings.selectedChatBot,
            null
          )}
          showDeleteIcon
          deleteAction={(chatId) => handleDeleteChatBot(chatId)}
          // name="FORMAT"
        />
      </div>
      <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
        <div
          id='name'
          className='flex w-full items-start gap-[12px]'
          data-tooltip-id='chatBotPanel-yourChatBotName'
        >
          <FieldDescription>
            <T keyName='eleo-chat-bot-name-label'>Your Chat Bot Name</T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-chat-bot-name-label', 'Your Chat Bot Name')}
            className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={chatBotSettings.name}
            onChange={(name) =>
              handleDispatchFieldValue({ field: 'name', value: transformBotName(name) })
            }
          />
        </div>
        <div
          id='story'
          className={classNames('flex w-full  items-center gap-[10px]')}
          data-tooltip-id='chatBotPanel-yourStory'
        >
          <FieldDescription>
            <T keyName='eleo-your-story'>Your story</T>
          </FieldDescription>
          <ReactSelect
            options={storyTemplateOptions}
            isClearable
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            setSelectedValue={({ data }) =>
              handleDispatchFieldValue({ field: 'storyId', value: data?.value })
            }
            value={transformSelectOptionValue(storyTemplateOptions, chatBotSettings.storyId, null)}
          />
        </div>
      </div>
      <div
        id='advanced'
        className={classNames(
          'flex flex-col gap-[12px]',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
      >
        <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
          <div
            className='flex w-full items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='chatBotPanel-tone'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <ReactSelect
              options={ToneInputData}
              isClearable
              // defaultValue={options[0]}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              setSelectedValue={({ value }) => handleDispatchFieldValue({ field: 'tone', value })}
              value={transformSelectOptionValue(ToneInputData, chatBotSettings.tone, null)}
              menuPlacement='bottom'
            />
          </div>
          <div
            className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='chatBotPanel-language'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>
            <ReactSelect
              options={languages}
              setSelectedValue={({ value }) => {
                handleDispatchFieldValue({ field: 'language', value })
              }}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(
                languages,
                chatBotSettings.language,
                defaultToolOutPutLanguage
              )}
              menuPlacement='bottom'
            />
          </div>
        </div>
        {/* Show advanced button */}
        <div className='flex items-center justify-center text-[14px] '>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'flex flex-col',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
          style={{ gap: height <= 768 ? '14px' : '16px' }}
        >
          <div className='items-top flex !w-full gap-[12px]' data-tooltip-id='chatBotPanel-intro'>
            <FieldDescription>
              <T keyName='eleo-chat-bot-intro-label'>Intro</T>
            </FieldDescription>
            <Input
              className='placeholder-font-regular min-h-[60px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              value={chatBotSettings.intro}
              onChange={(intro) =>
                handleDispatchFieldValue({
                  field: 'intro',
                  value: intro,
                })
              }
            />
          </div>
          <div className='flex !w-full !flex-wrap items-center gap-[12px]'>
            <div
              className='flex !w-full items-start gap-[12px]'
              data-tooltip-id='chatBotPanel-monthlyLimit'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-max-response-monthly-limit'>Monthly Limit (Max Words)</T>
              </FieldDescription>
              <Input
                placeholder='2000'
                type='number'
                className='placeholder-font-regular w-full min-w-[100px] rounded-[8px] text-[13px] placeholder-[#36363680]'
                value={chatBotSettings.monthlyLimit}
                onChange={(limit) =>
                  handleDispatchFieldValue({ field: 'monthlyLimit', value: limit })
                }
              />
              {renderMonthlyLimitAlert()}
            </div>
            <div
              className='flex w-full items-start gap-[12px] lg:w-1/2'
              data-tooltip-id='chatBotPanel-responseLength'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-max-response-length-limit'>Response Length (Max Words)</T>
              </FieldDescription>
              <Input
                placeholder='20-500'
                type='number'
                className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
                value={chatBotSettings.responseLength}
                onChange={(length) =>
                  handleDispatchFieldValue({ field: 'responseLength', value: length })
                }
              />
            </div>
          </div>

          <div className='items-top flex !w-full gap-[12px]' data-tooltip-id='chatBotPanel-avatar'>
            <FieldDescription>
              <T keyName='eleo-chat-bot-upload-image-avatar-label'>Avatar</T>
            </FieldDescription>
            <ImageDropZone
              baseImage={baseImage}
              setBaseImage={setBaseImage}
              handleFileUpload={(e) => handleFileUpload(e, handleSetAvatar)}
              classes='h-[80%] !w-full px-0 !m-0 text-center min-w-[150px]'
            />
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <p className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-guru-option-title-chat-bot'>Guru (1 word = 2 )</T>
              <div>
                <div data-tooltip-id='guru-tooltip'>
                  <HelpOutline style={{ fontSize: 18, cursor: 'pointer' }} />
                </div>
                <Tooltip place='bottom' id='guru-tooltip' className='!bg-brand-violet  shadow-md'>
                  <ul className='flex w-[150px] flex-col gap-[10px]'>
                    <li className='flex flex-col text-[14px] leading-[1.2]'>
                      <b>
                        <T keyName='eleo-guru-option-chat-bot'>One word is counted as 2 words</T>
                      </b>
                    </li>
                  </ul>
                </Tooltip>
              </div>
            </p>
            <Toggle
              isChecked={chatBotSettings.isGuruEnabled}
              setIsChecked={(isEnabled) =>
                handleDispatchFieldValue({ field: 'isGuruEnabled', value: isEnabled })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
