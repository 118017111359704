import classNames from 'classnames'
import React from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { ResizableContainer } from 'ui'
import { useWindowDimensions } from 'components/hooks/window'

export const ToolLayoutPrimary = ({
  folded,
  setFolded,
  leftSideContent,
  rightSideContent,
  children,
  storageKey,
  maxPanelWidth = 750,
}) => {
  const { width } = useWindowDimensions()
  const layoutLarge = width >= 1024
  const panelMaxWidth = Math.min(maxPanelWidth, Math.floor(width * 0.5))

  return (
    <div className='relative h-full'>
      <div className='relative flex h-full flex-wrap lg:flex-nowrap'>
        {layoutLarge ? (
          <ResizableContainer
            isOpen={!folded}
            setIsOpen={(val) => setFolded(!val)}
            storageKey={storageKey}
            minWidth={320}
            initWidth={600}
            maxWidth={panelMaxWidth}
          >
            {leftSideContent}
          </ResizableContainer>
        ) : (
          <div
            className={classNames('w-full', {
              'absolute hidden': folded,
            })}
          >
            {leftSideContent}
          </div>
        )}
        <div className={classNames('relative overflow-y-auto', layoutLarge ? 'flex-1' : 'w-full')}>
          {/* Fold button */}
          {!layoutLarge && (
            <div
              className={classNames(
                'fold absolute -top-[15px] left-1/2 z-[10] flex h-[50px] w-[16px]  rotate-90 cursor-pointer items-center justify-center rounded-[4px] lg:left-0 lg:top-[50%] lg:rotate-0',
                {
                  'bg-brand-green': !folded,
                  'bg-[#36363680]': folded,
                }
              )}
              onClick={() => setFolded((prev) => !prev)}
            >
              {folded ? (
                <ChevronRight size={22} style={{ color: '#ffffff' }} weight='bold' />
              ) : (
                <ChevronLeft size={22} style={{ color: '#ffffff' }} weight='bold' />
              )}
            </div>
          )}
          {rightSideContent}
        </div>
        {children}
      </div>
    </div>
  )
}
