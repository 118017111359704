/***
 *
 *   PASSWORD
 *   Update the users password
 *
 *   if the user has a password show old and new inputs
 *   if no existing password (eg. in case of social signin)
 *   then allow the user to set a password on their account
 *
 **********/

import { T } from '@tolgee/react'
import { Animate, AuthContext, Card, Form, Message } from 'components/lib'
import { Fragment, useContext, useState } from 'react'

export function Password(props) {
  const context = useContext(AuthContext)
  const [done, setDone] = useState(false)

  return (
    <Fragment>
      {/* <AccountNav /> */}
      <Animate>
        <Card
          title={<T keyName='eleo-profile-update-your-password'>Update Your Password</T>}
          restrictWidth
          className={props.className}
        >
          {!done ? (
            <Form
              url='/api/user/password'
              method='PUT'
              buttonText={<T keyName='eleo-profile-save-password'>Save Password</T>}
              inputs={{
                ...(context.user.has_password && {
                  oldpassword: {
                    label: <T keyName='eleo-profile-old-password'>Old Password</T>,
                    type: 'password',
                    required: true,
                  },
                  has_password: {
                    type: 'hidden',
                    value: true,
                  },
                }),
                newpassword: {
                  label: <T keyName='eleo-profile-create-new-password'>Create New Password</T>,
                  type: 'password',
                  required: true,
                  complexPassword: true,
                },
              }}
              callback={() => {
                setDone(true)
                context.update({ has_password: true })
              }}
            />
          ) : (
            <Message
              title={<T keyName='eleo-profile-save-password-success-text-header'>Password Saved</T>}
              type='success'
              text={
                <T keyName='eleo-profile-save-password-success-text-description'>
                  Your new password has been saved successfully
                </T>
              }
            />
          )}
        </Card>
      </Animate>
    </Fragment>
  )
}
