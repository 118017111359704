import axios from 'axios'

export class AdvisorAPIService {
  static generateRequest(data, segment, method = 'POST', signal) {
    if (method === 'POST') {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
        ...signal,
      })
    } else {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })
    }
  }

  static async generateAdvice(writerOptions, signal) {
    const data = {
      tool: 'advice',
      writerOptions,
      kwargs: { stream: true, temperature: 0.2 },
    }

    return this.generateRequest(data, '/ai/advisor', 'POST', signal)
  }

  static async saveHistory(data) {
    return this.generateRequest(data, '/advisor/save-history')
  }

  static async deleteHistory(data) {
    return this.generateRequest(data, '/advisor/delete-history')
  }

  static async clearHistory(data) {
    return this.generateRequest(data, '/advisor/delete-histories')
  }

  static async getHistories() {
    return this.generateRequest({}, '/advisor/histories', 'GET')
  }

  static async getTemplates() {
    return this.generateRequest({}, '/advisor/templates', 'GET')
  }

  static async saveTemplate(data) {
    return this.generateRequest(data, '/advisor/save-template')
  }

  static async updateTemplate(data) {
    return this.generateRequest(data, '/advisor/update-template')
  }

  static async deleteTemplate(data) {
    return this.generateRequest(data, '/advisor/delete-template')
  }
}
