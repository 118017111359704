import { T } from '@tolgee/react'
import { CircleNotch } from 'phosphor-react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectChatBotInputValues,
  SelectGenerateChatAsyncStates,
  setClearFields,
} from 'store/account/chatBot'
import { Button } from 'ui'

const ChatActions = ({ handleGenerateChatBot }) => {
  const asyncStates = useSelector(SelectGenerateChatAsyncStates)
  const chatBotSettings = useSelector(SelectChatBotInputValues)
  const dispatch = useDispatch()
  return (
    <>
      <Button
        color='gray'
        className='h-[45px]'
        onClick={() => dispatch(setClearFields())}
        data-tooltip-id='chatBotPanel-clear'
      >
        <p className='text-[15px] font-medium uppercase leading-none'>
          <T keyName='eleo-history-action-clear'>clear</T>
        </p>
      </Button>
      <Button
        id='submit'
        className='h-[45px] w-full'
        color='dark'
        onClick={() => handleGenerateChatBot()}
        data-tooltip-id='chatBotPanel-generateChatBot'
        disabled={asyncStates.loading}
      >
        {asyncStates.loading ? (
          <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
        ) : (
          <p className='text-[15px] font-medium uppercase leading-none'>
            {chatBotSettings.selectedChatBot ? (
              <T keyName='eleo-update-chat-bot'>update chat bot</T>
            ) : (
              <T keyName='eleo-generate-chat-bot'>generate chat bot</T>
            )}
          </p>
        )}
      </Button>
    </>
  )
}

export default ChatActions
