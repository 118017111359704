import React from 'react'
import { T } from '@tolgee/react'
import { Toggle } from 'ui'
const ChatStatusSwitcher = ({ handleDispatchChatSettings, initialValue, field }) => {
  return (
    <div className='flex items-center gap-[10px]'>
      <p className='text-brand-body break-words text-[13px] font-medium capitalize  leading-[1.2]'>
        <T keyName='eleo-chat-bot-chat-status-toggle-label-active'>active</T>
        /
        <br />
        <T keyName='eleo-chat-bot-chat-status-toggle-label-inactive'>inactive</T>
      </p>
      <Toggle
        isChecked={initialValue}
        setIsChecked={(isEnabled) => handleDispatchChatSettings(isEnabled)}
      />
    </div>
  )
}

export default ChatStatusSwitcher
