import { useTranslate } from '@tolgee/react'

export const useLanguages = () => {
  const { t } = useTranslate()
  // const tolgee = useTolgee(['pendingLanguage'])
  // const pendingLang = tolgee.getPendingLanguage()
  // const languageName = appSupportedLanguages.find(
  //   (lang) =,' lang?.value === pendingLang
  // )

  // const selectedLang = languageName.rawValue

  const languages = [
    {
      label: t('eleo-language-auto', 'Auto'),
      value: 'auto',
      rawValue: 'auto',
      lookup:
        'automatic,language: auto,Język: automatyczny,Idioma: Automático,Langue: Automatique,Lingua: automatica,Dil: Otomatik,Sprache: Automatisch',
    },
    {
      label: t('eleo-language-afrikaans', 'Afrikaans'),
      value: 'Afrikaans',
      rawValue: 'Afrikaans',
      lookup: 'Afrikaans,afrikaans,afrikáans,afrikaans,Afrikaans,afrikaans,Afrikaans,africâner',
      code: 'af',
    },
    {
      label: t('eleo-language-arabic', 'Arabic'),
      value: 'Arabic',
      rawValue: 'Arabic',
      lookup: 'Arabic,arabski,árabe,arabe,Arabisch,arabo,Arapça,árabe',
      code: 'ar',
    },
    {
      label: t('eleo-language-bosnian', 'Bosnian'),
      value: 'Bosnian',
      rawValue: 'Bosnian',
      lookup: 'Bosnian,bośniacki,bosnio,bosniaque,Bosnisch,bosniaco,Boşnakça,bósnio',
      code: 'bs',
    },
    {
      label: t('eleo-language-bulgarian', 'Bulgarian'),
      value: 'Bulgarian',
      rawValue: 'Bulgarian',
      lookup: 'Bulgarian,bułgarski,búlgaro,bulgare,Bulgarisch,bulgaro,Bulgarca,búlgaro',
      code: 'bg',
    },
    {
      label: t('eleo-language-burmese', 'Burmese'),
      value: 'Burmese',
      rawValue: 'Burmese',
      lookup: 'Burmese,birmański,birmano,birman,Burmesisch,birmano,Burma dili,birmanês',
      code: 'my',
    },
    {
      label: t('eleo-language-chinese', 'Chinese'),
      value: 'Chinese',
      rawValue: 'Chinese',
      lookup: 'Chinese,chiński,chino,chinois,Chinesisch,cinese,Çince,chinês',
      code: 'zh',
    },
    {
      label: t('eleo-language-croatian', 'Croatian'),
      value: 'Croatian',
      rawValue: 'Croatian',
      lookup: 'Croatian,chorwacki,croata,croate,Kroatisch,croato,Hırvatça,croata',
      code: 'hr',
    },
    {
      label: t('eleo-language-czech', 'Czech'),
      value: 'Czech',
      rawValue: 'Czech',
      lookup: 'Czech,czeski,checo,tchèque,Tschechisch,ceco,Çekçe,tcheco',
      code: 'cs',
    },
    {
      label: t('eleo-language-danish', 'Danish'),
      value: 'Danish',
      rawValue: 'Danish',
      lookup: 'Danish,duński,danés,danois,Dänisch,danese,Danca,dinamarquês',
      code: 'da',
    },
    {
      label: t('eleo-language-dutch', 'Dutch'),
      value: 'Dutch',
      rawValue: 'Dutch',
      lookup:
        'Dutch,niderlandzki,neerlandés,néerlandais,Niederländisch,olandese,Hollandaca,holandês',
      code: 'nl',
    },
    {
      label: t('eleo-language-english', 'English'),
      value: 'English',
      rawValue: 'English',
      lookup: 'English,angielski,inglés,anglais,Englisch,inglese,İngilizce,inglês',
      code: 'en',
    },
    {
      label: t('eleo-language-estonian', 'Estonian'),
      value: 'Estonian',
      rawValue: 'Estonian',
      lookup: 'Estonian,estoński,estonio,estonien,Estnisch,estone,Estonyaca,estoniano',
      code: 'et',
    },
    {
      label: t('eleo-language-farsi', 'Farsi'),
      value: 'Farsi',
      rawValue: 'Farsi',
      lookup: 'Farsi,perski,persa,persan,Persisch,persiano,Farsça,persa',
      code: 'fo',
    },
    {
      label: t('eleo-language-finnish', 'Finnish'),
      value: 'Finnish',
      rawValue: 'Finnish',
      lookup: 'Finnish,fiński,finlandés,finnois,Finnisch,finlandese,Fince,finlandês',
      code: 'fi',
    },
    {
      label: t('eleo-language-french', 'French'),
      value: 'French',
      rawValue: 'French',
      lookup: 'French,francuski,francés,français,Französisch,francese,Fransızca,francês',
      code: 'fr',
    },
    {
      label: t('eleo-language-german', 'German'),
      value: 'German',
      rawValue: 'German',
      lookup: 'German,niemiecki,alemán,allemand,Deutsch,tedesco,Almanca,alemão',
      code: 'de',
    },
    {
      label: t('eleo-language-greek', 'Greek'),
      value: 'Greek',
      rawValue: 'Greek',
      lookup: 'Greek,grecki,griego,grec,Griechisch,greco,Yunanca,grego',
      code: 'el',
    },
    {
      label: t('eleo-language-hebrew', 'Hebrew'),
      value: 'Hebrew',
      rawValue: 'Hebrew',
      lookup: 'Hebrew,hebrajski,hebreo,hébreu,Hebräisch,ebraico,İbranice,hebraico',
      code: 'he',
    },
    {
      label: t('eleo-language-hindi', 'Hindi'),
      value: 'Hindi',
      rawValue: 'Hindi',
      lookup: 'Hindi,hindi,hindi,hindi,Hindi,hindi,Hintçe,hindi',
      code: 'hi',
    },
    {
      label: t('eleo-language-hungarian', 'Hungarian'),
      value: 'Hungarian',
      rawValue: 'Hungarian',
      lookup: 'Hungarian,węgierski,húngaro,hongrois,Ungarisch,ungherese,Macarca,húngaro',
      code: 'hu',
    },
    {
      label: t('eleo-language-icelandic', 'Icelandic'),
      value: 'Icelandic',
      rawValue: 'Icelandic',
      lookup: 'Icelandic,islandzki,islandés,islandais,Isländisch,islandese,İzlandaca,islandês',
      code: 'is',
    },
    {
      label: t('eleo-language-indonesian', 'Indonesian'),
      value: 'Indonesian',
      rawValue: 'Indonesian',
      lookup:
        'Indonesian,indonezyjski,indonesio,indonésien,Indonesisch,indonesiano,Endonezce,indonésio',
      code: 'id',
    },
    {
      label: t('eleo-language-italian', 'Italian'),
      value: 'Italian',
      rawValue: 'Italian',
      lookup: 'Italian,włoski,italiano,italien,Italienisch,italiano,İtalyanca,italiano',
      code: 'it',
    },
    {
      label: t('eleo-language-japanese', 'Japanese'),
      value: 'Japanese',
      rawValue: 'Japanese',
      lookup: 'Japanese,japoński,japonés,japonais,Japanisch,giapponese,Japonca,japonês',
      code: 'ja',
    },
    {
      label: t('eleo-language-korean', 'Korean'),
      value: 'Korean',
      rawValue: 'Korean',
      lookup: 'Korean,koreański,coreano,coréen,Koreanisch,coreano,Korece,coreano',
      code: 'ko',
    },
    {
      label: t('eleo-language-latvian', 'Latvian'),
      value: 'Latvian',
      rawValue: 'Latvian',
      lookup: 'Latvian,łotewski,letón,letton,Lettisch,lettone,Letonca,letão',
      code: 'lv',
    },
    {
      label: t('eleo-language-lithuanian', 'Lithuanian'),
      value: 'Lithuanian',
      rawValue: 'Lithuanian',
      lookup: 'Lithuanian,litewski,lituano,lituanien,Litauisch,lituano,Litvanca,lituano',
      code: 'lt',
    },
    {
      label: t('eleo-language-malay', 'Malay'),
      value: 'Malay',
      rawValue: 'Malay',
      lookup: 'Malay,malajski,malayo,malais,Malaiisch,malese,Malayca,malaio',
      code: 'ms',
    },
    {
      label: t('eleo-language-norwegian', 'Norwegian'),
      value: 'Norwegian',
      rawValue: 'Norwegian',
      lookup: 'Norwegian,norweski,noruego,norvégien,Norwegisch,norvegese,Norveççe,norueguês',
      code: 'no',
    },
    {
      label: t('eleo-language-polish', 'Polish'),
      value: 'Polish',
      rawValue: 'Polish',
      lookup: 'polish,polski,polaco,polonais,polnisch,polacco,Lehçe,polonês',
      code: 'pl',
    },
    {
      label: t('eleo-language-portuguese', 'Portuguese'),
      value: 'Portuguese',
      rawValue: 'Portuguese',
      lookup:
        'Portuguese,portugalski,portugués,portugais,Portugiesisch,portoghese,Portekizce,português',
      code: 'pt',
    },
    {
      label: t('eleo-language-punjabi', 'Punjabi'),
      value: 'Punjabi',
      rawValue: 'Punjabi',
      lookup: 'Punjabi,pendżabski,punyabí,pendjabi,Punjabi,punjabi,Pencapça,punjabi',
      code: 'pa',
    },
    {
      label: t('eleo-language-romanian', 'Romanian'),
      value: 'Romanian',
      rawValue: 'Romanian',
      lookup: 'Romanian,rumuński,rumano,roumain,Rumänisch,rumeno,Romence,romeno',
      code: 'ro',
    },
    {
      label: t('eleo-language-russian', 'Russian'),
      value: 'Russian',
      rawValue: 'Russian',
      lookup: 'Russian,rosyjski,ruso,russe,Russisch,russo,Rusça,russo',
      code: 'ru',
    },
    {
      label: t('eleo-language-serbian', 'Serbian'),
      value: 'Serbian',
      rawValue: 'Serbian',
      lookup: 'Serbian,serbski,serbio,serbe,serbisch,serbo,Sırpça,sérvio',
      code: 'sr',
    },
    {
      label: t('eleo-language-slovak', 'Slovak'),
      value: 'Slovak',
      rawValue: 'Slovak',
      lookup: 'Slovak,słowacki,eslovaco,slovaque,slowakisch,slovacco,Slovakça,eslovaco',
      code: 'sk',
    },
    {
      label: t('eleo-language-slovenian', 'Slovenian'),
      value: 'Slovenian',
      rawValue: 'Slovenian',
      lookup: 'Slovenian,słoweński,esloveno,slovène,slowenisch,sloveno,Slovence,esloveno',
      code: 'sl',
    },
    {
      label: t('eleo-language-spanish', 'Spanish'),
      value: 'Spanish',
      rawValue: 'Spanish',
      lookup: 'Spanish,hiszpański,español,espagnol,Spanisch,spagnolo,İspanyolca,espanhol',
      code: 'es',
    },
    {
      label: t('eleo-language-swedish', 'Swedish'),
      value: 'Swedish',
      rawValue: 'Swedish',
      lookup: 'Swedish,szwedzki,sueco,suédois,schwedisch,svedese,İsveççe,sueco',
      code: 'sv',
    },
    {
      label: t('eleo-language-tagalog', 'Tagalog'),
      value: 'Tagalog',
      rawValue: 'Tagalog',
      lookup: 'Tagalog,tagalski,tagalo,tagalog,Tagalog,tagalog,Tagalogca,tagalo',
      code: 'tl',
    },
    {
      label: t('eleo-language-tamil', 'Tamil'),
      value: 'Tamil',
      rawValue: 'Tamil',
      lookup: 'Tamil,tamilski,tamil,tamil,Tamilisch,Tamil,Tamilce,tâmil',
      code: 'ta',
    },
    {
      label: t('eleo-language-thai', 'Thai'),
      value: 'Thai',
      rawValue: 'Thai',
      lookup: 'Thai,tajski,tailandés,thaï,Thailändisch,thailandese,Tayca,tailandês',
      code: 'th',
    },
    {
      label: t('eleo-language-tswana', 'Tswana'),
      value: 'Tswana',
      rawValue: 'Tswana',
      lookup: 'Tswana,tswana,tswana,tswana,setsuana,tswana,Tswana,tswana',
      code: 'tn',
    },
    {
      label: t('eleo-language-turkish', 'Turkish'),
      value: 'Turkish',
      rawValue: 'Turkish',
      lookup: 'Turkish,turecki,turco,turc,Türkisch,turco,Türkçe,turco',
      code: 'tr',
    },
    {
      label: t('eleo-language-ukrainian', 'Ukrainian'),
      value: 'Ukrainian',
      rawValue: 'Ukrainian',
      lookup: 'Ukrainian,ukraiński,ucraniano,ukrainien,Ukrainisch,ucraino,Ukraynaca,ucraniano',
      code: 'uk',
    },
    {
      label: t('eleo-language-urdu', 'Urdu'),
      value: 'Urdu',
      rawValue: 'Urdu',
      lookup: 'Urdu,urdu,urdu,ourdou,Urdu,urdu,Urduca,urdu',
      code: 'ur',
    },
    {
      label: t('eleo-language-vietnamese', 'Vietnamese'),
      value: 'Vietnamese',
      rawValue: 'Vietnamese',
      lookup:
        'Vietnamese,wietnamski,vietnamita,vietnamien,Vietnamesisch,vietnamita,Vietnam Dili,vietnamita.',
      code: 'vi',
    },
    {
      label: t('eleo-language-welsh', 'Welsh'),
      value: 'Welsh',
      rawValue: 'Welsh',
      lookup: 'Welsh,walijski,galés,gallois,Walisisch,gallese,Galce,galês',
      code: 'cy',
    },
  ]

  const defaultToolOutPutLanguage = languages.find((lang) => lang?.rawValue === 'auto')
  const sortedLanguages = languages.sort((a, b) => {
    if (a.value === 'auto') return -1
    if (b.value === 'auto') return 1
    return a.label.localeCompare(b.label)
  })

  return { defaultToolOutPutLanguage, languages: sortedLanguages }
}
