import { T } from '@tolgee/react'
import classNames from 'classnames'
import { CircleNotch } from 'phosphor-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'ui'
const WriterHistoryDeleteAlertContent = ({
  isBulkDelete = true,
  checkedHistory,
  items,
  setModalIsVisible,
  setReloadHistoryData,
  setCheckedHistory,
  clearHistoryEndpoint,
  deleteSelectedHistoryEndpoint,
}) => {
  const [loading, setLoading] = useState(false)

  const handleDeleteHistoryItems = async () => {
    const data = {
      items: items,
    }
    try {
      setLoading(true)
      const response = await deleteSelectedHistoryEndpoint(data)

      const responseJSON = await response.json()
      if (responseJSON.success) {
        toast.success(
          <T keyName={`eleo-success-${responseJSON.message}`}>{responseJSON.message}</T>
        )
        setModalIsVisible({ content: '', visible: false })
        setCheckedHistory({ radioBoxName: '', checked: false })
        setReloadHistoryData(Date.now())
      } else {
        toast.error(<T keyName={`eleo-error-${responseJSON.message}`}>{responseJSON.message}</T>)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleClearHistory = async () => {
    try {
      setLoading(true)
      const response = await clearHistoryEndpoint({})
      const responseJSON = await response.json()
      if (responseJSON.success) {
        toast.success(
          <T keyName={`eleo-success-${responseJSON.message}`}>{responseJSON.message}</T>
        )
        setModalIsVisible({ content: '', visible: false })
        setCheckedHistory({ radioBoxName: '', checked: false })
        setReloadHistoryData(Date.now())
      } else {
        toast.error(<T keyName={`eleo-error-${responseJSON.message}`}>{responseJSON.message}</T>)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {isBulkDelete ? (
        <>
          {' '}
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-history-confirmation-header'>
              Are you sure you want to clear your history?
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-history-confirmation-title'>This action is irreversible.</T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': loading,
              }
            )}
            onClick={handleClearHistory}
          >
            <div className='flex items-center gap-[5px]'>
              {loading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-clear-history-modal-title'>clear history</T>
              </p>
            </div>
          </Button>
        </>
      ) : (
        <>
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-selected-history-confirmation-header'>
              Are you sure you want to delete selected history?{' '}
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-selected-history-confirmation-title'>
                This action is irreversible.
              </T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': loading,
              }
            )}
            onClick={handleDeleteHistoryItems}
          >
            <div className='flex items-center gap-[5px]'>
              {loading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-confirm-delete-selected-history'>delete</T>
              </p>
            </div>
          </Button>
        </>
      )}
    </>
  )
}

export default WriterHistoryDeleteAlertContent
