import { T } from '@tolgee/react'

export default function Content(props) {
  return (
    <div className='bg-white pb-24 pt-5 sm:pb-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl sm:text-center'>
          <h2 className='text-brand-violet text-base font-semibold leading-7'>{props.subtitle}</h2>
          <p className='text-brand-text-primary mt-2 text-3xl font-bold tracking-tight sm:text-4xl'>
            {props.title}
          </p>
          <p className='text-brand-text-secondary mt-6 text-lg leading-8'>{props.description}</p>
        </div>
      </div>
      <div className='relative overflow-hidden pt-16'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          {props.img && (
            <img
              src={props.img}
              alt='Chat screenshot'
              className='mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10'
              width={1216}
              height={821}
            />
          )}
          <div className='relative' aria-hidden='true'>
            <div className='absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]' />
          </div>
        </div>
      </div>
      <div className='mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8'>
        <dl className='text-brand-text-secondary mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16'>
          {props.features.map((feature) => (
            <div key={feature.name} className='relative pl-9'>
              <dt className='text-brand-text-primary inline font-semibold'>
                {feature.icon && (
                  <feature.icon
                    className='text-brand-violet absolute left-1 top-1 h-5 w-5'
                    aria-hidden='true'
                  />
                )}
                <T keyName={`${feature.id}-name`}>{feature.name}</T>
              </dt>{' '}
              <dd className='inline'>
                <T keyName={`${feature.id}-description`}>{feature.description}</T>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
