/***
 *
 *   Features/chatbot Page
 *
 **********/

import SpeedIcon from '@mui/icons-material/Speed'
import ChildCareIcon from '@mui/icons-material/ChildCare'
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus'
import ShareIcon from '@mui/icons-material/Share'
import CodeIcon from '@mui/icons-material/Code'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'

import { T, useTranslate } from '@tolgee/react'

import image from 'images/features/chatbot.png'

export function Chatbot() {
  const { t } = useTranslate()

  const featureId = 'chatbot'
  const translations = {
    header: {
      title: `Chatbot`,
      subtitle: `Your Own Chatbot`,
      description: `Dreamt of an assistant that works while you relax? With Eleo, you can make a Chatbot in just a few minutes, and it'll chat for you 24/7.`,
      link: {
        href: '/account/chat-bot',
        text: t('eleo-try-now', 'Try now!'),
      },
    },
    content: {
      title: `Why Embrace a Chatbot with Eleo?`,
      subtitle: ``,
      description: `With Eleo, creating a Chatbot is not only easy but incredibly efficient. In mere minutes, you can have a Chatbot ready to assist your customers around the clock, in 50 different languages. You can use it at work or for anything you like. Need a Chatbot on a website, in emails, or on social media? Want one for special occasions like birthdays, weddings, or holidays? Eleo makes it super easy.
      `,
      features: [
        {
          name: `Quick setup.`,
          description: `Get your Chatbot up and running in moments, perfect for any occasion.`,
          icon: SpeedIcon,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `User-friendly.`,
          description: `No tech expertise? No problem. Launching a Chatbot is straightforward and hassle-free.`,
          icon: ChildCareIcon,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `Multiple chatbots.`,
          description: `Tailor multiple Chatbots to fit your personal and business needs.`,
          icon: Filter9PlusIcon,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `Wide reach.`,
          description: `Your Chatbot can live on the Eleo site, with links easily shared across social media, emails, or printed materials.`,
          icon: ShareIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Easy installation.`,
          description: `Embed your Chatbot on your website with just a few simple code lines.`,
          icon: CodeIcon,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `Personalized knowledge.`,
          description: `Your Chatbot stays up-to-date with your latest information, drawing from 'Your Story' - a feature you can easily edit and customize anytime.`,
          icon: ManageAccountsIcon,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        description={t(
          `eleo-features-${featureId}-header-description`,
          translations.header.description
        )}
        videoLink='https://www.youtube.com/embed/TasSQIy7LgY'
        link={translations.header.link}

        // description={t(`eleo-features-${featureId}-header-description`, translations.header.description)}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <div className='mx-auto max-w-4xl px-6 pb-24 sm:text-center lg:px-8'>
        <p className='text-brand-text-secondary text-lg leading-8'>
          <T keyName={`eleo-features-${featureId}-cta-text`}>
            Register, and unlock the power to create your own Chatbots. Dive into a world where your
            company, products, and promotions are represented 24/7. Embrace Eleo's innovative edge
            and elevate your marketing campaigns with custom-made chatbots, ready in just a few
            minutes.
          </T>
        </p>
      </div>

      <UseEleoToday next='chat-bot' />
    </div>
  )
}
