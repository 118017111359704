import { createSlice } from '@reduxjs/toolkit'

export const modifyTextInitialState = {
  history: {
    myText: '',
    whatToDo: '',
    keyWords: '',
    language: '',
    wordLength: { id: '500', checked: true },
    writerLengthOption: 'words',
    templateId: { label: '', value: '' },
    isOptimizedForSeoEnabled: false,
    isGuruModeEnabled: false,
    creativity: 'Medium',
    selectOptionValues: {
      language: '',
      style: '',
      tone: '',
      author: '',
      action: '',
    },
    showAdvanced: false,
    storyId: { label: '', value: '' },
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const modifyTextSlice = createSlice({
  name: 'modifyText',
  initialState: modifyTextInitialState,
  reducers: {
    setModifyTextInputHistory: (state, action) => {
      state.history = action.payload
    },
    setModifyTextGeneratedResultHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setModifyTextInputHistory, setModifyTextGeneratedResultHistory } =
  modifyTextSlice.actions

//selectors
export const modifyTextInputHistory = (state) => state.modifyText.history
export const modifyTextGeneratedResultHistory = (state) =>
  state.modifyText.generatedTextHistory.generatedText

export default modifyTextSlice.reducer
