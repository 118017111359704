import { ArrowBack, ArrowForward, Close, Refresh } from '@mui/icons-material'
import { T } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { HelpTooltips } from 'components/help/helpTooltips'
import { Animate, SocialShare, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { LANDING_PAGE_URL } from 'helpers/image'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectClientId,
  selectFinished,
  selectPending,
  selectQueue,
  setFinished,
  setPending,
  setQueue,
} from 'store/generations'
import {
  selectImageHolderHistoryValues,
  setImageHolderHistoryValues,
} from 'store/toolInputHistoriesHolder/modify-image'
import ImageDisplay from 'toolComponents/image/output/ImageDisplay'
import ImageModifyForm from 'toolComponents/image/ImageModifyForm'
import { Button } from 'ui'
import { v4 as uuidv4 } from 'uuid'

// Tour steps
const steps = [
  {
    target: '#upload',
    content: (
      <T keyName='eleo-tour-image-modify-1'>
        Upload the image you want to modify. You can upload an image from your disk or from My
        gallery.
      </T>
    ),
  },
  {
    target: '#keywords',
    content: (
      <T keyName='eleo-tour-image-modify-2'>
        Describe how you want to modify the image. Provide keywords that describe your modification.
      </T>
    ),
  },
  {
    target: '#strength',
    content: (
      <T keyName='eleo-tour-image-modify-3'>
        Set the strength of the changes. The higher the strength, the greater the changes to the
        image.
      </T>
    ),
  },
  {
    target: '#inpaint',
    content: (
      <T keyName='eleo-tour-image-modify-4'>
        Use Inpaint to change a fragment. Select the part of the image you want to change and
        describe what you want to modify.
      </T>
    ),
  },
  {
    target: '#background',
    content: (
      <T keyName='eleo-tour-image-modify-5'>
        Change the background. Describe how the image should look after changing the background.
      </T>
    ),
  },
]

export function ImageModify(props) {
  const imgForm = useRef()
  const context = useContext(ViewContext)
  const pendingGeneration = useSelector(selectPending).modify
  const finishedGeneration = useSelector(selectFinished).modify
  const clientId = useSelector(selectClientId)
  const queueSize = useSelector(selectQueue).modify

  const dispatchAction = useDispatch()
  const [abortController, setAbortController] = useState()
  const imageHolderHistoryValues = useSelector(selectImageHolderHistoryValues)

  // Sync these with redux to enable off-screen generation (additional logic required)
  const [isLoading, setIsLoading] = useState(imageHolderHistoryValues.isLoading)
  const [isImageInQueue, setIsImageInQueue] = useState(false)
  // const [pendingGeneration, setPendingGeneration] = useState({})

  const [baseImage, setBaseImage] = useState(imageHolderHistoryValues.baseImage)
  const [history, setHistory] = useState(imageHolderHistoryValues.history)
  const [currentIndex, setCurrentIndex] = useState(imageHolderHistoryValues.currentIndex)
  const [activeImage, setActiveImage] = useState(imageHolderHistoryValues.activeImage)
  const [showPreview, setShowPreview] = useState(imageHolderHistoryValues.showPreview)

  // Image creation data (form inputs)
  const [imageData, setImageData] = useState(imageHolderHistoryValues.imageData)

  // Prevent setting default values before reading store
  const [loadState, setLoadState] = useState({ index: false, history: false })
  useEffect(() => {
    if (!loadState.index) {
      setLoadState((prev) => ({ ...prev, index: true }))
      return
    }
    // if (currentIndex < 0) {
    //   setActiveImage(null)
    //   return
    // }
    setActiveImage({
      image: history[currentIndex]?.images[0],
      data: history[currentIndex]?.data,
    })
  }, [currentIndex])

  useEffect(() => {
    if (!loadState.history) {
      setLoadState((prev) => ({ ...prev, history: true }))
      return
    }
    setCurrentIndex(history.length - 1)
  }, [history])

  const imageHolders = []
  for (let i = 0; i < 4; i++) {
    const baseStyles = classNames('flex justify-center rounded-lg', {
      'aspect-1': !history[currentIndex]?.images[i],
    })
    const holderClassName = classNames(
      baseStyles,
      history[currentIndex]?.images[i]
        ? 'w-full hover:scale-105 transition-transform duration-300 hover:cursor-pointer object-scale-down'
        : 'border border-dashed shadow-md',
      history[currentIndex]?.images[i] && activeImage?.image === history[currentIndex]?.images[i]
        ? 'ring-2 ring-offset-2 ring-brand-violet'
        : ''
    )
    imageHolders.push(
      <div
        key={i}
        className={classNames('flex w-1/4 justify-center', {
          'aspect-1': !history[currentIndex]?.images[i],
        })}
      >
        <img
          className={holderClassName}
          src={history[currentIndex]?.images[i] ?? ''}
          alt=''
          onClick={() => {
            if (history[currentIndex]?.images[i])
              setActiveImage(
                {
                  image: history[currentIndex]?.images[i],
                  data: history[currentIndex]?.data,
                } ?? ''
              )
          }}
        />
      </div>
    )
  }

  const [isRunTour, setIsRunTour] = useState(false)
  useEffect(() => {
    const tourCompleted = localStorage.getItem('tour-image-modify')
    if (tourCompleted) return

    const timeout = setTimeout(() => {
      setIsRunTour(true)
      localStorage.setItem('tour-image-modify', 'true')
    }, 500)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    dispatchAction(
      setImageHolderHistoryValues({
        baseImage,
        activeImage,
        showPreview,
        imageData,
        history,
        currentIndex,
        pendingGeneration,
        isLoading,
        isImageInQueue,
      })
    )
  }, [
    baseImage,
    activeImage,
    showPreview,
    imageData,
    history,
    pendingGeneration,
    isLoading,
    isImageInQueue,
    currentIndex,
    dispatchAction,
  ])

  async function requestGeneration(formState) {
    try {
      const data = {
        clientId: clientId,
        data: { ...formState, action: 'modify' },
        promptId: uuidv4(),
      }

      // Save form data to associate with generation later
      dispatchAction(
        setPending({
          modify: { id: data.promptId, data: formState, inQueue: true },
        })
      )

      const res = await axios.post('/api/ai/comfy', data)
      if (res.data.prompt_id !== data.promptId) throw new Error('Image generation failed')

      context.setImagesLeft((prev) => prev - formState.samples)

      return res.data
    } catch (err) {
      setIsLoading(false)
      dispatchAction(
        setPending({
          modify: {},
        })
      )
      context.handleError(err)
    }
  }

  const getGeneration = useCallback(
    async (promptId) => {
      // if (promptId !== pendingGeneration.promptId) return
      try {
        const res = await axios.get(`/api/ai/comfy/${promptId}`)

        let images = []
        if (finishedGeneration.data.moreLikeThis)
          images = [finishedGeneration.data.image, ...res.data]
        else images = res.data

        // Remove promptId from image data
        const { promptId: _, ...imgData } = finishedGeneration.data

        setHistory((prev) => [...prev, { images: images, data: imgData }])
        // setPendingGeneration({})
      } catch (err) {
        context.handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [context, finishedGeneration.data]
  )

  async function abortGeneration(promptId) {
    if (!promptId) return
    // if (promptId !== pendingGeneration.promptId) return
    try {
      const data = { promptId: promptId, samples: pendingGeneration.data?.samples }
      await axios.post(`/api/ai/comfy/abort`, data)

      dispatchAction(setQueue({ modify: 0 }))
      dispatchAction(
        setPending({
          modify: {},
        })
      )

      context.setImagesLeft((prev) => prev + Number(pendingGeneration.data?.samples))
      // setPendingGeneration({})
      // setIsImageInQueue(false)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!finishedGeneration?.id) return

    getGeneration(finishedGeneration.id)
    dispatchAction(
      setFinished({
        modify: {},
      })
    )
  }, [finishedGeneration, dispatchAction, getGeneration])

  function handleImageModify(src) {
    setBaseImage(src.image)
    setImageData(src.data)
  }

  async function handleMoreLikeThis(src) {
    setIsLoading(true)

    try {
      setBaseImage(src.image)
      setImageData(src.data)
      const data = { ...src.data, image: src.image, samples: 3, strength: 0.6, moreLikeThis: true }
      return await requestGeneration(data)

      // const data = { ...src.data, initImage: src.image, samples: 3, strength: 0.6 }
      // const url = '/api/ai/image/morph'
      // const res = await axios.post(url, data)

      // let images = res.data.map((url) => IMAGE_PROXY_URL + url)

      // images = [src.image, ...res.data]

      // setHistory((prev) => [...prev, { images: images, data: data }])
      // context.setImagesLeft((prev) => prev - 3)
    } catch (err) {
      context.handleError(err)
      setIsLoading(false)
    }
  }

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(`Generated by Eleo.ai`)}
              url={`${LANDING_PAGE_URL}${content}`}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  return (
    <Animate type='pop'>
      <div className='h-full lg:flex'>
        {showPreview && (
          <div
            className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90'
            onClick={() => setShowPreview(false)}
          >
            <div className='relative  lg:h-[70dvh]'>
              <img
                className='lg:max-h-auto max-h-[60dvh] w-[90vw] rounded-lg object-contain shadow-lg lg:h-[70dvh] lg:w-full lg:bg-white lg:object-cover'
                src={activeImage?.image}
                alt=''
              />
              <Close
                size={32}
                className='absolute -right-[2px] -top-8 text-white hover:cursor-pointer'
              />
            </div>
          </div>
        )}
        {/* Input Data */}
        <div className='flex flex-col overflow-y-auto border-r lg:w-[55%] 2xl:w-1/2'>
          {/* Form */}
          <ImageModifyForm
            ref={imgForm}
            imageData={imageData}
            setImageData={setImageData}
            baseImage={baseImage}
            setBaseImage={setBaseImage}
            setActiveImage={setActiveImage}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setHistory={setHistory}
            setCurrentIndex={setCurrentIndex}
            setAbortController={setAbortController}
            requestGeneration={requestGeneration}
            getGeneration={getGeneration}
            setIsImageInQueue={setIsImageInQueue}
            queueSize={queueSize}
          />
        </div>

        {/* Output Data */}
        <div className='flex flex-col p-2 lg:w-[45%] 2xl:w-1/2'>
          {/* Main Image */}
          <div
            className={classNames(
              'group relative mx-auto my-3 flex max-h-[50dvh] min-w-[30vw] max-w-[60vw]  items-center justify-center',
              {
                'aspect-1 rounded-lg border border-dashed shadow': !activeImage,
              }
            )}
            data-tooltip-id='images-modifyImage-imageHolder'
          >
            <ImageDisplay
              image={activeImage?.image}
              imageData={activeImage?.data}
              preview={() => setShowPreview(true)}
              onModify={() => handleImageModify(activeImage)}
              moreLikeThis={() => handleMoreLikeThis(activeImage)}
              showShareModal={showShareModal}
              useCors
            />
          </div>

          {/* Image List */}
          <div className='my-3 flex gap-2 px-6' data-tooltip-id='images-modifyImage-imageHolder'>
            {imageHolders}
          </div>

          {/* Buttons */}
          <div className='mx-3 mb-[16px] mt-auto flex flex-wrap justify-between gap-2 align-middle'>
            {/* <div className='relative top-[5px]'>
              <EleoMascot />
            </div> */}
            <div className='flex gap-3' data-tooltip-id='images-imageCreator-imageArrows'>
              <Button
                className='h-[45px] w-12 self-end'
                disabled={currentIndex <= 0}
                color='gray'
                onClick={() => setCurrentIndex((prev) => prev - 1)}
              >
                <ArrowBack weight='bold' size={30} />
              </Button>
              <Button
                className='h-[45px] w-12 self-end'
                disabled={currentIndex === history.length - 1}
                color='gray'
                onClick={() => setCurrentIndex((prev) => prev + 1)}
              >
                <ArrowForward weight='bold' size={30} />
              </Button>
            </div>
            {/* <Button
              className='h-[45px] self-end px-[18px]'
              color='gray'
              data-tooltip-id='images-modifyImage-download'
              onClick={() => showDowloadModal(activeImage.image)}
            >
              <Download style={{ color: '#363636', fontSize: 24 }} />
            </Button> */}
            <Button
              color='gray'
              className='h-[45px] self-end px-[18px]'
              disabled={!isImageInQueue && isLoading}
              onClick={() => {
                if (!isLoading) imgForm.current.resubmit()
                // else if (abortController) abortController.abort()
                else if (isImageInQueue) abortGeneration(pendingGeneration.id)
              }}
              data-tooltip-id='images-modifyImage-regenerate'
            >
              <p className='mr-[6px] text-[15px] font-medium uppercase leading-none'>
                {!isLoading ? (
                  <T keyName='eleo-result-regenerate'>regenerate</T>
                ) : (
                  <T keyName='eleo-result-stop-generating'>stop generating</T>
                )}
              </p>
              <Refresh
                style={{ color: '#363636', fontSize: 24 }}
                className={classNames({
                  'animate-spin': isLoading,
                })}
              />
            </Button>
          </div>
        </div>
        <HelpTooltips group='images' tool='modifyImage' />
        <Tour steps={steps} run={isRunTour} />
      </div>
    </Animate>
  )
}
