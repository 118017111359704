import { AutoAwesome, Block, Subject, VpnKey } from '@mui/icons-material'
import { ContextMenu } from 'components/context-menu'
import * as Selection from 'selection-popover'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ContextModal } from 'components/document-context/ContextModal'
import { useAccess } from 'components/hooks/access'
import { ViewContext } from 'components/lib'
import { clamp, errorToast, queryToObj, successToast, warningToast } from 'helpers'
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import {
  imageCreatorInitialState,
  selectImageCreatorInputHistory,
  setImageCreatorInputHistory,
} from 'store/toolInputHistoriesHolder/image-creator'
import { Modal } from 'toolComponents/generic/modal'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import { ButtonGroup, Microbutton, ReactSelect, Slider } from 'ui'
import { ContextField } from '../../../components/document-context/ContextField'
import { modes, styles } from '../models/imageOptions'
import { resolutions } from '../models/imageSizes'
import { Footer } from './Footer'
import { Header } from './Header'

const DEFAULT_MODAL_DATA = {
  isVisible: false,
  title: '',
  acceptLabel: '',
  type: '',
  context: {},
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PROMPT':
      return { ...state, prompt: action.payload }
    case 'SET_KEYWORDS':
      return { ...state, keywords: action.payload }
    case 'SET_NEGATIVE_PROMPT':
      return { ...state, negativePrompt: action.payload }
    case 'SET_QUALITY':
      return { ...state, quality: action.payload }
    case 'SET_CREATIVITY':
      return { ...state, creativity: action.payload }
    case 'SET_STYLE':
      return { ...state, style: action.payload }
    case 'SET_SAMPLES':
      return { ...state, samples: action.payload }
    case 'SET_PROPORTIONS':
      return { ...state, chosenProportions: action.payload }
    case 'SET_SIZE':
      return { ...state, imageSize: action.payload }
    case 'SET_TEMPLATE':
      return { ...state, template: action.payload }
    case 'APPLY_TEMPLATE':
      const values = action.payload

      let templateData = {
        prompt: values.description,
        keywords: values.keywords,
        negativePrompt: values.avoid,
        quality: values.quality ? String(clamp(0, values.quality, 10)) : null,
        creativity: values.creativity ? clamp(0, values.creativity, 10) : null,
        style: styles.find((s) => values.style?.toLowerCase() === s.value.toLowerCase())?.value,
        chosenProportions: [...resolutions.sd15, ...resolutions.sdxl].find(
          (item) => item.value === values.proportions
        )
          ? values.proportions
          : null,
        template: values.id,
        ...(values.samples && {
          samples: String(clamp(1, values.samples, 4)),
        }),
      }

      templateData = Object.fromEntries(
        Object.entries(templateData).filter(([key, value]) => value !== null && value !== undefined)
      )

      return { ...state, ...templateData }
    case 'RESET':
      return imageCreatorInitialState.imageFormValues
    default:
      return state
  }
}

const ImageForm = (props) => {
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')
  const { t } = useTranslate()
  const location = useLocation()
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const enhanceRef = useRef()
  const descriptionRef = useRef()

  const dispatchAction = useDispatch()
  const imageFormData = useSelector(selectImageCreatorInputHistory)
  const [state, dispatch] = useReducer(reducer, imageFormData)
  // Update store
  useEffect(() => {
    dispatchAction(setImageCreatorInputHistory(state))
  }, [state, dispatchAction])

  const [templates, setTemplates] = useState({ user: [], predefined: [] })
  const [lastUsedTemplate, setLastUsedTamplate] = useState()

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'APPLY_TEMPLATE',
      payload: data,
    })
  }, [location])

  const getAvailableProportions = useCallback(() => {
    const styleData = styles.find((s) => s.value === state.style)
    const res = resolutions[styleData?.modelType ?? 'sdxl']

    return res.flat()
  }, [state.style])

  const fetchTemplates = useCallback(async () => {
    try {
      const url = '/api/image/create/templates'
      const res = await axios.get(url)

      // Predefined templates should be added in the api and here
      setTemplates({ user: res.data.data, predefined: [] })
    } catch (err) {
      context.handleError(err)
    }
  }, [])

  useEffect(() => {
    fetchTemplates()
  }, [fetchTemplates])

  // Update image proportions when changing style (different options available for different styles)
  useLayoutEffect(() => {
    const availableProportions = getAvailableProportions()
    if (
      !availableProportions ||
      availableProportions.find((item) => item.value === state.chosenProportions)
    )
      return

    const options = [...resolutions.sd15, ...resolutions.sdxl]
    const previousProportions = options.find((item) => item.value === state.chosenProportions)
    if (!previousProportions) return

    const newProportions = availableProportions.find(
      (item) => item.proportions === previousProportions.proportions
    )

    dispatch({
      type: 'SET_PROPORTIONS',
      payload: newProportions.value,
    })
  }, [state.style, getAvailableProportions, state.chosenProportions])

  function loadTemplate(id) {
    if (!allowStandard) return showUpgradePlanModal()

    const templateData = [...templates.user, ...templates.predefined].find((item) => item.id === id)
    if (!templateData) return

    dispatch({
      type: 'APPLY_TEMPLATE',
      payload: templateData,
    })
    setLastUsedTamplate(templateData)
    templateData.stories?.forEach((story) => {
      const storyData = props.documentContext.stories.data.find((x) => x.id === story)
      props.documentContext.addDocument({
        label: storyData.label,
        type: 'story',
        content: storyData.content,
        id: storyData.id,
      })
    })
    successToast(t('eleo-template-applied', 'Template applied'))
  }

  function handleSubmit(e) {
    if (e) e.preventDefault()

    if (!state.prompt) {
      errorToast(<T keyName='eleo-error-prompt-required'>The prompt field is required</T>)
      return false
    }

    // props.setIsImageInQueue(true)
    props.setIsLoading(true)
    props.requestGeneration(state)

    return true
  }

  function showUpgradePlanModal() {
    context.modal.show({
      children: <UpgradePlan />,
      modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
    })
  }

  async function handleSaveTemplate(name) {
    try {
      const url = '/api/image/save-template'
      console.log(state)
      const data = {
        image_templates: {
          templateName: name,
          description: state.prompt,
          keywords: state.keywords,
          avoid: state.negativePrompt,
          style: state.style,
          samples: state.samples,
          proportions: state.chosenProportions,
          // size: state.imageSize.value,
          quality: state.quality,
          creativity: state.creativity,
          stories: props.documentContext.docContext.map((item) => item.id),
        },
        tool: 'create',
      }

      await axios.post(url, data)
      successToast(t('eleo-save-template-success', 'Template saved successfully!'))
      fetchTemplates()
    } catch (err) {
      context.handleError(err)
    }
  }

  async function handleDeleteTemplate(templateId) {
    const data = { templateId: templateId }
    try {
      await axios.post('/api/image/delete-template', data)
      successToast(t('eleo-delete-template-success', 'Template deleted successfully!'))
      fetchTemplates()
    } catch (err) {
      context.handleError(err)
    }
  }

  async function handleUpdateTemplate(templateId, newContent) {
    const data = {
      image_templates: {
        description: state.prompt,
        keywords: state.keywords,
        avoid: state.negativePrompt,
        style: state.style,
        samples: state.samples,
        proportions: state.chosenProportions,
        quality: state.quality,
        creativity: state.creativity,
        stories: props.documentContext.docContext.map((item) => item.id),
        ...newContent,
      },
      tool: 'create',
      templateId: templateId,
    }

    try {
      await axios.post('/api/image/update-template', data)
      successToast(t('eleo-update-template-success', 'Template updated successfully!'))
      fetchTemplates()
    } catch (err) {
      context.handleError(err)
    }
  }

  const modalContent = {
    styles: (
      <div className='max-h-[60dvh] w-full overflow-y-auto p-[14px]'>
        <div className='grid grid-cols-2 gap-[10px]'>
          {styles.map((style) => (
            <div
              key={style.value}
              className='border-brand-gray-light rounded-[4px] border bg-white'
              onClick={() => {
                dispatch({
                  type: 'SET_STYLE',
                  payload: style.value,
                })
                setModalData(DEFAULT_MODAL_DATA)
              }}
            >
              <img
                className='h-[130px] rounded-t-[4px]'
                src={style.imageMobile ?? style.image}
                alt=''
              />
              <div className='list-position text-brand-gray-dark  p-3'>{style.label}</div>
            </div>
          ))}
        </div>
      </div>
    ),
  }

  return (
    <>
      <div className='flex h-full w-full flex-col'>
        <Header
          title={t('eleo-tool-create-image-header', 'Create Image')}
          templates={templates}
          setTemplate={loadTemplate}
          handleSaveTemplate={handleSaveTemplate}
          handleDeleteTemplate={handleDeleteTemplate}
          handleUpdateTemplate={handleUpdateTemplate}
        />

        {/* Form */}
        <div className='flex flex-1 flex-col gap-y-[14px] overflow-auto px-[14px] py-[16px] text-[13px] lg:gap-y-4 lg:py-[22px] lg:pl-5 lg:pr-3'>
          {/* <div className='flex flex-1 flex-col gap-y-4 px-5 py-[22px]'> */}
          {/* Row 1 */}
          <div className='flex flex-wrap gap-5'>
            {/* Style */}
            <div className='min-w-[230px] flex-1 space-y-[5px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                {t('eleo-write-text-style', 'style')}
              </div>
              <div
                id='style'
                className='h-[36px]'
                data-tooltip-id='images-imageCreator-style'
                data-tooltip-keyname='eleo-tooltip'
              >
                <ReactSelect
                  disabled={!state.allowStyles}
                  className={classNames(!state.allowStyles && 'opacity-50')}
                  options={styles}
                  placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                  value={styles.find((style) => style.value === state.style) || null}
                  setSelectedValue={(val) =>
                    dispatch({
                      type: 'SET_STYLE',
                      payload: val.value,
                    })
                  }
                  menuPlacement='bottom'
                  portalToBody
                  withImages
                  menuWidth='auto'
                  onClick={
                    !props.isLayoutLarge
                      ? () => {
                          setModalData({
                            isVisible: true,
                            title: (
                              <T
                                keyName='eleo-styles-modal-title'
                                defaultValue='Select image style'
                              />
                            ),
                            subtitle: (
                              <T
                                keyName='eleo-styles-modal-subtitle'
                                defaultValue='Each style is just a starting point. You can achieve very different effects using the description and avoid fields in the next step.'
                              />
                            ),
                            type: 'styles',
                            context: {},
                          })
                        }
                      : undefined
                  }
                />
              </div>
            </div>
            {/* Context */}
            <div className='min-w-[230px] flex-1'>
              <ContextField documentContext={props.documentContext} onlyStory />
            </div>
          </div>

          {/* Row 2 - Description */}
          <div className='space-y-[5px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              {t('eleo-tool-create-image-content', 'Image content')}
            </div>
            <div className='border-brand-gray-light rounded-md border bg-white'>
              {/* Positive */}
              <div className='relative'>
                <Selection.Root>
                  <Selection.Trigger className='relative'>
                    {/* Hidden element for enhance popover menu */}
                    <pre
                      ref={enhanceRef}
                      className='pointer-events-none absolute top-0 w-full text-wrap px-[14px] py-[10px] pb-8 indent-[22px] opacity-0'
                      style={{ fontFamily: 'Rubik, sans-serif' }}
                    >
                      {state.prompt}
                    </pre>
                    <textarea
                      ref={descriptionRef}
                      spellCheck={false}
                      className='min-h-44 w-full resize-none rounded-t-md px-[14px] py-[10px] pb-8 indent-[22px]'
                      placeholder={t(
                        'eleo-tool-create-image-description-placeholder',
                        'What image do you want to generate?'
                      )}
                      style={{ fieldSizing: 'content' }}
                      value={state.prompt}
                      onChange={(e) =>
                        dispatch({
                          type: 'SET_PROMPT',
                          payload: e.target.value,
                        })
                      }
                    />
                  </Selection.Trigger>
                  <Selection.Portal>
                    <Selection.Content
                      side='bottom'
                      align='start'
                      collisionPadding={{ bottom: 50 }}
                      className='z-[9999]'
                    >
                      <ContextMenu
                        noHeader
                        selectedText={state.prompt}
                        handleChangeSelectedText={(newText) => {
                          enhanceRef.current?.dispatchEvent(
                            new KeyboardEvent('keydown', {
                              key: 'Escape',
                              code: 'Escape',
                              bubbles: true,
                              cancelable: true,
                            })
                          )

                          dispatch({
                            type: 'SET_PROMPT',
                            payload: newText,
                          })
                        }}
                        deleteSelection={() => {
                          enhanceRef.current?.dispatchEvent(
                            new KeyboardEvent('keydown', {
                              key: 'Escape',
                              code: 'Escape',
                              bubbles: true,
                              cancelable: true,
                            })
                          )

                          dispatch({
                            type: 'SET_PROMPT',
                            payload: '',
                          })
                        }}
                        getTextContext={() => state.prompt}
                        tools={['rephrase']}
                        forceNotSynonyms
                        regenerateUrl='/api/ai/image/enhance_prompt'
                      />
                    </Selection.Content>
                  </Selection.Portal>
                </Selection.Root>
                <Tooltip
                  disableStyleInjection
                  id='image-description-tooltip'
                  place='top-start'
                  opacity={100}
                  noArrow
                  style={{
                    backgroundColor: '#EFEFF8',
                    padding: 0,
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <div className='text-brand-violet-light w-[250px] space-y-[6px] px-[14px] py-[12px]'>
                    <div className='text-[13px] font-bold leading-[1.2em]'>
                      {t('eleo-images-description-tooltip-title', 'Description')}
                    </div>
                    <div className='text-[12px] leading-[1.2em]'>
                      {t(
                        'eleo-images-description-tooltip-content',
                        "What should be on the image? What's the mood? What is the perspective? What colors do you want to use?"
                      )}
                    </div>
                  </div>
                </Tooltip>
                <div
                  className='text-brand-form-accent absolute left-1 top-1 flex cursor-pointer p-[6px] text-[20px]'
                  data-tooltip-id='image-description-tooltip'
                >
                  <Subject fontSize='inherit' />
                </div>

                {/* Enhance */}
                <Microbutton
                  tooltipId={`eleo-tooltip`}
                  tooltipKeyname={`eleo-tooltip-enchance-prompt`}
                  // title={t('eleo-image-enhance-button', 'Enhance prompt')}
                  iconPlacement='left'
                  variant='transparent-light'
                  icon={<AutoAwesome fontSize='18' />}
                  text={t('eleo-enhance', 'Enhance')}
                  className={classNames('!absolute bottom-2 right-2 h-[28px]')}
                  onClick={() => {
                    if (enhanceRef.current) {
                      const selection = window.getSelection()
                      const range = document.createRange()
                      selection.removeAllRanges()
                      range.selectNodeContents(enhanceRef.current)
                      selection.addRange(range)

                      const customEvent = new PointerEvent('pointerup', {
                        bubbles: true,
                        cancelable: true,
                        pointerType: 'mouse',
                        clientX: 0,
                        clientY: 0,
                      })

                      enhanceRef.current.dispatchEvent(customEvent)

                      const textareaRange = document.createRange()
                      textareaRange.selectNodeContents(descriptionRef.current)
                      setTimeout(() => {
                        selection.removeAllRanges()
                        selection.addRange(textareaRange)
                      }, 50)
                    }
                  }}
                />
              </div>

              {/* Negative */}
              <div className='relative'>
                <textarea
                  spellCheck={false}
                  className='max-h-32 w-full resize-none border-t px-[14px] pb-1 pt-[9px] indent-[22px]'
                  placeholder={t(
                    'eleo-tool-create-image-avoid-placeholder',
                    "What don't you want to see in you image?"
                  )}
                  style={{ fieldSizing: 'content' }}
                  value={state.negativePrompt}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_NEGATIVE_PROMPT',
                      payload: e.target.value,
                    })
                  }
                />
                <Tooltip
                  disableStyleInjection
                  id='image-negative-tooltip'
                  place='top-start'
                  opacity={100}
                  noArrow
                  style={{
                    backgroundColor: '#EFEFF8',
                    padding: 0,
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <div className='text-brand-violet-light w-[250px] space-y-[6px] px-[14px] py-[12px]'>
                    <div className='text-[13px] font-bold leading-[1.2em]'>
                      {t('eleo-images-negative-tooltip-title', 'Avoid')}
                    </div>
                    <div className='text-[12px] leading-[1.2em]'>
                      {t(
                        'eleo-images-negative-tooltip-content',
                        "What you don't want to see on the image?"
                      )}
                    </div>
                  </div>
                </Tooltip>
                <div
                  className='text-brand-form-accent absolute left-1 top-1 flex cursor-pointer p-[6px] text-[20px]'
                  data-tooltip-id='image-negative-tooltip'
                >
                  <Block fontSize='inherit' />
                </div>
              </div>

              {/* Keywords */}
              <div className='relative'>
                <textarea
                  spellCheck={false}
                  className='max-h-32 w-full resize-none border-t px-[14px] pb-1 pt-[9px] indent-[22px]'
                  placeholder={t(
                    'eleo-tool-create-image-keywords-placeholder',
                    "What's most important in your image?"
                  )}
                  style={{ fieldSizing: 'content' }}
                  value={state.keywords}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_KEYWORDS',
                      payload: e.target.value,
                    })
                  }
                />
                <Tooltip
                  id='image-keywords-tooltip'
                  place='top-start'
                  opacity={100}
                  noArrow
                  style={{
                    backgroundColor: '#EFEFF8',
                    padding: 0,
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                  }}
                  disableStyleInjection
                >
                  <div className='text-brand-violet-light w-[250px] space-y-[6px] px-[14px] py-[12px]'>
                    <div className='text-[13px] font-bold leading-[1.2em]'>
                      {t('eleo-images-keywords-tooltip-title', 'Keywords')}
                    </div>
                    <div className='text-[12px] leading-[1.2em]'>
                      {t(
                        'eleo-images-keywords-tooltip-content',
                        "What's most important on the image?"
                      )}
                    </div>
                  </div>
                </Tooltip>
                <div
                  className='text-brand-form-accent absolute left-1 top-1 flex rotate-90 cursor-pointer p-[6px] text-[20px]'
                  data-tooltip-id='image-keywords-tooltip'
                >
                  <VpnKey fontSize='inherit' />
                </div>
              </div>
            </div>
          </div>

          {/* Row 3 */}
          <div className='flex flex-wrap gap-5'>
            {/* Proportions */}
            <div className='min-w-[230px] flex-1 space-y-[5px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                {t('eleo-tool-create-image-proportions', 'Proportions')}
              </div>

              <div
                className='h-[36px] text-[16px]'
                data-tooltip-id='images-imageCreator-proportions'
              >
                <ReactSelect
                  options={getAvailableProportions()}
                  placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                  value={getAvailableProportions()
                    .map((item) => ({
                      ...item,
                      label: (
                        <>
                          <div
                            className='bg-brand-form-accent mr-2 rounded-[2px]'
                            style={{ aspectRatio: item.proportions, height: '14px' }}
                          />
                          {item.label}
                        </>
                      ),
                    }))
                    .find((item) => item.value === state.chosenProportions)}
                  setSelectedValue={(e) => {
                    dispatch({
                      type: 'SET_PROPORTIONS',
                      payload: e.data.value,
                    })
                  }}
                  menuPlacement='top'
                />
              </div>
            </div>

            {/* Amount of images */}
            <div className='min-w-[230px] flex-1 space-y-[5px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                {t('eleo-tool-create-image-amount', 'Amount of images')}
              </div>

              <div id='samples' className='h-[36px]' data-tooltip-id='images-imageCreator-amount'>
                <ButtonGroup
                  className='w-full'
                  options={['1', '2', '3', '4'].map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  value={state.samples}
                  onClick={(event) =>
                    dispatch({
                      type: 'SET_SAMPLES',
                      payload: event.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          {/* Row 4 */}
          <div className='flex flex-wrap gap-5'>
            {/* Quality */}
            <div className='min-w-fit flex-1 space-y-[5px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                {t('eleo-tool-create-image-speed', 'Speed/Quality')}
              </div>

              <div
                className='h-[36px] min-w-fit text-[16px]'
                data-tooltip-id='images-imageCreator-quality'
              >
                <ButtonGroup
                  className='w-full'
                  options={modes}
                  value={state.quality}
                  onClick={(event) =>
                    dispatch({
                      type: 'SET_QUALITY',
                      payload: event.target.value,
                    })
                  }
                />
              </div>
            </div>

            {/* Creativity */}
            <div className='min-w-[250px] flex-1 space-y-[5px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                {t('eleo-tool-create-image-creativity', 'Creativity')}
              </div>

              <div className='h-[36px]' data-tooltip-id='images-imageCreator-creativity'>
                <Slider
                  type='advanced'
                  min={0}
                  max={10}
                  step={0.1}
                  showPercentageThreshold={2}
                  value={state.creativity}
                  onChange={(val) =>
                    dispatch({
                      type: 'SET_CREATIVITY',
                      payload: val,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <Footer
          handleSubmit={handleSubmit}
          dispatch={dispatch}
          allowStandard={allowStandard}
          isLoading={props.isLoading}
          handleSaveTemplate={handleSaveTemplate}
          handleUpdateTemplate={handleUpdateTemplate}
          handleDeleteTemplate={handleDeleteTemplate}
          lastUsedTemplate={lastUsedTemplate}
          templates={templates}
          isLayoutLarge={props.isLayoutLarge}
          setIsDisplayOutput={props.setIsDisplayOutput}
          history={props.history}
          setTemplate={loadTemplate}
          documentContext={props.documentContext}
        />
      </div>

      {props.documentContext.isModalVisible && (
        <ContextModal documentContext={props.documentContext} docOptions={['stories']} />
      )}
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        // callback={() => handleCallback(modalData.type, modalData.context)}
        isValid
        isPrimary={false}
        width={300}
      />
    </>
  )
}

export default ImageForm
