import { T } from '@tolgee/react'
import { Animate, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { useContext, useEffect, useState } from 'react'
import DashboardLayout from 'views/dashboard/DashboardLayout'

// Tour steps
const steps = [
  {
    target: '#header-help',
    content: (
      <T keyName='eleo-tour-dashboard-1'>
        Use the hint system. Click the question mark and hover over the selected element of the
        application to get a hint.
      </T>
    ),
  },
  {
    target: '#scrollableDiv',
    content: (
      <T keyName='eleo-tour-dashboard-2'>
        You have the main menu on the left side. Choose the appropriate tool for writing, generating
        images, gathering information, or chatting from the menu.
      </T>
    ),
  },
]

export function Dashboard() {
  const context = useContext(ViewContext)
  const [isRunTour, setIsRunTour] = useState(false)

  useEffect(() => {
    if (context.words === null) return
    const tourCompleted = localStorage.getItem('tour-dashboard')
    if (tourCompleted) return

    const timeout = setTimeout(() => {
      setIsRunTour(true)
      localStorage.setItem('tour-dashboard', 'true')
    }, 500)
    return () => clearTimeout(timeout)
  }, [context.words])

  return (
    <Animate type='pop'>
      <DashboardLayout />
      {/* <Tour steps={steps} run={isRunTour} /> */}
    </Animate>
  )
}
