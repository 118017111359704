/***
 *
 *   Features/Extension Page
 *
 **********/

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import LanguageIcon from '@mui/icons-material/Language'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import SettingsIcon from '@mui/icons-material/Settings'
import ShareIcon from '@mui/icons-material/Share'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

import { Button } from 'ui'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'

import { T, useTranslate } from '@tolgee/react'

import image from 'images/features/extension.png'

export function Extension() {
  const { t } = useTranslate()

  const featureId = 'extension'
  const extensionHref =
    'https://chromewebstore.google.com/detail/eleo-ai-assistant/bdbefaoklfknlilfjdipnebanodoedph'
  const translations = {
    header: {
      title: `Eleo AI Assistant`,
      subtitle: `Your 24/7 Assistant for a Smarter Life`,
      description: `Imagine a world where an intelligent conversation partner is always there for you, ready to answer your questions, swiftly find information, or translate instantly. Picture an assistant that's almost like having a walking encyclopedia in your pocket.`,
    },
    content: {
      title: `Transform Your Workday with Eleo`,
      subtitle: ``,
      description: `Meet the Eleo AI Assistant - your key to a streamlined, efficient day, right at your fingertips as a handy browser plugin.`,
      features: [
        {
          name: `Always available.`,
          description: `Whether you need help day or night, Eleo is just a click away.`,
          icon: AccessTimeFilledIcon,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `No language barrier.`,
          description: `Communicate effortlessly in any language.`,
          icon: LanguageIcon,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `Tailored to You.`,
          description: `Receive personalized information, advice, and creative sparks.`,
          icon: ArchitectureIcon,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `Customizable.`,
          description: `Choose your language, adjust text and window size to suit your style.`,
          icon: SettingsIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Share easily.`,
          description: `Copy and distribute Eleo’s responses without hassle.`,
          icon: ShareIcon,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `Continuous conversation.`,
          description: `With the Eleo.ai app, pick up right where you left off in your last chat.`,
          icon: QuestionAnswerIcon,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        description={t(
          `eleo-features-${featureId}-header-description`,
          translations.header.description
        )}
        link={{
          href: extensionHref,
          text: t(`download-extension`, 'Download Extension'),
          target: '_blank',
        }}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <div className='mx-auto max-w-4xl px-6 pb-24 sm:text-center lg:px-8'>
        <p className='text-brand-text-secondary text-lg leading-8'>
          <T keyName={`eleo-features-${featureId}-cta-text`}>
            Enjoy the simplicity of instant access to information, advice, translations, and
            creative idea generation. Download the Eleo AI Assistant Chrome Extension.
          </T>
        </p>
        <Button className='mt-10' target='_blank' href={extensionHref} color='violet'>
          <T keyName='download-extension'>Download Extension</T>
        </Button>
      </div>

      <UseEleoToday />
    </div>
  )
}
