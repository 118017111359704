import Axios from 'axios'
import { CONSENT_COOKIE_EXPIRATION, appSupportedLanguages, getParentDomain } from 'helpers'
import { useState } from 'react'
import TagManager from 'react-gtm-module'
// components
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CookieBanner, View } from 'components/lib'
import TooltipContainer from 'components/tooltip-container'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider, PrivateRoute } from './auth'
//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// 404
import Cookies from 'js-cookie'
import { NotFound } from 'views/error/404'
// tailwind css
import { Provider } from 'react-redux'
import '../css/output.css'
import { CursorModeContext } from './cursorMode'
import { store } from './store'

// Packages css
import 'react-resizable/css/styles.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

//tolgee
import { FormatIcu } from '@tolgee/format-icu'
import {
  BackendFetch,
  DevTools,
  LanguageDetector,
  LanguageStorage,
  Tolgee,
  TolgeeProvider,
} from '@tolgee/react'

import WsClient from './wsClient'

// settings
const Settings = require('settings.json')
const currentProcess = process.env.REACT_APP_MODE
  ? process.env.REACT_APP_MODE
  : process.env.NODE_ENV

const StripePromise = loadStripe(Settings[currentProcess].stripe.publishableAPIKey)

const tolgee = Tolgee()
  .use(DevTools())
  .use(LanguageDetector())
  .use(LanguageStorage())
  .use(FormatIcu())
  .use(BackendFetch())
  .init({
    availableLanguages: appSupportedLanguages.map((locale) => locale.value),
    apiUrl: process.env.REACT_APP_TOLGEE_URL,
    apiKey: process.env.REACT_APP_TOLGEE_KEY,
    projectId: '2',
    fallbackLanguage: 'en',
    defaultLanguage: 'en',
    language: JSON.parse(localStorage.getItem('user'))?.language,
  })

const routes = [
  ...require('routes/account').default,
  ...require('routes/tools').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
]

export default function App(props) {
  const [cursorMode, setCursorMode] = useState('DEFAULT')
  const user = JSON.parse(localStorage.getItem('user'))

  //initialize tagmanager on load but with default denied consent
  const initializeTagManager = () => {
    TagManager.initialize({
      gtmId: Settings[currentProcess].gtmId,
    })
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }

    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
    })
  }

  const currentProcess = process.env.REACT_APP_MODE
    ? process.env.REACT_APP_MODE
    : process.env.NODE_ENV
  Axios.defaults.baseURL = Settings[currentProcess].server_url

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token

    //set authenticated cookie on app.eleo.ai and eleo.ai
    Cookies.set('eleo-user-status', 'authenticated', {
      domain: getParentDomain(),
      expires: CONSENT_COOKIE_EXPIRATION,
    })
  }

  initializeTagManager()

  // render the routes
  return (
    <TolgeeProvider tolgee={tolgee} filesUrlPrefix='/'>
      <Elements stripe={StripePromise}>
        <Provider store={store}>
          <AuthProvider>
            <CursorModeContext.Provider value={{ cursorMode, setCursorMode }}>
              <ToastContainer hideProgressBar={true} position='bottom-right' icon={false} />
              <TooltipContainer id={`eleo-tooltip`} />
              <BrowserRouter>
                <WsClient />
                <Routes>
                  {routes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          route.permission ? (
                            <PrivateRoute permission={route.permission}>
                              <View
                                display={route.view}
                                layout={route.layout}
                                title={route.title}
                                tokens={route.tokens ? route.tokens : null}
                              />
                            </PrivateRoute>
                          ) : (
                            <View display={route.view} layout={route.layout} title={route.title} />
                          )
                        }
                      />
                    )
                  })}

                  {/* 404 */}
                  <Route
                    path='*'
                    element={<View display={NotFound} layout='home' title='404 Not Found' />}
                  />
                </Routes>
                <CookieBanner GA_MEASUREMENT_ID={Settings[currentProcess].gtmId} />
              </BrowserRouter>
            </CursorModeContext.Provider>
          </AuthProvider>
        </Provider>
      </Elements>
    </TolgeeProvider>
  )
}

// function RouteFallback() {
//   const navigate = useNavigate()
//   console.log('🚀 ~ file: app.js:129 ~ RouteFallback ~ navigate:', navigate)
//   const { language } = useParams()
//   // If language is not specified, you can handle it here or redirect to a default language route
//   if (!language) {
//     setTimeout(() => {
//       navigate('/en')
//     }, 0) // Redirect to the default language route, change 'en' to your default language
//   } else {
//     // Render the appropriate component for the specified language
//     return (
//       // You can render your component here based on the language value
//       <View display={NotFound} layout='home' title='404 Not Found' />
//     )
//   }
// }
