import { T } from '@tolgee/react'

export const errorCodes = {
  WORD_LIMIT: <T keyName='eleo-error-word-limit'>Word limit exceeded</T>,
  IMAGE_LIMIT: <T keyName='eleo-error-image-limit'>Image limit exceeded</T>,
  JWT_EXPIRED: (
    <T keyName='eleo-error-jwt-expired'>
      Form expired. Please generate a new password reset request
    </T>
  ),
  JWT_EMAIL: <T keyName='eleo-error-jwt-wrong-email'>Please enter the correct email address</T>,
  EMAIL_IN_USE: (
    <T keyName='eleo-error-email-in-use'>
      You already have an account registered with this email address. Please use another email
      address
    </T>
  ),
  USE_SIGNUP_PAGE: (
    <T keyName='eleo-error-use-signup-page'>
      This email is not connected to an account. Use the signup page to create a new account.
    </T>
  ),
}
