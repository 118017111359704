/***
 *
 *   FAQ Page
 *
 **********/

import React from 'react'
import { T } from '@tolgee/react'
const faqs = [
  {
    id: 1,
    question: 'What is the difference between Eleo and other artificial intelligence services?',
    answer:
      'Our mission is simple: Unlock your productivity and creativity with Eleo.ai. Imagine being able to save thousands of hours searching for information, creating content, discovering ideas and images. Eleo is a tool that will help you fully utilize the potential of artificial intelligence in your everyday life and work. Unlike other AI services (e.g. ChatGPT, Bard, Claude), Eleo not only allows you to chat but also to write in the appropriate style, ask good questions, translate with a copywriting function, and generate images. Eleo provides you with tools that make using artificial intelligence enjoyable, fast, and practical. And all of this at a fraction of the cost of your work.',
  },
  {
    id: 2,
    question: 'Can I use Eleo on my phone?',
    answer:
      'Of course. With Eleo, you can use it on any computer, tablet, and phone. By logging in from any device, you have access to the history of your chats and queries as well as saved images.',
  },
  {
    id: 3,
    question: 'In which language can I use Eleo?',
    answer:
      'Eleo is specially prepared to work in eight languages (English, Spanish, French, German, Italian, Polish, Portuguese, Turkish), but it also understands and responds in over forty other languages of the world.',
  },
  {
    id: 4,
    question: 'Is Eleo free?',
    answer:
      'In Eleo, you have a choice of different user plans, which differ in functions and the number of words and images to use. There is also a free plan.',
  },
  {
    id: 5,
    question: 'Does Eleo store my images?',
    answer:
      'Eleo stores the images generated by you, which you save in your directory. To save an image in your directory, use the save function in the menu.',
  },
  {
    id: 6,
    question: 'Does Eleo translate texts precisely?',
    answer: `In addition to the regular translation, Eleo has a very helpful option of translation by a native speaker. It's like giving the source text to a native speaker and asking them to write a text in their native language based on the information in the source text. In this case, it's not about translating word for word, but about a text in a different language that we can use further.`,
  },
  {
    id: 7,
    question: 'Can I edit the text before copying it?',
    answer:
      'Eleo has additional, very useful functions that make it easier to quickly edit text before copying it. You can delete part of the text, change it, and even ask Eleo to rewrite the selected fragment.',
  },
  {
    id: 8,
    question: 'What is context?',
    answer: `Context in questions is very important because it helps artificial intelligence understand exactly what is being asked and provide precise answers. It's like telling someone only the middle part of a story and expecting them to understand what we're talking about. Without context, a question can be misleading, just like the question "Is it cold?" - the answer can be different depending on whether it's winter or summer. Thanks to context, AI can adapt its response to the situation. For example, when we ask about "the best movies," AI will better understand that we are referring to movies of a certain genre or time period. When writing context in questions, make sure the context is clear and precise and provide relevant information for the question.`,
  },
  {
    id: 9,
    question: 'Does Eleo remember the history of our conversations?',
    answer:
      'In Eleo, you have access to your historical conversations, as well as submitted queries and responses.',
  },
  {
    id: 10,
    question: 'Does Eleo use my questions for their own purposes?',
    answer:
      'In contrast to some services, which use your questions and answers to collect information, Eleo does not do this.',
  },
  {
    id: 11,
    question: 'By using a browser plug-in, do I have access to my history?',
    answer: 'Yes. By using the browser plugin, you have access to your data and chat history.',
  },
  {
    id: 12,
    question: 'How to change your plan?',
    answer:
      'Choose and click the purchase plan that you care about. If you have purchased a lower plan, the price will reflect the payments already made by you.',
  },
  {
    id: 13,
    question: 'Do people on the Team Plan see the history of my chats and documents?',
    answer: 'No. In the Team Plan, each person has their individual work history.',
  },
  {
    id: 14,
    question: 'How is the utilization of words calculated?',
    answer: (
      <>
        After each query and response, the words used in them are counted and deducted from your
        words balance. Paid packages in Eleo include a very large number of words so that the user
        does not have to worry about exceeding their limits.
      </>
    ),
    renderExtra: () => (
      <ul>
        <li className='my-2'>
          <b>
            <T keyName='eleo-faq-answer-counting-words-chat'>Chat</T>
          </b>
          <T keyName='eleo-faq-answer-counting-words-chat-description'>
            {' '}
            - A query consists of previous questions and answers (up to about 3,000 words). This
            allows for a smooth dialogue with artificial intelligence. You can clear the previous
            context at any time by clearing the chat with the "X" button.
          </T>
        </li>
        <li className='my-2'>
          <b>Your story</b>
          <T keyName='eleo-faq-answer-counting-words-story-description'>
            {' '}
            - Za każdym razem kiedy użyjesz Your Story przy generowaniu treści, Liczba słów w nie
            zawarta doliczy się do użytych przez Ciebie słów.
          </T>
        </li>
        <li className='my-2'>
          <b>Guru</b>
          <T keyName='eleo-faq-answer-counting-words-guru-description'>
            {' '}
            - Jeśli włączysz tę opcję, liczba użytych słów będzie mnożona x10. Dzięki temu masz
            możliwość korzystać z naszego najbardziej zaawansowanego modelu AI..
          </T>
        </li>
      </ul>
    ),
  },
  {
    id: 15,
    question: 'How is the utilization of images counted?',
    answer: 'Every image generated by Eleo based on user query is counted.',
  },
]

export function Questions(props) {
  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40'>
        <h2 className='text-2xl font-bold leading-10 tracking-tight text-gray-900'>
          <T keyName='eleo-frequently-asked-questions-header'>Frequently asked questions</T>
        </h2>
        <dl className='mt-10 space-y-8 divide-y divide-gray-900/10'>
          {faqs.map((faq) => (
            <div key={faq.id} className='pt-8 lg:grid lg:grid-cols-12 lg:gap-8'>
              <dt className='text-base font-semibold leading-7 text-gray-900 lg:col-span-5'>
                <T keyName={`eleo-faq-question-${faq.id}`}>{faq.question}</T>
              </dt>
              <dd className='mt-4 lg:col-span-7 lg:mt-0'>
                <p className='text-base leading-7 text-gray-600'>
                  <T keyName={`eleo-faq-answer-${faq.id}`}>{faq.answer}</T>
                  {faq.renderExtra && faq.renderExtra()}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
