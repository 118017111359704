import axios from 'axios'

export async function streamingRequest(url, data, signal, showErrorToast = true) {
  let isFormData = false
  if (data instanceof FormData) isFormData = true

  const res = await fetch(url, {
    method: 'POST',
    body: isFormData ? data : JSON.stringify(data),
    headers: {
      ...(!isFormData && { 'Content-Type': 'application/json' }),
      Authorization: axios.defaults.headers.common['Authorization'],
    },
    ...signal,
  })
  if (!res.ok) {
    // if (res.status === 401) {
    //   axios.delete('/api/auth')
    //   localStorage.removeItem('user')
    //   window.location = '/signin'
    //   throw new Error()
    // }
    // if (res.status === 402) {
    //   if (showErrorToast)
    //     return errorToast(
    //       <T keyName="eleo-word-limit-exceeded">
    //         You have run out of words. Visit your account page to buy more.
    //       </T>
    //     )
    //   const e = new Error('Word limit exceeded')
    //   e.name = 'LimitError'
    //   throw e
    // }
    let err = new Error('Request failed')
    err.response = res

    throw err
  }
  return res
}
