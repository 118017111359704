import { useEffect, useRef } from 'react'

const AudioPlayer = ({ streamSource, setSource, callback }) => {
  const mediaSourceRef = useRef(getMediaSource())

  useEffect(() => {
    if (!mediaSourceRef.current || !streamSource) return

    const audio = document.createElement('audio')
    audio.disableRemotePlayback = true
    audio.controls = true
    audio.autoplay = true
    audio.style.display = 'none'

    const mediaSource = mediaSourceRef.current

    const sourceOpenHandler = async () => {
      try {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg')
        const reader = streamSource.getReader()

        const processStream = ({ done, value }) => {
          if (done) {
            if (sourceBuffer.updating) return setTimeout(() => processStream({ done, value }), 100) // Retry ending shortly
            if (mediaSource.readyState === 'ended') return
            return mediaSource.endOfStream()
          }

          if (!sourceBuffer.updating) {
            sourceBuffer.appendBuffer(value)
          } else {
            // Retry appending shortly
            return setTimeout(() => processStream({ done, value }), 100)
          }

          reader.read().then(processStream)
        }

        reader.read().then(processStream)
      } catch (error) {
        console.error('Error handling MediaSource', error)
      }
    }

    const handlePlaybackEnd = () => {
      setSource(null)
      callback?.()
    }

    mediaSource.addEventListener('sourceopen', sourceOpenHandler)
    audio.addEventListener('ended', handlePlaybackEnd)
    document.body.appendChild(audio)

    audio.src = URL.createObjectURL(mediaSource)

    return () => {
      mediaSource.removeEventListener('sourceopen', sourceOpenHandler)
      document.body.removeChild(audio)
      URL.revokeObjectURL(audio.src)
    }
  }, [streamSource, setSource])

  return
}

function getMediaSource() {
  if (window.ManagedMediaSource) {
    return new window.ManagedMediaSource()
  }
  if (window.MediaSource) {
    return new window.MediaSource()
  }

  throw new Error('No MediaSource API available')
}

export default AudioPlayer
