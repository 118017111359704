import { createSlice } from '@reduxjs/toolkit'
import { SupportedModels } from 'helpers/enums'

export const chatInitialState = {
  history: {
    chatLog: [],
    activeHistory: [],
    chatId: null,
    showHistory: false,
  },
  form: {
    inputValue: '',
    language: { value: 'auto', label: 'auto' },
    isGuru: false,
    story: null,
    isAutoAudio: false,
    isSearchWeb: false,
    model: { ...SupportedModels.find((model) => model.isDefault), description: undefined },
    isSettingsOpen: true,
  },
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    setChatInputHistory: (state, action) => {
      state.history = action.payload
    },
    setChatForm: (state, action) => {
      state.form = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setChatInputHistory, setChatForm } = chatSlice.actions

//selectors
export const chatInputHistory = (state) => state.chat.history
export const chatForm = (state) => state.chat.form
export default chatSlice.reducer
