export const advisorReadyMadeTemplates = [
  {
    id: 'write-a-legal-opinion',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'write-a-legal-opinion',
    label: 'Write a legal opinion',
    topicPlaceHolder: {
      tolgee_id: 'eleo-write-a-legal-opinion-template-topic-placeholder-text',
      value:
        'Enter the topic of the task, e.g. Legal opinion on the matter of .please indicate what it is about.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-write-a-legal-opinion-template-context-placeholder-text',
      value:
        'Provide all necessary facts related to this opinion, specify in which legal jurisdiction this legal process takes place.',
    },
    wordLength: '2000',
    creativity: 'Low',
    author: 'lawyer',
  },
  {
    id: 'how-to-find-clients',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'how-to-find-clients',
    label: 'How to find clients?',
    topicPlaceHolder: {
      tolgee_id: 'eleo-how-to-find-clients-template-topic-placeholder-text',
      value: 'Provide the name of the product or service that the advice should be about',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-how-to-find-clients-template-context-placeholder-text',
      value:
        'Provide all necessary information about the product or service, such as its unique features, who the potential customer is, etc.',
    },
    wordLength: '2000',
    creativity: 'Medium',
    author: 'salesman',
  },
  {
    id: 'write-an-offer',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'write-an-offer',
    label: 'Write an offer',
    topicPlaceHolder: {
      tolgee_id: 'eleo-write-an-offer-template-topic-placeholder-text',
      value: 'Please provide the name of the product or service to which the offer should refer.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-write-an-offer-template-context-placeholder-text',
      value:
        'Please provide all necessary information about the product or service, such as its unique features, its strengths. In what quantities and prices it can be delivered. What are the conditions and terms of delivery?',
    },
    wordLength: '500',
    creativity: 'Medium',
    author: 'salesman',
  },
  {
    id: 'provide-market-data',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'provide-market-data',
    label: 'Provide market data',
    topicPlaceHolder: {
      tolgee_id: 'eleo-provide-market-data-template-topic-placeholder-text',
      value: 'Specify what the report should be about, what market or product or service.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-provide-market-data-template-context-placeholder-text',
      value: 'Provide more details about this market.',
    },
    wordLength: '500',
    creativity: 'Low',
    author: 'businessman',
  },
  {
    id: 'what-does-science-say-about',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'what-does-science-say-about',
    label: 'What does science say about',
    topicPlaceHolder: {
      tolgee_id: 'eleo-what-does-science-say-about-template-topic-placeholder-text',
      value: 'Enter the question here',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-what-does-science-say-about-template-context-placeholder-text',
      value: 'If you have any additional questions, write them here.',
    },
    wordLength: '500',
    creativity: 'Low',
    author: 'scientist',
  },
]
