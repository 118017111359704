import { T } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'
import { Info } from '@mui/icons-material'

export default function DashboardItem({
  name,
  description,
  image,
  href,
  infoTooltip,
  bestOnDesktop,
}) {
  const navigate = useNavigate()

  return (
    <div
      className='relative flex  min-w-0 cursor-pointer flex-col justify-between rounded-[10px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] transition hover:scale-[1.03] md:h-[230px] md:w-[200px] md:rounded-[20px] xl:h-[250px] xl:w-[220px]'
      onClick={() => navigate(href)}
    >
      <div className='w-[calc(100%-76px)] p-4 md:w-auto md:p-5 md:text-center xl:p-[30px]'>
        <div className='mb-2 flex items-start gap-[6px] text-[15px] font-semibold leading-[1.2em] md:block md:text-[16px] xl:text-[18px]'>
          {name}

          {infoTooltip && (
            <div
              onClick={(e) => e.stopPropagation()}
              data-tooltip-id={`eleo-tooltip`}
              data-tooltip-keyname={infoTooltip}
              className='-m-3 p-3 md:absolute md:bottom-4 md:right-4 md:-m-2 md:p-2'
            >
              <Info fontSize='small' className='text-brand-charcoal opacity-20 ' />
            </div>
          )}
        </div>
        <div className='text-balance text-[12px] leading-[1.5em] md:text-[14px] xl:text-[15px]'>
          {description}
        </div>
      </div>
      {image && (
        <img
          src={image}
          alt=''
          className='absolute right-4 top-4 size-[40px] md:bottom-[20px] md:left-1/2 md:right-auto md:top-auto  md:size-[70px] md:-translate-x-1/2  xl:bottom-[30px]'
        />
      )}

      {bestOnDesktop && (
        <div className='bg-brand-violet xs:rounded-none text-brand-violet mb-4 w-full bg-opacity-20 py-1 text-center text-[12px] font-medium leading-[1.5em] md:hidden'>
          <T keyName='eleo-dashboard-best-on-desktop'>Works best on desktop</T>
        </div>
      )}
    </div>
  )
}
