import Joyride from 'react-joyride'
import { T, useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { Modal } from 'components/lib'
import { Button } from 'ui'
import { useSearchParams } from 'react-router-dom'

export default function Tour({ steps, callback, autoScroll = true, name }) {
  const { t } = useTranslate()
  const [params, setParams] = useSearchParams()
  const forceTour = Boolean(params.get('tour'))

  const [isRun, setIsRun] = useState(false)
  const [isForced, setIsForced] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState({ visible: false })

  useEffect(() => {
    let timeout
    if (forceTour) {
      // Handle tours triggered from header help menu
      timeout = setTimeout(() => {
        localStorage.setItem(`tour-${name}`, 'true')
        setIsRun(true)
        setIsForced(true)
        setParams()
      }, 500)
    } else {
      // Check if the user has already completed this tour or disabled all tours
      if (localStorage.getItem(`tour-${name}`) || localStorage.getItem('disableTours'))
        return () => null

      timeout = setTimeout(() => {
        setIsRun(true)
      }, 500)
    }

    return () => clearTimeout(timeout)
  }, [forceTour, setParams, name])

  function handleCallback(e) {
    if (e.action === 'next' || e.action === 'skip') localStorage.setItem(`tour-${name}`, 'true')

    let endTour = false

    // Tour interrupted
    if (e.action === 'skip') {
      setIsRun(false)
      endTour = true
      if (!isForced) setIsModalVisible({ visible: true })

      setIsForced(false)
    }

    // Tour complete
    if (e.action === 'reset') {
      endTour = true
    }

    callback && callback(e, endTour)
  }

  function handleDisableFutureTours() {
    localStorage.setItem('disableTours', 'true')
    setIsModalVisible({ visible: false })
  }

  return (
    <>
      <Joyride
        steps={steps}
        run={isRun}
        callback={handleCallback}
        continuous
        showProgress
        disableOverlayClose
        showSkipButton
        disableScrollParentFix
        disableScrolling={!autoScroll}
        styles={{
          options: {
            primaryColor: '#6363E5',

            zIndex: 10000,
            backgroundColor: '#6363E5',
            arrowColor: '#6363E5',
            textColor: 'white',
            borderRadius: '100px',
          },
          buttonBack: {
            color: 'white',
          },
        }}
        locale={{
          back: t('eleo-tour-back', 'Back'),
          close: t('eleo-tour-close', 'Close'),
          last: t('eleo-tour-last', 'Last'),
          next: t('eleo-tour-next', 'Next'),
          open: t('eleo-tour-dialog', 'Open the dialog'),
          skip: t('eleo-tour-end', 'Skip'),
        }}
      />
      {isModalVisible.visible && (
        <Modal
          setHideModal={setIsModalVisible}
          text={
            <p className='text-brand-body text-[19px] font-bold'>
              <T keyName='eleo-tour-close-modal-title'>Do you want to disable future tours?</T>
            </p>
          }
        >
          <div className='xs:flex-nowrap flex w-full flex-wrap justify-between gap-x-8 gap-y-4 px-4 pt-4'>
            <Button
              color='green'
              variant='small'
              className='!w-full capitalize'
              onClick={handleDisableFutureTours}
            >
              <T keyName='eleo-yes'>Yes</T>
            </Button>
            <Button
              color='green'
              variant='small'
              className='!w-full capitalize'
              onClick={() => setIsModalVisible({ visible: false })}
            >
              <T keyName='eleo-no'>No</T>
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}
