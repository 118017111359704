import { T } from '@tolgee/react'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { SocialShare, ViewContext } from 'components/lib'
import { detectRtlDirection } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { useContext, useState } from 'react'
import * as Selection from 'selection-popover'
import defaultAvatar from '../../../images/avatars/default_avatar.svg'
import { MessageContextItem } from '../MessageContext/item'
import Markdown from './Markdown'
import { MessageLoader } from './MessageLoader'
import { MessageOptions } from './MessageOptions'

export default function ChatMessage({
  message,
  customAssistantLogo,
  widget = false,
  language,
  regenerateResponse,
  index,
  avatar,
  handleTTS,
  width,
  ttsIndex,
}) {
  const context = useContext(ViewContext)
  const [selectedText, setSelectedText] = useState('')
  const changeWritingDirection = detectRtlDirection(language)

  if (message.role === 'system') return

  const isLoading =
    message.content === 'STATE_LOADING' || message.content === 'STATE_LOADING_CONTEXT'

  const handleSelectionChange = () => {
    const selection = window.getSelection()
    if (selection && selection.toString()) {
      // Text is selected within the container
      setSelectedText(selection.toString())
    }
  }

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(content)}
              url={window.location.origin}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  return (
    <div
      className={classNames('relative min-w-0 first:mt-auto', {
        'items-end justify-end sm:items-start': message.role === 'user' && !widget,
        'flex gap-[5px]': widget,
        'flex flex-col sm:flex-row sm:items-end': !widget,
        '!flex-row-reverse': widget && message.role === 'user',
      })}
    >
      {/* Sender icon */}
      <div
        className={classNames(
          'flex items-center justify-center rounded-full sm:mb-0',
          !customAssistantLogo && message.role !== 'user' && 'bg-yellow-400',
          message.role === 'user' ? '-right-[62px]' : '-left-[62px]',
          'bottom-0 mb-2 size-[42px] min-h-[42px] min-w-[42px] sm:absolute sm:mb-0'
        )}
      >
        <img
          className={classNames(
            'rounded-full object-contain',
            message.role === 'user' ? 'size-[42px] ' : 'size-[34px]',
            {
              '!size-[42px] !object-cover': widget && customAssistantLogo,
            }
          )}
          src={
            message.role === 'user'
              ? (avatar ?? defaultAvatar)
              : customAssistantLogo
                ? customAssistantLogo
                : eleoLogo
          }
          alt='eleo logo'
        />
      </div>

      <Selection.Root>
        <Selection.Trigger className='h-full min-w-0'>
          <div
            className={classNames(
              'group relative whitespace-pre-line rounded-[10px]  p-[30px] pb-[10px]',
              {
                'bg-transparent pt-[20px]': message.role === 'user',
                'border-brand-grey bg-brand-grey-grey-bg border': message.role !== 'user',
                'rtl-text': changeWritingDirection,
                '!pr-[5px]': widget && message.role === 'user',
              },
              'message'
            )}
            onMouseUp={() => handleSelectionChange()}
            dir={changeWritingDirection ? 'rtl' : 'ltr'}
          >
            {/* Markdown formatter wrapped in useMemo to avoid rerenders */}
            {isLoading ? (
              <MessageLoader usesContext={message.content === 'STATE_LOADING_CONTEXT'} />
            ) : message.role === 'assistant' ? (
              <Markdown content={message.content} />
            ) : (
              <div className={classNames('break-words', message.role === 'user' && 'text-end')}>
                {message.content}
              </div>
            )}

            {message.role === 'user' ? (
              Boolean(message.context?.length) && (
                <div className='flex select-none flex-wrap items-center justify-end gap-[6px] p-[10px] pr-0'>
                  {message.context?.map((item) => (
                    <MessageContextItem
                      key={item.id}
                      item={item}
                      messageContext={message.context}
                      isEditable={false}
                    />
                  ))}
                </div>
              )
            ) : (
              <MessageOptions
                regenerateResponse={regenerateResponse}
                index={index}
                message={message}
                showShareModal={showShareModal}
                widget={widget}
                handleTTS={handleTTS}
                width={width}
                isLoading={isLoading}
                ttsIndex={ttsIndex}
              />
            )}
          </div>
        </Selection.Trigger>

        <Selection.Portal>
          <Selection.Content
            side='bottom'
            align='start'
            collisionPadding={{ bottom: 50 }}
            className='z-[9999]'
          >
            <ContextMenu selectedText={selectedText} enableEdit={false} />
          </Selection.Content>
        </Selection.Portal>
      </Selection.Root>
    </div>
  )
}
