import axios from 'axios'

export class GreatIdeasAPIService {
  static generateRequest(data, segment, method = 'POST', signal) {
    if (method === 'POST') {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
        ...signal,
      })
    } else {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })
    }
  }

  static async generateIdeas(writerOptions, creativity, signal) {
    const data = {
      tool: 'ideas',
      writerOptions,
      kwargs: { stream: true, temperature: Number(creativity) / 10 },
    }

    return this.generateRequest(data, '/ai/ideas', 'POST', signal)
  }

  static async rephrase(textContext, selectedText) {
    const data = {
      context: textContext,
      target: selectedText,
      kwargs: { stream: true },
    }

    return this.generateRequest(data, '/ai/rephrase')
  }

  static async getHistories() {
    return this.generateRequest({}, '/ideas/histories', 'GET')
  }

  static async clearHistory(data) {
    return this.generateRequest(data, '/ideas/delete-histories')
  }

  static async deleteHistory(data) {
    return this.generateRequest(data, '/ideas/delete-history')
  }
}
