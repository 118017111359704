/***
 *
 *   SOCIAL SIGN IN BUTTONS
 *   To sign up/in with Facebook, Google or Microsoft
 *
 **********/

import { useState } from 'react'
import { Button } from 'components/lib'
import { ClassHelper, Grid } from 'components/lib'
import settings from 'settings'
import Style from './social.module.scss'

export function SocialSignin(props) {
  const [loading, setLoading] = useState(
    props.network?.map((x) => {
      return { [x]: false }
    })
  )
  const currentProcess = process.env.REACT_APP_MODE
    ? process.env.REACT_APP_MODE
    : process.env.NODE_ENV
  const serverURL = settings[currentProcess].server_url

  // construct query string
  let qs = ''
  if (props.invite) qs = `?invite=${props.invite}&signin_url=${window.location.href}`
  if (props.signup) qs = '?signup=1'
  if (props.next) {
    if (qs) qs += `&next=${props.next}`
    else qs = `?next=${props.next}`
  }

  return (
    <div>
      <Grid cols={2}>
        {props.network?.map((n) => {
          const css = ClassHelper(Style, {
            [n]: true,
            signinButton: true,
          })
          return (
            <Button
              key={n}
              loading={loading[n]}
              icon={['fab', n]}
              iconPack='fontawesome'
              iconSize='1x'
              iconButton
              className={css}
              action={() => setLoading({ [n]: true })}
              url={`${serverURL}/auth/${n}${qs}`}
              text={`${n.charAt(0).toUpperCase() + n.slice(1)}`}
            />
          )
        })}
      </Grid>

      {props.showOr && <span className={Style.or}>or</span>}
    </div>
  )
}
