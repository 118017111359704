import { CircleNotch } from 'phosphor-react'
import { Save } from '@mui/icons-material'
import React from 'react'
import { Button, Input } from 'ui'
import { T, useTranslate } from '@tolgee/react'

const WriterTemplateNameModalContent = ({
  templateName,
  setTemplateName,
  handleTemplate,
  loading,
  templateId,
  placeHolder,
}) => {
  const { t } = useTranslate()

  return (
    <div className='flex  w-full flex-col gap-[10px]'>
      <Input
        placeholder={
          placeHolder ? placeHolder : t('eleo-enter-template-name', 'Enter template name')
        }
        className='border-brand-grey border'
        value={templateName}
        onChange={setTemplateName}
      />
      <Button
        color='green'
        className='!float-none h-[45px] !w-full'
        onClick={handleTemplate}
        disabled={loading.name === 'template' && loading.status}
      >
        <div className='flex items-center gap-[5px]'>
          {loading.name === 'template' && loading.status ? (
            <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
          ) : (
            <Save className='text-white' />
          )}
          <p className='!mb-0'>
            {templateId.value ? (
              <T keyName='eleo-template-update'>update</T>
            ) : (
              <T keyName='eleo-template-save'>save</T>
            )}
          </p>
        </div>
      </Button>
    </div>
  )
}

export default WriterTemplateNameModalContent
