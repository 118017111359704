import { CardElement } from '@stripe/react-stripe-js'
import { Label, Error, ClassHelper } from 'components/lib'
import Style from './input.tailwind.js'
import { T } from '@tolgee/react'

export function CardInput(props) {
  const error = props.errorMessage || (
    <T keyName='eleo-card-invalid-error-message'>Please provide valid credit card details</T>
  )

  const cardStyle = ClassHelper(Style, {
    textbox: true,
    cardbox: true,
    className: props.className,
    error: props.valid === false,
  })

  return (
    <div className={Style.input}>
      {props.label && <Label text={props.label} required={props.required} for={props.name} />}

      <CardElement
        onFocus={props.onFocus}
        className={cardStyle}
        style={{
          base: { fontFamily: 'Rubik', fontSize: '15px' },
        }}
      />

      {props.valid === false && <Error message={error} />}
    </div>
  )
}
