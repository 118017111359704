import { useTranslate } from '@tolgee/react'
import { TemplatesButton } from 'components/templates/TemplatesButton'

export function Header({
  title,
  templates,
  setTemplate,
  handleSaveTemplate,
  handleDeleteTemplate,
  handleUpdateTemplate,
}) {
  const { t } = useTranslate()

  return (
    <div className='border-brand-grey hidden items-center justify-between border-b px-5 py-3 leading-[1.2em] lg:-mr-2 lg:flex'>
      <div className='flex items-center text-[14px] font-medium'>{title}</div>
      <div className='flex gap-2'>
        {/* <Microbutton
          text={t('eleo-history', 'History')}
          variant='plan'
          className='text-brand-violet-light bg-brand-form-accent-light hover:bg-brand-form-accent hover:text-brand-violet capitalize'
        /> */}
        <TemplatesButton
          templates={templates}
          setTemplate={setTemplate}
          handleSaveTemplate={handleSaveTemplate}
          handleDeleteTemplate={handleDeleteTemplate}
          handleUpdateTemplate={handleUpdateTemplate}
        />
      </div>
    </div>
  )
}
