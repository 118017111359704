import { T, useTranslate } from '@tolgee/react'
import { CONSENT_COOKIE_EXPIRATION, getParentDomain, pageview } from 'helpers'
import Cookies from 'js-cookie'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { CookieConsent } from 'ui'

export const CookieBanner = ({ GA_MEASUREMENT_ID }) => {
  const { t } = useTranslate()
  const { pathname } = useLocation()
  const searchParams = useSearchParams()
  const [statisticalConsentApproved, setStatisticalConsentApproved] = useState(false)
  const [marketingConsentApproved, setMarketingConsentApproved] = useState(false)
  const [banerStatus, setBannerStatus] = useState(false)

  const handleApproveStatisticalConsent = (cb) => {
    setStatisticalConsentApproved(cb)
  }

  const handleApproveMarketingConsent = (cb) => {
    setMarketingConsentApproved(cb)
  }

  const handleAcceptAll = async () => {
    const acceptedCookies = {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      personalization_storage: 'granted',
    }

    window.gtag('consent', 'update', acceptedCookies)
    Cookies.set('eleo-selected-consents', JSON.stringify(acceptedCookies), {
      domain: getParentDomain(),
      expires: CONSENT_COOKIE_EXPIRATION,
    })
    setBannerStatus(true)
  }

  const handleAcceptSelectedConsents = async () => {
    const acceptedCookies = {
      ad_storage: marketingConsentApproved ? 'granted' : 'denied',
      analytics_storage: statisticalConsentApproved ? 'granted' : 'denied',
      ad_user_data: marketingConsentApproved ? 'granted' : 'denied',
      ad_personalization: marketingConsentApproved ? 'granted' : 'denied',
      personalization_storage: 'denied',
    }

    window.gtag('consent', 'update', acceptedCookies)
    Cookies.set('eleo-selected-consents', JSON.stringify(acceptedCookies), {
      domain: getParentDomain(),
      expires: CONSENT_COOKIE_EXPIRATION,
    })
    setBannerStatus(true)
  }

  const handleLeaveApp = () => {
    window.close()
  }

  useLayoutEffect(() => {
    if (Cookies.get('eleo-selected-consents')) {
      setBannerStatus(true)
      const parsedValues = JSON.parse(Cookies.get('eleo-selected-consents'))
      window.gtag('consent', 'update', {
        ...parsedValues,
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      pageview(GA_MEASUREMENT_ID, pathname)
    }
  }, [pathname, searchParams, GA_MEASUREMENT_ID])

  return (
    <CookieConsent
      acceptAllButtonText={<T keyName='eleo-consent-banner-accept-all'>Accept all</T>}
      leaveSiteButtonText={<T keyName='eleo-consent-banner-leave-site'>Leave site</T>}
      manageButtonText={<T keyName='eleo-consent-banner-manage-cookies'>Manage cookies</T>}
      showBanner={!banerStatus || !Cookies.get('eleo-selected-consents')}
      acceptSelectionButtonText={
        <T keyName='eleo-consent-banner-accept-selection'>Accept selection</T>
      }
      bannerTitle={
        <T keyName='eleo-consent-banner-intro-text'>
          Our platform uses cookies to improve the use of services, personalize content and ads, and
          increase user satisfaction. Some files are necessary for the site to function. Manage them
          in your browser settings or through the Manage cookies option. More information in the
          Privacy Policy and Terms of Service.
        </T>
      }
      handleAcceptAllCookies={handleAcceptAll}
      handleAcceptSelectedConsents={handleAcceptSelectedConsents}
      handleLeaveApp={handleLeaveApp}
      options={[
        {
          optionTitle: <T keyName='eleo-consent-banner-accept-necessary-consent'>Necessary</T>,
          optionDescription: (
            <T keyName='eleo-consent-banner-accept-necessary-consent-description'>
              Necessary cookies help make a website usable by enabling basic functions like page
              navigation and access to secure areas of the website. The website cannot function
              properly without these cookies.
            </T>
          ),
          defaultSelected: true,
          onSelect: () =>
            alert(
              t(
                'eleo-consent-banner-accept-necessary-consent-description',
                'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.'
              )
            ),
        },
        {
          optionTitle: <T keyName='eleo-consent-banner-accept-statistical-consent'>Statistical</T>,
          optionDescription: (
            <T keyName='eleo-consent-banner-accept-statistical-consent-description'>
              Statistic cookies help website owners to understand how visitors interact with
              websites by collecting and reporting information anonymously.
            </T>
          ),
          defaultSelected: statisticalConsentApproved,
          onSelect: (selectedValue) => handleApproveStatisticalConsent(selectedValue),
        },
        {
          optionTitle: <T keyName='eleo-consent-banner-accept-marketing-consent'>Marketing</T>,
          optionDescription: (
            <T keyName='eleo-consent-banner-accept-marketing-consent-description'>
              Marketing cookies are used to track visitors across websites. The intention is to
              display ads that are relevant and engaging for the individual user and thereby more
              valuable for publishers and third party advertisers.
            </T>
          ),
          defaultSelected: marketingConsentApproved,
          onSelect: (selectedValue) => handleApproveMarketingConsent(selectedValue),
        },
      ]}
    />
  )
}
