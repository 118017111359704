import { useWindowDimensions } from 'components/hooks/window'
import React, { useEffect } from 'react'
//
export const ChatBotComponent = () => {
  const { width } = useWindowDimensions()
  useEffect(() => {
    if (width >= 768) {
      // Load chat-bot-widget.js script
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.defer = true
      script.src = 'https://chatbot.eleo.ai/chat-bot-widget.js'

      document.body.appendChild(script)

      return () => {
        // Cleanup script on component unmount
        document.body.removeChild(script)
      }
    }
  }, [])
  return (
    <>
      <div
        id='eleo-chat-bot'
        position='bottom-right'
        chat-bot-name='eleo-ai'
        chat-window-height='500'
        chat-window-width='700'
        widget='true'
      ></div>
    </>
  )
}
