import { createSlice } from '@reduxjs/toolkit'

export const writerInitialState = {
  history: {
    topic: '',
    writerContext: '',
    keyWords: '',
    checkBoxValues: [],
    isGuruModeEnabled: false,
    wordLength: { id: '500', checked: true },
    isOptimizedForSeoEnabled: false,
    writerLengthOption: 'words',
    creativity: 'Medium',
    templateId: { label: '', value: '' },
    storyId: { label: '', value: '' },
    selectOptionValues: {
      format: '',
      language: '',
      style: '',
      tone: '',
      author: '',
    },
    showAdvanced: false,
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const writerSlice = createSlice({
  name: 'writer',
  initialState: writerInitialState,
  reducers: {
    setInputHistory: (state, action) => {
      state.history = action.payload
    },
    setGeneratedTextHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setInputHistory, setGeneratedTextHistory } = writerSlice.actions

//selectors
export const writerInputHistory = (state) => state.writer.history
export const writerGeneratedTextHistory = (state) => state.writer.generatedTextHistory.generatedText

export default writerSlice.reducer
