import { createSlice } from '@reduxjs/toolkit'

export const greatIdeasInitialState = {
  history: {
    text: '',
    negativeWords: '',
    details: '',
    writerLengthOption: 'words',
    isGuruModeEnabled: false,
    creativity: 8,
    wordLength: { id: '500', checked: true },
    selectOptionValues: {
      tone: '',
      ideaFor: '',
      language: 'auto',
    },
    showAdvanced: false,
    storyId: { label: '', value: '' },
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const greatIdeasSlice = createSlice({
  name: 'greatIdeas',
  initialState: greatIdeasInitialState,
  reducers: {
    setGreatIdeasInputHistory: (state, action) => {
      state.history = action.payload
    },
    setGreatIdeasGeneratedResultHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setGreatIdeasInputHistory, setGreatIdeasGeneratedResultHistory } =
  greatIdeasSlice.actions

//selectors
export const greatIdeasInputHistory = (state) => state.greatIdeas.history
export const greatIdeasGeneratedResultHistory = (state) =>
  state.greatIdeas.generatedTextHistory.generatedText

export default greatIdeasSlice.reducer
