import { useContext } from 'react'
import { CursorModeContext } from 'app/cursorMode'
import { StyledTooltip } from 'ui'
import { CursorMode } from 'helpers/enums'

export function HelpTooltip(props) {
  const { cursorMode } = useContext(CursorModeContext)
  // const titleTranslation = props.title &&
  // const contentTranslation = props.content &&

  if (cursorMode === CursorMode.Help || props.showAlways)
    return <StyledTooltip {...props}>{props.children}</StyledTooltip>

  return null
}
