import React from 'react'
import { Animate } from 'components/lib'
import GreatIdeasLayout from 'toolComponents/great-ideas/GreatIdeasLayout'

export function GreatIdeas(props) {
  return (
    <Animate type='pop'>
      <GreatIdeasLayout />
    </Animate>
  )
}
