/***
 *
 *   TERMS & CONDITIONS
 *   Boilerplate terms & conditions – replace the values
 *   inside [] with your own text
 *
 **********/

import React from 'react'
import { Article } from 'components/lib'

export function Terms(props) {
  const content = `
<h1>Terms and Conditions of Eleo.ai</h1>

ELEO PSA
ul. Św. Marcin 29/9, 61-806 Poznań, Polska
E-mail: office@eleo.ai


TERMS OF SERVICE FOR SERVICES RENDERED THROUGH THE ELEO.AI WEBSITE (REGULATION)

<b>I. Definitions</b>

Service Provider - a company providing services through the eleo.ai website:

ELEO PROSTA SPÓŁKA AKCYJNA
Address: ul. Św. Marcin 29/9, 61-806 Poznań, Poland
National Court Register (KRS): 0001050042
Tax Identification Number (NIP): 7831885200
National Official Business Register (REGON): 525991139

Services – services provided by the Service Provider or its affiliated entities, including application programming interfaces, software, tools, programming services, data, documentation, and websites, offered by the Service Provider under the terms stipulated in this Regulation.

User – an individual or legal entity using the Services under the terms defined in this Regulation.

Website – the website available at the address eleo.ai, through which the Service Provider offers the Services.

<b>II. Types and Scope of Services</b>

1. Scope of Services

The scope of Services is specified on the Website and may change in accordance with the provisions of this Regulation.

2. Registration and Access

To use the Services, the User must be at least 13 years old. If the User is not yet 18 years old, they must have the consent of a parent or legal guardian to use the Services. If the User is using the Services on behalf of an entity such as a company, they must have the authority to accept the Regulation on its behalf. To create an account, the User must provide accurate and complete information. The User may not share their access data or account with individuals outside their organization and is responsible for all actions taken using their authentication data.

3. Content

Within the scope of the Service, the User can provide input data ("Query") and receive output data generated and returned by the Services based on the Queries. Input and output data together are referred to as "Content." In the relationships between the parties and to the extent permitted by applicable law, the User owns all Queries, provided they comply with this Regulation. Subject to the User's compliance with this Regulation, the Service Provider hereby assigns to the User all its rights, titles, and interests in the Content. This means that the User may use the Content for any purpose, including commercial purposes such as sale or publication, as long as they comply with this Regulation. The User is responsible for the Content, including ensuring that it does not violate any applicable laws or this Regulation.

4. Similarity of Content

Due to the nature of machine learning, Content may not be unique to all Users, and the Services may generate the same or similar results for different Users. Different Users may ask similar questions and receive the same answers. Answers generated for other Users are not considered Content within the meaning of this Regulation.

5. Use of Content for Service Improvement

The Service Provider does not use Content, Queries, and generated responses to develop or improve the Services.

6. Accuracy

Artificial intelligence and machine learning are rapidly evolving fields of science. We continuously work to improve our Services to make them more accurate, reliable, secure, and beneficial. Considering the probabilistic nature of machine learning, using the Services may, in some cases, result in inaccurate output data that does not accurately reflect real individuals, places, or facts. The User should independently assess the accuracy of any result and Content as appropriate to their use case, including by verifying the Content through human review.

7. Termination or Suspension of Services

The User begins to use the Services from the moment of the first use. The User may terminate the use of the Service at any time and for any reason by ceasing to use the Services and Content. The Service Provider may terminate the provision of Services for any reason by notifying the User with at least 30 days' notice. The Service Provider may immediately terminate the provision of Services if the User materially breaches the provisions of the Regulation, if changes occur in relationships with external technology providers beyond the control of the Service Provider, or to comply with legal requirements. The Service Provider may suspend the User's access to the Services if the User does not comply with the Regulation, if the User's use of the Services poses a security risk to the Service, the Service Provider, or any third party, or if the User's use of the Services is fraudulent or could expose the Service Provider or any third party to liability.

8. Relationship of the Parties

These Regulation terms do not create a partnership, joint venture, or agency relationship between the User and the Service Provider or any associated entity of the Service Provider. Neither party has the right to bind the other party or incur obligations on its behalf without the prior written consent of the other party.

9. Export Control

Services may not be exported or re-exported:
a) to any countries subject to U.S. and European Union embargoes (collectively referred to as "Embargoed Countries") or
b) to any persons listed on the U.S. Department of the Treasury's specially designated nationals list or the Denied Persons List or Entity List of the U.S. Department of Commerce or any other restricted party lists (currently existing or in the future) identified by the Office of Foreign Assets Control (collectively referred to as "Restricted Parties," Lists").

10. Remedies

The User acknowledges that a breach of this Regulation may cause irreparable harm to the Service Provider and its associated entities, and the Service Provider shall have the right to seek remedies.

<b>III. Rights and Obligations of the Parties</b>

Service Provider's Rights:

a) The Service Provider reserves the right to modify the scope and nature of the Services and to suspend their provision if necessary for the maintenance or modernization of the Website.

b) The Service Provider retains the right to monitor User behavior on the Website to ensure its security and compliance with the law.

c) The Service Provider may assign these Regulation terms in connection with a merger, acquisition, or sale of all or substantially all of the Service Provider's assets to any other entity.

d) Modifications. The Service Provider may amend this Regulation periodically by publishing its current version on the Website or, if an update significantly and adversely affects the User's rights or obligations under this Regulation, by sending an email notification to the User's associated account or providing notification within the Service. These changes will take effect no earlier than 30 days from the date of publication or notification. All other changes will become effective immediately. Continued use of the Services after any such changes constitutes acceptance of the changes.

User's Rights:

a) The User has the right to use the Services in accordance with their intended purpose and the provisions of this Regulation.

b) The User has the right to file complaints regarding the provided Services in accordance with the provisions of this Regulation.

c) The User has the right to terminate the agreement in accordance with the provisions of this Regulation.

d) A consumer who has entered into a distance contract or an off-premises contract has the right to withdraw from it without giving any reason, within 14 days.

Service Provider's Obligations:

a) The Service Provider undertakes to provide the Services with due care and in compliance with applicable law.

User's Obligations:

The User undertakes to use the Services in accordance with the provisions of this Regulation and applicable law. The User may not:

Utilize the Services in a manner that infringes, misappropriates, or violates the rights of any individual or organization.
Engage in reverse engineering, reverse compiling, decompiling, translating, or otherwise attempting to uncover the source code or underlying components of the models, algorithms, and systems of the Services (except where such limitations conflict with applicable law).
Employ the output data of the Services to develop models that compete with Eleo.ai and models used by our suppliers.
Employ any automated or software methods to extract data or output data from the Services, including scraping, data harvesting from the internet, or data extraction from the internet.
Falsely represent that the output data of the Services was generated by a human when it was not, or otherwise violate our Usage Policies.
Transmit any personal data (including photographs) of children under 13 years of age or below the applicable digital consent age using the Services.
Utilize the Services without adhering to any limits, rates, and other requirements specified in the documentation.
Use the Services in countries other than those currently supported by Eleo.ai.
Security. The User shall implement reasonable and appropriate measures to secure access to and use of the Services. If the User discovers any vulnerabilities or breaches related to the use of the Services, they agree to promptly notify the Service Provider and provide detailed information about the vulnerability or breach. If the terms of the Regulation, due to their nature, should apply after the use of the Services has ended, they shall remain in force.
Trademark Use. The User may not use the names, logos, or trademarks of the Service Provider or any of its affiliated entities without the prior written consent of the Service Provider.
The User may not assign or transfer any rights or obligations arising from this Regulation. Any purported transfers and delegations are null and void.
The User represents and warrants that they are not located in any country subject to an embargo or listed on any restricted party lists in the EU and the USA. The User agrees to comply with all applicable regulations regarding embargoed countries or restricted party lists, including any requirements and obligations related to directly identifying end users.

<b>IV. Intellectual Property</b>

1. All rights to the content, software, graphics, and other materials available on the Website belong to the Service Provider and are protected by copyright and other applicable laws.

2. The User is not authorized to copy, distribute, or modify the information available on the Website without the written consent of the Service Provider.

<b>V. Technical Requirements</b>

1. The User agrees to use the Services on devices and browsers that meet the technical requirements specified on the Website.

2. The Service Provider is not responsible for technical issues resulting from the User's failure to meet the technical requirements.

<b>VI. Privacy</b>

1. The privacy policy governing the processing of Users' personal data is available on the Website.

<b>VII. Complaints and Warranty</b>

1. Complaints regarding the provided Services and warranty claims can be submitted by the User in writing to the address of the Service Provider or via the contact form available on the Website.

2. The complaint should include a detailed description of the problem and the contact details of the person making the complaint.

3. The Service Provider will consider the complaint within 14 days from the date of its receipt. A lack of response within this period means that the Service Provider has acknowledged the complaint as justified.

4. In case of detecting a defect in the service, the Client has the right to: demand the removal of the defect, submit a statement on reducing the price, submit a statement on withdrawing from the contract, unless the Service Provider immediately and without excessive inconvenience for the Client removes the defect.

5. If the defect is insignificant, the Client cannot withdraw from the contract.

6. The Client should describe the type of detected defect and indicate their expectations regarding the way of its resolution.

<b>VIII. Payments</b>

1. Services may be subject to charges. Payment terms are specified on the Website.

2. The User undertakes to settle all fees accrued on their User account ("Fees") in accordance with the prices and terms defined on the Website or as otherwise agreed upon in writing between the parties.

3. The Service Provider reserves the right to rectify pricing errors or mistakes, even if an invoice has already been issued or payment received.

4. The User is obligated to provide complete and accurate billing information, including a valid and authorized payment method.

5. The Service Provider will apply the payment method indicated by the User at agreed intervals. The Service Provider may reasonably change the billing date.

6. The User authorizes the Service Provider, its affiliated entities, and external payment processing entities to charge the Fees to the User's designated card or use another payment method specified by the User. If payment cannot be processed, the Service Provider may suspend access to the Services until payment is received. Fees are payable in US dollars and are due upon invoicing. Payments are non-refundable, except as expressly provided in this Regulation.

7. Taxes. Unless otherwise specified, the Fees include applicable taxes based on Polish tax jurisdiction and EU regulations as appropriate. If taxes and customs duties apply to the User based on their place of residence, the User is responsible for paying all taxes related to the purchase. The User agrees to make timely payments for such Taxes and provide the Service Provider with documentation showing payment or additional evidence as reasonably requested by the Service Provider. The Service Provider uses the name and address provided in the User's account registration as the place of delivery for tax purposes, and the User warrants that this information is accurate and current.

8. Price Changes. The Service Provider may change the prices of the Services by posting a notification on the User's account and/or on the Website. Price changes will take effect 14 days after their publication, except for changes made for legal reasons or changes made for free Services or Beta Services (as defined in our Terms of Service), which will take effect immediately. Any price changes will apply to Fees charged to the User's account immediately upon the changes taking effect.

9. Disputes and Payment Delays. If the User wishes to dispute any Fees or taxes, they may contact the Service Provider at cash@eleo.ai within thirty (30) days from the date of the disputed invoice. Uncontested past-due amounts may incur a financial charge of 2% of the unpaid balance per month. If any amount of the User's Fees becomes overdue, the Service Provider may immediately suspend the User's access to the Services.

10. Free Services. The Service Provider may offer certain Services free of charge. The User may not use more than one account to access free Services. If the Service Provider determines that the User is not using free Services in good faith, the Service Provider may charge the User standard Fees or suspend the User's access to the Services.

11. The entity providing online payment services is Autopay S.A.

12. Available payment methods: Credit/Debit Cards: Visa, Visa Electron, Mastercard, MasterCard Electronic, Maestro.

13. In the event of a need to refund a transaction made by a customer using a credit/debit card, the seller will process the refund to the bank account associated with the Purchaser's credit/debit card.

14. The order processing time begins from the moment a successful payment authorization is received.

<b>IX. Delays</b>

1. The Service Provider shall not be held liable for delays in accessing the Services resulting from technical reasons or force majeure.

<b>X. Reservations</b>

1. Confidentiality. 

Users may access the Confidential Information of the Service Provider, its affiliated entities, and other third parties. Users may use Confidential Information solely as necessary for using the Services in accordance with this Regulation. Users may not disclose Confidential Information to any third party and must protect Confidential Information in the same manner as they protect their own similar confidential information, using at least reasonable care. Confidential Information means non-public information that the Service Provider, its affiliated entities, or third parties designate as confidential or that, under the circumstances, should reasonably be understood to be confidential, including software, specifications, and other non-public business information. Confidential Information does not include information that:
a) is or becomes generally available to the public without the User's breach;
b) the User already possesses without any obligation of confidentiality when received under this Regulation;
c) is rightfully disclosed to the User by a third party without any confidentiality obligations; or
d) the User independently develops without the use of Confidential Information. The User may disclose Confidential Information if required by law or a valid court or government order, provided that the User gives the Service Provider prior written notice and makes reasonable efforts to limit the scope of the disclosure, to the extent practicable in each case.

2. Reservation. 

THE SERVICES ARE PROVIDED "AS IS." EXCEPT TO THE EXTENT REQUIRED BY LAW, THE SERVICE PROVIDER AND ITS AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE) REGARDING THE SERVICES AND DISCLAIM ALL WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND WARRANTIES ARISING OUT OF THE COURSE OF DEALING OR USAGE OF TRADE. THE SERVICE PROVIDER DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ACCURATE, OR FREE OF ERRORS OR THAT ANY CONTENT WILL BE SECURE, COMPLETE, OR UNCHANGED.

3. The Service Provider reserves the right to change this regulation. Changes will take effect from the date of their publication on the Website.

4. Third-Party Services. Any software, services, or other products from third-party sites that the User uses in connection with the Services are subject to their own terms, and the Service Provider is not responsible for third-party products.

<b>XI. Limitation of Liability</b>

1. The Service Provider shall not be liable for damages arising from the use or inability to use the Services unless such damages result from the intentional actions or gross negligence of the Service Provider.

2. The Service Provider shall not be liable for content provided by Users or for their actions on the Website.

3. The Service Provider shall not be liable for any loss of User data.

4. Limitations of Liability.

NEITHER THE SERVICE PROVIDER NOR ANY OF ITS AFFILIATES OR LICENSORS SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, LOSS OF VALUE, INABILITY TO USE OR ACCESS DATA, OR OTHER LOSSES, EVEN IF THE SERVICE PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY UNDER THESE TERMS SHALL NOT EXCEED THE GREATER OF THE AMOUNT PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS PRIOR TO THE LIABILITY OR ONE HUNDRED U.S. DOLLARS ($100 USD). THE LIMITATIONS SET FORTH IN THIS SECTION APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.

5. Indemnification. 

The User agrees to defend, indemnify, and hold harmless the Service Provider, its affiliated entities, and personnel from any claims, losses, and expenses (including attorney's fees) arising out of or related to the User's use of the Services, including any Content, products, or services that the User develops or offers in connection with the Services, as well as any breach of this Regulation or violation of applicable law by the User.

<b>XII. Final Provisions</b>

1. This Regulation is subject to Polish law.

2. Any disputes regarding this Regulation and the Services provided will be attempted to be resolved amicably by the parties.

3. The competent court for resolving disputes related to the Services is the court with jurisdiction over the Service Provider's registered office.

4. Matters not regulated by this Regulation shall be governed by the provisions of the Civil Code and other relevant laws.

5. Contact. Any questions and notifications can be directed to the Service Provider at the following address:

ELEO PSA
ul. Św. Marcin 29/9, 61-806 Poznań, Poland
E-mail: office@eleo.ai`
  return (
    <Article>
      <pre
        className='whitespace-pre-wrap'
        style={{ font: 'inherit' }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></pre>
    </Article>
  )
}
