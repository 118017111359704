import {
  AutoAwesome,
  Close,
  CropFree,
  Delete,
  Download,
  MoreVert,
  Save,
  Share,
} from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { ViewContext } from 'components/lib'
import { successToast } from 'helpers'
import { downloadImage, saveToGallery } from 'helpers/image'
import { Sparkle } from 'phosphor-react'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectClientId, setPending } from 'store/generations'
import { Modal } from 'toolComponents/generic/modal'
import { ButtonGroup, ListPosition, Microbutton } from 'ui'
import { v4 as uuidv4 } from 'uuid'

const DEFAULT_MODAL_DATA = {
  isVisible: false,
  title: '',
  subtitle: '',
  acceptLabel: '',
  type: '',
  context: {},
}

export default function ImageDisplay({
  image,
  imageData,
  preview,
  moreLikeThis,
  noupload,
  onModify,
  showShareModal,
  removeItem,
  previewProportions,
  imagePreviewData,
  setImagePreviewData,
}) {
  const { t } = useTranslate()
  const context = useContext(ViewContext)
  const navigate = useNavigate()
  const dispatchAction = useDispatch()
  const clientId = useSelector(selectClientId)
  const { width } = useWindowDimensions()

  const [anchorEl, setAnchorEl] = useState()

  // Data for modal
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [downloadName, setDownloadName] = useState('')
  const [dimentions, setDimentions] = useState()
  const [upscaleValue, setUpscaleValue] = useState(1)

  // Remove unwanted attributes before saving image data
  const newImageData = {
    ...imageData,
    template: undefined,
    allowStyles: undefined,
    promptId: undefined,
  }

  useEffect(() => {
    const img = new Image()
    img.onload = () =>
      setDimentions({
        width: img.width,
        height: img.height,
      })
    img.src = image

    return () => {
      img.onload = null
    }
  }, [image])

  const handleCallback = async (type) => {
    switch (type) {
      case 'download':
        if (upscaleValue === 1) {
          await downloadImage(image, downloadName ?? t('eleo-default-image-name', 'Your image'))
          return
        }

        try {
          const data = {
            clientId: clientId,
            front: true,
            data: { image: image, times: upscaleValue, action: 'upscale', samples: 1 },
            promptId: uuidv4(),
          }

          dispatchAction(
            setPending({
              upscale: {
                id: data.promptId,
                name: downloadName ?? t('eleo-default-image-name', 'Your image'),
                inQueue: true,
              },
            })
          )

          await axios.post('/api/ai/comfy', data)

          successToast(
            <T keyName='eleo-upscale-queued'>
              Your request has been queued. Image will be downloaded automatically
            </T>,
            null,
            2000
          )
        } catch (err) {
          dispatchAction(
            setPending({
              upscale: {},
            })
          )

          context.handleError(err)
        }
        break
      case 'gallery':
        await saveToGallery(image, downloadName ?? image.split('generations/')[1], newImageData)
        break
      default:
        break
    }
  }

  const handleOpenDownloadModal = () => {
    setDownloadName('')
    setUpscaleValue(1)

    setModalData({
      isVisible: true,
      title: <T keyName='eleo-download-modal-title' defaultValue='Download image' />,
      subtitle: (
        <T
          keyName='eleo-download-modal-subtitle'
          defaultValue='If you want to modify this image in the future, we rocmmend saving it in the Eleo gallery. This way you will have an access to the initial request during the modification.'
        />
      ),
      acceptLabel: t('eleo-download', 'download'),
      type: 'download',
      context: {},
    })
  }

  const handleOpenGalleryModal = () => {
    setDownloadName('')

    setModalData({
      isVisible: true,
      title: <T keyName='eleo-gallery-modal-title' defaultValue='Save to gallery' />,
      // subtitle: (
      //   <T
      //     keyName='eleo-download-modal-subtitle'
      //     defaultValue='If you want to modify this image in the future, we rocmmend saving it in the Eleo gallery. This way you will have an access to the initial request during the modification.'
      //   />
      // ),
      acceptLabel: t('eleo-save', 'save'),
      type: 'gallery',
      context: {},
    })
  }

  const options = (dimentions) => {
    if (!dimentions) return [{}, {}, {}]

    const breakpoint1 = 300000
    const breakpoint2 = 110000000

    let size
    if (dimentions.width * dimentions.height < breakpoint1) size = 1
    else if (dimentions.width * dimentions.height < breakpoint2) size = 2
    else size = 4

    const options = [
      {
        label: `${Math.floor(dimentions.width / size)}x${Math.floor(dimentions.height / size)}`,
        value: 1 / size,
      },
      {
        label: `${Math.floor((2 * dimentions.width) / size)}x${Math.floor((2 * dimentions.height) / size)}`,
        value: 2 / size,
      },
      {
        label: `${Math.floor((4 * dimentions.width) / size)}x${Math.floor((4 * dimentions.height) / size)}`,
        value: 4 / size,
      },
    ]

    return options
  }

  const modalContent = {
    download: (
      <div className='space-y-[20px] p-[24px]'>
        <div className='space-y-[5px]'>
          <p className='body-small text-brand-gray-dark'>
            <T keyName='eleo-download-modal-image-name' defaultValue='Image name' />
          </p>
          <input
            autoFocus
            spellCheck={false}
            value={downloadName}
            onChange={(e) => setDownloadName(e.target.value)}
            className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
            placeholder={t('eleo-download-modal-input-placeholder', 'Enter image name')}
          />
        </div>

        <div className='space-y-[5px]'>
          <p className='body-small text-brand-gray-dark'>
            <T keyName='eleo-download-modal-image-name' defaultValue='Image name' />
          </p>
          <ButtonGroup
            className='w-full'
            options={options(dimentions)}
            value={Number(upscaleValue)}
            onClick={(e) => setUpscaleValue(e.target.value)}
          />
        </div>
      </div>
    ),
    gallery: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          <T keyName='eleo-download-modal-image-name' defaultValue='Image name' />
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={downloadName}
          onChange={(e) => setDownloadName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
          placeholder={t('eleo-download-modal-input-placeholder', 'Enter image name')}
        />
      </div>
    ),
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'context-popover' : undefined

  const contextMenu = image && (
    <>
      <div
        className={classNames(
          'absolute bottom-[10px] right-[10px] hidden items-center gap-1 rounded-[4px] bg-white p-1 opacity-0 shadow-[1px_1px_5px_#00000035] transition-opacity duration-200 ease-in-out group-hover:opacity-100 sm:flex',
          anchorEl && 'opacity-100'
        )}
      >
        {/* <button
        onClick={() => imageToClipboard(image)}
        title={t('eleo-title-copy', 'Copy this image')}
      >
        <ContentCopy />
      </button> */}

        {moreLikeThis && width >= 1024 && (
          <>
            <Microbutton
              className='bg-transparent'
              variant='transparent-light'
              // onClick={() => setModalIsVisible({ visible: true })}
              onClick={handleClick}
              icon={<MoreVert fontSize='inherit' />}
            />
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className='space-y-[10px] p-2 pb-[10px]'>
                <ListPosition
                  icon={<Share fontSize='inherit' />}
                  text={t('eleo-result-share', 'Share')}
                  className='w-[100px]'
                  onClick={() => {
                    handleClose()
                    showShareModal(image)
                  }}
                />

                <ListPosition
                  icon={<AutoAwesome fontSize='inherit' />}
                  text={t('eleo-modify', 'Modify')}
                  onClick={
                    onModify
                      ? onModify
                      : () =>
                          navigate('/image/modify', {
                            state: {
                              baseImage: image ?? '',
                              imageData: newImageData,
                            },
                          })
                  }
                />

                {preview && (
                  <ListPosition
                    icon={<CropFree fontSize='inherit' />}
                    text={t('eleo-zoom', 'Zoom')}
                    onClick={() => {
                      handleClose()
                      preview(image)
                    }}
                  />
                )}
              </div>
            </Popover>
          </>
        )}

        <div className='border-brand-form-accent h-[18px] w-[1px] border-r' />

        {!noupload && (
          <Microbutton
            className='bg-transparent'
            variant='transparent-light'
            onClick={() => handleOpenGalleryModal()}
            title={t('eleo-title-upload', 'Save this image in your gallery')}
            icon={<Save fontSize='inherit' />}
          />
        )}

        <Microbutton
          className='h-[28px] bg-transparent'
          variant='transparent-light'
          onClick={() => handleOpenDownloadModal()}
          title={t('eleo-title-download', 'Download this image')}
          icon={<Download fontSize='inherit' />}
        />

        {removeItem && (
          <Microbutton
            className='h-[28px] bg-transparent'
            variant='transparent-light'
            onClick={() => removeItem(image)}
            icon={<Delete fontSize='inherit' />}
            title={t('eleo-title-delete', 'Delete this image')}
          />
        )}

        {moreLikeThis ? (
          <>
            <div className='border-brand-form-accent h-[18px] w-[1px] border-r' />

            <Microbutton
              className='bg-transparent'
              variant='transparent-light'
              iconPlacement='left'
              icon={<Sparkle fontSize={18} weight='fill' />}
              text={t('eleo-more-like-this', 'More like this')}
              onClick={() => moreLikeThis()}
            />
          </>
        ) : (
          <Microbutton
            className='h-[28px] bg-transparent'
            variant='transparent-light'
            onClick={() =>
              navigate('/image/modify', {
                state: {
                  baseImage: image ?? '',
                  imageData: newImageData,
                },
              })
            }
            icon={<AutoAwesome fontSize='inherit' />}
            title={t('eleo-title-modify', 'Modify this image')}
          />
        )}
      </div>
    </>
  )

  return (
    <>
      {image ? (
        <>
          <div
            className='group relative max-h-full max-w-full rounded-[10px]'
            style={{
              aspectRatio: previewProportions ?? '1/1',
            }}
            onClick={() => width < 1024 && preview(image, true)}
          >
            <img
              className={classNames(
                'h-full max-h-full w-full max-w-full rounded-[10px]',
                noupload && 'w-full'
              )}
              src={image}
              alt=''
            />
            {contextMenu}
          </div>
          {imagePreviewData && imagePreviewData.isVisible && imagePreviewData.target === image && (
            <div
              className={classNames(
                'fixed inset-0 z-[999] flex flex-col items-center justify-between',
                imagePreviewData.isMobile ? 'bg-black' : 'bg-black/90'
              )}
              onClick={(e) =>
                e.target === e.currentTarget &&
                setImagePreviewData((prev) => ({ ...prev, isVisible: false }))
              }
            >
              <div />
              <div className='relative lg:h-[70dvh]'>
                <img
                  className={classNames(
                    'lg:max-h-auto max-h-[60dvh] object-contain shadow-lg lg:h-[70dvh] lg:w-full lg:bg-white lg:object-cover',
                    imagePreviewData.isMobile ? 'w-full' : 'w-[90vw] rounded-lg'
                  )}
                  src={imagePreviewData.target}
                  alt=''
                />
                {!imagePreviewData.isMobile && (
                  <Close
                    size={32}
                    className='absolute -right-[2px] -top-8 text-white hover:cursor-pointer'
                    onClick={() => setImagePreviewData((prev) => ({ ...prev, isVisible: false }))}
                  />
                )}
              </div>
              <div
                className={classNames(
                  'my-2 flex w-full items-center justify-center gap-[22px] px-[22px]',
                  !imagePreviewData.isMobile && 'invisible'
                )}
              >
                <>
                  <Microbutton
                    className='size-[36px] bg-transparent text-white'
                    variant='plain'
                    onClick={handleClick}
                    icon={<MoreVert fontSize='inherit' />}
                  />
                  <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <div className='space-y-[10px] p-2 pb-[10px]'>
                      <ListPosition
                        icon={<Share fontSize='inherit' />}
                        text={t('eleo-result-share', 'Share')}
                        className='w-[100px]'
                        onClick={() => {
                          handleClose()
                          showShareModal(imagePreviewData.target)
                        }}
                      />

                      <ListPosition
                        icon={<AutoAwesome fontSize='inherit' />}
                        text={t('eleo-modify', 'Modify')}
                        onClick={() =>
                          navigate('/image/modify', {
                            state: {
                              baseImage: imagePreviewData.target ?? '',
                              // imageData: newImageData,
                            },
                          })
                        }
                      />
                    </div>
                  </Popover>

                  <Microbutton
                    className='size-[36px] bg-transparent text-white'
                    variant='plain'
                    onClick={() => handleOpenGalleryModal()}
                    title={t('eleo-title-upload', 'Save this image in your gallery')}
                    icon={<Save fontSize='inherit' />}
                  />

                  <Microbutton
                    className='size-[36px] bg-transparent text-white'
                    variant='plain'
                    onClick={() => handleOpenDownloadModal()}
                    title={t('eleo-title-download', 'Download this image')}
                    icon={<Download fontSize='inherit' />}
                  />

                  <div className='h-[18px] w-[1px] border-r border-white/15' />

                  <Microbutton
                    className='h-[36px] bg-transparent text-white'
                    variant='plain'
                    iconPlacement='left'
                    icon={<Sparkle fontSize={18} weight='fill' />}
                    text={t('eleo-more-like-this', 'More like this')}
                    onClick={() => {
                      setImagePreviewData((prev) => ({ ...prev, isVisible: false }))
                      moreLikeThis()
                    }}
                  />
                </>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className='bg-brand-form-accent-light max-h-full max-w-full rounded-[10px]'
          style={{
            aspectRatio: previewProportions ?? '1/1',
          }}
        />
      )}

      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        callback={() => handleCallback(modalData.type, modalData.context)}
        isValid
      />
    </>
  )
}
