export const chat = {
  history: {
    title: 'History',
    content: `History displays your past chats with Eleo in chronological order. To resume an old conversation, just select one from the list.`,
    tolgee_id: 'eleo-tool-chat-help-tooltip-1',
  },

  clear: {
    title: 'Clear',
    content: `Click to begin a new conversation. This action clears the previous chat history, so Eleo starts fresh without referring back to past interactions.`,
    tolgee_id: 'eleo-tool-chat-help-tooltip-2',
  },

  selectFromList: {
    title: 'Select from List',
    content: 'To resume an old conversation, just select one from the list.',
    tolgee_id: 'eleo-tool-chat-help-tooltip-3',
  },

  samples: {
    title: 'Sample Questions',
    content:
      "Here, you'll find examples of what you can ask Eleo. Try clicking on a sample to see how Eleo responds.",
    tolgee_id: 'eleo-tool-chat-help-tooltip-4',
  },

  languages: {
    title: 'Language',
    content: "Select the desired language for Eleo's response.",
    tolgee_id: 'eleo-tool-chat-help-tooltip-5',
  },

  information: {
    title: 'Information',
    content:
      "Select the  Information setting if you're looking to get facts or have a casual chat with Eleo.",
    tolgee_id: 'eleo-tool-chat-help-tooltip-6',
  },

  advice: {
    title: 'Advice',
    content: "Use the Advice setting if you're seeking suggestions or guidance.",
    tolgee_id: 'eleo-tool-chat-help-tooltip-7',
  },

  translation: {
    title: 'Translation',
    content:
      'Enable the  Translation setting if you need Eleo to translate your typed sentences. It will translate your text into your chosen language.',
    tolgee_id: 'eleo-tool-chat-help-tooltip-8',
  },

  idea: {
    title: 'Idea',
    content: 'Pick the Idea setting if you want creative ideas from Eleo about a specific topic.',
    tolgee_id: 'eleo-tool-chat-help-tooltip-9',
  },

  start: {
    title: 'Command Entry Window',
    content:
      'This is where you type your questions or tasks for Eleo. Eleo keeps track of your conversation but will start with a clean slate when you click New Chat.',
    tolgee_id: 'eleo-tool-chat-help-tooltip-10',
  },

  image: {
    title: 'Use an image in your conversation',
    content: "You can upload an image and ask eleo about it's contents",
    tolgee_id: 'eleo-tool-chat-help-tooltip-image',
  },

  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },

  yourStory: {
    title: 'Your Story',
    content:
      "Choose 'Your Story' as the content source for your Chatbot. Create and regularly update this content in the 'Your Story' section under 'My Account' to reflect your personal, company, or product narrative.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-3',
  },

  buttonGroupMobile: {
    title: 'Information, Advice, Translation, Idea',
    content: (
      <div className='flex flex-col gap-[5px]'>
        <span>
          Select the  Information setting if you're looking to get facts or have a casual chat with
          Eleo.
        </span>
        <span>Use the Advice setting if you're seeking suggestions or guidance.</span>
        <span>
          Enable the  Translation setting if you need Eleo to translate your typed sentences. It
          will translate your text into your chosen language.
        </span>
        <span>
          Pick the Idea setting if you want creative ideas from Eleo about a specific topic.
        </span>
      </div>
    ),
  },
}
