export function addContextToFormData(formData, context) {
  for (let i = 0; i < context.length; i++) {
    const item = context[i]
    if (item.invalid) continue

    for (let key in item) {
      if (key === 'invalid') continue
      if (item.hasOwnProperty(key)) {
        formData.append(`context[${i}][${key}]`, item[key])
      }
    }
  }

  return formData
}
