import classNames from 'classnames'
import { CircleNotch } from 'phosphor-react'
import React from 'react'
import { Button } from 'ui'
import { T } from '@tolgee/react'
const CheckTextActions = ({ clearInputs, handleSubmitWriterOptions, loading }) => {
  return (
    <>
      {/* <Button
        color="gray"
        className={classNames('h-[45px] px-[18px]')}
      >
        <div className="flex items-center gap-[6px]">
          <Trash weight="fill" color="#363636" size={24} />
        </div>
      </Button> */}
      <Button
        color='gray'
        className={classNames('h-[45px]')}
        onClick={clearInputs}
        data-tooltip-id='writer-checkText-clear'
      >
        <p className='text-[15px] font-medium uppercase leading-none'>
          <T keyName='eleo-history-action-clear'>clear</T>
        </p>
      </Button>
      <Button
        className={classNames('h-[45px] w-full')}
        onClick={() => handleSubmitWriterOptions()}
        color='dark'
        disabled={loading.name === 'generate' && loading.status}
        data-tooltip-id='writer-checkText-checkText'
      >
        {loading.name === 'generate' && loading.status ? (
          <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
        ) : (
          <p className='text-[15px] font-medium uppercase leading-none'>
            <T keyName='eleo-tool-check-text-action'>check text</T>
          </p>
        )}
      </Button>
    </>
  )
}

export default CheckTextActions
