import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext, useEffect, useState } from 'react'

export function useYourStory() {
  const context = useContext(ViewContext)

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [formattedData, setFormattedData] = useState([])

  async function fetch() {
    setIsLoading(true)

    try {
      const res = await axios.get('/api/your-story/templates')

      const stories = res.data.data?.['your_story_templates']
      if (!stories) return

      setData(stories)
      const formated = stories?.map((story) => ({
        value: story.id,
        label: story.templateName,
      }))
      setFormattedData(formated)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return { data: data, formatted: formattedData, reload: fetch, isLoading }
}
