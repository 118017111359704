/***
 *
 *   About Us Page
 *
 **********/
import { T } from '@tolgee/react'
const stats = [
  { label: 'To generate breathtaking images', value: '5 Seconds', id: 'eleo-stats-1' },
  { label: 'To access any information', value: '3 Clicks', id: 'eleo-stats-2' },
  { label: 'Languages supported globally', value: 'Over 40', id: 'eleo-stats-3' },
]
const values = [
  {
    name: 'Made for humans',
    description:
      'Our solutions are the quintessence of practical use of artificial intelligence. Thanks to Eleo, you can take advantage of its potential through any computer, tablet, or phone. Without unnecessary hassle, without additional courses. Our tools will help you gather information, create content, generate ideas and visualizations - quickly and efficiently.',
    id: 'eleo-values-1',
  },
  {
    name: 'Accessibility',
    description:
      'Our work brings results. Millions of users - from managers to students - rely on Eleo at work, in education and in everyday life. We provide tools that facilitate work and help achieve goals. Regardless of whether you are an independent user or work in a team, Eleo is for you.',
    id: 'eleo-values-2',
  },
  {
    name: 'Pure pleasure',
    description:
      'We are innovators in the field of communication with artificial intelligence. Our specialty is creating prompts that enable fast and efficient collaboration with AI. We want using AI technology to be a pure pleasure, regardless of whether you are a technology professional or a complete beginner user.',
    id: 'eleo-values-3',
  },
  {
    name: 'Best possible experiences',
    description:
      'We use the latest achievements of AI technology, such as ChatGPT, Bard, Claude, and Stable Diffusion, to provide you with the best possible experiences. And our commitment does not end with technology. We support the Prometheus association and strive for ethical use of artificial intelligence.',
    id: 'eleo-values-4',
  },
]

export function About() {
  return (
    <div className='isolate bg-white'>
      {/* Hero section */}
      <div className='relative isolate -z-10'>
        <svg
          className='absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]'
          aria-hidden='true'
        >
          <defs>
            <pattern
              id='1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84'
              width={200}
              height={200}
              x='50%'
              y={-1}
              patternUnits='userSpaceOnUse'
            >
              <path d='M.5 200V.5H200' fill='none' />
            </pattern>
          </defs>
          <svg x='50%' y={-1} className='overflow-visible fill-gray-50'>
            <path
              d='M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z'
              strokeWidth={0}
            />
          </svg>
          <rect
            width='100%'
            height='100%'
            strokeWidth={0}
            fill='url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)'
          />
        </svg>
        <div
          className='absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48'
          aria-hidden='true'
        >
          <div
            className='aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30'
            style={{
              clipPath:
                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
            }}
          />
        </div>
        <div className='overflow-hidden'>
          <div className='mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32'>
            <div className='mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center'>
              <div className='w-full max-w-xl lg:shrink-0 xl:max-w-2xl'>
                <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
                  <T keyName='eleo-about-header'>Welcome to Eleo.ai</T>
                </h1>
                <p className='relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none'>
                  <T keyName='eleo-about-description'>
                    We are here to ensure you have access to the incredible possibilities offered by
                    artificial intelligence. Unlock your productivity and creativity with Eleo.ai.
                    Imagine that you can save thousands of hours searching for information, creating
                    content, discovering ideas, and images. Eleo is the tool that will help you
                    fully utilize the potential of artificial intelligence in your everyday life and
                    work.
                  </T>
                </p>
              </div>
              <div className='mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0'>
                <div className='ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80'>
                  <div className='relative'>
                    <img
                      src='https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80'
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                </div>
                <div className='mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36'>
                  <div className='relative'>
                    <img
                      src='https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80'
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                  <div className='relative'>
                    <img
                      src='https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80'
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                </div>
                <div className='w-44 flex-none space-y-8 pt-32 sm:pt-0'>
                  <div className='relative'>
                    <img
                      src='https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80'
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                  <div className='relative'>
                    <img
                      src='https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80'
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content section */}
      <div className='mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8'>
        <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-none'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            <T keyName='eleo-about-our-mission'>Our mission</T>
          </h2>
          <div className='mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row'>
            <div className='lg:w-full lg:max-w-2xl lg:flex-auto'>
              <p className='text-xl leading-8 text-gray-600'>
                <T keyName='eleo-about-our-mission-description-1'>
                  Our mission is simple: Free your productivity and creativity with Eleo. We want to
                  enable people worldwide to use AI in their everyday life and work. We see a future
                  where human collaboration with artificial intelligence becomes the norm, and the
                  progress of humanity becomes even more dynamic.
                </T>
              </p>
              <div className='mt-10 max-w-xl text-base leading-7 text-gray-700'>
                <p>
                  <T keyName='eleo-about-our-mission-description-2'>
                    Eleo is more than just a tool. We create innovative solutions that allow you to
                    achieve the best results using artificial intelligence on a computer, tablet, or
                    phone. Thanks to our tools, you can acquire information, create content,
                    generate ideas and images - everything in an efficient and inspiring way.
                  </T>
                </p>
                <p className='mt-10'>
                  <T keyName='eleo-about-our-mission-description-3'>
                    We are happy with the joint journey into the world of Eleo, where artificial
                    intelligence becomes your partner in achieving goals and fulfilling dreams.
                    Thank you for being with us on this extraordinary path!
                  </T>
                </p>
              </div>
            </div>
            <div className='lg:flex lg:flex-auto lg:justify-center'>
              <dl className='w-64 space-y-8 xl:w-80'>
                {stats.map((stat) => (
                  <div key={stat.label} className='flex flex-col-reverse gap-y-4'>
                    <dt className='text-base leading-7 text-gray-600'>
                      <T keyName={`${stat.id}-label`}>{stat.label}</T>
                    </dt>
                    <dd className='text-5xl font-semibold tracking-tight text-gray-900'>
                      <T keyName={`${stat.id}-value`}>{stat.value}</T>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* Image section */}
      <div className='mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8'>
        <img
          src='https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80'
          alt=''
          className='aspect-[5/2] w-full object-cover xl:rounded-3xl'
        />
      </div>

      {/* Values section */}
      <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:mx-0'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            <T keyName='eleo-our-values'>Our values</T>
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            <T keyName='eleo-our-values-description'>
              We value you time and want to help you manage it. Our world class solutions can
              improve you work cycle tenfold.
            </T>
          </p>
        </div>
        <dl className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none'>
          {values.map((value) => (
            <div key={value.name} className='pr-4'>
              <dt className='font-semibold text-gray-900'>
                <T keyName={`${value.id}-name`}>{value.name}</T>
              </dt>
              <dd className='mt-1 text-gray-600'>
                <T keyName={`${value.id}-description`}>{value.description}</T>
              </dd>
            </div>
          ))}
        </dl>
      </div>

      {/* Logo cloud */}
      {/* <div className="relative isolate -z-10 mt-32 sm:mt-48">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg
              className="h-[40rem] w-[80rem] flex-none stroke-gray-200"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                <path
                  d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
              />
            </svg>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
              Trusted by the world’s most innovative teams
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                alt="Reform"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                alt="Tuple"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                alt="SavvyCal"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                alt="Statamic"
                width={158}
                height={48}
              />
            </div>
          </div>
        </div> */}
    </div>
  )
}
