/***
 *
 *   Features/Chat Page
 *
 **********/

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/chat/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'
import { useTranslate } from '@tolgee/react'

export function Chat() {
  const { t } = useTranslate()

  const featureId = 'chat'
  const translations = {
    header: {
      title: `Chat`,
      subtitle: `Your 24/7 Smart Assistant`,
      description: `Watch the video to discover how Eleo Chat improves conversations with customers.`,
      link: {
        href: '/chat',
        text: t('eleo-try-now', 'Try now!'),
      },
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        description={t(
          `eleo-features-${featureId}-header-description`,
          translations.header.description
        )}
        videoLink='https://www.youtube.com/embed/qrLSWrmODOQ'
        link={translations.header.link}
      />
      <Content />
      <UseEleoToday next='chat' />
    </div>
  )
}
