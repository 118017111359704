import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { Sparkle } from 'phosphor-react'
import { Microbutton } from 'ui'

export function Footer({
  currentIndex,
  setCurrentIndex,
  history,
  isGenerating,
  setIsDisplayOutput,
  isLayoutLarge,
}) {
  const { t } = useTranslate()

  return (
    <div className='bg-brand-grey-bg border-brand-gray-light z-10 -ml-[5px] flex h-[72px] justify-between gap-[8px] border-t p-[16px] lg:gap-[10px]'>
      {!isLayoutLarge && (
        <Microbutton
          variant='transparent'
          text={t('eleo-form', 'form')}
          icon={<ChevronLeft fontSize='inherit' />}
          className='h-[40px] max-w-[160px] flex-1 uppercase'
          onClick={() => setIsDisplayOutput(false)}
        />
      )}

      <div
        id='image-navigation'
        className='flex select-none'
        data-tooltip-id='images-imageCreator-imageArrows'
      >
        <Microbutton
          iconSize={24}
          variant='transparent-light'
          className={classNames(
            'size-[40px] rounded-r-none',
            currentIndex <= 0 && '!text-brand-form-accent'
          )}
          disabled={currentIndex <= 0}
          icon={<FirstPage fontSize='inherit' />}
          onClick={() => setCurrentIndex(0)}
        />
        <Microbutton
          iconSize={24}
          variant='transparent-light'
          className={classNames(
            'size-[40px] rounded-none',
            currentIndex <= 0 && '!text-brand-form-accent'
          )}
          disabled={currentIndex <= 0}
          icon={<ChevronLeft fontSize='inherit' />}
          onClick={() => setCurrentIndex((prev) => prev - 1)}
        />
        <div className='bg-brand-form-accent-light flex size-[40px] items-center justify-center'>
          <span
            className={classNames(
              'text-[12px] font-medium',
              history.length === 0 ? 'text-brand-form-accent' : 'text-brand-violet'
            )}
          >
            {currentIndex + 1}/{history.length}
          </span>
        </div>
        <Microbutton
          iconSize={24}
          variant='transparent-light'
          className={classNames(
            'size-[40px] rounded-none',
            currentIndex === history.length - 1 && '!text-brand-form-accent'
          )}
          disabled={currentIndex === history.length - 1}
          icon={<ChevronRight fontSize='inherit' />}
          onClick={() => setCurrentIndex((prev) => prev + 1)}
        />
        <Microbutton
          iconSize={24}
          variant='transparent-light'
          className={classNames(
            'size-[40px] rounded-l-none',
            currentIndex === history.length - 1 && '!text-brand-form-accent'
          )}
          disabled={currentIndex === history.length - 1}
          icon={<LastPage fontSize='inherit' />}
          onClick={() => setCurrentIndex(history.length - 1)}
        />
      </div>

      {isLayoutLarge && isGenerating && currentIndex !== history.length - 1 && (
        <Microbutton
          variant='transparent-light'
          onClick={() => setCurrentIndex(history.length - 1)}
        >
          <div className='flex items-center !gap-[10px] pl-[14px] pr-[10px] text-[24px]'>
            <Sparkle weight='fill' fontSize={18} />
            <div className='text-[15px] font-medium uppercase leading-[1.2em]'>
              {t('eleo-image-output-generating images', 'Generating images')}
            </div>

            <LastPage fontSize='inherit' />
          </div>
        </Microbutton>
      )}
    </div>
  )
}
