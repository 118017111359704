/***
 *
 *   Features/Writer Page
 *
 **********/

import BoltIcon from '@mui/icons-material/Bolt'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import ReplyIcon from '@mui/icons-material/Reply'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'
import EditIcon from '@mui/icons-material/Edit'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import HistoryIcon from '@mui/icons-material/History'

import { useTranslate } from '@tolgee/react'

import image from 'images/features/writer.png'

export function Writer() {
  const { t } = useTranslate()

  const featureId = 'writer'
  const translations = {
    header: {
      title: 'Writer',
      subtitle: 'Professional Writer',
      description: `Professional Writer helps you write powerful documents and emails in any style and language, making your writing better and easier. Witness the magic of Eleo as it crafts a product description with the finesse of Donald Miller's Storybrand approach.`,
      link: {
        href: '/writer',
        text: t('eleo-try-now', 'Try now!'),
      },
    },
    content: {
      title: 'Why Choose Eleo?',
      subtitle: 'Everything you need',
      description:
        "Unlock the power of Eleo's “Writer” feature, your versatile tool for creating documents in any style, tone, and language, covering any topic imaginable. Eleo goes beyond just writing – it's your partner in refining documents and analyzing the emotional undertones of your text. Respond to emails, handle offers, or draft official letters with unprecedented ease, transforming the way you handle your daily communication.",
      features: [
        {
          name: 'Instantly create.',
          description:
            'Compelling articles, letters, posts, descriptions, narratives, and emails with a selection of templates at your disposal.',
          icon: BoltIcon,
          id: `eleo-features-${featureId}-content-feature-browser-plugin`,
        },
        {
          name: 'Tailored.',
          description:
            'Craft documents with a professional touch, tailoring the writing style, tone, and authorship to suit your specific needs.',
          icon: ArchitectureIcon,
          id: `eleo-features-${featureId}-content-feature-use-anywhere`,
        },
        {
          name: 'Generate.',
          description:
            'Craft responses to emails, official letters, and documents swiftly and efficiently.',
          icon: ReplyIcon,
          id: `eleo-features-${featureId}-content-feature-multilingual`,
        },
        {
          name: 'Summarize.',
          description: `Effortlessly edit texts, simplify complex ideas, and synthesize information into concise summaries.`,
          icon: EditIcon,
          id: `eleo-features-${featureId}-content-feature-personalized-assistant`,
        },
        {
          name: 'Analyze.',
          description:
            'Analyze your text for linguistic quality, statistical relevance, and emotional impact.',
          icon: AutoFixHighIcon,
          id: `eleo-features-${featureId}-content-feature-memorable-chat-experiences`,
        },
        {
          name: `History.`,
          description: `With Eleo's saved History feature, revisit, refine, and forward your documents anytime, ensuring nothing is ever lost.`,
          icon: HistoryIcon,
          id: `eleo-features-${featureId}-content-feature-chat-history`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        description={t(
          `eleo-features-${featureId}-header-description`,
          translations.header.description
        )}
        videoLink='https://www.youtube.com/embed/aEZwixJUN7o'
        link={translations.header.link}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <UseEleoToday next='writer' />
    </div>
  )
}
