import { CircularProgress } from '@mui/material'
import { useTranslate } from '@tolgee/react'

export function MessageLoader({ usesContext }) {
  const { t } = useTranslate()

  return (
    <div className='text-brand-text-secondary flex items-center gap-2'>
      <CircularProgress
        disableShrink
        size={18}
        sx={{ animationDuration: '1000ms' }}
        color='inherit'
      />
      {usesContext && (
        <div className='opacity-90'>
          {t('eleo-chat-loader', 'Searching... This could take a few minutes.')}
        </div>
      )}
    </div>
  )
}
