import { T } from '@tolgee/react'
import { Card, ViewContext, useAPI } from 'components/lib'
import { useContext, useState } from 'react'
import { Button } from 'ui'
import PaymentModal from './PaymentModal'

export function Packs(props) {
  const context = useContext(ViewContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState({})

  const { data, loading } = useAPI('/api/account/packs')
  const { updateLimits } = context

  // Legacy code supporting espago
  // useEffect(() => {
  //   const transactionId = localStorage.getItem('transactionId')
  //   if (!transactionId) return

  //   async function checkStatus() {
  //     try {
  //       const res = await axios.get(`/api/utility/transaction/${transactionId}`)

  //       if (res.data.status === 'new') {
  //         console.log('Processing transaction...')
  //         setIsLoading(true)
  //         setTimeout(checkStatus, 3000)
  //       } else if (res.data.status === 'executed') {
  //         setIsLoading(false)
  //         updateLimits()
  //         toast('Tokens Added successfuly!', {
  //           position: 'bottom-right',
  //           type: 'success',
  //           theme: 'colored',
  //           icon: false,
  //           toastId: 'Tokens Added successfuly!',
  //           autoClose: 2000,
  //         })
  //         localStorage.removeItem('transactionId')
  //       }
  //     } catch (err) {
  //       setIsLoading(false)
  //       console.log(err)
  //     }
  //   }
  //   checkStatus()
  // }, [updateLimits])

  if (loading || !data) return

  const packs = data?.packs
  const imagePacks = packs.filter((item) => item.tokens === 'images')
  const wordPacks = packs.filter((item) => item.tokens === 'words')

  async function handleStartPayment(id) {
    setModalData((prev) => ({ ...prev, item: id }))
    setModalVisible(true)
  }

  return (
    <>
      <Card className={props.className}>
        <div className='mx-auto max-w-3xl px-4 py-4 sm:px-6 lg:max-w-6xl lg:px-8'>
          <div className='flex items-center justify-between space-x-4'>
            <h2 className='text-lg font-medium text-gray-900'>
              <T keyName={`eleo-word-pack-header`}>Additional word packs</T>
            </h2>
          </div>
          <PacksRow packs={wordPacks} handlePurchase={handleStartPayment} />
          <div className='mt-[30px] flex items-center justify-between space-x-4'>
            <h2 className='text-lg font-medium text-gray-900'>
              <T keyName={`eleo-image-pack-header`}>Additional image packs</T>
            </h2>
          </div>
          <PacksRow packs={imagePacks} handlePurchase={handleStartPayment} />
        </div>
      </Card>
      {modalVisible && (
        <PaymentModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          modalData={modalData}
          updateLimits={updateLimits}
        />
      )}
    </>
  )
}

const PacksRow = ({ packs, isImage, handlePurchase }) => {
  return (
    <div className='mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3'>
      {packs.map((pack) => (
        <div
          key={pack.id}
          className='ring-brand-grey group flex min-h-[10rem] flex-col rounded-xl bg-white p-4 ring-1'
        >
          <div className='text-brand-text-primary flex items-center justify-between space-x-8 text-base font-medium'>
            <h3>
              <span aria-hidden='true' />
              <T keyName={`${pack.tolgee_id}-name`}>{pack.name}</T>
            </h3>
            <p>${pack.price}</p>
          </div>
          <p className='text-brand-text-secondary mb-4 mt-1 text-sm'>
            <T keyName={`${pack.tolgee_id}-description`}>{pack.description}</T>
          </p>
          <Button
            onClick={() => handlePurchase(pack.id)}
            className='mt-auto'
            variant='small'
            color='violet'
          >
            <T keyName='eleo-pack-buy-now-button'>Buy now</T>
          </Button>
        </div>
      ))}
    </div>
  )
}
