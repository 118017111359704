import React, { useEffect, useState } from 'react'
import { RadioBox } from 'ui'
import classNames from 'classnames'
import { T } from '@tolgee/react'

const WriterLengthOptions = [
  {
    label: <T keyName='eleo-word-length-option-label-1'>Short</T>,
    name: '200',
    id: '200',
    subLabel: <T keyName='eleo-word-length-option-sub-label-1'>max 200 words</T>,
  },
  {
    label: <T keyName='eleo-word-length-option-label-2'>Medium</T>,
    name: '500',
    id: '500',
    subLabel: <T keyName='eleo-word-length-option-sub-label-2'>max 500 words</T>,
  },
  {
    label: <T keyName='eleo-word-length-option-label-3'>Long</T>,
    name: '2000',
    id: '2000',
    subLabel: <T keyName='eleo-word-length-option-sub-label-3'>max 2000</T>,
  },
]

const WriterLengthContainer = ({
  handleRadioButtonCheck,
  selectedWordLength,
  setWriterLengthOption,
  writerLengthOption,
  forceMobile,
}) => {
  const [customWriterLength, setCustomWriterLength] = useState('')
  useEffect(() => {
    const checkOptions = WriterLengthOptions.map((option) => option.id).includes(
      selectedWordLength.id
    )
    if (selectedWordLength.id && !checkOptions) {
      setCustomWriterLength(selectedWordLength.id)
    }
  }, [selectedWordLength])

  return (
    <div className='border-b-brand-gray-light !w-full rounded-[10px] border'>
      <div
        className={classNames(
          'flex w-full flex-col items-start',
          !forceMobile && 'min-[1200px]:flex-row'
        )}
      >
        {WriterLengthOptions.map((writerOption) => (
          <div
            key={writerOption.id}
            className={classNames(
              'option border-b-brand-gray-light h-full w-full cursor-pointer border-b px-[10px] py-[9px] first:rounded-tl-[10px] first:rounded-tr-[10px] last:border-r-0  ',
              {
                'bg-[#6363e51a]':
                  writerOption.id === selectedWordLength.id &&
                  selectedWordLength.checked &&
                  !customWriterLength,
                'min-[1200px]:border-r-brand-gray-light last:rounded-tr-none min-[1200px]:w-1/3 min-[1200px]:border-b-0 min-[1200px]:border-r min-[1200px]:first:rounded-tr-none min-[1200px]:last:rounded-tr-[10px]':
                  !forceMobile,
              }
            )}
            onClick={() => {
              handleRadioButtonCheck({
                radioBoxName: writerOption.id,
                checked: true,
              })
              setCustomWriterLength('')
            }}
          >
            <div className='flex flex-col items-start'>
              <RadioBox
                id={writerOption.id}
                radioBoxValue={
                  writerOption.id === selectedWordLength.id && selectedWordLength.checked
                }
                classes='h-[18px] w-[18px]'
                radioBoxName='writer-length'
                label={writerOption.label}
                setChecked={(val) => {
                  handleRadioButtonCheck(val)
                  setCustomWriterLength('')
                }}
                labelClasses='text-[13px]'
              />
              <div className='ml-[25px] flex justify-end'>
                <span className='text-[12px] leading-[1.2em]'>{writerOption.subLabel}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={classNames(
          'border-t-brand-gray-light-light cursor-pointer rounded-bl-[10px] rounded-br-[10px]  px-[10px]',
          {
            'bg-[#6363e51a]': customWriterLength === selectedWordLength.id,
            'min-[1200px]:border-t min-[1366px]:py-[6px] 2xl:py-[9px]': !forceMobile,
          }
        )}
        onClick={(e) => {
          e.stopPropagation()
          handleRadioButtonCheck({
            radioBoxName: customWriterLength,
            checked: selectedWordLength.checked,
          })
          setCustomWriterLength('')
        }}
      >
        <div
          className={classNames(
            'flex flex-wrap items-center justify-between py-[10px] ',
            !forceMobile && 'min-[1200px]:flex-nowrap min-[1200px]:py-0'
          )}
        >
          <RadioBox
            id={customWriterLength}
            radioBoxValue={customWriterLength === selectedWordLength.id}
            radioBoxName='writer-length'
            classes='h-[18px] w-[18px]'
            label={<T keyName='eleo-length-option-choose-length'>Choose the length</T>}
            labelClasses='text-[13px]'
            setChecked={() => void 0}
          />
          <div className='flex w-full items-center gap-[4px] text-[13px]'>
            <div className='flex items-center gap-[4px]'>
              <span className='text-brand-body'>
                <T keyName='eleo-length-option-max'>max</T>
              </span>
              <input
                className='border-brand-gray-light mb-[4px] max-w-[80px] border-b border-dashed bg-transparent text-center outline-none'
                value={customWriterLength}
                onChange={(e) => {
                  handleRadioButtonCheck({
                    radioBoxName: e.target.value,
                    checked: selectedWordLength.checked,
                  })
                  setCustomWriterLength(e.target.value)
                }}
              />
            </div>
            <div className='flex items-center gap-[4px]'>
              <p
                className={classNames('cursor-pointer', {
                  'text-brand-violet': writerLengthOption === 'words',
                })}
                onClick={() => setWriterLengthOption('words')}
              >
                <T keyName='eleo-length-option-words'>words</T>
              </p>
              <div className='bg-brand-body h-[14px] w-[1px]' />
              <p
                className={classNames('cursor-pointer', {
                  'text-brand-violet': writerLengthOption === 'characters',
                })}
                onClick={() => setWriterLengthOption('characters')}
              >
                <T keyName='eleo-length-option-characters'>characters</T>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WriterLengthContainer
