import { useAccess } from 'components/hooks/access'
import { ToolGeneratorLayoutPrimary, ViewContext } from 'components/lib'
import { useContext } from 'react'
import UpgradePlan from '../UpgradePlan'
import WriterHeader from '../WriterHeader'
import ReplyTextActions from './ReplyTextActions'
import ReplyTextOptions from './ReplyTextOptions'

const ReplyTextGenerator = ({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setTextToReply,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  optionsData,
  loading,
  handleSubmitWriterOptions,
  templateOptions,
  setTemplateId,
  setWhatToReply,
  clearInputs,
  setModalIsVisible,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  isHistoryVisible,
  setIsHistoryVisible,
  documentContext,
}) => {
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')

  const showUpgradePlanModal = () => {
    context.modal.show({
      children: <UpgradePlan />,
      modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
    })
  }

  const showSaveModal = () => {
    if (allowStandard) {
      setModalIsVisible({ content: 'SAVE_TEMPLATE', visible: true })
    } else {
      showUpgradePlanModal()
    }
  }

  return (
    <>
      <ToolGeneratorLayoutPrimary
        topContent={
          <WriterHeader
            historyIsVisible={isHistoryVisible}
            setHistoryIsVisible={setIsHistoryVisible}
            showHistoryIcon={true}
            headerTitle='Reply'
            toolName='writer-reply'
          />
        }
        options={
          <ReplyTextOptions
            visible={!isHistoryVisible}
            dispatch={dispatch}
            actionTypes={actionTypes}
            setWordLength={setWordLength}
            setTextToReply={setTextToReply}
            wordLength={wordLength}
            setWhatToReply={setWhatToReply}
            setWriterLengthOption={setWriterLengthOption}
            writerLengthOption={writerLengthOption}
            setCreativity={setCreativity}
            creativity={creativity}
            optionsData={optionsData}
            templateOptions={templateOptions}
            setTemplateId={setTemplateId}
            allowStandard={allowStandard}
            showUpgradePlanModal={showSaveModal}
            templateId={templateId}
            handleDeleteTemplate={handleDeleteTemplate}
            isShowAdvanced={isShowAdvanced}
            setIsShowAdvanced={setIsShowAdvanced}
            isGuruModeEnabled={isGuruModeEnabled}
            setIsGuruModeEnabled={setIsGuruModeEnabled}
            documentContext={documentContext}
          />
        }
        actions={
          <ReplyTextActions
            clearInputs={clearInputs}
            handleSubmitWriterOptions={handleSubmitWriterOptions}
            loading={loading}
            showSaveModal={showSaveModal}
            allowStandard={allowStandard}
          />
        }
      ></ToolGeneratorLayoutPrimary>
    </>
  )
}

export default ReplyTextGenerator
