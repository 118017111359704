import links from '../../docsLinks'

export const reply = {
  myText: {
    title: 'Text To Reply To',
    content: 'Place the text you wish to reply to in this area.',
    tolgee_id: 'eleo-tool-reply-text-help-tooltip-1',
  },

  whatToDo: {
    title: 'What To Reply?',
    content: "Tell Eleo what you'd like to reply with the text.",
    tolgee_id: 'eleo-tool-reply-text-help-tooltip-2',
  },

  template: {
    title: 'Template',
    content: "Use either predefined or your saved templates for Eleo's response.",
    actions: [links.templates],
    tolgee_id: 'eleo-tooltip-template',
  },
  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },

  actions: {
    title: 'Write A Reply As:',
    content:
      "You can choose a specific action from the 'Write A Reply As:' list, like 'Agree' or 'Confirm' for Eleo to perform.",
    tolgee_id: 'eleo-tool-reply-text-help-tooltip-3',
  },

  language: {
    title: 'Language',
    content: "Select the desired language for Eleo's response.",
    tolgee_id: 'eleo-tooltip-language',
  },

  length: {
    title: 'Length',
    content: "Determine how wordy or concise you want Eleo's response to be.",
    tolgee_id: 'eleo-tooltip-length',
  },

  creativity: {
    title: 'Creativity',
    content:
      "Unleash Eleo's creative power. Setting a higher creativity level encourages Eleo to provide unique and engaging responses, not just repeat known facts.",
    tolgee_id: 'eleo-tooltip-creativity',
  },

  saveTemplate: {
    title: 'Save Template',
    content:
      "Store your query as a template for future use. Find and use these templates from the list at the form's top when needed.",
    actions: [links.templates],
    tolgee_id: 'eleo-tooltip-save-template',
  },
  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },
  modifyText: {
    title: 'Reply',
    content: 'Click REPLY to produce text from your current commands and settings.',
    tolgee_id: 'eleo-tool-modify-text-help-tooltip-4',
  },

  history: {
    title: 'History',
    content: `The 'History' icon takes you to previously generated texts.`,
    tolgee_id: 'eleo-tooltip-history',
  },

  // WRITE TEXT / HISTORY Window

  selectFromList: {
    title: 'Select from List',
    content: 'Choose previous texts by clicking the circle beside them.',
    tolgee_id: 'eleo-tooltip-select-history',
  },
  trash: {
    title: 'Deleting history items',
    content: 'The trash icon deletes unwanted texts from your history.',
    tolgee_id: 'eleo-tooltip-trash',
  },
  clearHistory: {
    title: 'Clearing history',
    content: "'Clear' wipes your history clean.",
    tolgee_id: 'eleo-tooltip-clear-history',
  },
  confirmSelection: {
    title: 'Confirm Selection',
    content: 'CONFIRM opens the chosen text along with the original creation settings.',
    tolgee_id: 'eleo-tooltip-confirm-history',
  },

  // Right window functions

  share: {
    title: 'Share',
    content: 'Use this button to post your generated text on social media platforms.',
    tolgee_id: 'eleo-tooltip-share',
  },
  copy: {
    title: 'Copy',
    content: 'This button copies your text, ready to be pasted anywhere using commands like Ctrl-V',
    tolgee_id: 'eleo-tooltip-copy',
  },
  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of the text with existing instructions.',
    tolgee_id: 'eleo-tooltip-regenerate',
  },
  responseArea: {
    title: 'Response Area',
    content:
      'The generated text shows up here. Select text for options: regenerate a section, copy, forward, or remove it.',
    actions: [links.textEditing],
    tolgee_id: 'eleo-tooltip-response-area',
  },
  myStory: {
    title: 'Your Story',
    content:
      'The "Your Story" feature allows you to save text in your profile, enabling you to utilize it as an additional context when generating documents. You have the capability to save multiple stories for varied purposes and convenience.',
    tolgee_id: 'eleo-tooltip-my-story',
  },
}
