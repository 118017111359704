import { Modal } from 'components/lib'
import React from 'react'
import { Button } from 'ui'
import { T, useTranslate } from '@tolgee/react'
import { useState } from 'react'
import { CircleNotch } from 'phosphor-react'
import moment from 'moment'

const ChangePlanModal = (props) => {
  const { t } = useTranslate()
  const { type, isVisible, setIsVisible, callback, newTier, currentTier, interval, subscription } =
    props
  const [isLoading, setIsLoading] = useState(false)
  const currentName = currentTier ? currentTier.name : ''
  const newName = newTier ? newTier.name : ''
  const currentPrice = currentTier ? getRealPrice('current') : 0
  const newPrice = newTier ? getRealPrice('new') : 0

  function getPaymentLeft() {
    const paymentLeft = getNewMoneyLeft()
    const discount = getCurrentMoneyLeft()
    const finalPayment = paymentLeft - discount
    return finalPayment.toFixed(2)
  }

  function getNewMoneyLeft() {
    return (newPrice * getTimeLeft().left).toFixed(2)
  }

  function getCurrentMoneyLeft() {
    return (currentPrice * getTimeLeft().left).toFixed(2)
  }

  function getRealPrice(type) {
    if (type === 'current') return currentTier.pricing[subscription?.period]?.price
    else return newTier.pricing[interval].price
  }

  function getTimeLeft() {
    const startTime = new Date(subscription.current_period_start).getTime()
    const endTime = new Date(subscription.current_period_end).getTime()
    const period = endTime - startTime
    const periodLeft = endTime - Date.now()

    const periods = {
      passed: (period - periodLeft) / period,
      left: periodLeft / period,
    }

    return periods
  }

  function getMonthlySaving() {
    return (currentPrice * 12 - newPrice) / 12
  }

  const modalContent = {
    subscription: {
      // translate
      title: t('plansModal-subscribe-plan', 'Subscribe {planName} plan', { planName: newName }),
      getContent: () => {
        const intervalTranslation =
          interval === 'month' ? t('plansModal-month', 'month') : t('plansModal-year', 'year')
        return t(
          'plansModal-subscription-summary',
          "Congratulations! You're almost ready to use Eleo on the {newPlan} plan! We will collect fees every {interval}, starting today. Complete your subscription purchase below.",
          { newPlan: newName, interval: intervalTranslation }
        )
      },
    },
    // current -> higher
    upgrade: {
      title: t('plansModal-upgrade-from-to', `Upgrade from {currentName} to {newName}`, {
        currentName,
        newName,
      }),
      getContent: () => {
        return (
          <>
            <p>
              <T keyName='plansModal-upgrade-summary'>
                The unused amount for the current plan will be deducted from the first payment. Your
                new plan will be activated immediately.
              </T>
            </p>
            <p className='!mb-0.5'>
              <T keyName='plansModal-new-price'>New plan price</T>:{' $'}
              {newPrice}
            </p>
            <p className='!mb-0.5'>
              <T keyName='plansModal-payment-amount'>That's how much you'll pay</T>:{' $'}
              {getPaymentLeft()}
            </p>
            <p className='!mb-0.5'>
              <T keyName='plansModal-payment-period'>You pay for the plan by</T>:{' '}
              {moment(subscription?.current_period_end).format('DD.MM.YYYY')}
            </p>
          </>
        )
      },
    },
    // current -> current_but_annual
    upgradeToAnnual: {
      // translate
      title: t('plansModal-upgrade-to-annual', 'Upgrade to annual'),
      getContent: () =>
        t(
          'plansModal-upgrade-to-annual-summary',
          // eslint-disable-next-line no-template-curly-in-string
          'Way to go! By upgrading to an annual subscription, you save ${saving} per month! Complete your subscription purchase below.',
          { saving: getMonthlySaving() }
        ),
    },
    // current -> lower
    downgrade: {
      // translate
      title: t('plansModal-downgrade', 'Downgrade to {newPlan} plan', { newPlan: newName }),
      getContent: () =>
        t(
          'plansModal-downgrade-summary',
          'Your paid {currentPlan} subscription will apply until {periodEnd}. After that you plan will change to {newPlan}',
          {
            currentPlan: currentName,
            periodEnd: moment(subscription?.current_period_end).format('DD.MM.YYYY'),
            newPlan: newName,
          }
        ),
    },
    // current annual -> higher monthly
    upgradeFromAnnualToMonthly: {
      // translate
      title: t(
        'plansModal-upgrade-to-monthly',
        'Upgrade from annual {currentPlan} to monthly {newPlan}',
        { currentPlan: currentName, newPlan: newName }
      ),
      getContent: () => {
        const left = getCurrentMoneyLeft()
        return (
          <>
            <p>
              {t(
                'plansModal-upgrade-to-monthly-summary',
                // eslint-disable-next-line no-template-curly-in-string
                '${left} left on your annual subscription fee. We will use the above amount towards your first payment for a new monthly subscription.',
                { left: getCurrentMoneyLeft() }
              )}
              {left > newPrice ? (
                <T keyName='plansModal-upgrade-to-monthly-warning'>
                  {' '}
                  The remaining amount will be lost
                </T>
              ) : (
                ''
              )}
            </p>
            <p className='!mb-0.5'>
              <T keyName='plansModal-1st-month-price'>
                That's how much you'll pay for the first month
              </T>
              :{' $'}
              {Math.max(0, newPrice - left)}
            </p>
            <p className='!mb-0.5'>
              <T keyName='plansModal-month-price'>
                This is how much you will pay for each subsequent month
              </T>
              :{' $'}
              {newPrice}
            </p>
          </>
        )
      },
    },
    upgradeFromGift: {
      // translate
      title: t('plansModal-upgrade-from-gift', 'Upgrade to {newPlan}', { newPlan: newName }),
      getContent: () => {
        const intervalTranslation =
          interval === 'month'
            ? t('plansModal-gift-month', 'monthly')
            : t('plansModal-gift-year', 'yearly')
        return t(
          'plansModal-upgrade-from-gift-summary',
          'When your gift code expires your subscription will be set to {newPlan} ({interval})',
          {
            subscriptionEnd: moment(subscription?.current_period_end).format('DD.MM.YYYY'),
            newPlan: newName,
            interval: intervalTranslation,
          }
        )
      },
    },
    downgradeFromAnnualToMonthly: {
      // translate
      title: t('plansModal-downgrade-to-monthly', 'Downgrade to monthly'),
      getContent: () =>
        t(
          'plansModal-downgrade-to-monthly-summary',
          'Your paid subscription to the annual plan will last until {subscriptionEnd}, after which your plan will convert to monthly.',
          { subscriptionEnd: moment(subscription?.current_period_end).format('DD.MM.YYYY') }
        ),
    },
  }

  if (isVisible && !!modalContent[type]) {
    return (
      <Modal
        setHideModal={() => {
          setIsVisible({ isVisible: false, ...props })
        }}
        text={<p className='text-brand-body text-[19px] font-bold'>{modalContent[type].title}</p>}
      >
        <div>{modalContent[type].content || modalContent[type].getContent()}</div>
        <div className='mt-6 flex w-full gap-4'>
          <Button
            color='gray'
            size={16}
            onClick={() => {
              setIsVisible({ isVisible: false, ...props })
            }}
            className='!float-none'
          >
            <T keyName='plansModal-cancel'>Cancel</T>
          </Button>
          <Button
            color='green'
            disabled={isLoading}
            size={16}
            onClick={async () => {
              setIsLoading(true)
              callback && (await callback())
              setIsLoading(false)
            }}
            className='!float-none'
          >
            {isLoading ? (
              <CircleNotch
                weight='fill'
                color='#FFFFFF'
                size={24}
                className='mx-auto animate-spin'
              />
            ) : (
              <T keyName='plansModal-continue'>Continue</T>
            )}
          </Button>
        </div>
      </Modal>
    )
  } else {
    return null
  }
}

export default ChangePlanModal
