/***
 *
 *   Features/Translator Page
 *
 **********/

import Language from '@mui/icons-material/Language'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import History from '@mui/icons-material/History'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'

import { useTranslate } from '@tolgee/react'

import image from 'images/features/translator.png'

export function Translator() {
  const { t } = useTranslate()
  const featureId = 'translator'
  const translations = {
    header: {
      title: `Intuitive Translator`,
      subtitle: `Eleo's Translator accurately translates your message into 50 languages, keeping its true meaning, and it's really easy to use.`,
      description: `Discover how Eleo effortlessly translates a business proposal into Spanish, using the Native Speaker mode.
      `,
      link: { href: '/translator', text: t('eleo-try-now', 'Try now!') },
    },
    content: {
      title: 'Why use the Translator?',
      subtitle: 'Everything you need',
      description: `Poor translation risks business! Experience translations with a human touch. Eleo’s 'Native Speaker' feature goes beyond word-for-word translation to convey the essence of your message across languages.`,
      features: [
        {
          name: `Many languages.`,
          description: `Translate any text into 50 languages from around the world.`,
          icon: Language,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `Native speaker.`,
          description: `Translate and communicate as fluently as a native speaker.`,
          icon: RecordVoiceOverIcon,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `RTL support.`,
          description: `Translate into and from languages written right-to-left as well.`,
          icon: FormatTextdirectionRToLIcon,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `Copy & Share.`,
          description: `Quickly copy and forward your translations.`,
          icon: ContentCopyIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Easy edits.`,
          description: `Trim or retranslate selected parts of the text.`,
          icon: ContentCutIcon,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `History.`,
          description: `Easily access your translation history and swiftly return to previous translations.`,
          icon: History,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        videoLink='https://www.youtube.com/embed/DZzl4PvVa9g'
        link={translations.header.link}
        // description={t(`eleo-features-${featureId}-header-description`, translations.header.description)}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <UseEleoToday next='translator' />
    </div>
  )
}
