import { T } from '@tolgee/react'

export const IdeaFor = [
  // {
  //   value: 'anything',
  //   label: <T keyName='eleo-select-option-anything'>Anything</T>,
  // },
  { value: 'title', label: <T keyName='eleo-select-option-title'>Title</T> },
  {
    value: 'business name',
    label: <T keyName='eleo-select-option-business-name'>Business name</T>,
  },
  {
    value: 'product name',
    label: <T keyName='eleo-select-option-product-name'>Product name</T>,
  },
  {
    value: 'hashtags',
    label: <T keyName='eleo-select-option-hashtags'>Hashtags</T>,
  },
  {
    value: 'instagram caption',
    label: <T keyName='eleo-select-option-instagram-caption'>Instagram caption</T>,
  },
  { value: 'plan', label: <T keyName='eleo-select-option-plan'>Plan</T> },
  {
    value: 'effective arguments',
    label: <T keyName='eleo-select-option-effective-arguments'>Effective arguments</T>,
  },
]
