import { Add } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { CheckBox, Microbutton } from 'ui'

export function Story({ items, setItems, messageContext }) {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const handleCheck = (data) => {
    if (data.checked && !items.some((item) => item.id === data.checkBoxName)) {
      const checkedStory = messageContext.stories.data.find(
        (story) => story.id === data.checkBoxName
      )
      setItems((prev) => [
        ...prev,
        {
          label: checkedStory.label,
          content: checkedStory.content,
          id: checkedStory.id,
          isValid: true,
        },
      ])
    } else {
      setItems((prev) => prev.filter((item) => item.id !== data.checkBoxName))
    }
  }

  return (
    <div className='xs:px-[26px] space-y-[6px] px-3 py-5'>
      <div className='text-brand-violet text-[13px]'>
        {/* url input */}
        {messageContext.stories.data?.map((story) => (
          <label
            key={story.id}
            className={classNames(
              'hover:bg-brand-violet/10 group flex h-[32px] items-center gap-x-[10px] truncate rounded-[4px] bg-white py-2 pl-3 pr-3 transition-all duration-300'
            )}
          >
            <CheckBox
              checkBoxName={story.id}
              checkBoxValue={items.some((item) => item.id === story.id)}
              setChecked={(data) => handleCheck(data)}
              classes='border-brand-violet'
            />
            <div
              className={classNames(items.some((item) => item.id === story.id) && 'font-medium')}
            >
              {story.label}
            </div>
          </label>
        ))}

        <div className='border-brand-violet/20 mx-[14px] my-[6px] border-b'></div>

        <Microbutton
          iconPlacement='left'
          variant='plain'
          icon={<Add fontSize='inherit' />}
          text={t('eleo-context-modal-create-story', 'Create new story')}
          onClick={() => navigate('/account/your-story')}
          className='hover:bg-brand-violet/10 !justify-start'
          iconSize={14}
        />
      </div>
    </div>
  )
}
