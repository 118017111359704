import { Tooltip } from 'react-tooltip'
import { T } from '@tolgee/react'

export default function TooltipContainer({ id }) {
  return (
    <Tooltip
      id={id}
      globalCloseEvents={{ clickOutsideAnchor: true, scroll: true }}
      className='!bg-brand-violet'
      style={{
        maxWidth: '300px',
        background: '#6363e5',
        zIndex: 999,
        borderRadius: '10px',
        padding: '14px',
      }}
      render={({ activeAnchor }) => (
        <ul className='flex max-w-[250px] flex-col gap-[10px]'>
          <li className='flex flex-col text-[14px] leading-[1.2]'>
            <b>
              <T keyName={activeAnchor?.getAttribute('data-tooltip-keyname')}></T>
            </b>
          </li>
        </ul>
      )}
    />
  )
}
