import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import { Message, Card, ViewContext } from 'components/lib'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

export function Return() {
  const context = useContext(ViewContext)
  const [status, setStatus] = useState(null)
  const params = useSearchParams()[0]

  useEffect(() => {
    async function getStatus() {
      const sessionId = params.get('session_id')

      const res = await axios.get(`/stripe/status?session_id=${sessionId}`)
      setStatus(res.data.status)
    }
    getStatus()
  }, [params])

  if (status === 'open') {
    return <Navigate to='/checkout' />
  }

  if (status === 'complete') {
    // Refresh the counter after 3s
    setTimeout(() => {
      context.updateLimits()
    }, 3000)

    return (
      <section id='success'>
        <Card title={<T keyName='eleo-return-title'>Purchase status</T>} restrictWidth>
          <Message
            title={<T keyName='eleo-return-header'>Purchase successful</T>}
            type='success'
            text={
              <T keyName='eleo-return-content'>
                Your purchase was successful. Your balance will be updated in a moment
              </T>
            }
          />
        </Card>
      </section>
    )
  }

  return null
}
