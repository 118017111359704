import { T } from '@tolgee/react'

export const helpMenuData = [
  { name: <T keyName='eleo-nav-chat' />, href: '/chat' },
  {
    name: <T keyName='eleo-nav-writer' />,
    children: [
      { name: <T keyName='eleo-nav-writer-write-text' />, href: '/writer' },
      { name: <T keyName='eleo-nav-writer-reply' />, href: '/writer/reply' },
      { name: <T keyName='eleo-nav-writer-modify-text' />, href: '/writer/modify' },
      { name: <T keyName='eleo-nav-writer-check-text' />, href: '/writer/check' },
      { name: <T keyName='eleo-nav-advisor' />, href: '/advisor' },
      { name: <T keyName='eleo-nav-great-ideas' />, href: '/great-ideas' },
    ],
  },
  { name: <T keyName='eleo-nav-translator' />, href: '/translator' },
  {
    name: <T keyName='eleo-nav-images' />,
    children: [
      { name: <T keyName='eleo-nav-images-create-image' />, href: '/image' },
      { name: <T keyName='eleo-nav-images-modify-image' />, href: '/image/modify' },
    ],
  },
  { name: <T keyName='eleo-your-story' />, href: '/account/your-story' },
  { name: <T keyName='eleo-chat-bot' />, href: '/account/chat-bot' },
]
