export const readyMadeTemplates = [
  {
    id: 'pas-framework',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'pas-framework',
    label: 'PAS Framework',
    topicPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. bicycle sales, tourist trip sales.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-context-placeholder-text',
      value:
        'Provide more information on the given topic, If you can specify briefly:\nProblem: e.g. Low quality bike parts\nAgitation: e.g. We only use high-quality components\nSolution: e.g. Buy a bike built only with very good parts',
    },
    // format: 'email',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'official-letter',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'official-letter',
    label: 'Official letter',
    topicPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. bicycle sales, tourist trip sales.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-official-letter-template-context-placeholder-text',
      value:
        "Clearly indicate the recipient and the subject of the letter.\nRecipient: e.g. employment office\nSubject: e.g. grant application for employment\nBriefly describe the matter: What is the current factual situation. What is expected to happen as a result of the recipient's actions.\ne.g. I am an employee. If I receive the grant, I will hire 2 employees.",
    },
    // format: 'letter',
    wordLength: '500',
    creativity: 'Low',
    tone: 'professional',
    author: 'lawyer',
    // style: 'formal',
    isOptimizedForSeoEnabled: false,
  },
  {
    id: 'aida-template',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'aida-template',
    label: 'AIDA Template',
    topicPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. bicycle sales, tourist trip sales.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-aida-template-context-placeholder-text',
      value:
        'Provide more information on the given topic,\nWrite what attracts attention in your product, what are its key features, what action do you expect from the recipients.',
    },
    // format: 'article',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'feature-benefits',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'feature-benefits',
    label: 'Feature to Benefit',
    topicPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. bicycle sales, tourist trip sales.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-feature-benefits-template-context-placeholder-text',
      value:
        'Provide more information on the given topic,\nWrite what features your product has, for example high quality parts.\nAdd what benefits customers will have from using it,\nfor example long-lasting use without the need for repairs.',
    },
    // format: 'article',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'before-after-bridge',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'before-after-bridge',
    label: 'Before-After-Bridge',
    topicPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. bicycle sales, tourist trip sales.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-before-after-bridge-template-context-placeholder-text',
      value:
        'Provide more information on the given topic,\nWrite down what problems a customer has when they do not use your product,\nfor example frequent repairs. Write down how their world will look like after the purchase,\nfor example long-term use without the need for repairs.',
    },
    // format: 'email',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'unique-value-propositions',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'unique-value-propositions',
    label: 'Unique Value Propositions',
    topicPlaceHolder: {
      tolgee_id: 'eleo-pas-framework-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. bicycle sales, tourist trip sales.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-unique-value-propositions-template-context-placeholder-text',
      value:
        'Provide more information on the given topic,\nWhat product or service is your company selling?\nWhat is the end-benefit of using it?\nWho is your target customer for this product or service?\nWhat makes your offering unique and different?',
    },
    // format: 'post',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'text-for-landing-page',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'text-for-landing-page',
    label: 'Text for Landing Page',
    topicPlaceHolder: {
      tolgee_id: 'eleo-text-for-landing-page-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. website promoting off-road bicycles.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-text-for-landing-page-template-context-placeholder-text',
      value:
        'Provide more information on the given topic:\nDefine your unique selling proposition (USP) with a catchy headline that conveys your main benefit.\nPresent your product or service in clear and concise language.\nIncorporate testimonials or case studies to build trust and credibility.\nClearly state the desired action, such as signing up, purchasing, or requesting a demo.\nMake your call-to-action prominent and compelling.',
    },
    // format: 'anything',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'text-for-website',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'text-for-website',
    label: 'Text for website',
    topicPlaceHolder: {
      tolgee_id: 'eleo-text-for-landing-page-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. website promoting off-road bicycles.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-text-for-website-template-context-placeholder-text',
      value:
        "Please provide the necessary information to create a compelling text for your website.\nWe kindly request you to share details such as your company's background, services offered, target audience, and any specific preferences for the writing style. By providing these details, we can ensure that the text aligns with your brand's values and effectively communicates your message to your target audience.",
    },
    // format: 'anything',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'advertising-script',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'advertising-script',
    label: 'Advertising script',
    topicPlaceHolder: {
      tolgee_id: 'eleo-advertising-script-template-topic-placeholder-text',
      value: 'Enter the topic of the task, e.g. off-road bicycles.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-advertising-script-template-context-placeholder-text',
      value:
        'Define the advertising goal: e.g. brand awareness, increased sales.\nFind unique value: e.g. high quality, parts, unique design.\nWho is the target audience: e.g. seniors.\nProvide any other necessary data',
    },
    // format: 'anything',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'marketer',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'company-bio-story',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'company-bio-story',
    label: 'Company Bio/Story',
    topicPlaceHolder: {
      tolgee_id: 'eleo-company-bio-story-topic-placeholder-text',
      value: 'Enter the name of the company',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-company-bio-story-template-context-placeholder-text',
      value:
        "Can you provide the company's founding date?\nWho is the founder of the company?\nIn which location was the business established?\nWhat was the motivation behind starting the business?\nCan you share the key milestones achieved during the company's journey?\nIs there a specific story or meaning behind the company's name?",
    },
    // format: 'Bio/CV',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'manager',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'FAQ',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'FAQ',
    label: 'FAQ',

    topicPlaceHolder: {
      tolgee_id: 'eleo-faq-topic-placeholder-text',
      value: 'Please provide the name of the company, website, or product.',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-faq-template-context-placeholder-text',
      value:
        'Please provide: What are the most common questions we receive from customers?\nWhat are the most important information that customers should know?\nWhat are the most important problems that customers face?\nWhat are the most important information about the purchasing process or customer service?\nWhat are the most important information about contacting us?\nRemember that the FAQ should be clear, concise and easy to navigate.\nRegularly update the FAQ to include new questions and information.',
    },
    // format: 'anything',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'professional',
    author: 'manager',
    // style: 'journalism',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'write-a-contract',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'write-a-contract',
    label: 'Write a Contract',

    topicPlaceHolder: {
      tolgee_id: 'eleo-write-a-contract-topic-placeholder-text',
      value:
        'Please provide the type of contract, party names, etc. for example, contract for specific task, Company XYZ, John Brown',
    },
    contextPlaceHolder: {
      tolgee_id: 'eleo-write-a-contract-template-context-placeholder-text',
      value:
        "Please provide: Identification of Parties: Full and accurate identification data of the parties to the agreement, including names, addresses, identification numbers (e.g. tax identification numbers), and other relevant information. Description of the Subject Matter of the Agreement:...Financial Conditions: … Conditions of Contract Performance: … Protective Clauses:.. Parties' Obligations: … Final Provisions: …",
    },
    // format: 'agreement',
    wordLength: '2000',
    creativity: 'Low',
    tone: 'professional',
    author: 'lawyer',
    // style: 'formal',
    isOptimizedForSeoEnabled: false,
  },
]

export const replyTextReadyMadeTemplates = [
  {
    id: 'customers-question-response',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'customers-question-response',
    label: "Customer's question response",
    action: 'Provide explanations',
    wordLength: '500',
    creativity: 'Low',
  },
  {
    id: 'complaint-response',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'complaint-response',
    label: 'Complaint response',
    action: 'Disagree',
    wordLength: '500',
    creativity: 'Low',
  },
  {
    id: 'reply-to-an-invitation',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'reply-to-an-invitation',
    label: 'Reply to an invitation',
    action: 'Confirm',
    wordLength: '200',
    creativity: 'Low',
  },
  {
    id: 'reply-to-an-official-letter',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'reply-to-an-official-letter',
    label: 'Reply to an official letter',
    action: 'Confirm',
    wordLength: '500',
    creativity: 'Low',
  },
  {
    id: 'reply-to-a-thank-you',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'reply-to-a-thank-you',
    label: 'Reply to a thank you',
    action: 'Thank',
    wordLength: '200',
    creativity: 'Low',
  },
  {
    id: 'reply-to-a-quotation-request',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'reply-to-a-quotation-request',
    label: 'Reply to a quotation request',
    action: 'Present the offer',
    wordLength: '500',
    creativity: 'Medium',
  },
  {
    id: 'reply-to-a-meeting-proposal',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'reply-to-a-meeting-proposal',
    label: 'Reply to a meeting proposal',
    action: 'Arrange a meeting',
    wordLength: '200',
    creativity: 'Low',
  },
]

export const modifyTextReadyMadeTemplates = [
  {
    id: 'clarify-the-text',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'clarify-the-text',
    label: 'Clarify the text',
    action: 'explain in easy words',
    // style: 'casual',
    author: 'teacher',
    wordLength: '500',
    creativity: 'Low',
    tone: 'friendly',
    isOptimizedForSeoEnabled: false,
  },
  {
    id: 'boost-marketing-communication',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'boost-marketing-communication',
    label: 'Boost marketing communication',
    action: 'rephrase',
    // style: 'emotional marketing (Email)',
    author: 'marketer',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'friendly',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'optimize-for-SEO',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'optimize-for-SEO',
    label: 'Optimize for SEO',
    action: 'rephrase',
    // style: 'creative',
    author: 'marketer',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'friendly',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'tailor-it-to-a-different-audience',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'tailor-it-to-a-different-audience',
    label: 'Tailor it to a different audience',
    action: 'rephrase',
    // style: 'creative',
    author: 'marketer',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'friendly',
    isOptimizedForSeoEnabled: true,
  },
  {
    id: 'eleo-custom-template-summarize',
    isReadyMade: true,
    showDeleteOption: false,
    value: 'eleo-custom-template-summarize',
    label: 'Summarize',
    action: 'summarize',
    // style: 'casual',
    author: 'writer',
    wordLength: '500',
    creativity: 'Medium',
    tone: 'friendly',
    isOptimizedForSeoEnabled: true,
  },
]
