import classNames from 'classnames'
import React from 'react'

import eleoLogo from '../../images/eleo_raw.svg'

export const EleoMascot = ({ classes, imageClasses }) => {
  return (
    <div
      className={classNames(
        classes,
        'hidden h-[60px] w-[60px] items-center justify-center rounded-full bg-yellow-400 md:flex'
      )}
    >
      <img className={classNames(imageClasses, 'w-[33px]')} src={eleoLogo} alt='eleo logo' />
    </div>
  )
}
