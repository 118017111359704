import { Save } from '@mui/icons-material'
import classNames from 'classnames'
import { CircleNotch } from 'phosphor-react'
import React from 'react'
import { Button } from 'ui'
import { T } from '@tolgee/react'

const ReplyTextActions = ({
  clearInputs,
  handleSubmitWriterOptions,
  loading,
  showSaveModal,
  allowStandard,
}) => {
  return (
    <>
      <Button
        id='save-template'
        color='gray'
        className='h-[45px] px-[18px]'
        onClick={() => showSaveModal()}
        data-tooltip-id='writer-reply-saveTemplate'
      >
        <div
          className={classNames('flex items-center gap-[6px]', {
            'opacity-50': !allowStandard,
          })}
        >
          <Save className='text-brand-secondary' />
        </div>
      </Button>
      <Button
        color='gray'
        className='h-[45px] min-w-[80px]'
        onClick={clearInputs}
        data-tooltip-id='writer-reply-clear'
      >
        <p className='text-[15px] font-medium uppercase leading-none'>
          <T keyName='eleo-history-action-clear'>clear</T>
        </p>
      </Button>
      <Button
        className={classNames('h-[45px] w-full !px-2')}
        onClick={() => handleSubmitWriterOptions()}
        disabled={loading.name === 'generate' && loading.status}
        color='dark'
        data-tooltip-id='writer-reply-modifyText'
      >
        {loading.name === 'generate' && loading.status ? (
          <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
        ) : (
          <p className='text-[15px] font-medium uppercase leading-none'>
            <T keyName='eleo-tool-reply-action-reply'>reply</T>
          </p>
        )}
      </Button>
    </>
  )
}

export default ReplyTextActions
