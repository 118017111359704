import { Message } from 'components/lib'
import React from 'react'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import { ViewContext } from 'components/lib'
import { useNavigate } from 'components/lib'
const UpgradePlan = ({ availableFrom = 'Standard' }) => {
  const context = useContext(ViewContext)
  const navigate = useNavigate()

  function handleUpgrade() {
    context.modal.hide()
    navigate('/account/plans')
  }

  return (
    <Message
      title={
        <>
          <T keyName='eleo-upgrade-plan-title' params={{ availableFrom: availableFrom }}>
            This functionality is only available in Pro and Standard plans
          </T>
          <div className='mt-2'>
            <T keyName='eleo-upgrade-plan-trial-info'>Upgrade now to get a 7 day trial for free!</T>
          </div>
        </>
      }
      buttonText={<T keyName='upgrade-plan'>Upgrade plan</T>}
      buttonAction={handleUpgrade}
      type='error'
    />
  )
}

export default UpgradePlan
