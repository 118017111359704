import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useAccess } from 'components/hooks/access'
import { Modal, ViewContext } from 'components/lib'
import { useContext, useEffect, useState } from 'react'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import { Button, History } from 'ui'
import { CircleNotch } from 'phosphor-react'
import { successToast } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'

export default function ChatSelector({
  setChatId,
  chatId,
  setShowHistory,
  loadChat,
  chatHistory,
  setChatHistory,
}) {
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const { t } = useTranslate()

  const [isPaginateEnd, setIsPaginateEnd] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    content: null,
    visible: false,
  })

  function handleChangeChat(chatId) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    loadChat(chatId)
  }

  function showDeleteHistoryItemsModal(items) {
    setModal({
      visible: true,
      content: (
        <>
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-selected-history-confirmation-header'>
              Are you sure you want to delete selected history?{' '}
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-selected-history-confirmation-title'>
                This action is irreversible.
              </T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': isLoading,
              }
            )}
            onClick={() => handleDeleteHistoryItems(items)}
          >
            <div className='flex items-center gap-[5px]'>
              {isLoading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-confirm-delete-selected-history'>delete</T>
              </p>
            </div>
          </Button>
        </>
      ),
    })
  }

  async function handleDeleteHistoryItems(items) {
    if (!items.length) return

    try {
      setIsLoading(true)

      const data = { items: items }
      await axios.post('/api/chats/delete', data)

      setChatHistory((prev) => prev.filter((item) => !items.includes(item.id)))
      if (items.includes(chatId)) setChatId(null)
      setModal({ visible: false, content: null })

      successToast(
        <T keyName={`eleo-success-History deleted successfully`}>History deleted successfully</T>
      )
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  function showClearHistoryModal() {
    setModal({
      visible: true,
      content: (
        <>
          {' '}
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-history-confirmation-header'>
              Are you sure you want to clear your history?
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-history-confirmation-title'>This action is irreversible.</T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': isLoading,
              }
            )}
            onClick={handleClearHistory}
          >
            <div className='flex items-center gap-[5px]'>
              {isLoading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-clear-history-modal-title'>clear history</T>
              </p>
            </div>
          </Button>
        </>
      ),
    })
  }

  async function handleClearHistory() {
    try {
      setIsLoading(true)
      await axios.delete('/api/chats')

      setChatHistory([])
      setChatId(null)
      setModal({ visible: false, content: null })
      successToast(
        <T keyName={`eleo-success-Histories deleted successfully`}>
          Histories deleted successfully
        </T>
      )
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (chatHistory.length && chatHistory.length % 20 === 0) setIsPaginateEnd(false)
    else setIsPaginateEnd(true)
  }, [chatHistory.length])

  async function handlePaginate() {
    const currentPage = Math.ceil(chatHistory.length / 20)

    const res = await axios.get(`/api/chats?skip=${currentPage}`)
    setChatHistory((prev) => [...prev, ...res.data])
  }

  const historyItems = chatHistory.map((item) => ({
    ...item,
    context: {
      id: item.stories?.[0]?.id,
      name: item.stories?.[0]?.name,
      isStory: true,
    },
  }))

  return (
    <>
      <History
        headerText={t('eleo-history-header-chat', 'chat / history')}
        dateText={t('eleo-history-item-date', 'Started:')}
        storyText={t('eleo-history-item-story', 'Your story:')}
        wordCounterText={t('eleo-history-item-words', 'words:')}
        historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
        clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
        storyMissingText={t('eleo-history-item-story-missing', 'None')}
        logo={eleoLogo}
        storageKey='chat'
        items={historyItems}
        loadItem={handleChangeChat}
        closeHistory={() => setShowHistory(false)}
        removeItems={showDeleteHistoryItemsModal}
        clearHistory={showClearHistoryModal}
        fetchMoreItems={handlePaginate}
        hasMore={!isPaginateEnd}
      />
      {modal.visible && (
        <Modal
          setHideModal={setModal}
          text={<p className='text-brand-body text-[19px] font-bold'>{modal.text}</p>}
        >
          {modal.content}
        </Modal>
      )}
    </>
  )
}
