import links from '../docsLinks'

export const advisor = {
  topic: {
    title: 'Topic',
    content:
      "The topic is a concise sentence or phrase that specifies your question and the kind of advice you're seeking from Eleo. For optimal help, ensure it's both clear and precise.",
    tolgee_id: 'eleo-tool-advisor-help-tooltip-1',
  },

  context: {
    title: 'Context',
    content:
      'Provide Eleo with extra details or relevant facts to understand your query better. This might include a comprehensive problem description, prior discussions, constraints, or any other crucial data that would facilitate a more informed answer.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-2',
  },

  language: {
    title: 'Language',
    content: "Select the desired language for Eleo's response.",
    tolgee_id: 'eleo-tooltip-language',
  },

  template: {
    title: 'Template',
    content: "Use either predefined or your saved templates for Eleo's response.",
    actions: [links.templates],
    tolgee_id: 'eleo-tooltip-template',
  },

  keywords: {
    title: 'Keywords',
    content:
      'These are pivotal words that encapsulate your question, guiding Eleo to focus and deliver a pertinent answer. Aim for brevity and relevance.',
    tolgee_id: 'eleo-tooltip-keywords',
  },

  length: {
    title: 'Length',
    content: "Determine how wordy or concise you want Eleo's response to be.",
    tolgee_id: 'eleo-tooltip-length',
  },

  author: {
    title: 'Author',
    content:
      "This feature lets Eleo respond as if she's a certain person or character, like a writer, expert, or even a teenager. The selected persona shapes both the style and mood of the reply. For example, an 'expert' persona yields a more formal tone, while a 'teenager' persona is likely more casual.",
    tolgee_id: 'eleo-tooltip-author',
  },

  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },

  share: {
    title: 'Share',
    content: 'Use this button to post your generated text on social media platforms.',
    tolgee_id: 'eleo-tooltip-share',
  },
  copy: {
    title: 'Copy',
    content: 'This button copies your text, ready to be pasted anywhere using commands like Ctrl-V',
    tolgee_id: 'eleo-tooltip-copy',
  },
  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of the text with existing instructions.',
    tolgee_id: 'eleo-tooltip-regenerate',
  },
  responseArea: {
    title: 'Response Area',
    content:
      'The generated text shows up here. Select text for options: regenerate a section, copy, forward, or remove it.',
    actions: [links.textEditing],
    tolgee_id: 'eleo-tooltip-response-area',
  },

  //actions

  saveTemplate: {
    title: 'Save Template',
    content:
      "Store your query as a template for future use. Find and use these templates from the list at the form's top when needed.",
    actions: [links.templates],
    tolgee_id: 'eleo-tooltip-save-template',
  },
  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },
  submitText: {
    title: 'Submit',
    content: 'Click SUBMIT to produce advice from your current commands and settings.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-5',
  },

  // HISTORY Window

  history: {
    title: 'History',
    content: `The 'History' icon takes you to previously generated texts.`,
    tolgee_id: 'eleo-tooltip-history',
  },

  selectFromList: {
    title: 'Select from List',
    content: 'Choose previous texts by clicking the circle beside them.',
    tolgee_id: 'eleo-tooltip-select-history',
  },
  trash: {
    title: 'Deleting history items',
    content: 'The trash icon deletes unwanted texts from your history.',
    tolgee_id: 'eleo-tooltip-trash',
  },
  clearHistory: {
    title: 'Clearing history',
    content: "'Clear' wipes your history clean.",
    tolgee_id: 'eleo-tooltip-clear-history',
  },
  confirmSelection: {
    title: 'Confirm Selection',
    content: 'CONFIRM opens the chosen text along with the original creation settings.',
    tolgee_id: 'eleo-tooltip-confirm-history',
  },
  myStory: {
    title: 'Your Story',
    content:
      'The "Your Story" feature allows you to save text in your profile, enabling you to utilize it as an additional context when generating documents. You have the capability to save multiple stories for varied purposes and convenience.',
    tolgee_id: 'eleo-tooltip-my-story',
  },
}
