import { ToolGeneratorLayoutPrimary } from 'components/lib'
import WriterHeader from '../WriterHeader'
import CheckTextActions from './CheckTextActions'
import CheckTextOptions from './CheckTextOptions'

const CheckTextGenerator = ({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setMyText,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  optionsData,
  loading,
  handleSubmitWriterOptions,
  templateOptions,
  setTemplateId,
  clearInputs,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
}) => {
  return (
    <>
      <ToolGeneratorLayoutPrimary
        topContent={
          <WriterHeader historyIsVisible={false} showHistoryIcon={false} headerTitle='Check Text' />
        }
        options={
          <CheckTextOptions
            visible
            dispatch={dispatch}
            actionTypes={actionTypes}
            setWordLength={setWordLength}
            setMyText={setMyText}
            wordLength={wordLength}
            setWriterLengthOption={setWriterLengthOption}
            writerLengthOption={writerLengthOption}
            setCreativity={setCreativity}
            creativity={creativity}
            optionsData={optionsData}
            templateOptions={templateOptions}
            setTemplateId={setTemplateId}
            isGuruModeEnabled={isGuruModeEnabled}
            setIsGuruModeEnabled={setIsGuruModeEnabled}
          />
        }
        actions={
          <CheckTextActions
            clearInputs={clearInputs}
            handleSubmitWriterOptions={handleSubmitWriterOptions}
            loading={loading}
          />
        }
      ></ToolGeneratorLayoutPrimary>
    </>
  )
}

export default CheckTextGenerator
