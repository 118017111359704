import { Error, WebAsset } from '@mui/icons-material'
import classNames from 'classnames'
import { isValidUrl } from 'helpers'
import { useTranslate } from '@tolgee/react'

export function Website({ items, setItems }) {
  const { t } = useTranslate()
  console.log(items)

  return (
    <div className='xs:px-[26px] space-y-[6px] px-3 py-5'>
      <div className='text-brand-violet text-[13px]'>
        {/* url input */}
        <label
          htmlFor='url-input'
          className={classNames(
            'hover:bg-brand-violet/10 group flex items-center gap-x-[10px] truncate rounded-[4px] bg-white py-2 pl-3 pr-3 transition-all duration-300'
          )}
        >
          <span className='text-brad-violet flex text-[14px]'>
            <WebAsset fontSize='inherit' />
          </span>
          <input
            id='url-input'
            autoFocus
            type='url'
            placeholder={t('eleo-context-modal-url-input-placeholder', 'Enter a website address')}
            value={items[0]?.label ?? ''}
            onChange={(e) => {
              setItems([
                {
                  label: e.target.value,
                  content: e.target.value,
                  isValid: isValidUrl(e.target.value),
                },
              ])
            }}
            className='caret-brand-violet w-full bg-transparent'
          />

          {items[0] && !items[0].isValid && (
            <div className='text-brand-pink flex items-center gap-1 text-nowrap'>
              <Error className='text-[14px]' fontSize='inherit' />
              {t('eleo-context-modal-invalid-url', 'Enter a valid url')}
            </div>
          )}
        </label>
      </div>
    </div>
  )
}
