import { T } from '@tolgee/react'

import RealisticExample from '../../../images/style-examples/realistic.webp'
import RealisticPlusExample from '../../../images/style-examples/realisticplus.webp'
import ArtExample from '../../../images/style-examples/art.webp'
import Anime from '../../../images/style-examples/anime.webp'
import Cartoon from '../../../images/style-examples/cartoon.webp'
import Oil from '../../../images/style-examples/oil.webp'
import Watercolor from '../../../images/style-examples/watercolor.webp'
import DigitalArt from '../../../images/style-examples/digital.webp'
import Impressionism from '../../../images/style-examples/impressionism.webp'
import Surrealism from '../../../images/style-examples/surrealism.webp'
import Abstract from '../../../images/style-examples/abstractionism.webp'
import Cubism from '../../../images/style-examples/cubism.webp'
import PopArt from '../../../images/style-examples/popart.webp'
import Expressionism from '../../../images/style-examples/expressionism.webp'
import Minimalism from '../../../images/style-examples/minimalism.webp'

import PopArt_min from '../../../images/style-examples/mobile/pop-art.webp'
import DigitalArt_min from '../../../images/style-examples/mobile/digital-art.webp'
import Abstract_min from '../../../images/style-examples/mobile/abstract.webp'
import Expressionism_min from '../../../images/style-examples/mobile/expressionism.webp'
import Impressionism_min from '../../../images/style-examples/mobile/impressionism.webp'
import Watercolor_min from '../../../images/style-examples/mobile/watercolor.webp'
import Art_min from '../../../images/style-examples/mobile/art.webp'
import Cartoon_min from '../../../images/style-examples/mobile/cartoon.webp'
import Surrealism_min from '../../../images/style-examples/mobile/surrealism.webp'
import Cubism_min from '../../../images/style-examples/mobile/cubism.webp'
import Minimalism_min from '../../../images/style-examples/mobile/minimalism.webp'
import Oil_min from '../../../images/style-examples/mobile/oil.webp'
import RealisticPlus_min from '../../../images/style-examples/mobile/realistic+.webp'
import Realistic_min from '../../../images/style-examples/mobile/realistic.webp'
import Anime_min from '../../../images/style-examples/mobile/anime.webp'

export const types = [
  { value: 'Anything', label: <T keyName='eleo-select-option-anything'>Anything</T> },
  { value: 'Icon', label: <T keyName='eleo-select-option-icon'>Icon</T>, exclusive: true },
  { value: 'Logo', label: <T keyName='eleo-select-option-logo'>Logo</T>, exclusive: true },
  { value: 'Portrait', label: <T keyName='eleo-select-option-portrait'>Portrait</T> },
  { value: 'Character', label: <T keyName='eleo-select-option-character'>Character</T> },
  // Just hiding on the frontend
  // { value: 'r_dream', label: <T keyName="eleo-select-option-replicate-dream">Replicate Dream</T> },
]

export const styles = [
  {
    value: 'Realistic',
    modelType: 'sd15',
    label: <T keyName='eleo-select-option-realistic'>Realistic</T>,
    image: RealisticExample,
    imageMobile: Realistic_min,
  },
  {
    value: 'RealisticPlus',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-realistic-plus'>Realistic+</T>,
    image: RealisticPlusExample,
    imageMobile: RealisticPlus_min,
  },
  {
    value: 'Art',
    modelType: 'sd15',
    label: <T keyName='eleo-select-option-art'>Art</T>,
    image: ArtExample,
    imageMobile: Art_min,
  },
  {
    value: 'Anime',
    modelType: 'sd15',
    label: <T keyName='eleo-select-option-anime'>Anime</T>,
    image: Anime,
    imageMobile: Anime_min,
  },
  {
    value: 'Cartoon',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-cartoon'>Cartoon</T>,
    image: Cartoon,
    imageMobile: Cartoon_min,
  },
  {
    value: 'Oil',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-oil'>Oil Painting</T>,
    image: Oil,
    imageMobile: Oil_min,
  },
  {
    value: 'Watercolor',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-watercolor'>Watercolor</T>,
    image: Watercolor,
    imageMobile: Watercolor_min,
  },
  {
    value: 'DigitalArt',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-digitalart'>Digital Art</T>,
    image: DigitalArt,
    imageMobile: DigitalArt_min,
  },
  {
    value: 'Impressionism',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-impressionism'>Impressionism</T>,
    image: Impressionism,
    imageMobile: Impressionism_min,
  },
  {
    value: 'Surrealism',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-surrealism'>Surrealism</T>,
    image: Surrealism,
    imageMobile: Surrealism_min,
  },
  {
    value: 'Abstract',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-abstract'>Abstract</T>,
    image: Abstract,
    imageMobile: Abstract_min,
  },
  {
    value: 'Cubism',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-cubism'>Cubism</T>,
    image: Cubism,
    imageMobile: Cubism_min,
  },
  {
    value: 'PopArt',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-popart'>Pop Art</T>,
    image: PopArt,
    imageMobile: PopArt_min,
  },
  {
    value: 'Expressionism',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-expressionism'>Expressionism</T>,
    image: Expressionism,
    imageMobile: Expressionism_min,
  },
  {
    value: 'Minimalism',
    modelType: 'sdxl',
    label: <T keyName='eleo-select-option-minimalism'>Minimalism</T>,
    image: Minimalism,
    imageMobile: Minimalism_min,
  },
]

export const modes = [
  { value: '2', label: <T keyName={'eleo-select-option-fastest'}>Fastest</T> },
  { value: '5', label: <T keyName={'eleo-select-option-average'}>Average</T> },
  { value: '8', label: <T keyName={'eleo-select-option-detailed'}>Detailed</T> },
]
