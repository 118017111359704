import { T, useTranslate } from '@tolgee/react'
import { useWindowDimensions } from 'components/hooks/window'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setYourStoryText } from 'store/account/yourStory'
import TextLength from 'toolComponents/text-length'
import { Input } from 'ui'

const StoryGenerator = ({ storyText, wordLength, setWordLength }) => {
  const { t } = useTranslate()
  const { height } = useWindowDimensions()
  const dispatch = useDispatch()

  const handleInputChange = (text) => {
    dispatch(setYourStoryText(text))
  }

  useEffect(() => {
    const numWords = storyText?.split(/\s+/)?.length
    const wordLength = storyText?.length ? numWords : 0
    setWordLength(wordLength)
  }, [storyText])

  return (
    <div className='flex items-start gap-[10px]' data-tooltip-id='yourStory-yourStoryText'>
      <div>
        <p className='text-brand-body min-w-[75px] max-w-[75px] flex-shrink-0 flex-grow-0 break-words text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-your-story-text'>Your story text</T>
        </p>
      </div>
      <div id='textbox' className='relative w-full'>
        <Input
          placeholder={t('eleo-your-story-placeholder', 'Write your story')}
          className='placeholder-font-regular w-full rounded-[8px] pb-8 text-[13px] placeholder-[#36363680]'
          style={{ minHeight: `${height - 620}px` }}
          onChange={handleInputChange}
          value={storyText}
          isTextArea
          resize='none'
        />
        <TextLength
          numWords={wordLength}
          characters={storyText?.length}
          limit={4000}
          className='bottom-2'
        />
      </div>
    </div>
  )
}

export default StoryGenerator
