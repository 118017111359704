/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from 'react'
import { useState } from 'react'
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { Button, Input, TextArea } from 'ui'
import axios from 'axios'
import { T } from '@tolgee/react'
import { errorToast } from 'helpers'
import eleoLogo from '../../images/eleo_raw.svg'

export function Contact(props) {
  // return (
  //   <>
  //     <Row title="Welcome to Eleo.ai">
  //       <div className="text-lg text-center">
  //         Welcome to Eleo, the place where your questions and needs take center
  //         stage. We understand that communication preferences vary for each
  //         individual, which is why we provide an array of contact options,
  //         allowing you to choose the one that suits your needs best or just
  //         write send us a message at:
  //         <div className="mt-3">
  //           <a className="text-2xl text-center" href="mailto: contact@eleo.ai">
  //             contact@eleo.ai
  //           </a>
  //         </div>
  //       </div>
  //     </Row>
  //     <Row title="Contact Us">
  //       <div className="text-lg mb-5 px-14">
  //         If you prefer expressing your questions and feedback in written form,
  //         you can make use of our question form. Just fill it out in a few
  //         simple steps, and we'll strive to get back to you as swiftly as
  //         possible.
  //       </div>
  // <ContactForm />
  //     </Row>{' '}
  //   </>
  // )

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    const url = '/api/utility/mail'
    const data = {
      name,
      email,
      message,
      destination: 'contact',
    }
    try {
      const response = await axios.post(url, data)
      if (response) {
        setSuccess(true)
      }
    } catch (err) {
      errorToast(JSON.stringify(err))
    }
  }

  return (
    <div className='relative isolate border-y bg-white'>
      <div className='mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2 '>
        <div className='relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48'>
          <div className='mx-auto max-w-xl lg:mx-0 lg:max-w-lg'>
            <div className='absolute inset-y-0 left-0 -z-10 w-full overflow-hidden border-x lg:w-1/2'>
              <svg
                className='absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
                aria-hidden='true'
              >
                <defs>
                  <pattern
                    id='83fd4e5a-9d52-42fc-97b6-718e5d7ee527'
                    width={200}
                    height={200}
                    x='100%'
                    y={-1}
                    patternUnits='userSpaceOnUse'
                  >
                    <path d='M130 200V.5M.5 .5H200' fill='none' />
                  </pattern>
                </defs>
                <rect width='100%' height='100%' strokeWidth={0} fill='white' />
                <svg x='100%' y={-1} className='overflow-visible fill-gray-50'>
                  <path d='M-470.5 0h201v201h-201Z' strokeWidth={0} />
                </svg>
                <rect
                  width='100%'
                  height='100%'
                  strokeWidth={0}
                  fill='url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)'
                />
              </svg>
            </div>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900'>
              <T keyName='eleo-contact-header'>Get in touch</T>
            </h2>
            <p className='mt-6 text-lg leading-8 text-gray-600'>
              <T keyName='eleo-contact-description'>
                Welcome to Eleo, the place where your questions and needs take center stage. We
                understand that communication preferences vary for each individual, which is why we
                provide an array of contact options, allowing you to choose the one that suits your
                needs best
              </T>
            </p>
            <dl className='mt-10 space-y-4 text-base leading-7 text-gray-600'>
              <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Address</span>
                  <BuildingOffice2Icon className='h-7 w-6 text-gray-400' aria-hidden='true' />
                </dt>
                <dd>
                  Sw. Marcin 29/8
                  <br />
                  Poznan
                </dd>
              </div>

              <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Email</span>
                  <EnvelopeIcon className='h-7 w-6 text-gray-400' aria-hidden='true' />
                </dt>
                <dd>
                  <a className='hover:text-gray-900' href='mailto:contact@eleo.ai'>
                    contact@eleo.ai
                  </a>
                </dd>
              </div>
              <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Email</span>
                  <PhoneIcon className='h-7 w-6 text-gray-400' aria-hidden='true' />
                </dt>
                <dd>
                  <a className='hover:text-gray-900' href='tel:+48510457041'>
                    +48 510 457 041
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        {success ? (
          <Success />
        ) : (
          <form onSubmit={handleSubmit} className='px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48'>
            <div className='mx-auto max-w-xl lg:mr-0 lg:max-w-lg'>
              <div className='flex flex-col gap-y-6'>
                <div>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-semibold leading-6 text-gray-900'
                  >
                    <T keyName={`eleo-contact-first-name`}>First name</T>
                  </label>
                  <div className='mt-2.5'>
                    <Input
                      required
                      className='w-full'
                      value={name}
                      onChange={setName}
                      placeholder=''
                    ></Input>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-semibold leading-6 text-gray-900'
                  >
                    <T keyName='eleo-create-your-account-email'>Email</T>
                  </label>
                  <div className='mt-2.5'>
                    <Input
                      required
                      className='w-full'
                      value={email}
                      onChange={setEmail}
                      placeholder=''
                    ></Input>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='message'
                    className='block text-sm font-semibold leading-6 text-gray-900'
                  >
                    <T keyName='eleo-contact-message'>Message</T>
                  </label>
                  <div className='mt-2.5'>
                    <TextArea
                      required
                      className='w-full'
                      value={message}
                      onChange={setMessage}
                      rows={4}
                    ></TextArea>
                  </div>
                </div>
              </div>
              <div className='mt-8 flex justify-end'>
                <button
                  type='submit'
                  className='bg-brand-green rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  <T keyName='eleo-contact-send-message'>Send message</T>
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

const Success = () => {
  return (
    <div className='flex pb-[30px] pt-[120px]'>
      <div className='flex flex-col gap-[20px]'>
        <img src={eleoLogo} className='h-[120px] w-full' alt='logo' />
        <h1 className='text-brand-body text-center text-[45px] font-bold leading-[1.1em]'>
          <T keyName='eleo-contact-success-alert'>Your Message Has Been Successfully Submitted!</T>
        </h1>
        <div className='mx-auto w-full max-w-[300px]'>
          <Button color='violet' className='w-full' href='/'>
            <T keyName='eleo-contact-back-to-main-page'>Back To Main Page</T>
          </Button>
        </div>
      </div>
    </div>
  )
}
