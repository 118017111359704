/***
 *
 *   PRIVACY POLICY
 *   Boilerplate privacy statement – replace the values
 *   inside [] with your own text
 *
 **********/

import React from 'react'
import { Article } from 'components/lib'

export function Privacy(props) {
  const content = `
<h1>Privacy Policy of Eleo.ai</h1>

<b>1. Introduction</b>

This Privacy Policy outlines the principles governing the processing and protection of personal data provided by Users in connection with their use of the Eleo.ai service (referred to as the "Service").

<b>2. Data Controller</b>

The Data Controller for personal data is:

ELEO PROSTA SPÓŁKA AKCYJNA
Registered office: ul. Św. Marcin 29/9, 61-806 Poznań, Poland
KRS: 0001050042
NIP: 7831885200
REGON: 525991139

<b>3. Contact Details</b>

You can reach the Data Controller at the following contact information:

Eleo PSA
ul. Św. Marcin 29/9
61-806 Poznań
Email: dpo@eleo.ai

<b>4. Data Security</b>

To ensure the security of entrusted data, the Data Controller has developed internal procedures and recommendations aimed at preventing unauthorized access to data. The Data Controller supervises their implementation and continuously verifies their compliance with relevant legal acts, including the Personal Data Protection Act, the Act on the Provision of Electronic Services, and all types of executive and community law acts.

<b>5. Purposes of Data Processing</b>

Personal data of Service users are processed for the following purposes:

a) Providing services, including user account management and ensuring access to Service content and features.
b) Handling inquiries and questions from users addressed to the Data Controller.
c) Conducting marketing and promotional activities, including sending commercial information, offers, and newsletters, based on user consent.
d) Ensuring the security and protection of the Service and user data.

<b>6. Legal Basis for Processing</b>

Personal data are processed based on the consent expressed by the User and in cases where legal provisions authorize the Data Controller to process personal data based on the law or for the purpose of performing a contract between the parties.

<b>7. Information Collection</b>

The Service collects information about users and their behavior through the following means:

a) Voluntarily provided information in forms.
b) Collection of "cookies" files.

<b>8. Voluntary Information</b>

The Service collects information that users voluntarily provide.

<b>9. Categories of Personal Data</b>

The Data Controller processes the following categories of personal data of users:

Identifying data, such as name and surname, email address, phone number.
Data collected automatically during the use of the Service, such as IP address, device data, and information about visited pages.

<b>10. Purpose-Based Processing</b>

Data provided in forms are processed for specific form-related purposes, e.g., for informational contact, payment processing, etc.

<b>11. Non-Disclosure of Personal Data</b>

Personal data left on the website will not be sold or disclosed to third parties, except for verification related to payment or issuance of financial documents, in accordance with the provisions of the Personal Data Protection Act.

<b>12. Data Retention</b>

Personal data of users are stored for the period necessary to achieve the purposes for which they were collected, in accordance with applicable law. After the purposes of processing have been completed, personal data are deleted or anonymized.

<b>13. Consent to Privacy Policy</b>

By using the Eleo.ai website and its functions, you consent to the provisions contained in this Privacy Policy, which is an integral part of the Terms of Service available on the Eleo.ai website.

<b>14. User Rights</b>

Individuals who have provided data in a form have the right to access, modify, and cease the processing of their data at any time.

<b>15. Privacy Policy Updates</b>

The Data Controller reserves the right to change the Privacy Policy of the Service, which may be influenced by the development of internet technology, changes in data protection law, and the development of the internet service. The current version of the Privacy Policy is always available on the Service's website.

<b>16. External Links</b>

The Service may contain links to other websites that operate independently of the Eleo.ai Service and are not supervised by Eleo.ai. These websites may have their own privacy policies and terms of use, which we recommend reviewing.

<b>17. Governing Law</b>

Matters not covered by the Terms of Service and the Privacy Policy are governed by the provisions of the Polish Civil Code and relevant Polish laws, as well as EU law, particularly the GDPR (Regulation (EU) 2016/679).

<b>18. Effective Date</b>

This Privacy Policy is effective from September 19, 2023. Using the Service after this date signifies acceptance of the provisions of the Privacy Policy.

<b>19. Contact Information</b>

For questions or concerns regarding the processing of personal data or to exercise the rights granted to users, please contact the Data Controller at dpo@eleo.ai.

`

  return (
    <Article>
      <pre
        className='whitespace-pre-wrap'
        style={{ font: 'inherit' }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></pre>
    </Article>
  )
}
