/***
 *
 *   Features/Ideas Page
 *
 **********/

import Bolt from '@mui/icons-material/Bolt'
import { AutoStories, Share, History } from '@mui/icons-material'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import DrawIcon from '@mui/icons-material/Draw'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'

import { useTranslate } from '@tolgee/react'

import image from 'images/features/ideas.png'

export function Ideas() {
  const { t } = useTranslate()

  const featureId = 'ideas'
  const translations = {
    header: {
      title: `Great Ideas`,
      subtitle: `"Great Ideas" helps you quickly find and shape creative ideas for all sorts of projects, making it easy to come up with names, plans, and more, all tailored to your needs.`,
      description: `Discover how Eleo becomes your creative ally in brainstorming the perfect name for your Christmas promotion..`,
      link: {
        href: '/great-ideas',
        text: t('eleo-try-now', 'Try now!'),
      },
    },
    content: {
      title: `Why is 'Great Ideas' a Game-Changer?`,
      subtitle: ``,
      description: `Struggling for inspiration? Don't let a shortage of ideas slow you down, consuming your valuable time and energy. 'Great Ideas' is your partner in creativity, offering a wealth of ideas for names, descriptions, plans, titles, promotions, products, and services. It's the spark you need for your next big project.`,
      features: [
        {
          name: `Instant.`,
          description: `Instantly tap into a universe of ideas on any subject, from names and titles to complete marketing strategies.`,
          icon: Bolt,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `Use Your Story`,
          description: `Let Eleo personalize these ideas by integrating Your Story, ensuring each suggestion is precisely aligned with your unique scenario.`,
          icon: AutoStories,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `Diverse.`,
          description: `Benefit from Eleo’s diverse tonal range, making every idea resonate with your specific context.`,
          icon: Diversity3Icon,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `Creative.`,
          description: `Direct the flow of creativity, from subtle to wildly imaginative, to suit your project’s needs.`,
          icon: DrawIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Share.`,
          description: `Effortlessly edit, share, and build upon these ideas.`,
          icon: Share,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `History.`,
          description: `Revisit and refine your pool of ideas anytime, with easy access to your idea history.`,
          icon: History,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        link={translations.header.link}
        // description={t(`eleo-features-${featureId}-header-description`, translations.header.description)}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <UseEleoToday next='great-ideas' />
    </div>
  )
}
