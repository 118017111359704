import React from 'react'
import { Animate } from 'components/lib'
import CheckTextLayout from 'toolComponents/writer/check-text/CheckTextLayout'

export function WriterCheckText(props) {
  return (
    <Animate type='pop'>
      <CheckTextLayout />
    </Animate>
  )
}
