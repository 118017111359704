/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React, { useContext } from 'react'
import { Animate, AuthContext, Form, useNavigate, useLocation } from 'components/lib'
import { T } from '@tolgee/react'

export function ResetPassword(props) {
  // context
  const navigate = useNavigate()
  const location = useLocation()
  const context = useContext(AuthContext)

  // check for token
  const qs = location.search
  const token = qs.substring(qs.indexOf('?token=') + 7)

  return (
    <Animate type='pop'>
      <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='text-brand-violet mt-6 text-center text-2xl font-bold leading-9 tracking-tight'>
            <T keyName='eleo-reset-your-password-header'>Reset Your Password</T>
          </h2>
        </div>
        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
          <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12'>
            <Form
              inputs={{
                jwt: {
                  type: 'hidden',
                  value: token,
                },
                email: {
                  label: <T keyName='eleo-create-your-account-email'>Email</T>,
                  type: 'email',
                  required: true,
                  class:
                    'block w-full rounded-md border-0 py-1.5 text-brand-body shadow-sm ring-1 ring-inset ring-brand-grey placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-violet sm:text-sm sm:leading-6',
                },
                password: {
                  label: <T keyName='eleo-reset-password-new-password'>New Password</T>,
                  type: 'password',
                  required: true,
                  complexPassword: true,
                  class:
                    'block w-full rounded-md border-0 py-1.5 text-brand-body shadow-sm ring-1 ring-inset ring-brand-grey placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-violet sm:text-sm sm:leading-6',
                },
              }}
              url='/api/auth/password/reset'
              method='POST'
              buttonText={<T keyName='eleo-reset-password-set-new-password'>Set New Password</T>}
              inputLabelClass='block text-sm font-medium leading-6 text-brand-body capitalize'
              btnColor='purple'
              btnClasses='flex w-full justify-center rounded-md bg-brand-violet px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-violet-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              callback={(res) => {
                res.data['2fa_required']
                  ? navigate(`/signin/otp?token=${res.data.token}`)
                  : context.signin(res)
              }}
            />
          </div>
        </div>
      </div>
    </Animate>
  )
}
