/***
 *
 *   REDEEM
 *   Redeem a gift code
 *
 **********/

import { T } from '@tolgee/react'
import { Animate, Card, Form, Message } from 'components/lib'
import { Fragment, useState } from 'react'
import { useEffect } from 'react'

export function Redeem(props) {
  const [done, setDone] = useState(localStorage.getItem('code_redeemed'))

  // Remove localstorage item on unmount
  useEffect(() => {
    return () => {
      localStorage.removeItem('code_redeemed')
    }
  }, [])

  return (
    <Fragment>
      <Animate>
        <Card
          title={<T keyName='eleo-profile-redeem-header'>Redeem a code</T>}
          restrictWidth
          className={props.className}
        >
          {!done ? (
            <Form
              url='/api/account/redeem'
              method='POST'
              buttonText={<T keyName='eleo-profile-redeem-button'>Redeem Code</T>}
              inputs={{
                code_id: {
                  label: <T keyName='eleo-profile-redeem-label'>Input your code</T>,
                  type: 'text',
                  required: true,
                },
              }}
              callback={() => {
                setDone(true)
                window.location.reload()
                localStorage.setItem('code_redeemed', 'true')
              }}
            />
          ) : (
            <Message
              title={<T keyName='eleo-profile-redeem-success-header'>Code redeemed</T>}
              type='success'
              text={
                <T keyName='eleo-profile-redeem-success-description'>
                  Your code has been applied successfully
                </T>
              }
            />
          )}
        </Card>
      </Animate>
    </Fragment>
  )
}
