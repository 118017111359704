import { Popover } from '@mui/material'
import classNames from 'classnames'
import React, { useState } from 'react'
import { ListPosition, Microbutton, ReactSelect } from 'ui'
import { KeyboardArrowDown } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import { Modal } from 'toolComponents/generic/modal'

const DEFAULT_MODAL_DATA = {
  isVisible: false,
  title: '',
  subtitle: '',
  acceptLabel: '',
  type: '',
  context: {},
}

export function SaveButton({
  title,
  id,
  handleSaveTemplate,
  handleUpdateTemplate,
  lastUsedTemplate,
  templates,
}) {
  const { t } = useTranslate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [newTemplateName, setNewTemplateName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({ label: '', value: '' })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCallback = async (type, context) => {
    switch (type) {
      case 'save':
        await handleSaveTemplate(newTemplateName)
        break
      case 'update':
        await handleUpdateTemplate(context.template.id, {
          templateName: context.template.templateName,
        })
        break
      case 'select-update':
        await handleUpdateTemplate(selectedTemplate.value, {
          templateName: selectedTemplate.label,
        })
        break
      default:
        break
    }
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'save-template' : undefined

  const modalContent = {
    save: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          {t('eleo-templates-modal-subtitle', 'Template name')}
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
          placeholder={t('eleo-templates-modal-input-placeholder', 'Enter template name')}
        />
      </div>
    ),

    update: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-secondary text-brand-violet/80'>
          <T
            keyName='eleo-templates-modal-update-specific'
            params={{ i: <i />, templateName: modalData.context?.template?.templateName }}
            defaultValue='Template <i>{templateName}</i> will be overwritten with the current form content.'
          />
        </p>
      </div>
    ),
    'select-update': (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          <T keyName='eleo-templates-modal-update-selected' defaultValue='Template to update' />
        </p>
        <ReactSelect
          options={templates.user.map((template) => ({
            value: template.id,
            label: template.templateName,
          }))}
          setSelectedValue={({ value, label }) => setSelectedTemplate({ value, label })}
        />
      </div>
    ),
  }

  return (
    <>
      <div title={title}>
        <Microbutton
          id={id}
          className='hover:bg-brand-form-accent text-brand-violet-light bg-brand-form-accent-light hover:text-brand-violet border-brand-form-accent h-[40px] rounded-r-none border-r uppercase'
          variant='plain'
          type='main'
          text={t('save', 'save')}
          iconPlacement='right'
          icon={<KeyboardArrowDown fontSize='inherit' />}
          data-tooltip-id='images-imageCreator-saveTemplate'
          onClick={handleClick}
        />

        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className='bg-brand-accent-flat border-brand-form-accent space-y-[6px] rounded-[4px] border p-2 pb-[10px]'>
            {lastUsedTemplate && (
              <ListPosition
                text={t('eleo-templates-update-x', 'Update {templateName}', {
                  templateName: lastUsedTemplate.templateName,
                })}
                onClick={() => {
                  handleClose()

                  setModalData({
                    isVisible: true,
                    title: (
                      <T
                        keyName='eleo-templates-modal-title-update-specific'
                        params={{ i: <i />, templateName: lastUsedTemplate.templateName }}
                        defaultValue='Update template <i>{templateName}</i>'
                      />
                    ),
                    acceptLabel: t('eleo-update', 'update'),
                    type: 'update',
                    context: { template: lastUsedTemplate },
                  })
                }}
              />
            )}
            <ListPosition
              text={t('eleo-templates-select-to-update', 'Select template to update')}
              onClick={() => {
                handleClose()
                setNewTemplateName('')

                setModalData({
                  isVisible: true,
                  title: t('eleo-templates-modal-title-update', 'Update template'),
                  subtitle: t(
                    'eleo-templates-modal-subtitle-update',
                    'Selected template will be overwritten with current form content'
                  ),
                  acceptLabel: t('eleo-update', 'update'),
                  type: 'select-update',
                  context: {},
                })
              }}
            />
            <div className='border-brand-form-accent-light mx-[6px] border-b' />
            <ListPosition
              text={t('eleo-templates-save-as-new', 'Save as new template')}
              onClick={() => {
                handleClose()
                setNewTemplateName('')

                setModalData({
                  isVisible: true,
                  title: t('eleo-templates-modal-title-save', 'Save as template'),
                  acceptLabel: t('eleo-save', 'save'),
                  type: 'save',
                  context: {},
                })
              }}
            />
          </div>
        </Popover>
      </div>
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        callback={() => handleCallback(modalData.type, modalData.context)}
        isValid={
          ['update'].includes(modalData.type) ||
          newTemplateName.length ||
          selectedTemplate.value?.length
        }
      />
    </>
  )
}
