import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { T } from '@tolgee/react'
import axios from 'axios'
import { Modal, ViewContext } from 'components/lib'
import { CircleNotch } from 'phosphor-react'
import { useContext, useState } from 'react'
import { Button } from 'ui'

const Settings = require('settings.json')
const currentProcess = process.env.REACT_APP_MODE
  ? process.env.REACT_APP_MODE
  : process.env.NODE_ENV
const stripePromise = loadStripe(Settings[currentProcess].stripe.publishableAPIKey)

const PaymentModal = ({ modalVisible, setModalVisible, modalData, updateLimits }) => {
  const context = useContext(ViewContext)
  // const [tokenValid, setTokenValid] = useState({ valid: true, error: null })
  const [isLoading, setIsLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState()

  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)

    try {
      // const cardElement = await elements.getElement(CardElement)
      // const token = await stripe.createToken(cardElement)
      // if (token.error) {
      //   setTokenValid(false)
      //   return
      // }
      // setTokenValid(true)

      const url = '/api/account/pack'
      const data = { item: modalData.item }
      const res = await axios.post(url, data)

      setClientSecret(res.data.client_secret)

      // Confirm the transaction and perform 3ds if neccessary
      // const stripeRes = await stripe.confirmCardPayment(res.data.client_secret, {
      //   payment_method: {
      //     card: cardElement,
      //   },
      // })

      // if (stripeRes.error) {
      //   context.handleError(stripeRes.error.message)
      // } else {
      //   setModalVisible(false)
      //   successToast('Purchase successful!')

      //   // Update counters
      //   setTimeout(() => {
      //     updateLimits()
      //   }, 3000)
      // }
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      modalClassName={!modalVisible && 'hidden'}
      modalContainerClassNames={clientSecret && '!max-w-[65rem] overflow-y-auto max-h-[90dvh]'}
      setHideModal={() => {
        !isLoading && setModalVisible(false)
      }}
      text={
        !clientSecret && (
          <p className='text-brand-body text-[19px] font-bold'>
            <T keyName='packs-modal-header'>Proceed to checkout</T>
          </p>
        )
      }
    >
      {/* <CardInput valid={tokenValid} onFocus={() => setTokenValid(true)} /> */}
      <div id='checkout'>
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      {!clientSecret && (
        <div className='mt-6 flex w-full gap-4'>
          <Button
            disabled={isLoading}
            color='gray'
            size={16}
            onClick={() => {
              setModalVisible(false)
            }}
            className='!float-none'
          >
            <T keyName='packs-modal-cancel'>Cancel</T>
          </Button>
          <Button
            disabled={isLoading}
            color='green'
            size={16}
            onClick={handleSubmit}
            className='!float-none'
          >
            {isLoading ? (
              <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
            ) : (
              <T keyName='eleo-pack-buy-now-button'>Buy now</T>
            )}
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default PaymentModal
