import { useState, useEffect } from 'react'
import { useAPI } from 'components/lib'

export function useAccess() {
  const [state, setState] = useState({ plan: null, loading: false })
  const plans = useAPI('/api/account/plans')
  const planHierarchy = { Free: 0, Basic: 1, Standard: 2, Pro: 3, Team: 4 }

  useEffect(() => {
    setState({ loading: true })

    if (plans?.data) {
      setState({
        plan: plans.data.active,
        loading: false,
      })
    }
  }, [plans])

  function hasAccess(requiredPlan) {
    if (!state.plan || state.loading) return false

    // Override if the user has a gift code
    if (state.plan.hasCoupon) return true

    return planHierarchy[state.plan.name] >= planHierarchy[requiredPlan]
  }

  return hasAccess
}
