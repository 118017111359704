import { Animate, useAPI, usePlans } from 'components/lib'
import ChangePlan from './ChangePlan'
import YourPlan from './YourPlan'

export default function Plans() {
  const { data: subscription, loading: loadingSubscription } = useAPI('/api/account/subscription')
  const { data: plans, loading: loadingPlans } = usePlans()

  if (!subscription || loadingSubscription) return
  if (!plans || loadingPlans) return

  return (
    <div className='h-full overflow-y-auto px-5'>
      <Animate>
        <YourPlan subscription={subscription} plans={plans} />
        <ChangePlan subscription={subscription} plans={plans} />
      </Animate>
    </div>
  )
}
