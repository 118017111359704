import { APP_NAME } from 'constants'
import ChatBot from 'views/tools/chat-bot'
import { About } from 'views/website/about'
import { Contact } from 'views/website/contact'
import { Advisor } from 'views/website/features/advisor'
import { Chat } from 'views/website/features/chat'
import { Chatbot } from 'views/website/features/chatbot'
import { Extension } from 'views/website/features/extension'
import { Ideas } from 'views/website/features/ideas'
import { Images } from 'views/website/features/images'
import { Translator } from 'views/website/features/translator'
import { Writer } from 'views/website/features/writer'
import { YourStory } from 'views/website/features/yourStory'
import { Home } from 'views/website/home'
import Partners from 'views/website/partners'
import { Plans } from 'views/website/plans'
import { Pricing } from 'views/website/pricing'
import { Privacy } from 'views/website/privacy'
import { Questions } from 'views/website/questions'
import { Terms } from 'views/website/terms'

const Routes = [
  {
    path: '/',
    view: Home,
    // layout: 'home',
    title: `${APP_NAME}`,
  },
  {
    path: '/chat/:botName',
    view: ChatBot,
    layout: 'chatBot',
    title: 'Chat',
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact',
  },
  {
    path: '/questions',
    view: Questions,
    layout: 'home',
    title: 'Questions',
  },
  {
    path: '/about',
    view: About,
    layout: 'home',
    title: 'About',
  },
  {
    path: '/partners',
    view: Partners,
    layout: 'home',
    title: 'Partners',
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing',
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy',
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions',
  },
  {
    path: '/plans',
    view: Plans,
    layout: 'home',
    title: 'Plans & Benefits',
  },
  // FEATURES
  {
    path: '/features/chat',
    view: Chat,
    layout: 'home',
    title: 'Chat',
  },
  {
    path: '/features/writer',
    view: Writer,
    layout: 'home',
    title: 'Writer',
  },
  {
    path: '/features/advisor',
    view: Advisor,
    layout: 'home',
    title: 'Advisor',
  },
  {
    path: '/features/translator',
    view: Translator,
    layout: 'home',
    title: 'Translator',
  },
  {
    path: '/features/ideas',
    view: Ideas,
    layout: 'home',
    title: 'Ideas',
  },
  {
    path: '/features/images',
    view: Images,
    layout: 'home',
    title: 'images',
  },
  {
    path: '/features/your-story',
    view: YourStory,
    layout: 'home',
    title: 'your story',
  },
  {
    path: '/features/chatbot',
    view: Chatbot,
    layout: 'home',
    title: 'chatbot',
  },
  {
    path: '/features/extension',
    view: Extension,
    layout: 'home',
    title: 'Extension',
  },
]

export default Routes
