import links from '../../docsLinks'

export const imageCreator = {
  description: {
    title: 'Description',
    content:
      "Describe the image you want to generate. Briefly outline the main idea or scene you wish to see in the image. Specify your expectations, such as 'beach with palms,' so Eleo can generate the appropriate image.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-1',
  },

  keyWords: {
    title: 'Keywords',
    content: 'List the key elements you want to see in the image.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-2',
  },

  avoid: {
    title: 'Avoid',
    content:
      "Specify what you do NOT want in the image. It's like giving a prohibition, for example, 'no animals,' which helps Eleo exclude unwanted elements while generating the image.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-3',
  },

  type: {
    title: 'Type',
    content:
      "'Type' refers to the kind of image you'd like to receive. It could be a character, logo, portrait, etc.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-4',
  },

  amount: {
    title: 'Amount',
    content: 'Indicate how many images you want Eleo to generate for you.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-5',
  },

  style: {
    title: 'Style',
    content:
      'Define the style of the image you want. It could be a photorealistic landscape, an anime frame, or a comic strip.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-6',
  },

  proportions: {
    title: 'Proportions',
    content: "Specify the image's desired proportions, such as square, vertical, or horizontal",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-7',
  },

  size: {
    title: 'Size',
    content: 'Specify the width and height of the image.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-8',
  },

  quality: {
    title: 'Quality',
    content:
      'The higher the number, the better the image quality. It could be low quality, like a sketch; medium quality, like an image from the internet; or high quality, like a photograph.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-9',
  },

  creativity: {
    title: 'Creativity',
    content:
      "The higher the creativity, the greater Eleo's ability to create original and imaginative images. It's like magic brushes that allow for the generation of unusual and creative images, such as a futuristic city, fantastic creatures, or surreal landscapes.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-10',
  },

  imageArea: {
    title: 'Image Area',
    content:
      "This is where you'll find images generated by Eleo. Hovering over a selected image will bring up a context menu. Using this menu, you can continue to generate similar images, copy, zoom in, modify, download, save in 'My Gallery,' or share on your social media.",
    actions: [links.imageEditing],
    tolgee_id: 'eleo-tool-image-create-help-tooltip-11',
  },

  imageArrows: {
    title: 'Left and Right Arrows',
    content:
      'Using the arrows, you can navigate to images generated earlier or later using the REGENERATE button.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-12',
  },

  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of images with existing instructions.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-13',
  },

  //actions

  saveTemplate: {
    title: 'Save Template',
    content:
      "Store your query as a template for future use. Find and use these templates from the list at the form's top when needed.",
    tolgee_id: 'eleo-tooltip-save-template',
  },

  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },

  generateImage: {
    title: 'Generate Image',
    content: 'Click GENERATE IMAGE to produce an image from your current commands and settings.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-14',
  },
}
