/***
 *
 *   Features/your-story Page
 *
 **********/

import ChildCareIcon from '@mui/icons-material/ChildCare'
import TuneIcon from '@mui/icons-material/Tune'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { SmartToy } from '@mui/icons-material'
import UpgradeIcon from '@mui/icons-material/Upgrade'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'

import { useTranslate } from '@tolgee/react'

import image from 'images/features/yourstory.png'

export function YourStory() {
  const { t } = useTranslate()

  const featureId = 'your-story'
  const translations = {
    header: {
      title: `Your Story`,
      subtitle: `Your Story, Your Way`,
      description: `Your Story transforms Eleo into a personalized tool that responds and thinks like you.`,
      link: {
        href: '/account/your-story',
        text: t('eleo-try-now', 'Try now!'),
      },
    },
    content: {
      title: `Why 'Your Story' is Essential`,
      subtitle: ``,
      description: `Personalize your experience with 'Your Story.' Input your personal, company, or product details to receive customized solutions and insights, turning Eleo into a tool that thinks and responds like you do.`,
      features: [
        {
          name: `Easy.`,
          description: `Crafting Your Story is simple - just a few sentences or a quick text copy from your website, brochure, or pitch.`,
          icon: ChildCareIcon,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `Fine-tuning.`,
          description: `'Your Story' enables you to fine-tune Eleo's responses, maximizing the potential of AI to suit your needs.`,
          icon: TuneIcon,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `Your Stories.`,
          description: `Store various versions of Your Story, selecting the most appropriate one for different contexts.`,
          icon: ListAltIcon,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `4,000 words.`,
          description: `Each version of Your Story can be a rich narrative of up to 4,000 words.`,
          icon: MenuBookIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Use with chatbots.`,
          description: `Deploy your personalized Chatbot in moments with Your Story, creating an automated assistant that understands and reflects your background to everyone from clients to colleagues and friends.`,
          icon: SmartToy,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `Update as you go.`,
          description: `Remember, Your Story is dynamic - update and evolve it as your journey unfolds.`,
          icon: UpgradeIcon,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        link={translations.header.link}
        // description={t(`eleo-features-${featureId}-header-description`, translations.header.description)}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <UseEleoToday next='your-story' />
    </div>
  )
}
