import React, { Fragment, useState, useEffect } from 'react'
import { Animate, Card, Form, Message, useAPI } from 'components/lib'
import { T } from '@tolgee/react'
export function TwoFA(props) {
  // get the user state
  const user = useAPI('/api/user')
  const [qrCode, setQrCode] = useState(undefined)
  const [enabled, setEnabled] = useState(undefined)
  const [backupCode, setBackupCode] = useState(undefined)

  useEffect(() => {
    setEnabled(user.data?.['2fa_enabled'])
  }, [user.data])

  return (
    <Fragment>
      {/* <AccountNav /> */}
      <Animate>
        <Card
          title={<T keyName='eleo-profile-2fa-header'>Two-Factor Authentication</T>}
          restrictWidth
          className={props.className}
          loading={user.loading}
        >
          <Form
            url='/api/user/2fa'
            method='put'
            submitOnChange
            inputs={{
              '2fa_enabled': {
                type: 'switch',
                label: (
                  <T keyName='eleo-profile-2fa-toggle-text'>Enable two-factor authentication</T>
                ),
                default: user?.data?.['2fa_enabled'],
              },
            }}
            callback={(res) => {
              setQrCode(res.data.data.qr_code)

              if (!res.data.data['2fa_enabled']) setEnabled(false)
            }}
          />

          {qrCode ? (
            <Fragment>
              <Message
                title={<T keyName='eleo-profile-2fa-scan-qr'>Scan QR Code</T>}
                type='info'
                text={
                  <T keyName='eleo-profile-2fa-scan-qr-description'>
                    Scan the QR code below with an authenticator app such as Google Authenticator
                    and enter the verification code.
                  </T>
                }
              />

              <img
                src={qrCode}
                alt={
                  <T keyName='eleo-profile-2fa-scan-with-authenticator-text'>
                    Please scan this with your authenticator app
                  </T>
                }
                style={{
                  marginBottom: '1em',
                  marginLeft: '-0.75em',
                }}
              />

              <Form
                method='post'
                url='/api/user/2fa/verify'
                buttonText={<T keyName='eleo-profile-2fa-verify'>Verify</T>}
                inputs={{
                  code: {
                    label: <T keyName='eleo-profile-2fa-verification-code'>Verification code</T>,
                    type: 'text',
                  },
                }}
                callback={(res) => {
                  setQrCode(null)
                  setEnabled(true)
                  setBackupCode(res.data.data.backup_code)
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              {enabled ? (
                <Fragment>
                  {backupCode ? (
                    <Message
                      title={<T keyName='eleo-profile-2fa-backup-code'>Your backup code</T>}
                      type='success'
                      text={
                        <T keyName='eleo-profile-2fa-backup-code-description'>
                          Please store your backup code somewhere safe.
                        </T>
                      }
                    >
                      <Form
                        inputs={{
                          code: {
                            type: 'text',
                            value: backupCode,
                          },
                        }}
                      />
                    </Message>
                  ) : (
                    <Message
                      title={<T keyName='eleo-profile-2fa-backup-code-2fa-enabled'>2FA Enabled</T>}
                      type='success'
                      text={
                        <T keyName='eleo-profile-2fa-backup-code-success-message'>
                          Good job! Two-factor authentication is enabled on your account.
                        </T>
                      }
                    />
                  )}
                </Fragment>
              ) : (
                <Message
                  title={<T keyName='eleo-profile-2fa-backup-code-2fa-enabled-2'>Enable 2FA</T>}
                  type='warning'
                  text={
                    <T keyName='eleo-profile-2fa-backup-code-success-message-2'>
                      We recommended enabling 2FA on your account for added security.
                    </T>
                  }
                />
              )}
            </Fragment>
          )}
        </Card>
      </Animate>
    </Fragment>
  )
}
