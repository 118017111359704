import axios from 'axios'

export class YourStoryAPIService {
  static generateWriterRequest(data, segment, method = 'POST', signal) {
    if (method === 'POST') {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
        ...signal,
      })
    } else {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })
    }
  }

  static async saveTemplate(data) {
    return this.generateWriterRequest(data, '/your-story/save-template')
  }

  static async updateTemplate(data) {
    return this.generateWriterRequest(data, '/your-story/update-template')
  }

  static async deleteTemplate(data) {
    return this.generateWriterRequest(data, '/your-story/delete-template')
  }

  static async getTemplates() {
    return this.generateWriterRequest({}, '/your-story/templates', 'GET')
  }
}
