/***
 *
 *   Features/Images Page
 *
 **********/

import Bolt from '@mui/icons-material/Bolt'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CollectionsIcon from '@mui/icons-material/Collections'
import Edit from '@mui/icons-material/Edit'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import { useTranslate } from '@tolgee/react'
import image from 'images/features/images.png'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'
import Content from 'tailwindComponents/eleoFeatures/Content'
import Header from 'tailwindComponents/eleoFeatures/Header'
import ImageCarousel from 'components/carousel/ImageCarousel'

export function Images() {
  const { t } = useTranslate()

  const featureId = 'images'
  const translations = {
    header: {
      title: `Custom Images`,
      subtitle: 'Capture attention with the right image',
      description: `Picture this: a futuristic car, crafted swiftly and precisely to your specifications.`,
      link: {
        href: '/image',
        text: t('eleo-try-now', 'Try now!'),
      },
    },
    content: {
      title: 'Why Choose Images?',
      subtitle: 'Everything you need',
      description:
        "Don't underestimate the power of images. Eleo can create pictures in different styles, so you don't need to hunt for stock photos. Just describe what you want, and Eleo will make the image for you. You can even make changes easily. Want a red hat instead of a black one? Eleo can do that in a snap",
      features: [
        {
          name: `Effortlessly.`,
          description: `Create new, unique images in any style with just a click.`,
          icon: Bolt,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `Use Templates.`,
          description: `Utilize templates for quicker, more streamlined image creation.`,
          icon: LibraryBooksIcon,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `Modify.`,
          description: `Personalize and perfect your images with easy modifications.`,
          icon: Edit,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `Customize.`,
          description: `Upload and customize your images to fit your specific needs.`,
          icon: CloudUploadIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Resize.`,
          description: `Take control of your image's size and quality for the perfect result.`,
          icon: PhotoSizeSelectLargeIcon,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `Cloud storage`,
          description: `Store and revisit your creations anytime in My Gallery, your personal visual archive.`,
          icon: CollectionsIcon,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        link={translations.header.link}

        // description={t(`eleo-features-${featureId}-header-description`, translations.header.description)}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <ImageCarousel />
      <UseEleoToday next='image' />
    </div>
  )
}
