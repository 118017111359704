import { useEffect, useState } from 'react'

export function useDetectLandscapeMode() {
  const [isLandscape, setIsLandscape] = useState(false)

  useEffect(() => {
    function handleOrientationChange() {
      if (window.screen?.orientation?.angle === 90 || window.screen?.orientation?.angle === 270) {
        setIsLandscape(true)
      } else {
        setIsLandscape(false)
      }
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    // Initial check
    handleOrientationChange()

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  return { isLandscape }
}
