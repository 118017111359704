import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { Apartment, Diamond, Lock, Savings, Verified } from '@mui/icons-material'
import { useStripe } from '@stripe/react-stripe-js'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { AuthContext, ViewContext, useNavigate } from 'components/lib'
import { periods } from 'helpers'
import moment from 'moment'
import { CircleNotch, Question } from 'phosphor-react'
import React, { Fragment, useContext, useState } from 'react'
import ChangePlanModal from './ChangePlanModal'

const teamsPlanIndex = 3
const tierIcons = [<Savings />, <Verified />, <Diamond />, <Apartment />]
const planOrder = {
  Free: 0,
  Basic: 1,
  Standard: 2,
  Pro: 3,
  Team: 4,
}

export default function ChangePlan({ subscription, plans }) {
  const stripe = useStripe()
  const authContext = useContext(AuthContext)
  const context = useContext(ViewContext)
  const navigate = useNavigate()
  const { t } = useTranslate()
  const currentInterval =
    periods.find((item) => item.value === subscription?.object?.period) || periods[0]

  const [displayedInterval, setDisplayedInterval] = useState(currentInterval)
  const [loadingPlan, setLoadingPlan] = useState(null)
  const [modalProps, setModalProps] = useState({
    type: null,
    visible: false,
    callback: null,
    newTier: null,
    currentTier: null,
    subscription: null,
  })

  const currentSubscription = subscription.object || null
  const activePlan = currentSubscription?.plan
  const prettyEndDate = moment(currentSubscription?.current_period_end).format('DD.MM.YYYY')

  const activeTier = plans?.raw?.plans.find((tier) => {
    return tier.id === activePlan?.id
  })

  const tiersProps = {
    current: {
      buttonText: <T keyName='eleo-profile-plan-current-plan-button-text'>Current plan</T>,
      buttonStyle: 'bg-[#D8F6EA] text-brand-green hover:bg-opacity-80',
      disabled: true,
    },
    current_expiring: {
      buttonText: t('eleo-profile-plan-expiring', 'Ends at {endDate} Resubscribe', {
        endDate: prettyEndDate,
      }),
      buttonStyle: 'bg-[#D8F6EA] text-brand-green hover:bg-opacity-80',
    },
    current_but_annual: {
      buttonText: <T keyName='eleo-profile-plan-upgrade-annual'>Upgrade to annual</T>,
      buttonStyle: 'bg-[#D8F6EA] text-brand-green hover:bg-opacity-80',
    },
    current_but_monthly: {
      buttonText: (
        <T keyName='eleo-profile-plan-current-downgrade-to-monthly'>
          Current plan (annual) Downgrade
        </T>
      ),
      buttonStyle: 'bg-[#D8F6EA] text-brand-green hover:bg-opacity-80',
    },
    higher: {
      buttonText: <T keyName='eleo-profile-plan-upgrade-plan-button-text'>Upgrade</T>,
      buttonStyle: 'bg-brand-violet text-white shadow-sm hover:bg-brand-violet-light',
    },
    lower: {
      buttonText: <T keyName='eleo-profile-plan-downgrade-plan-button-text'>Downgrade</T>,
      buttonStyle: 'bg-brand-grey',
    },
    next: {
      buttonText: t('eleo-profile-plan-future', 'Starts at {endDate}', { endDate: prettyEndDate }),
      buttonStyle: 'bg-brand-grey',
      disabled: true,
    },
    next_only_period_change: {
      buttonText: t('eleo-profile-plan-future', 'Starts at {endDate}', { endDate: prettyEndDate }),
      buttonStyle: 'bg-[#D8F6EA] text-brand-green hover:bg-opacity-80',
      disabled: true,
    },
  }

  const tiers = setTierTags()

  function setTierTags() {
    if (!currentSubscription) return null

    return plans?.raw?.plans.map((tier) => {
      // tier = { ...tier, mostPopular: Boolean(tier.mostPopular) && isUpgrade(tier, false) }
      tier = { ...tier, mostPopular: Boolean(tier.mostPopular) }
      //
      if (tier.id !== activePlan?.id) {
        // This is not the current plan
        let status = 'lower'
        if (isUpgrade(tier)) status = 'higher'

        // Check if this is the future plan
        if (tier.pricing[displayedInterval.value].id === currentSubscription?.future_price)
          status = 'next'

        return {
          ...tier,
          status: status,
        }
      }
      // This is the currently active plan

      // Check if displayed period is the current one or the period doesnt apply (free or coupon)
      const period = currentSubscription.period
      if (!period /* === free */) return { ...tier, status: 'current' }
      if (currentSubscription.hasCoupon) {
        // The user has a coupon active. Handle future states

        // Get future plan object
        const futurePlan = plans?.raw?.plans.find(
          (plan) =>
            plan.pricing.month.id === currentSubscription.future_price ||
            plan.pricing.year.id === currentSubscription.future_price
        )

        if (
          futurePlan.pricing.month.id === activePlan?.id ||
          futurePlan.pricing.year.id === activePlan?.id
        ) {
          // The user will stay at the same subscription after coupon
          if (period === displayedInterval.value) return { ...tier, status: 'current' }
          if (displayedInterval.value === 'year') return { ...tier, status: 'current_but_annual' }
          return { ...tier, status: 'current_but_monthly' }
        } else return { ...tier, status: 'current_expiring' }
      }
      if (period === displayedInterval.value) {
        // Plan is expiring
        if (currentSubscription.future_price) return { ...tier, status: 'current_expiring' }

        // Plan is active
        return { ...tier, status: 'current' }
      } else {
        if (displayedInterval.value === 'year') return { ...tier, status: 'current_but_annual' }
        return { ...tier, status: 'current_but_monthly' }
      }
    })
  }

  const showUpgradeModal = (tier) => {
    // const tierId = displayedInterval.value === 'month' ? tier.pricing.month.id : tier.pricing.year.id
    let type

    if (!currentSubscription || currentSubscription.plan.id === 'free') type = 'subscription'
    else if (plans.active.hasCoupon) type = 'upgradeFromGift'
    else if (
      tier.status === 'higher' &&
      displayedInterval.value === 'month' &&
      currentSubscription?.period === 'year'
    )
      type = 'upgradeFromAnnualToMonthly'
    else if (tier.status === 'higher') type = 'upgrade'
    else if (tier.status === 'current_but_annual') type = 'upgradeToAnnual'
    else if (tier.status === 'lower') type = 'downgrade'
    else if (tier.status === 'current_but_monthly') type = 'downgradeFromAnnualToMonthly'
    else {
      changePlan(tier.id)
      console.warn('no modal for selected plan', tier)
      return
    }

    setModalProps({
      type,
      visible: true,
      callback: changePlan.bind(null, tier.id),
      newTier: tier,
      currentTier: activeTier,
      subscription: currentSubscription,
    })
  }

  function isUpgrade(tier, considerPeriod = true) {
    const currentPeriod = currentSubscription ? currentSubscription.period : null

    if (planOrder[tier.name] < planOrder[activePlan?.name]) return false
    if (planOrder[tier.name] > planOrder[activePlan?.name]) return true

    if (!considerPeriod) return false
    // Same tier - consider period
    if (displayedInterval.value === 'year' && currentPeriod === 'month') return true
    return false
  }

  async function changePlan(planId) {
    try {
      setLoadingPlan(planId)
      const url = '/api/account/plan'

      const data = {
        plan: planId,
        period: displayedInterval.value,
        hasCoupon: plans.active.hasCoupon,
        ...(plans.active.hasCoupon && { start_date: new Date(plans.active.codeExpires).getTime() }),
      }

      let res = await axios.patch(url, data)

      // check for 2-factor payment requirement
      if (res.data.requires_payment_action) {
        const stripeRes = await stripe.handleCardPayment(res.data.client_secret)

        if (stripeRes.error) {
          context.handleError(stripeRes.error.message)
          setLoadingPlan(null)
          setModalProps((prev) => ({ ...prev, visible: false }))

          return false
        } else {
          // re-send the form
          data.stripe = res.data
          res = await axios.patch(url, data)
        }
      }

      authContext.update({ plan: res.data.data.plan })

      if (res.data.data.plan.name === 'Team' && authContext.user?.plan?.name !== 'Team')
        navigate('/account/users')
      else window.location.reload()
    } catch (err) {
      context.handleError(err)
    } finally {
      setLoadingPlan(null)
    }
  }

  return (
    <div className='flex flex-col py-[30px] md:mx-8'>
      <ChangePlanModal
        setIsVisible={setModalProps}
        isVisible={modalProps.visible}
        type={modalProps.type}
        callback={modalProps.callback}
        newTier={modalProps.newTier}
        currentTier={modalProps.currentTier}
        interval={displayedInterval.value}
        subscription={modalProps.subscription}
      />

      {/* Header */}
      <div className='flex items-center justify-between'>
        {/* Title */}
        <h2 className='text-[18px] font-semibold'>
          <T keyName='eleo-profile-change-plan'>Change plan</T>
        </h2>

        {/* Period selector */}
        <RadioGroup
          value={displayedInterval}
          onChange={setDisplayedInterval}
          className='flex items-center rounded-full p-1 text-center text-xs font-medium leading-5 ring-1 ring-inset ring-gray-200'
        >
          <RadioGroup.Label className='sr-only'>Payment frequency</RadioGroup.Label>
          {periods.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option}
              className={({ checked }) =>
                classNames(
                  checked ? 'bg-brand-violet text-white' : 'text-brand-body',
                  'flex cursor-pointer items-center justify-center gap-[6px] rounded-full px-5 py-[7px] leading-[1.2]'
                )
              }
            >
              {({ checked }) => (
                <>
                  {checked && (
                    <CheckIcon className='h-6 w-5 flex-none text-white' aria-hidden='true' />
                  )}
                  <span>
                    <T keyName={`eleo-payment-frequency-${option.value}`}>{option.label}</T>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>

      {/* Plan cards */}
      <div
        className='isolate mx-auto mt-[70px] grid grid-cols-1 gap-4
          sm:grid-cols-2
          md:max-w-max md:gap-6
          xl:max-w-none xl:grid-cols-4 xl:gap-4'
      >
        {tiers.map((tier, index) => (
          <div
            key={tier.id}
            className={classNames(
              'bob-up relative mx-auto w-full max-w-[345px] justify-center sm:h-full',
              tier.mostPopular && 'mt-[28px] sm:mt-0'
            )}
          >
            {/* Backdrop */}
            <div
              className={classNames(
                ' absolute left-0 h-full w-full rounded-[25px] bg-[#ffffff59] py-[10px]'
              )}
            />

            {/* Card content */}
            <div className='relative h-full'>
              <div className='h-full p-[10px]'>
                {/* Popular marker */}
                {tier.mostPopular ? (
                  <div className='absolute -top-[28px] left-[14px] right-[14px] flex justify-center rounded-tl-[20px] rounded-tr-[20px] bg-[#CC26B4] px-[20px] py-[9px] pb-8 text-[12px] font-medium text-white ring-4 ring-[#CC26B4] md:text-[13px] lg:text-[15px]'>
                    <T keyName='eleo-pricing-most-popular-tag-title'>Most popular</T>
                  </div>
                ) : null}

                <div
                  className={classNames(
                    tier.mostPopular
                      ? 'outline outline-4 -outline-offset-[4px] outline-[#CC26B4]'
                      : null,
                    'relative h-full rounded-[20px] bg-[#ffffff] p-[24px] shadow-[0_0_15px_#00000040] lg:p-[30px]'
                  )}
                >
                  {/* Header */}
                  <div className='flex items-center justify-between'>
                    <p
                      id={tier.id}
                      className={classNames(
                        'text-brand-body flex items-center justify-between text-[16px] font-bold uppercase leading-[1.2em] tracking-[0.02em] md:text-[18px] lg:text-[20px]'
                      )}
                    >
                      {index === teamsPlanIndex ? (
                        <span>
                          PRO<span className='ml-[8px] !font-light'>{tier.name}</span>
                        </span>
                      ) : (
                        tier.name
                      )}
                    </p>
                    {React.cloneElement(tierIcons[index], {
                      className: 'h-[24px] w-[25px] text-brand-body',
                    })}
                  </div>

                  {/* Description */}
                  <p className='text-brand-body mt-[4px] text-[13px] leading-[1.1em]'>
                    <T keyName={`eleo-tier-description-${tier.name}`}>{tier.description}</T>
                  </p>

                  {/* Price */}
                  <div className='mt-6 flex items-baseline gap-x-1'>
                    {index === teamsPlanIndex && tier.price_for_next_person ? (
                      // Team plan
                      <div className='flex grow items-start gap-[14px]'>
                        <div className='flex flex-col'>
                          <div className='text-brand-body !mb-[3px] !text-left !text-[24px] !font-semibold !leading-[1.2em] md:text-[36px] lg:mb-6 lg:text-[44px]'>{`${tier.currency.symbol}${
                            displayedInterval.value === 'year'
                              ? Math.round(
                                  (tier.pricing[displayedInterval.value].price * 10) / 12
                                ) / 10
                              : tier.pricing[displayedInterval.value].price
                          }`}</div>
                          <span className='text-brand-body text-[13px] leading-[1.1em]'>
                            <T keyName={`eleo-tier-price-pro-team-plan-next-person-quantity`}>
                              {tier.person_qty}
                            </T>
                          </span>
                        </div>
                        <div className='bg-brand-body h-[66px] w-[1px] opacity-10'></div>
                        <div className='flex w-min grow flex-col'>
                          <div className='text-brand-body !mb-[3px] !text-left text-[32px] !font-semibold !leading-[1em] md:text-[36px] lg:mb-6 lg:text-[44px]'>{`${tier.currency.symbol}${tier.price_for_next_person}`}</div>
                          <span className='text-brand-body ml-[2px] w-full text-[13px] leading-[1.1em]'>
                            <T keyName={`eleo-tier-price-pro-team-plan-next-person-price`}>
                              every next user
                            </T>
                          </span>
                        </div>
                      </div>
                    ) : (
                      // Other plans
                      <>
                        <span className='text-brand-body !mb-[0] text-center text-[32px] !font-semibold leading-[1em] md:!mb-[22px] md:text-[36px] lg:mb-6 lg:text-[44px]'>{`${
                          tier.currency.symbol
                        }${
                          displayedInterval.value === 'year'
                            ? Math.round((tier.pricing[displayedInterval.value].price * 10) / 12) /
                              10
                            : tier.pricing[displayedInterval.value].price
                        }`}</span>
                        <span className='text-brand-body mt-[4px] text-[13px] leading-[1.1em]'>
                          <T keyName={`eleo-tier-price-suffix`}>{displayedInterval.priceSuffix}</T>
                        </span>
                      </>
                    )}
                  </div>

                  {/* Action button */}
                  <button
                    aria-describedby={tier.id}
                    onClick={() => showUpgradeModal(tier)}
                    disabled={
                      !!tiersProps[tier.status].disabled ||
                      (tier.value === 'current' && !currentSubscription?.future_price) ||
                      (subscription.status === 'requires_action' && tier.name !== 'Free')
                    }
                    className={classNames(
                      tiersProps[tier.status].buttonStyle,
                      'focus-visible:outline-brand-violet mt-6 block w-full rounded-md px-3 py-2 text-center text-sm leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                    )}
                  >
                    {loadingPlan === tier.id ? (
                      <CircleNotch
                        weight='fill'
                        color={isUpgrade(tier) ? '#000000' : '#FFFFFF'}
                        size={24}
                        className='mx-auto animate-spin'
                      />
                    ) : subscription.status === 'requires_action' && tier.name !== 'Free' ? (
                      <Lock />
                    ) : (
                      tiersProps[tier.status].buttonText
                    )}
                  </button>

                  {/* Features */}
                  <ul className='text-brand-text-secondary mt-5 space-y-[4px] text-sm leading-6 md:mt-8'>
                    {tier.features.map((feature, i) => (
                      <Fragment key={i}>
                        <li className={classNames('flex items-center justify-between gap-x-3')}>
                          <div className='flex items-center gap-[10px] text-[13px] leading-[1.1em]'>
                            <CheckIcon
                              className='h-6 w-5 flex-none text-[#7430E0]'
                              aria-hidden='true'
                            />
                            <span
                              className={classNames({
                                'text-[#7430E0]': feature?.highlight_text,
                                'text-brand-body': !feature?.highlight_text,
                              })}
                            >
                              <T keyName={`eleo-feature-${feature.name}`}>{feature.name}</T>
                              {feature?.interval ? (
                                <span className='text-brand-body'>
                                  {' '}
                                  <T keyName={`eleo-tier-price-suffix`}>
                                    {displayedInterval.priceSuffix}
                                    {feature?.interval}
                                  </T>
                                </span>
                              ) : null}
                            </span>
                          </div>
                          {feature?.definition && (
                            <div
                              data-tooltip-id={`eleo-tooltip`}
                              // data-tooltip-keyname={`eleo-tier-feature-description-${feature.name}`}
                              data-tooltip-keyname={`eleo-tier-feature-description-${feature.name}`}
                            >
                              <Question className='text-brand-body' />
                            </div>
                          )}
                        </li>
                        {(tier.id === 'free' && i === 1) ||
                        (tier.id !== 'free' && i === 2) ||
                        (tier.id !== 'free' && i === 4) ||
                        i === 8 ||
                        (i === 9 && index === teamsPlanIndex) ? (
                          <div className='py-[8px]'>
                            <div className='bg-brand-grey h-[1px] w-full ' />
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
