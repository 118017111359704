import ContentLoader from 'react-content-loader'

export default function Synonyms({ synonyms, handleChangeSelectedText }) {
  if (!synonyms) return <div className='p-2 pb-[10px]'></div>

  return (
    <div className='p-2 pb-[10px]'>
      {synonyms.length
        ? synonyms.map((item, index) => (
            <div
              key={index}
              className='hover:bg-brand-violet/10 flex h-[25px] cursor-pointer items-center rounded-[4px] px-[6px] text-[13px]'
              onClick={() => handleChangeSelectedText(item)}
            >
              <div>{item}</div>
            </div>
          ))
        : Array(6)
            .fill()
            .map((_, index) => (
              <ContentLoader
                key={index}
                speed={2}
                width={200}
                height={25}
                backgroundColor='#6363E510'
                foregroundColor='#6363E530'
              >
                <rect x='0' y='0' width='94' height='19' />
              </ContentLoader>
            ))}
    </div>
  )
}
