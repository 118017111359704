import classNames from 'classnames'
import { CircleNotch } from 'phosphor-react'
import React from 'react'
import { Button } from 'ui'
import { T } from '@tolgee/react'
const GreatIdeasActions = ({ clearInputs, handleSubmitWriterOptions, loading }) => {
  return (
    <>
      <Button
        color='gray'
        className={classNames('h-[45px] min-w-[80px]')}
        onClick={clearInputs}
        data-tooltip-id='greatIdeas-clear'
      >
        <p className='text-[15px] font-medium uppercase leading-none'>
          <T keyName='eleo-history-action-clear'>clear</T>
        </p>
      </Button>
      <Button
        className={classNames('h-[45px] w-full !px-2')}
        onClick={() => handleSubmitWriterOptions()}
        disabled={loading.name === 'generate' && loading.status}
        color='dark'
        data-tooltip-id='greatIdeas-generateIdea'
      >
        {loading.name === 'generate' && loading.status ? (
          <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
        ) : (
          <p className='text-[15px] font-medium uppercase leading-none'>
            <T keyName='eleo-tool-great-ideas-generate-idea-action'>generate idea</T>
          </p>
        )}
      </Button>
    </>
  )
}

export default GreatIdeasActions
