import { createSlice } from '@reduxjs/toolkit'

export const modifyImageInitialState = {
  imageFormValues: {
    morphStrength: 0.8,
    quality: 5,
    creativity: 5,
    style: 'Realistic',
    type: 'Anything',
    samples: '2',
    chosenProportions: 'As is',
    size: 'Keep',
    action: 'Create similar',
    prompt: '',
    negativePrompt: '',
    tool: 'modify',
    mask: '',
    isBgModify: true,
  },
  imagesHolderValues: {
    baseImage: '',
    activeImage: '',
    history: [],
    currentIndex: -1,
    showPreview: false,
    imageData: {},
    pendingGeneration: {},
    isLoading: false,
    isImageInQueue: false,
  },
}

export const modifyImageSlice = createSlice({
  name: 'modifyImage',
  initialState: modifyImageInitialState,
  reducers: {
    setModifyImageInputHistory: (state, action) => {
      state.imageFormValues = action.payload
    },
    setImageHolderHistoryValues: (state, action) => {
      state.imagesHolderValues = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setModifyImageInputHistory, setImageHolderHistoryValues } = modifyImageSlice.actions

//selectors
export const selectModifyImageInputHistory = (state) => state.modifyImage.imageFormValues
export const selectImageHolderHistoryValues = (state) => state.modifyImage.imagesHolderValues

export default modifyImageSlice.reducer
