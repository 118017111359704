import links from '../docsLinks'

export const translator = {
  from: {
    title: 'From',
    content: 'Select the language from which Eleo should translate.',
    tolgee_id: 'eleo-tool-translator-help-tooltip-1',
  },

  to: {
    title: 'To',
    content: 'Select the language into which Eleo should translate the text.',
    tolgee_id: 'eleo-tool-translator-help-tooltip-2',
  },

  writeAsNative: {
    title: 'Write as native',
    content:
      "Choose this option if you don't want a word-for-word translation but prefer Eleo to write the text as if a native speaker did, instead of just translating it.",
    tolgee_id: 'eleo-tool-translator-help-tooltip-3',
  },
  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },
  textInput: {
    title: 'Text field',
    content: 'Enter or copy-paste the text you want to translate here.',
    tolgee_id: 'eleo-tool-translator-help-tooltip-4',
  },

  output: {
    title: 'Response Area',
    content:
      'The generated translation shows up here. Select text for options: regenerate a section, copy, forward, or remove it.',
    actions: [links.textEditing],
    tolgee_id: 'eleo-tool-translator-help-tooltip-5',
  },

  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },

  translate: {
    title: 'Translate',
    content: 'Click TRANSLATE to translate text from your current commands.',
    tolgee_id: 'eleo-tool-translator-help-tooltip-6',
  },

  share: {
    title: 'Share',
    content: 'Use this button to post your generated text on social media platforms.',
    tolgee_id: 'eleo-tooltip-share',
  },
  copy: {
    title: 'Copy',
    content: 'This button copies your text, ready to be pasted anywhere using commands like Ctrl-V',
    tolgee_id: 'eleo-tooltip-copy',
  },
  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of the text with existing instructions.',
    tolgee_id: 'eleo-tooltip-regenerate',
  },

  yourStory: {
    title: 'Your Story',
    content:
      "Choose 'Your Story' as the content source for your Chatbot. Create and regularly update this content in the 'Your Story' section under 'My Account' to reflect your personal, company, or product narrative.",
    tolgee_id: 'eleo-tool-chat-bot-panel-help-tooltip-3',
  },

  // HISTORY Window

  history: {
    title: 'History',
    content: `The 'History' icon takes you to previously translated texts.`,
    tolgee_id: 'eleo-tool-translator-help-tooltip-7',
  },

  selectFromList: {
    title: 'Select from List',
    content: 'To choose previously translated texts, click on them.',
    tolgee_id: 'eleo-tool-translator-help-tooltip-8',
  },
  trash: {
    title: 'Deleting history items',
    content: 'The trash icon deletes unwanted texts from your history.',
    tolgee_id: 'eleo-tooltip-trash',
  },
  clearHistory: {
    title: 'Clearing history',
    content: "'Clear' wipes your history clean.",
    tolgee_id: 'eleo-tooltip-clear-history',
  },
  confirmSelection: {
    title: 'Confirm Selection',
    content: 'CONFIRM opens the chosen text along with the original creation settings.',
    tolgee_id: 'eleo-tooltip-confirm-history',
  },
}
