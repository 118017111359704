import { T } from '@tolgee/react'

export const CursorMode = {
  Help: 'HELP',
}

export const SupportedModels = [
  {
    value: 'gpt-4o-mini',
    label: 'GPT-4o mini',
    supportsImages: true,
    isGuru: false,
    isDefault: true,
    description: (
      <T keyName={'eleo-chat-settings-model-description-gpt4o'}>
        Most sophisticated model by OpenAI. Words count x10
      </T>
    ),
  },
  {
    value: 'claude-haiku',
    label: 'Claude Haiku',
    supportsImages: true,
    isGuru: false,
    isDefault: false,
    description: (
      <T keyName={'eleo-chat-settings-model-description-haiku'}>Best speed and good results</T>
    ),
  },
  {
    value: 'claude-sonnet',
    label: 'Claude Sonnet 3.5',
    supportsImages: true,
    isGuru: true,
    isDefault: false,
    description: (
      <T keyName={'eleo-chat-settings-model-description-sonnet'}>
        Most sophisticated model by Anthropic. Words count x10
      </T>
    ),
  },
  {
    value: 'gpt-4o',
    label: 'GPT-4o',
    supportsImages: true,
    isGuru: true,
    isDefault: false,
    description: (
      <T keyName={'eleo-chat-settings-model-description-gpt4o'}>
        Most sophisticated model by OpenAI. Words count x10
      </T>
    ),
  },
]
