import { T } from '@tolgee/react'
import { Button } from 'ui'

export default function UpgradeModal({ tokens }) {
  return (
    <>
      <div>
        <T params={{ tokens: tokens }} keyName='eleo-modal-upgrade-content'>
          You have run out of words. Please visit your account page to upgrade or purchase a pack.
        </T>
      </div>
      <div className='mt-8 flex items-center justify-around gap-10'>
        <Button href='/account/plans' color='green' className='flex-1'>
          <T keyName='eleo-plans'>Plans</T>
        </Button>
        <Button href='/account/packs' color='green' className='flex-1'>
          <T keyName='eleo-packs'>Packs</T>
        </Button>
      </div>
    </>
  )
}
