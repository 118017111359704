/***
 *
 *   MODAL
 *   Display an overlay modal anywhere in your application by calling
 *   context.modal.show() with an object containing the following props
 *
 *   PROPS
 *   title: (optional)
 *   text: message to the user (optional)
 *   form: a form object (optional: see form docs for more information)
 *   url: destination to send the form
 *   method: HTTP post type
 *   buttonText – text for the confirm button
 *
 **********/

import { useContext, useRef } from 'react'
import { ViewContext, Card, Form } from 'components/lib'
import { CSSTransition } from 'react-transition-group'
import './modal.scss'
import classNames from 'classnames'
import { Close } from '@mui/icons-material'

export function Modal(props) {
  const context = useContext(ViewContext)
  const transitionRef = useRef()

  const handleCloseModal = (e) => {
    e.stopPropagation()
    e.target === e.currentTarget && context.modal.hide(true)
    e.target === e.currentTarget &&
      props.setHideModal &&
      props.setHideModal({ content: '', visible: false })
  }

  return (
    <CSSTransition nodeRef={transitionRef} in appear timeout={0} classNames='modal'>
      <div
        ref={transitionRef}
        className={classNames('modal', props.overlayClassName)}
        onClick={handleCloseModal}
      >
        <div className={classNames('modal-content', props.modalContainerClassNames)}>
          <Card title={props.title} className={classNames(props.modalCardClassNames)}>
            <Close
              className='absolute right-2 top-2 z-50 cursor-pointer'
              onClick={handleCloseModal}
            />
            {props.text && <div>{props.text}</div>}
            {props.children && <div>{props.children}</div>}
            {props.form && (
              <Form
                method={props.method}
                url={props.url}
                inputs={props.form}
                destructive={props.destructive}
                buttonText={props.buttonText}
                btnClasses={props.btnClasses}
                cancelBtnClasses={props.cancelBtnClasses}
                cancel={(e) => {
                  context.modal.hide(true)
                  props.setHideModal && props.setHideModal(false)
                }}
              />
            )}
          </Card>
        </div>
      </div>
    </CSSTransition>
  )
}
