export const modifyImage = {
  imageArea: {
    title: 'Click to Upload',
    content: 'Upload the image you want to change here.',
    tolgee_id: 'eleo-tool-image-modify-help-tooltip-1',
  },

  description: {
    title: 'Description',
    content: "Indicate how you want to modify your image. For example, 'add more red.'",
    tolgee_id: 'eleo-tool-image-modify-help-tooltip-2',
  },

  avoid: {
    title: 'Avoid',
    content:
      "Specify what you do NOT want in the image. It's like giving a prohibition, for example, 'no animals,' which helps Eleo exclude unwanted elements while generating the image.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-3',
  },

  actions: {
    title: 'Actions',
    content:
      'Define image actions, including the ability to generate a similar image or restore a face from an existing one.',
    tolgee_id: 'eleo-tool-image-modify-help-tooltip-4',
  },

  amount: {
    title: 'Amount',
    content: 'Indicate how many images you want Eleo to generate for you.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-5',
  },

  style: {
    title: 'Style',
    content:
      'Define the style of the image you want. It could be a photorealistic landscape, an anime frame, or a comic strip.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-6',
  },

  type: {
    title: 'Type',
    content:
      "'Type' refers to the kind of image you'd like to receive. It could be a character, logo, portrait, etc.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-4',
  },

  quality: {
    title: 'Quality',
    content:
      'The higher the number, the better the image quality. It could be low quality, like a sketch; medium quality, like an image from the internet; or high quality, like a photograph.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-9',
  },

  creativity: {
    title: 'Creativity',
    content:
      "The higher the creativity, the greater Eleo's ability to create original and imaginative images. It's like magic brushes that allow for the generation of unusual and creative images, such as a futuristic city, fantastic creatures, or surreal landscapes.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-10',
  },

  morph: {
    title: 'Morph strength',
    content:
      "Morph Strength indicates how much you want to alter the image. A higher setting means more noticeable changes. It's a slider that controls whether the changes should be minor or major.",
    tolgee_id: 'eleo-tool-image-modify-help-tooltip-5',
  },

  saveTemplate: {
    title: 'Save Template',
    content:
      "Store your query as a template for future use. Find and use these templates from the list at the form's top when needed.",
    tolgee_id: 'eleo-tooltip-save-template',
  },

  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },

  generate: {
    title: 'Generate Image',
    content: 'Click GENERATE Image to produce an image from your current commands and settings.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-14',
  },

  download: {
    title: 'Download',
    content: 'By clicking this icon, you can download the image.',
    tolgee_id: 'eleo-tool-image-modify-help-tooltip-6',
  },

  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of images with existing instructions.',
    tolgee_id: 'eleo-tool-image-create-help-tooltip-13',
  },

  imageHolder: {
    title: 'Image Area',
    content:
      "This is where you'll find images generated by Eleo. Hovering over a selected image will bring up a context menu. Using this menu, you can continue to generate similar images, copy, zoom in, modify, download, save in 'My Gallery,' or share on your social media.",
    tolgee_id: 'eleo-tool-image-create-help-tooltip-11',
  },
}
