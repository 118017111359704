import { T } from '@tolgee/react'

export const Creativity = [
  { value: 'Low', label: <T keyName='eleo-creativity-low'>Low</T> },
  { value: 'Medium', label: <T keyName='eleo-creativity-medium'>Medium</T> },
  { value: 'High', label: <T keyName='eleo-creativity-high'>High</T> },
]

export const FormatInputData = [
  // {
  //   value: 'anything',
  //   label: <T keyName='eleo-select-option-anything'>Anything</T>,
  // },
  { value: 'post', label: <T keyName='eleo-select-option-post'>Post</T> },
  { value: 'news', label: <T keyName='eleo-select-option-news'>News</T> },
  {
    value: 'article',
    label: <T keyName='eleo-select-option-article'>Article</T>,
  },
  { value: 'story', label: <T keyName='eleo-select-option-story'>Story</T> },
  { value: 'script', label: <T keyName='eleo-select-option-script'>Script</T> },
  { value: 'list', label: <T keyName='eleo-select-option-list'>List</T> },
  {
    value: 'newsletter',
    label: <T keyName='eleo-select-option-newsletter'>Newsletter</T>,
  },
  { value: 'Bio/CV', label: <T keyName='eleo-select-option-bio/cv'>Bio/CV</T> },
  {
    value: 'product description',
    label: <T keyName='eleo-select-option-product-description'>Product description</T>,
  },
  { value: 'meme', label: <T keyName='eleo-select-option-meme'>Meme</T> },
  { value: 'quiz', label: <T keyName='eleo-select-option-quiz'>Quiz</T> },

  //ready made templates
  {
    value: 'call to action',
    label: <T keyName='eleo-select-option-call-to-action'>Call To Action</T>,
  },
  { value: 'agreement', label: <T keyName='eleo-select-option-agreement'>Agreement</T> },
  { value: 'letter', label: <T keyName='eleo-select-option-letter'>Letter</T> },
  {
    value: 'cover letter',
    label: <T keyName='eleo-select-option-cover-letter'>Cover Letter</T>,
  },
  {
    value: 'press release',
    label: <T keyName='eleo-select-option-press-release'>Press Release</T>,
  },
  {
    value: 'testimonial',
    label: <T keyName='eleo-select-option-press-testimonial'>Testimonial</T>,
  },
  { value: 'review', label: <T keyName='eleo-select-option-press-review'>Review</T> },
  { value: 'essay', label: <T keyName='eleo-select-option-press-essay'>Essay </T> },
  { value: 'email', label: <T keyName='eleo-select-option-press-email'>Email</T> },
  {
    value: 'social media advertisement',
    label: (
      <T keyName='eleo-select-option-press-social-media-advertisement'>
        Social Media Advertisement
      </T>
    ),
  },
  {
    value: 'song lyrics',
    label: <T keyName='eleo-select-option-press-song-lyrics'>Song lyrics</T>,
  },
]

export const ToneInputData = [
  {
    value: 'friendly',
    label: <T keyName='eleo-select-option-friendly'>Friendly</T>,
  },
  { value: 'luxury', label: <T keyName='eleo-select-option-luxury'>Luxury</T> },
  {
    value: 'relaxed',
    label: <T keyName='eleo-select-option-relaxed'>Relaxed</T>,
  },
  {
    value: 'professional',
    label: <T keyName='eleo-select-option-professional'>Professional</T>,
  },
  { value: 'bold', label: <T keyName='eleo-select-option-bold'>Bold</T> },
  {
    value: 'adventurous',
    label: <T keyName='eleo-select-option-adventurous'>Adventurous</T>,
  },
  { value: 'witty', label: <T keyName='eleo-select-option-witty'>Witty</T> },
  {
    value: 'persuasive',
    label: <T keyName='eleo-select-option-persuasive'>Persuasive</T>,
  },
  {
    value: 'empathetic',
    label: <T keyName='eleo-select-option-empathetic'>Empathetic</T>,
  },
]

export const AuthorInputData = [
  { value: 'writer', label: <T keyName='eleo-select-option-writer'>Writer</T> },
  {
    value: 'marketer',
    label: <T keyName='eleo-select-option-marketer'>Marketer</T>,
  },
  { value: 'expert', label: <T keyName='eleo-select-option-expert'>Expert</T> },
  {
    value: 'scientist',
    label: <T keyName='eleo-select-option-scientist'>Scientist</T>,
  },
  {
    value: 'teacher',
    label: <T keyName='eleo-select-option-teacher'>Teacher</T>,
  },
  {
    value: 'manager',
    label: <T keyName='eleo-select-option-manager'>Manager</T>,
  },
  { value: 'lawyer', label: <T keyName='eleo-select-option-lawyer'>Lawyer</T> },
  { value: 'kid', label: <T keyName='eleo-select-option-kid'>Kid</T> },
  {
    value: 'teenager',
    label: <T keyName='eleo-select-option-teenager'>Teenager</T>,
  },
  { value: 'Editor', label: <T keyName='eleo-select-option-editor'>Editor</T> },
]

export const WritingStyleInputData = [
  // { value: 'none', label: <T keyName="eleo-select-option-none">None</T> },
  { value: 'formal', label: <T keyName='eleo-select-option-formal'>Formal</T> },
  {
    value: 'creative',
    label: <T keyName='eleo-select-option-creative'>Creative</T>,
  },
  {
    value: 'technical',
    label: <T keyName='eleo-select-option-technical'>Technical</T>,
  },
  { value: 'casual', label: <T keyName='eleo-select-option-casual'>Casual</T> },
  {
    value: 'academic',
    label: <T keyName='eleo-select-option-academic'>Academic</T>,
  },
  {
    value: 'storytelling',
    label: <T keyName='eleo-select-option-storytelling'>Storytelling</T>,
  },
  {
    value: 'journalism',
    label: <T keyName='eleo-select-option-journalism'>Journalism</T>,
  },
  {
    value: 'persuasive',
    label: <T keyName='eleo-select-option-persuasive'>Persuasive</T>,
  },
  {
    value: 'humorous',
    label: <T keyName='eleo-select-option-humorous'>Humorous</T>,
  },
  { value: 'poetic', label: <T keyName='eleo-select-option-poetic'>Poetic</T> },
  {
    value: 'Seth Godin (post)',
    label: <T keyName='eleo-select-option-seth-godin-post'>Seth Godin (post)</T>,
  },
  {
    value: 'Neil Patel (article)',
    label: <T keyName='eleo-select-option-neil-patel-article'>Neil Patel (article)</T>,
  },
  {
    value: 'Gary Vaynerchuk (social media)',
    label: (
      <T keyName='eleo-select-option-gary-vaynerchuk-social-media'>
        Gary Vaynerchuk (social media)
      </T>
    ),
  },
  {
    value: 'Donald Miller (StoryBrand)',
    label: <T keyName='eleo-select-option-donald-miller-storybrand'>Donald Miller (StoryBrand)</T>,
  },
  {
    value: 'emotional marketing (Email)',
    label: (
      <T keyName='eleo-select-option-emotional-marketing-email'>Emotional Marketing (Email)</T>
    ),
  },
  {
    value: 'influencer marketing (Script, Product Description)',
    label: (
      <T keyName='eleo-select-option-influencer-marketing-script-product-description'>
        Influencer Marketing (Script, Product Description)
      </T>
    ),
  },
  {
    value: 'Ann Handley (Newsletter)',
    label: <T keyName='eleo-select-option-ann-handley-newsletter'>Ann Handley (Newsletter)</T>,
  },
]

export const checkboxData = [
  {
    title: 'Bullet Points',
    name: 'bullet points',
    tolgee_id: 'eleo-checkbox-bullet-points',
  },
  {
    title: 'Citations',
    name: 'citations',
    tolgee_id: 'eleo-checkbox-citations',
  },
  {
    title: 'Pros and cons',
    name: 'pros and cons',
    tolgee_id: 'eleo-checkbox-pros-cons',
  },
  {
    title: 'Interesting facts',
    name: 'interesting facts',
    tolgee_id: 'eleo-checkbox-interesting-facts',
  },
  // {
  //   title: 'Sources',
  //   name: 'sources',
  //   tolgee_id: 'eleo-checkbox-sources',
  // },
]

export const Languages = [
  { value: 'English', label: 'English' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Polish', label: 'Polish' },
]

export const ActionsInputData = [
  {
    value: 'rephrase',
    label: <T keyName='eleo-select-option-rephrase'>Rephrase</T>,
  },
  {
    value: 'summarize',
    label: <T keyName='eleo-select-option-summarize'>Summarize</T>,
  },
  {
    value: 'Revise',
    label: <T keyName='eleo-select-option-revise'>Revise</T>,
  },
  {
    value: 'Proofreading',
    label: <T keyName='eleo-select-option-proofreading'>Proofreading</T>,
  },
  {
    value: 'make bullet points',
    label: <T keyName='eleo-select-option-make-bullet-points'>Make bullet points</T>,
  },
  {
    value: 'explain in easy words',
    label: <T keyName='eleo-select-option-explain-in-easy-words'>Explain in easy words</T>,
  },
]

export const CheckForInputData = [
  {
    value: 'all',
    label: <T keyName='eleo-select-option-all'>All</T>,
  },
  {
    value: 'grammar',
    label: <T keyName='eleo-select-option-grammar'>Grammar</T>,
  },
  {
    value: 'SEO',
    label: <T keyName='eleo-select-option-seo'>SEO</T>,
  },
  {
    value: 'emotions',
    label: <T keyName='eleo-select-option-emotions'>Emotions</T>,
  },
  // {
  //   value: 'text stats',
  //   label: <T keyName="eleo-select-option-text-stats">Text stats</T>,
  // },
]

export const replyTextActions = [
  {
    value: 'Thank',
    label: <T keyName='eleo-select-option-thank'>Thank</T>,
  },
  {
    value: 'Congratulate',
    label: <T keyName='eleo-select-option-congratulate'>Congratulate</T>,
  },
  {
    value: 'Confirm',
    label: <T keyName='eleo-select-option-confirm'>Confirm</T>,
  },
  {
    value: 'Decline',
    label: <T keyName='eleo-select-option-decline'>Decline</T>,
  },
  {
    value: 'Share Feedback',
    label: <T keyName='eleo-select-option-share-feedback'>Share Feedback</T>,
  },
  {
    value: 'Agree',
    label: <T keyName='eleo-select-option-agree'>Agree</T>,
  },
  {
    value: 'Disagree',
    label: <T keyName='eleo-select-option-disagree'>Disagree</T>,
  },
  {
    value: 'Apologize',
    label: <T keyName='eleo-select-option-apologize'>Apologize</T>,
  },
  {
    value: 'Ask additional questions',
    label: <T keyName='eleo-select-option-ask-additional-questions'>Ask additional questions</T>,
  },
  {
    value: 'Request clarification',
    label: <T keyName='eleo-select-option-request-clarification'>Request clarification</T>,
  },
  {
    value: 'Extend the deadline',
    label: <T keyName='eleo-select-option-extend-the-deadline'>Extend the deadline</T>,
  },
  {
    value: 'Raise objections',
    label: <T keyName='eleo-select-option-raise-objections'>Raise objections</T>,
  },
  {
    value: 'Negotiate',
    label: <T keyName='eleo-select-option-negotiate'>Negotiate</T>,
  },
  {
    value: 'Forward',
    label: <T keyName='eleo-select-option-forward'>Forward</T>,
  },
  {
    value: 'Arrange a meeting',
    label: <T keyName='eleo-select-option-arrange-a-meeting'>Arrange a meeting</T>,
  },
  {
    value: 'Introduce',
    label: <T keyName='eleo-select-option-introduce'>Introduce</T>,
  },
  {
    value: 'Provide explanations',
    label: <T keyName='eleo-select-option-provide-explanations'>Provide explanations</T>,
  },
  {
    value: 'Present the offer',
    label: <T keyName='eleo-select-option-present-the-offer'>Present the offer</T>,
  },
]
