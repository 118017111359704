import React, { useCallback, useState } from 'react'
import { T } from '@tolgee/react'
import classNames from 'classnames'
import { Close } from '@mui/icons-material'

const ImageDropZone = ({
  baseImage,
  setBaseImage,
  handleFileUpload,
  classes,
  handleImageClick,
  id = 'dropzone-file',
  mask,
}) => {
  const [isDragActive, setIsDragActive] = useState(false)

  const onDragOver = useCallback((e) => {
    e.preventDefault()
    setIsDragActive(true)
  }, [])

  const onDragLeave = useCallback((e) => {
    e.preventDefault()
    setIsDragActive(false)
  }, [])

  function handleDropzoneClick(e) {
    if (baseImage) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onDrop = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragActive(false)
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFileUpload({ target: { files: e.dataTransfer.files } })
      }
    },
    [handleFileUpload]
  )

  return (
    <div
      id='upload'
      className={classNames(
        classes,
        'm-x-auto relative m-3 flex h-2/5 flex-col items-center justify-center rounded-lg border border-dashed',
        {
          'border-brand-green border border-dashed': isDragActive,
        }
      )}
    >
      <label
        htmlFor={id}
        className={classNames(
          'flex h-full w-full flex-1 flex-col items-center justify-center overflow-hidden rounded-lg',
          !baseImage && 'cursor-pointer'
        )}
        data-tooltip-id='images-modifyImage-imageArea'
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {baseImage && (
          <>
            <div className='max-h-full max-w-full bg-black'>
              <img
                src={baseImage}
                onClick={handleImageClick}
                alt=''
                // crossOrigin='use-credentials'
                // referrerPolicy='origin'
                className='h-full cursor-pointer object-scale-down'
                style={{
                  ...(mask && {
                    maskImage: ` url(${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/static/white'), url(${mask})`,
                  }),
                  ...(mask && {
                    WebkitMaskImage: ` url(${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/static/white), url(${mask})`,
                  }),
                  maskSize: 'cover',
                  maskRepeat: 'no-repeat',
                  maskComposite: 'subtract',
                  WebkitMaskComposite: 'subtract',
                }}
              />
            </div>
          </>
        )}
        {!baseImage && (
          <div className={classNames('flex flex-col items-center justify-center pb-6 pt-5')}>
            <svg
              className='mb-4 h-8 w-8'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 20 16'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
              />
            </svg>
            <p className='mb-2 text-sm'>
              <span className='font-semibold'>
                <T keyName='eleo-dropzone-text-1'>Click to upload</T>{' '}
              </span>
              <T keyName='eleo-dropzone-text-2'>or drag and drop</T>
            </p>
            <p className='text-xs'>SVG, PNG, JPG, GIF (MAX. 1024x1024px)</p>
          </div>
        )}
        <input
          // disabled={Boolean(baseImage)}
          id={id}
          type='file'
          className='hidden'
          accept='image/*'
          onChange={handleFileUpload}
          onClick={handleDropzoneClick}
        />
      </label>

      {baseImage && (
        <div className='absolute right-4 top-4 cursor-pointer rounded-[4px] bg-white/70 hover:bg-white'>
          <Close onClick={() => setBaseImage(null)} />
        </div>
      )}
    </div>
  )
}

export default ImageDropZone
