/***
 *
 *   Features/Advisor Page
 *
 **********/

import QuickreplyIcon from '@mui/icons-material/Quickreply'
import Architecture from '@mui/icons-material/Architecture'
import { AutoStories, History, ContentCopy } from '@mui/icons-material'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'

import Header from 'tailwindComponents/eleoFeatures/Header'
import Content from 'tailwindComponents/eleoFeatures/Content'
import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'

import { useTranslate } from '@tolgee/react'

import image from 'images/features/advisor.png'

export function Advisor() {
  const { t } = useTranslate()

  const featureId = 'advisor'

  const translations = {
    header: {
      title: `AI-Powered Advisor`,
      subtitle: 'Your Guide to Smarter Business Decisions',
      description: `Discover how Eleo analyzes and offers guidance on new business contracts.`,
      link: { href: '/advisor', text: t('eleo-try-now', 'Try now!') },
    },
    content: {
      title: "Why Choose Eleo's Advisor?",
      subtitle: 'Everything you need',
      description:
        "Making smart decisions means having the right information at your fingertips. With Eleo, you're not just sorting through opinions; you're discovering clear, actionable insights.",
      features: [
        {
          name: `Quick expert knowledge.`,
          description: `Gain immediate insight from a wide range of fields including science, sales, marketing, law, business, and medicine.`,
          icon: QuickreplyIcon,
          id: `eleo-features-${featureId}-content-feature-1`,
        },
        {
          name: `Premade templates.`,
          description: `Tailor Eleo to your needs with customizable question templates or use our ready-made options.`,
          icon: Architecture,
          id: `eleo-features-${featureId}-content-feature-2`,
        },
        {
          name: `Use Your Story.`,
          description: `With a single click, Eleo taps into Your Story, providing advice that's uniquely suited to your circumstances.`,
          icon: AutoStories,
          id: `eleo-features-${featureId}-content-feature-3`,
        },
        {
          name: `Guru.`,
          description: `Explore deeper insights with the Guru feature, leveraging advanced AI for comprehensive answers.`,
          icon: SelfImprovementIcon,
          id: `eleo-features-${featureId}-content-feature-4`,
        },
        {
          name: `Copy & Share.`,
          description: `Easily copy and share the insights you gain.`,
          icon: ContentCopy,
          id: `eleo-features-${featureId}-content-feature-5`,
        },
        {
          name: `History.`,
          description: `Have continuous access to your history of advice, ready for use whenever you need it.`,
          icon: History,
          id: `eleo-features-${featureId}-content-feature-6`,
        },
      ],
    },
  }

  return (
    <div className='isolate bg-white'>
      <Header
        title={t(`eleo-features-${featureId}-header-title`, translations.header.title)}
        subtitle={t(`eleo-features-${featureId}-header-subtitle`, translations.header.subtitle)}
        link={translations.header.link}

        // description={t(`eleo-features-${featureId}-header-description`, translations.header.description)}
      />
      <Content
        title={t(`eleo-features-${featureId}-content-title`, translations.content.title)}
        subtitle={t(`eleo-features-${featureId}-content-subtitle`, translations.content.subtitle)}
        description={t(
          `eleo-features-${featureId}-content-description`,
          translations.content.description
        )}
        features={translations.content.features}
        img={image}
      />
      <UseEleoToday next='advisor' />
    </div>
  )
}
