import { Modal } from 'components/lib'
import React from 'react'
import TemplateDeleteAlertContent from 'toolComponents/delete-template-modal-content'
import WriterHistoryDeleteAlertContent from './write-text/WriterHistoryDeleteAlertContent'
import WriterTemplateNameModalContent from './write-text/WriterTemplateNameModalContent'
import { T, useTranslate } from '@tolgee/react'
const ActionModal = ({
  modalKey,
  templateId,
  templateName,
  setTemplateName,
  handleTemplate,
  loading,
  setModalIsVisible,
  setReloadHistoryData,
  setCheckedHistory,
  checkedHistory,
  clearHistoryEndpoint,
  deleteSelectedHistoryEndpoint,
  reloadTemplatesData,
  deleteTemplateEndpoint,
  deleteStoryEndpoint,
  reloadStoryTemplatesData,
  mainTitle,
  modalHeaderText,
  items,
}) => {
  const { t } = useTranslate()

  const modalContent = {
    SAVE_TEMPLATE: {
      title: templateId?.value ? (
        <T keyName='eleo-update-template-modal-title'>Update Template</T>
      ) : (
        <T keyName='eleo-save-template-modal-title'>Save Template</T>
      ),
      content: (
        <WriterTemplateNameModalContent
          templateName={templateName}
          setTemplateName={setTemplateName}
          handleTemplate={handleTemplate}
          loading={loading}
          templateId={templateId}
        />
      ),
    },

    SAVE_STORY_TEMPLATE: {
      title: templateId?.value ? (
        <T keyName='eleo-update-story-template-modal-title'>Update Your Story</T>
      ) : (
        <T keyName='eleo-save-story-template-modal-title'>Save Your Story</T>
      ),
      content: (
        <WriterTemplateNameModalContent
          templateName={templateName}
          setTemplateName={setTemplateName}
          handleTemplate={handleTemplate}
          loading={loading}
          templateId={templateId}
          placeHolder={t('eleo-enter-your-story-name', 'Enter your story name')}
        />
      ),
    },
    CLEAR_HISTORY: {
      title: <T keyName='eleo-clear-history-modal-title'>Clear History</T>,
      content: (
        <WriterHistoryDeleteAlertContent
          setModalIsVisible={setModalIsVisible}
          setReloadHistoryData={setReloadHistoryData}
          setCheckedHistory={setCheckedHistory}
          clearHistoryEndpoint={clearHistoryEndpoint}
        />
      ),
    },
    DELETE_TEMPLATE: {
      title: modalHeaderText ? (
        modalHeaderText
      ) : (
        <T keyName='eleo-delete-template-modal-title'>Delete Template</T>
      ),
      content: (
        <TemplateDeleteAlertContent
          setModalIsVisible={setModalIsVisible}
          reloadTemplatesData={reloadTemplatesData}
          deleteTemplateEndpoint={deleteTemplateEndpoint}
          mainTitle={mainTitle}
        />
      ),
    },
    DELETE_STORY_TEMPLATE: {
      title: <T keyName='eleo-delete-your-story-modal-title'>Delete Your Story</T>,
      content: (
        <TemplateDeleteAlertContent
          setModalIsVisible={setModalIsVisible}
          reloadTemplatesData={reloadStoryTemplatesData}
          deleteTemplateEndpoint={deleteStoryEndpoint}
        />
      ),
    },
    DELETE_HISTORY: {
      title: <T keyName='eleo-delete-history-modal-title'>Delete History</T>,
      content: (
        <WriterHistoryDeleteAlertContent
          isBulkDelete={false}
          checkedHistory={checkedHistory}
          setModalIsVisible={setModalIsVisible}
          setReloadHistoryData={setReloadHistoryData}
          setCheckedHistory={setCheckedHistory}
          deleteSelectedHistoryEndpoint={deleteSelectedHistoryEndpoint}
          items={items}
        />
      ),
    },
  }

  if (modalContent.hasOwnProperty(modalKey)) {
    return (
      <Modal
        setHideModal={setModalIsVisible}
        text={
          <p className='text-brand-body text-[19px] font-bold'>{modalContent[modalKey].title}</p>
        }
      >
        {modalContent[modalKey].content}
      </Modal>
    )
  } else {
    return null
  }
}

export default ActionModal
