import axios from 'axios'
import { convertCreativityToTemperature } from 'helpers'

export class WriterAPIService {
  static generateWriterRequest(data, segment, method = 'POST', signal) {
    if (method === 'POST') {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
        ...signal,
      })
    } else {
      return fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })
    }
  }

  static async generateWriterText(writerOptions, signal) {
    const data = {
      tool: 'writeText',
      writerOptions,
      kwargs: {
        stream: true,
        temperature: convertCreativityToTemperature(writerOptions.creativity),
      },
    }

    return this.generateWriterRequest(data, '/ai/writer', 'POST', signal)
  }

  static async generateModifiedText(writerOptions, signal) {
    const data = {
      tool: 'modifyText',
      writerOptions,
      kwargs: {
        stream: true,
        temperature: convertCreativityToTemperature(writerOptions.creativity),
      },
    }

    return this.generateWriterRequest(data, '/ai/writer', 'POST', signal)
  }

  static async generateReplyText(writerOptions, signal) {
    const data = {
      tool: 'replyText',
      writerOptions,
      kwargs: {
        stream: true,
        temperature: convertCreativityToTemperature(writerOptions.creativity),
      },
    }

    return this.generateWriterRequest(data, '/ai/writer', 'POST', signal)
  }

  static async generateCheckedText(writerOptions, signal) {
    const data = {
      tool: 'checkText',
      writerOptions,
      kwargs: {
        stream: true,
        temperature: convertCreativityToTemperature(writerOptions.creativity),
      },
    }

    return this.generateWriterRequest(data, '/ai/writer', 'POST', signal)
  }

  static async rephrase(textContext, selectedText) {
    const data = {
      context: textContext,
      target: selectedText,
      kwargs: { stream: true },
    }

    return this.generateWriterRequest(data, '/ai/rephrase')
  }

  static async saveTemplate(data) {
    return this.generateWriterRequest(data, '/writer/save-template')
  }

  static async updateTemplate(data) {
    return this.generateWriterRequest(data, '/writer/update-template')
  }

  static async saveModifyTextTemplate(data) {
    return this.generateWriterRequest(data, '/writer/modify-text/save-template')
  }

  static async saveReplyTextTemplate(data) {
    return this.generateWriterRequest(data, '/writer/reply/save-template')
  }

  static async deleteModifyTextTemplate(data) {
    return this.generateWriterRequest(data, '/writer/modify-text/delete-template')
  }

  static async deleteReplyTextTemplate(data) {
    return this.generateWriterRequest(data, '/writer/reply/delete-template')
  }

  static async updateModifyTemplate(data) {
    return this.generateWriterRequest(data, '/writer/modify-text/update-template')
  }

  static async updateReplyTextTemplate(data) {
    return this.generateWriterRequest(data, '/writer/reply/update-template')
  }

  static async deleteTemplate(data) {
    return this.generateWriterRequest(data, '/writer/delete-template')
  }

  static async deleteReplyTextHistory(data) {
    return this.generateWriterRequest(data, '/writer/reply/delete-history')
  }

  static async deleteHistory(data) {
    return this.generateWriterRequest(data, '/writer/delete-history')
  }
  static async deleteModifyHistory(data) {
    return this.generateWriterRequest(data, '/writer/modify/delete-history')
  }

  static async clearHistory(data) {
    return this.generateWriterRequest(data, '/writer/delete-histories')
  }
  static async clearModifyHistory(data) {
    return this.generateWriterRequest(data, '/writer/modify/delete-histories')
  }

  static async clearReplyTextHistory(data) {
    return this.generateWriterRequest(data, '/writer/reply/delete-histories')
  }

  static async deleteAllTemplates(data) {
    return this.generateWriterRequest(data, '/writer/delete-all')
  }

  static async saveHistory(data) {
    return this.generateWriterRequest(data, '/writer/save-history')
  }
  static async saveModifyHistory(data) {
    return this.generateWriterRequest(data, '/writer/modify/save-history')
  }

  static async saveReplyTextHistory(data) {
    return this.generateWriterRequest(data, '/writer/reply/save-history')
  }

  static async getTemplates() {
    return this.generateWriterRequest({}, '/writer/templates', 'GET')
  }

  static async getReplyTextHistories() {
    return this.generateWriterRequest({}, '/writer/reply/histories', 'GET')
  }

  static async getHistories({ skip, pages }) {
    return this.generateWriterRequest(
      {},
      `/writer/histories${skip ? `?skip=${skip}` : pages ? `?pages=${pages}` : ''}`,
      'GET'
    )
  }

  static async getModifyHistories() {
    return this.generateWriterRequest({}, '/writer/modify/histories', 'GET')
  }
}
