import { T, useTranslate } from '@tolgee/react'
import { useState } from 'react'
import { Input } from 'ui'
import GalleryList from './GalleryList'
import moment from 'moment'

export default function GalleryLayout({ items: itemsData, refreshData }) {
  const [search, setSearch] = useState('')

  const items = itemsData.map((item) => ({
    image: item.image,
    data: item.image_data,
    created: item.date_created,
    name: item.name,
  }))

  let filteredItems = items.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      moment(item.created).format('DD.MM.YYYY h:mm').includes(search)
  )
  filteredItems = filteredItems.sort((a, b) => (a.created < b.created ? 1 : -1))

  const { t } = useTranslate()

  if (items.length === 0)
    return (
      <h2 className='mt-5 text-center text-4xl'>
        <T keyName='eleo-tool-gallery-no-images-to-display'>No images to display</T>
      </h2>
    )

  return (
    <div className='flex h-full flex-col'>
      <div className='flex items-center justify-between border-b px-4 py-3 text-lg'>
        <div>
          <T keyName='eleo-tool-gallery-title'>GALLERY</T>
        </div>
        <Input
          placeholder={t('eleo-tool-gallery-search-placeholder', 'Search')}
          onChange={setSearch}
          value={search}
        />
      </div>
      <GalleryList items={filteredItems} refreshData={refreshData} />
    </div>
  )
}
