import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useAccess } from 'components/hooks/access'
import { useWindowDimensions } from 'components/hooks/window'
import { useYourStory } from 'components/hooks/yourStory'
import { Modal, ViewContext } from 'components/lib'
import { successToast } from 'helpers'
import { CircleNotch } from 'phosphor-react'
import { useContext, useState } from 'react'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import { Button, History } from 'ui'

export default function TranslatorHistory({
  history,
  dispatch,
  setOutputValue,
  setShowHistory,
  fetchHistory,
  setHistory,
  historyMeta,
  setHistoryMeta,
}) {
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const { data: stories } = useYourStory()

  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    content: null,
    visible: false,
  })

  function handleLoadHistory(id) {
    if (!hasAccess('Standard')) {
      context.modal.show({
        children: <UpgradePlan availableFrom='Standard' />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }
    const item = history.find((x) => x.id === id)

    item.story = stories.find((s) => s.id === item.storyId)

    if (width < 768) setShowHistory(false)
    dispatch({ type: 'LOAD_HISTORY', payload: item })
    setOutputValue(item.output)
  }

  function showDeleteHistoryItemsModal(items) {
    setModal({
      visible: true,
      content: (
        <>
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-selected-history-confirmation-header'>
              Are you sure you want to delete selected history?{' '}
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-selected-history-confirmation-title'>
                This action is irreversible.
              </T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': isLoading,
              }
            )}
            onClick={() => handleDeleteHistoryItems(items)}
          >
            <div className='flex items-center gap-[5px]'>
              {isLoading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-confirm-delete-selected-history'>delete</T>
              </p>
            </div>
          </Button>
        </>
      ),
    })
  }

  async function handleDeleteHistoryItems(items) {
    if (!items.length) return

    try {
      setIsLoading(true)

      const data = { items: items }
      await axios.post('/api/translations/delete', data)

      setHistory((prev) => prev.filter((item) => !items.includes(item.id)))
      setModal({ visible: false, content: null })

      successToast(
        <T keyName={`eleo-success-History deleted successfully`}>History deleted successfully</T>
      )
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  function showClearHistoryModal() {
    setModal({
      visible: true,
      content: (
        <>
          {' '}
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-history-confirmation-header'>
              Are you sure you want to clear your history?
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-history-confirmation-title'>This action is irreversible.</T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': isLoading,
              }
            )}
            onClick={handleClearHistory}
          >
            <div className='flex items-center gap-[5px]'>
              {isLoading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-clear-history-modal-title'>clear history</T>
              </p>
            </div>
          </Button>
        </>
      ),
    })
  }

  async function handleClearHistory() {
    try {
      setIsLoading(true)
      await axios.delete('/api/translator')

      setModal({ visible: false, content: null })
      fetchHistory()
      successToast(
        <T keyName={`eleo-success-Histories deleted successfully`}>
          Histories deleted successfully
        </T>
      )
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  async function handlePaginate() {
    const currentPage = Number(historyMeta.page) + 1
    const { data: response } = await axios.get(
      `api/translator/history?limit=10&page=${currentPage}`
    )
    const historyData = response.data

    setHistory((prev) => [...prev, ...historyData.data.history])
    setHistoryMeta({
      limit: historyData.limit,
      page: historyData.page,
      total: historyData.total,
    })
  }

  const data =
    history?.map((item) => {
      const storyData = stories.find((s) => s.id === item.storyId)
      return {
        id: item.id,
        question: item.input,
        answer: item.output,
        date_created: item.date_created,
        story: { id: storyData?.id, name: storyData?.templateName },
        words: item.input?.split(/\s+/).length + item.output?.split(/\s+/).length,
        data: item,
      }
    }) || []

  return (
    <>
      <div className='w-full border-r pr-2 md:w-64 lg:w-72'>
        <History
          headerText={t('eleo-history-header-translator', 'translator / history')}
          dateText={t('eleo-history-item-date', 'Started:')}
          storyText={t('eleo-history-item-story', 'Your story:')}
          wordCounterText={t('eleo-history-item-words', 'words:')}
          historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
          clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
          storyMissingText={t('eleo-history-item-story-missing', 'None')}
          items={data}
          loadItem={handleLoadHistory}
          closeHistory={() => setShowHistory(false)}
          storageKey='translator'
          clearHistory={showClearHistoryModal}
          removeItems={showDeleteHistoryItemsModal}
          hasMore={history?.length !== 0 && history?.length < historyMeta.total}
          fetchMoreItems={() => handlePaginate()}
        />
      </div>
      {modal.visible && (
        <Modal
          setHideModal={setModal}
          text={<p className='text-brand-body text-[19px] font-bold'>{modal.text}</p>}
        >
          {modal.content}
        </Modal>
      )}
    </>
  )
}
