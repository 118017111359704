import links from '../../docsLinks'

export const modifyText = {
  myText: {
    title: 'My Text',
    content: 'Place the text you wish to modify in this area.',
    tolgee_id: 'eleo-tool-modify-text-help-tooltip-1',
  },

  whatToDo: {
    title: 'What should I do?',
    content: "Tell Eleo what you'd like to be done with the text.",
    tolgee_id: 'eleo-tool-modify-text-help-tooltip-2',
  },

  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },
  seo: {
    title: 'Optimize for SEO',
    content: 'Enhance your text with Search Engine Optimization in mind.',
    tolgee_id: 'eleo-tool-writer-write-text-help-tooltip-4',
  },
  template: {
    title: 'Template',
    content: "Use either predefined or your saved templates for Eleo's response.",
    actions: [links.templates],
    tolgee_id: 'eleo-tooltip-template',
  },

  actions: {
    title: 'Actions',
    content:
      "You can choose a specific task from the 'Actions' list, like 'Rephrase' or 'Summarize,' for Eleo to perform.",
    tolgee_id: 'eleo-tooltip-actions',
  },

  style: {
    title: 'Style',
    content:
      "Steer Eleo's textual style. It might be formal as in business correspondence, journalism as in a report, or casual as in a chat among pals. This choice helps customize the response.",
    tolgee_id: 'eleo-tooltip-style',
  },

  writeAs: {
    title: 'Write New Text AS',
    content:
      "This feature lets Eleo respond as if she's a certain person or character, like a writer, expert, or even a teenager. The selected persona shapes both the style and mood of the reply. For example, an 'expert' persona yields a more formal tone, while a 'teenager' persona is likely more casual.",
    tolgee_id: 'eleo-tool-modify-text-help-tooltip-3',
  },

  language: {
    title: 'Language',
    content: "Select the desired language for Eleo's response.",
    tolgee_id: 'eleo-tooltip-language',
  },

  length: {
    title: 'Length',
    content: "Determine how wordy or concise you want Eleo's response to be.",
    tolgee_id: 'eleo-tooltip-length',
  },

  tone: {
    title: 'Tone',
    content:
      "Define the ambiance of Eleo's response. Whether it's friendly, professional, adventurous, or persuasive, select a tone that fits your need, allowing Eleo to tailor her reply.",
    tolgee_id: 'eleo-tooltip-tone',
  },

  creativity: {
    title: 'Creativity',
    content:
      "Unleash Eleo's creative power. Setting a higher creativity level encourages Eleo to provide unique and engaging responses, not just repeat known facts.",
    tolgee_id: 'eleo-tooltip-creativity',
  },

  saveTemplate: {
    title: 'Save Template',
    content:
      "Store your query as a template for future use. Find and use these templates from the list at the form's top when needed.",
    actions: [links.templates],
    tolgee_id: 'eleo-tooltip-save-template',
  },
  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },
  modifyText: {
    title: 'Modify Text',
    content: 'Click MODIFY TEXT to produce text from your current commands and settings.',
    tolgee_id: 'eleo-tool-modify-text-help-tooltip-5',
  },

  history: {
    title: 'History',
    content: `The 'History' icon takes you to previously generated texts.`,
    tolgee_id: 'eleo-tooltip-history',
  },

  // WRITE TEXT / HISTORY Window

  selectFromList: {
    title: 'Select from List',
    content: 'Choose previous texts by clicking the circle beside them.',
    tolgee_id: 'eleo-tooltip-select-history',
  },
  trash: {
    title: 'Deleting history items',
    content: 'The trash icon deletes unwanted texts from your history.',
    tolgee_id: 'eleo-tooltip-trash',
  },
  clearHistory: {
    title: 'Clearing history',
    content: "'Clear' wipes your history clean.",
    tolgee_id: 'eleo-tooltip-clear-history',
  },
  confirmSelection: {
    title: 'Confirm Selection',
    content: 'CONFIRM opens the chosen text along with the original creation settings.',
    tolgee_id: 'eleo-tooltip-confirm-history',
  },

  // Right window functions

  share: {
    title: 'Share',
    content: 'Use this button to post your generated text on social media platforms.',
    tolgee_id: 'eleo-tooltip-share',
  },
  copy: {
    title: 'Copy',
    content: 'This button copies your text, ready to be pasted anywhere using commands like Ctrl-V',
    tolgee_id: 'eleo-tooltip-copy',
  },
  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of the text with existing instructions.',
    tolgee_id: 'eleo-tooltip-regenerate',
  },
  responseArea: {
    title: 'Response Area',
    content:
      'The generated text shows up here. Select text for options: regenerate a section, copy, forward, or remove it.',
    actions: [links.textEditing],
    tolgee_id: 'eleo-tooltip-response-area',
  },
  myStory: {
    title: 'Your Story',
    content:
      'The "Your Story" feature allows you to save text in your profile, enabling you to utilize it as an additional context when generating documents. You have the capability to save multiple stories for varied purposes and convenience.',
    tolgee_id: 'eleo-tooltip-my-story',
  },
}
