export const yourStory = {
  yourStory: {
    title: 'Your Story',
    content:
      'The "Your Story" feature allows you to save text in your profile, enabling you to utilize it as an additional context when generating documents. You have the capability to save multiple stories for varied purposes and convenience.',
    tolgee_id: 'eleo-tooltip-my-story',
  },
  yourStoryText: {
    title: 'Your Story Text',
    content: 'You can fill detailed information about your story here',
    tolgee_id: 'eleo-tooltip-your-story-text',
  },
  yourStoryClear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },
}
