import { Message, SocialShare, ViewContext } from 'components/lib'
import { useContext } from 'react'
import ImageDisplay from 'toolComponents/image/output/ImageDisplay'
import moment from 'moment'
import { T } from '@tolgee/react'
import { LANDING_PAGE_URL } from 'helpers/image'
import axios from 'axios'
import { errorToast, successToast } from 'helpers'

export default function GalleryList({ items, refreshData }) {
  const context = useContext(ViewContext)

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(`Generated by Eleo.ai`)}
              url={`${LANDING_PAGE_URL}${content}`}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  const showDeleteItemModal = (data) => {
    context.modal.show({
      children: (
        <Message
          title={<T keyName='eleo-gallery-delete-item'>Do you want to delete this item?</T>}
          buttonText={<T keyName='permanently-delete'>Delete permanently</T>}
          buttonAction={() => handleRemoveItem(data)}
          type='warning'
        />
      ),
      modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
    })
  }

  const handleRemoveItem = async (url) => {
    try {
      await axios.post('/api/gallery/remove-item', { url: url })
      await refreshData()
      context.modal.hide()
      successToast(<T keyName='eleo-remove-image-success'>Image removed successfully</T>)
    } catch (err) {
      errorToast(<T keyName='eleo-remove-image-failed'>{err.response.data.message}</T>)
    }
  }

  return (
    <div className='flex flex-wrap gap-5 overflow-y-auto p-5'>
      {items.map((item, index) => (
        <div className='group relative m-auto last:pb-5' key={index}>
          <div className='text-center text-xl font-medium'>{item.name}</div>
          <div className='mb-2 text-center text-sm'>
            {moment(item.created).format('DD.MM.YYYY h:mm')}
          </div>
          <ImageDisplay
            image={item.image}
            name={item.name}
            showShareModal={showShareModal}
            imageData={item.data}
            noupload
            useCors
            removeItem={showDeleteItemModal}
          />
        </div>
      ))}
    </div>
  )
}
