/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from 'react'
import { Animate, Form } from 'components/lib'
import { T } from '@tolgee/react'

export function ForgotPassword(props) {
  return (
    <Animate type='pop'>
      <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='text-brand-violet mt-6 text-center text-2xl font-bold leading-9 tracking-tight'>
            <T keyName='eleo-reset-your-password-header'>Reset Your Password</T>
          </h2>
          <p className='mb-5 text-center leading-[1.2]'>
            <T keyName='eleo-reset-your-password-description'>
              Enter your email address and we'll send you instructions to reset your password.
            </T>
          </p>
        </div>
        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
          <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12'>
            <Form
              inputs={{
                email: {
                  label: <T keyName='eleo-login-email-label'>Email</T>,
                  type: 'email',
                  required: true,
                  class:
                    'block w-full rounded-md border-0 py-1.5 text-brand-body shadow-sm ring-1 ring-inset ring-brand-grey placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-violet sm:text-sm sm:leading-6',
                },
              }}
              url='/api/auth/password/reset/request'
              method='POST'
              buttonText={<T keyName='eleo-reset-password-button'>Reset Password</T>}
              inputLabelClass='block text-sm font-medium leading-6 text-brand-body capitalize'
              btnColor='purple'
              btnClasses='flex w-full justify-center rounded-md bg-brand-violet px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-violet-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            />
          </div>
        </div>
      </div>
    </Animate>
  )
}
