import { T } from '@tolgee/react'
import { Button } from 'ui'

export default function UseEleoToday({ next }) {
  return (
    <div className='bg-brand-yellow'>
      <div className='px-6 py-24 sm:px-6 sm:py-32 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-brand-text-primary text-3xl font-bold tracking-tight sm:text-4xl'>
            <T keyName='eleo-start-today-header'>Start using Eleo today</T>
          </h2>
          <p className='text-brand-text-secondary mx-auto mt-6 max-w-xl text-lg leading-8'>
            <T keyName='eleo-start-today-description'>
              Create your Eleo account and choose the plan that suits you. There is a free plan and
              professional plans.
            </T>
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Button
              href={`/signup${next ? `?next=${next}` : ''}`}
              color='violet'
              // className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              <T keyName='eleo-start-today-action'>Get started</T>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
