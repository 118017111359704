import { chat } from './chat'
import { writeText } from './writer/writeText'
import { modifyText } from './writer/modifyText'
import { checkText } from './writer/checkText'
import { advisor } from './advisor'
import { translator } from './translator'
import { greatIdeas } from './great-ideas'
import { imageCreator } from './images/imageCreator'
import { modifyImage } from './images/modifyImage'
import { reply } from './writer/reply'
import { yourStory } from './yourStory'
import { chatBotPanel } from './chat-bot-panel'

export const helpMessages = {
  chat,
  writer: {
    writeText,
    modifyText,
    checkText,
    reply,
  },
  images: {
    imageCreator,
    modifyImage,
  },
  translator,
  advisor,
  greatIdeas,
  yourStory,
  chatBotPanel,
}
