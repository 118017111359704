import { createSlice } from '@reduxjs/toolkit'

export const yourStoryInitialState = {
  yourStoryText: '',
  selectedTemplate: {
    id: '',
    templateName: '',
    storyText: '',
    documents: [],
  },
}

export const yourStorySlice = createSlice({
  name: 'yourStory',
  initialState: yourStoryInitialState,
  reducers: {
    setYourStoryText: (state, action) => {
      state.yourStoryText = action.payload
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload
    },
    setClearFields: () => yourStoryInitialState,
  },
})

// Action creators are generated for each case reducer function
export const { setYourStoryText, setSelectedTemplate, setClearFields } = yourStorySlice.actions

//selectors
export const SelectYourStoryText = (state) => state.yourStory.yourStoryText
export const SelectChosenTemplate = (state) => state.yourStory.selectedTemplate
// export const replyTextGeneratedResultHistory = (state) =>
//   state.replyText.generatedTextHistory.generatedText

export default yourStorySlice.reducer
