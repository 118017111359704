import { T, useTranslate } from '@tolgee/react'
import { Add, Cancel, RestorePage, Save } from '@mui/icons-material'
import { ArrowUUpLeft, File, FileDoc, FilePdf, GoogleChromeLogo } from 'phosphor-react'
import { downloadImage } from 'helpers/image'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { errorToast } from 'helpers'
import { Button, Input } from 'ui'
import { Modal } from 'components/lib'
import { useState } from 'react'
import { isValidUrl } from 'helpers'

const ICON_SIZE = 40
const docTypes = {
  'application/pdf': <FilePdf size={ICON_SIZE} />,
  'application/msword': <FileDoc size={ICON_SIZE} />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
    <FileDoc size={ICON_SIZE} />
  ),
  url: <GoogleChromeLogo size={ICON_SIZE} />,
}

export function StoryDocuments({
  documents,
  websites,
  documentsToDelete,
  setDocumentsToDelete,
  documentsToUpload,
  setDocumentsToUpload,
  websitesToUpload,
  setWebsitesToUpload,
  websitesToDelete,
  setWebsitesToDelete,
}) {
  const { t } = useTranslate()
  const [modalState, setModalState] = useState({ visible: false })
  const [newUrl, setNewUrl] = useState('')

  async function handleFileUpload(e) {
    e.preventDefault()

    const files = e.target.files
    if (!files) return

    for (const file of files) {
      if (!docTypes[file.type]) {
        errorToast(
          t('eleo-your-story-document-not-supported', 'This document type is not supported')
        )
        return
      }

      setDocumentsToUpload((prev) => [
        ...prev,
        {
          name: file.name,
          mimetype: file.type,
          document_id: uuidv4(),
          file: file,
        },
      ])
    }

    // Clear file input
    e.target.value = null
  }

  function toggleDeleteDoc(e, doc) {
    e.stopPropagation()
    setDocumentsToDelete((prev) => {
      if (prev.includes(doc.document_id)) return prev.filter((item) => item !== doc.document_id)
      else return [...prev, doc.document_id]
    })
  }

  function toggleDeleteWebsite(e, doc) {
    e.stopPropagation()
    setWebsitesToDelete((prev) => {
      if (prev.includes(doc.document_id)) return prev.filter((item) => item !== doc.document_id)
      else return [...prev, doc.document_id]
    })
  }

  function addNewWebsite() {
    if (!isValidUrl(newUrl)) {
      setNewUrl('')
      errorToast(t('eleo-error-invalid-url', 'Please provide a valid url'))
      return
    }

    setWebsitesToUpload((prev) => [
      ...prev,
      {
        name: newUrl.split('//')[1],
        mimetype: 'url',
        document_id: uuidv4(),
        url: newUrl,
      },
    ])
    setNewUrl('')
  }

  return (
    <>
      <div className='flex gap-[10px]'>
        <p className='text-brand-body min-w-[75px] max-w-[75px] flex-shrink-0 flex-grow-0 text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-select-your-story-documents'>Documents</T>
        </p>

        <div
          className='flex h-[88px] w-full items-center gap-3 overflow-x-auto rounded-lg border px-4 py-2'
          onDrop={handleFileUpload}
          onDragOver={(event) => event.preventDefault()}
        >
          {/* Documents assigned to this story */}
          {documents?.map((doc) => (
            <div
              key={doc.document_id}
              className={classNames('group relative rounded-md px-[6px] py-1 hover:bg-gray-100')}
              onClick={() => window.open(doc.url, '_blank').focus()}
            >
              <div
                className={classNames(
                  'flex flex-col items-center gap-1',
                  documentsToDelete.includes(doc.document_id) && 'opacity-60'
                )}
              >
                <div className='group-hover:scale-[102%]'>
                  {docTypes[doc.mimetype] ?? <File size={ICON_SIZE} />}
                </div>
                <div className='max-w-[60px] cursor-default select-none truncate text-[12px]'>
                  {doc.name.split('.')[0]}
                </div>
              </div>

              {/* Delete doc button */}
              <div
                className={classNames(
                  'absolute -right-3 -top-2 scale-95 cursor-pointer pr-1',
                  !documentsToDelete.includes(doc.document_id) && 'hidden group-hover:block'
                )}
                onClick={(e) => toggleDeleteDoc(e, doc)}
              >
                {documentsToDelete.includes(doc.document_id) ? (
                  <RestorePage className='text-green-500' />
                ) : (
                  <Cancel className='text-red-600' />
                )}
              </div>
            </div>
          ))}
          {/* Separator */}
          {Boolean(documents.length) && <div className='h-full border-l' />}

          {/* Documents waiting for upload */}
          {documentsToUpload?.map((doc) => (
            <div
              key={doc.document_id}
              className={classNames('group relative rounded-md px-[6px] py-1 hover:bg-gray-100')}
            >
              <div className='flex flex-col items-center gap-1'>
                <div className='group-hover:scale-[102%]'>
                  {docTypes[doc.mimetype] ?? <File size={ICON_SIZE} />}
                </div>
                <div className='max-w-[60px] cursor-default select-none truncate text-[12px]'>
                  {doc.name.split('.')[0]}
                </div>
              </div>

              {/* Delete doc button */}
              <div
                className='absolute -right-3 -top-2 hidden scale-95 cursor-pointer pr-1 group-hover:block'
                onClick={(e) => {
                  e.stopPropagation()
                  setDocumentsToUpload((prev) =>
                    prev.filter((item) => item.document_id !== doc.document_id)
                  )
                }}
              >
                <Cancel className='text-red-600' />
              </div>
            </div>
          ))}
          {/* Separator */}
          {Boolean(documentsToUpload.length) && <div className='h-full border-l' />}

          {/* Add document button */}
          <label
            htmlFor='file-input'
            className='flex size-12 cursor-pointer items-center justify-center rounded-full bg-gray-200'
          >
            <Add className='' fontWeight='bold' />
          </label>
          <input
            id='file-input'
            type='file'
            multiple
            className='hidden'
            accept='application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            onChange={handleFileUpload}
          />
        </div>
      </div>
      <div className='flex gap-[10px]'>
        <p className='text-brand-body min-w-[75px] max-w-[75px] flex-shrink-0 flex-grow-0 text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-select-your-story-websites'>Websites</T>
        </p>

        <div className='flex h-[88px] w-full items-center gap-3 overflow-x-auto rounded-lg border px-4 py-2'>
          {/* Documents assigned to this story */}
          {websites?.map((doc) => (
            <div
              key={doc.document_id}
              className={classNames('group relative rounded-md px-[6px] py-1 hover:bg-gray-100')}
              onClick={() => window.open(doc.url, '_blank').focus()}
            >
              <div
                className={classNames(
                  'flex flex-col items-center gap-1',
                  websitesToDelete.includes(doc.document_id) && 'opacity-60'
                )}
              >
                <div className='group-hover:scale-[102%]'>
                  {docTypes[doc.mimetype] ?? <File size={ICON_SIZE} />}
                </div>
                <div className='max-w-[60px] cursor-default select-none truncate text-[12px]'>
                  {doc.name}
                </div>
              </div>

              {/* Delete doc button */}
              <div
                className={classNames(
                  'absolute -right-3 -top-2 scale-95 cursor-pointer pr-1',
                  !websitesToDelete.includes(doc.document_id) && 'hidden group-hover:block'
                )}
                onClick={(e) => toggleDeleteWebsite(e, doc)}
              >
                {websitesToDelete.includes(doc.document_id) ? (
                  <RestorePage className='text-green-500' />
                ) : (
                  <Cancel className='text-red-600' />
                )}
              </div>
            </div>
          ))}
          {/* Separator */}
          {Boolean(websites.length) && <div className='h-full border-l' />}

          {/* Documents waiting for upload */}
          {websitesToUpload?.map((doc) => (
            <div
              key={doc.document_id}
              className={classNames('group relative rounded-md px-[6px] py-1 hover:bg-gray-100')}
            >
              <div className='flex flex-col items-center gap-1'>
                <div className='group-hover:scale-[102%]'>
                  {docTypes[doc.mimetype] ?? <File size={ICON_SIZE} />}
                </div>
                <div className='max-w-[60px] cursor-default select-none truncate text-[12px]'>
                  {doc.name}
                </div>
              </div>

              {/* Delete doc button */}
              <div
                className='absolute -right-3 -top-2 hidden scale-95 cursor-pointer pr-1 group-hover:block'
                onClick={(e) => {
                  e.stopPropagation()
                  setWebsitesToUpload((prev) =>
                    prev.filter((item) => item.document_id !== doc.document_id)
                  )
                }}
              >
                <Cancel className='text-red-600' />
              </div>
            </div>
          ))}
          {/* Separator */}
          {Boolean(websitesToUpload.length) && <div className='h-full border-l' />}

          {/* Add document button */}
          <div
            className='flex size-12 cursor-pointer items-center justify-center rounded-full bg-gray-200'
            onClick={() => setModalState({ visible: true })}
          >
            <Add className='' fontWeight='bold' />
          </div>
        </div>
      </div>

      {/* Add website modal */}
      {modalState.visible && (
        <Modal
          setHideModal={setModalState}
          text={
            <p className='text-brand-body text-[19px] font-bold'>
              <T keyName='eleo-your-story-doc-url-header'>Enter website to include</T>
            </p>
          }
        >
          <div className='flex  w-full flex-col gap-[10px]'>
            <Input
              placeholder={t('eleo-story-url-placeholder', 'Enter website url')}
              className='border-brand-grey border'
              value={newUrl}
              onChange={setNewUrl}
            />
            <Button
              color='green'
              className='!float-none h-[45px] !w-full'
              onClick={() => {
                addNewWebsite()
                setModalState({ visible: false })
              }}
            >
              <div className='flex items-center gap-[5px]'>
                <Save className='text-white' />
                <p className='!mb-0'>
                  <T keyName='eleo-tool-create-image-save-action'>Save</T>
                </p>
              </div>
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}
