import { T } from '@tolgee/react'

export const resolutions = {
  sd15: [
    {
      width: '512',
      height: '512',
      proportions: '1/1',
      label: <T keyName='eleo-resolution-1:1'>Square 1:1</T>,
      value: '512x512',
    },
    {
      width: '768',
      height: '512',
      proportions: '3/2',
      label: <T keyName='eleo-resolution-3:2'>Horizontal 3:2</T>,
      value: '768x512',
    },
    {
      width: '768',
      height: '576',
      proportions: '4/3',
      label: <T keyName='eleo-resolution-4:3'>Horizontal 4:3</T>,
      value: '768x576',
    },
    {
      width: '912',
      height: '512',
      proportions: '16/9',
      label: <T keyName='eleo-resolution-16:9'>Horizontal 16:9</T>,
      value: '912x512',
    },
    {
      width: '512',
      height: '768',
      proportions: '2/3',
      label: <T keyName='eleo-resolution-2:3'>Vertical 2:3</T>,
      value: '512x768',
    },
    {
      width: '576',
      height: '768',
      proportions: '3/4',
      label: <T keyName='eleo-resolution-3:4'>Vertical 3:4</T>,
      value: '576x768',
    },
    {
      width: '512',
      height: '912',
      proportions: '9/16',
      label: <T keyName='eleo-resolution-9:16'>Vertical 9:16</T>,
      value: '512x912',
    },
  ],
  sdxl: [
    {
      width: '1024',
      height: '1024',
      proportions: '1/1',

      label: <T keyName='eleo-resolution-1:1'>Square 1:1</T>,
      value: '1024x1024',
    },
    {
      width: '1152',
      height: '768',
      proportions: '3/2',
      label: <T keyName='eleo-resolution-3:2'>Horizontal 3:2</T>,
      value: '1152x768',
    },
    {
      width: '1152',
      height: '864',
      proportions: '4/3',
      label: <T keyName='eleo-resolution-4:3'>Horizontal 4:3</T>,
      value: '1152x864',
    },
    {
      width: '1360',
      height: '768',
      proportions: '16/9',
      label: <T keyName='eleo-resolution-16:9'>Horizontal 16:9</T>,
      value: '1360x768',
    },
    {
      width: '768',
      height: '1152',
      proportions: '2/3',
      label: <T keyName='eleo-resolution-2:3'>Vertical 2:3</T>,
      value: '768x1152',
    },
    {
      width: '864',
      height: '1152',
      proportions: '3/4',
      label: <T keyName='eleo-resolution-3:4'>Vertical 3:4</T>,
      value: '864x1152',
    },
    {
      width: '768',
      height: '1360',
      proportions: '9/16',
      label: <T keyName='eleo-resolution-9:16'>Vertical 9:16</T>,
      value: '768x1360',
    },
  ],
}
