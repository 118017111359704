import React from 'react'
import { Animate } from 'components/lib'
import ModifyText from 'toolComponents/writer/modify-text/ModifyTextLayout'

export function WriterModifyText(props) {
  return (
    <Animate type='pop'>
      <ModifyText />
    </Animate>
  )
}
