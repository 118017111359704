import classNames from 'classnames'

const ChatPositionSelector = ({ handleDispatchChatSettings, initialPosition = 'bottom-right' }) => {
  const positions = [
    'top-left',
    'top-center',
    'top-right',
    'middle-left',
    'middle',
    'middle-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
  ]

  return (
    <div className='border-brand-gray border'>
      <div className='grid grid-cols-[25px,25px,25px]'>
        {positions.map((position, i) => (
          <div
            className={classNames('border-brand-gray h-[25px] w-[25px] cursor-pointer border', {
              'bg-brand-green': initialPosition === position,
            })}
            onClick={() => handleDispatchChatSettings({ field: 'chatPlacement', value: position })}
            key={i}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default ChatPositionSelector
