import React from 'react'
import { T } from '@tolgee/react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from 'react-tooltip'
import { Toggle } from 'ui'
import classNames from 'classnames'
import { useContext } from 'react'
import { CursorModeContext } from 'app/cursorMode'
import { CursorMode } from 'helpers/enums'

const Guru = ({
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  tool,
  className,
  hideExtraText = false,
  place = 'bottom',
}) => {
  const { cursorMode } = useContext(CursorModeContext)

  return (
    <div
      className={classNames('flex items-center gap-[10px]', className)}
      data-tooltip-id={tool}
      id='guru'
    >
      <div className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
        {hideExtraText ? (
          'Guru'
        ) : (
          <T keyName='eleo-tool-advisor-guru-option-text'>Guru (1 word = 10 )</T>
        )}
        <div>
          <div data-tooltip-id='guru-tooltip'>
            <HelpOutlineIcon style={{ fontSize: 18, cursor: 'pointer' }} />
          </div>
          {cursorMode !== CursorMode.Help && (
            <Tooltip
              disableStyleInjection='core'
              place={place}
              id='guru-tooltip'
              className=' !bg-brand-violet shadow-md'
            >
              <ul className='flex w-[150px] flex-col gap-[10px]'>
                <li className='flex flex-col text-[14px] leading-[1.2]'>
                  <b>
                    <T keyName='eleo-guru-option'>One word is counted as 30 words</T>
                  </b>
                </li>
              </ul>
            </Tooltip>
          )}
        </div>
      </div>
      <Toggle isChecked={isGuruModeEnabled} setIsChecked={setIsGuruModeEnabled} />
    </div>
  )
}

export default Guru
