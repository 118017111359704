import React from 'react'
import { Animate } from 'components/lib'
import ReplyLayout from 'toolComponents/writer/reply/ReplyLayout'

export function Reply(props) {
  return (
    <Animate type='pop'>
      <ReplyLayout />
    </Animate>
  )
}
