/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  AutoStories,
  Close,
  Extension,
  Image,
  KeyboardArrowDown,
  Lightbulb,
  Menu,
  Phone,
  PlayCircle,
  QuestionAnswer,
  SmartToy,
  Stars,
  Subject,
  Translate,
} from '@mui/icons-material'
import { T } from '@tolgee/react'
import { LangSelector } from 'components/LangSwitcher'
import { DEFAULT_PAGE } from 'constants'
import { getLandingUrl } from 'helpers'
import eleoLogo from 'images/eleo-logo_h_s.svg'
import { Fragment, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import { Button } from 'ui'

const products = [
  {
    name: <T keyName='eleo-feature-chat'>Chat</T>,
    description: (
      <T keyName='eleo-feature-chat-description'>
        Get specific information, translations, ideas, or messages
      </T>
    ),
    href: '/features/chat',
    icon: QuestionAnswer,
  },
  {
    name: <T keyName='eleo-feature-writer'>Writer</T>,
    description: (
      <T keyName='eleo-feature-writer-description'>
        Create a new article, letter, post, description, story, email within seconds.
      </T>
    ),
    href: '/features/writer',
    icon: Subject,
  },
  {
    name: <T keyName='eleo-feature-advisor'>Advisor</T>,
    description: (
      <T keyName='eleo-feature-advisor-description'>
        Immediately get information in the field of science, marketing, law, business, medicine.
      </T>
    ),
    href: '/features/advisor',
    icon: Stars,
  },
  {
    name: <T keyName='eleo-feature-translator'>Translator</T>,
    description: (
      <T keyName='eleo-feature-translator-description'>
        Translate any texts into 50 languages from around the world.
      </T>
    ),
    href: '/features/translator',
    icon: Translate,
  },
  {
    name: <T keyName='eleo-feature-great-ideas'>Great Ideas</T>,
    description: (
      <T keyName='eleo-feature-great-ideas-description'>
        Discover new ideas for: name, description, plan, title, promotions, products, and services.
      </T>
    ),
    href: '/features/ideas',
    icon: Lightbulb,
  },
  {
    name: <T keyName='eleo-feature-images'>Images</T>,
    description: (
      <T keyName='eleo-feature-images-description'>
        Create and modify new images in any style with a single click.
      </T>
    ),
    href: '/features/images',
    icon: Image,
  },
  {
    name: <T keyName='eleo-feature-your-story'>Your Story</T>,
    description: (
      <T keyName='eleo-features-your-story-header-description'>
        Transforms Eleo into a personalized tool that responds and thinks like you.
      </T>
    ),
    href: '/features/your-story',
    icon: AutoStories,
  },
  {
    name: <T keyName='eleo-feature-chatbot'>Chatbot</T>,
    description: (
      <T keyName='eleo-tier-feature-description-Chatbot'>
        Make a Chatbot in just a few minutes, and it'll chat for you 24/7.
      </T>
    ),
    href: '/features/chatbot',
    icon: SmartToy,
  },
  {
    name: <T keyName='eleo-feature-browser-extension'>Browser Extension</T>,
    description: (
      <T keyName='eleo-feature-browser-extension-description'>
        You have Eleo always at hand thanks to the Chrome, Firefox, and Brave extension.
      </T>
    ),
    href: '/features/extension',
    icon: Extension,
  },
]
const callsToAction = [
  {
    name: <T keyName='eleo-watch-demo'>Watch demo</T>,
    href: 'https://www.youtube.com/watch?v=aEZwixJUN7o',
    target: '_blank',
    icon: PlayCircle,
  },
  {
    name: <T keyName='eleo-contact-sales'>Contact sales</T>,
    href: getLandingUrl() + '/contact',
    icon: Phone,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function HomeNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <header className='bg-white'>
      <nav
        className='relative mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <a href={getLandingUrl()} className='-m-1.5 p-1.5'>
            <img className='h-[48px] w-[150px] sm:h-[97px] sm:w-[300px]' src={eleoLogo} alt='' />
          </a>
        </div>
        <div className='flex gap-[5px] lg:hidden'>
          <LangSelector />
          <button
            type='button'
            className='text-brand-text-primary -m-2.5 inline-flex items-center justify-center rounded-md p-2.5'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Menu className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <Popover.Group className='hidden lg:flex lg:gap-x-12'>
          <Popover>
            <Popover.Button className='text-brand-text-primary flex items-center gap-x-1 text-sm font-semibold leading-6 outline-none'>
              <T keyName='eleo-header-features'>Features</T>
              <KeyboardArrowDown
                className='text-brand-text-secondary h-5 w-5 flex-none'
                aria-hidden='true'
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel
                style={{ top: 'calc(100% - 40px)' }}
                className='absolute inset-x-0 z-10 w-full max-w-full overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5'
              >
                <div className='grid grid-cols-3 p-4'>
                  {products.map((item, i) => (
                    <div
                      key={i}
                      className='group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'
                    >
                      <div className='flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white'>
                        <item.icon
                          className='group-hover:text-brand-violet h-6 w-6 text-gray-600'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='flex-auto'>
                        <a
                          href={getLandingUrl() + item.href}
                          className='text-brand-text-primary block font-semibold'
                        >
                          {item.name}
                          <span className='absolute inset-0' />
                        </a>
                        <p className='text-brand-text-secondary mt-1'>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50'>
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={item.target || '_self'}
                      className='text-brand-text-primary flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 hover:bg-gray-100'
                    >
                      <item.icon
                        weight='fill'
                        className='h-5 w-5 flex-none text-gray-400'
                        aria-hidden='true'
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <HashLink
            onClick={() => setMobileMenuOpen(false)}
            to={getLandingUrl() + '/#pricing'}
            className='text-brand-text-primary text-sm font-semibold leading-6'
          >
            <T keyName='eleo-header-pricing'>Pricing</T>
          </HashLink>
          {/* <HashLink
            onClick={() => setMobileMenuOpen(false)}
            to='/christmas-codes'
            className='text-brand-text-primary text-sm font-semibold leading-6'
          >
            <T keyName='eleo-header-christmas-codes'>Christmas Codes</T>
          </HashLink> */}
        </Popover.Group>
        <div className='hidden flex-1 items-center justify-end gap-x-6 lg:flex'>
          <LangSelector />
          {user?.token ? (
            <Button href={DEFAULT_PAGE} color='violet' variant='small' className='font-bold'>
              <T keyName='eleo-header-open-app-button'>Open App</T>
            </Button>
          ) : (
            <>
              <a
                href='/signin'
                className='lg:text-brand-text-primary hidden lg:block lg:text-sm lg:font-semibold lg:leading-6'
              >
                <T keyName='eleo-header-log-in-button'>Log in</T>
              </a>
              <a
                href='/signup'
                className='bg-brand-violet hover:bg-brand-violet-light focus-visible:outline-brand-violet-light hidden rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 lg:block'
              >
                <T keyName='eleo-header-sign-up-button'>Sign up</T>
              </a>
            </>
          )}
        </div>
      </nav>
      <Dialog as='div' className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <a href='/' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Eleo</span>
              <img className='h-[48px] w-[150px]' src={eleoLogo} alt='' />
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <Close className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-8 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                <Disclosure as='div' className='-mx-3'>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='text-brand-text-primary flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50'>
                        <T keyName='eleo-header-features'>Features</T>
                        <KeyboardArrowDown
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden='true'
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className='mt-2 space-y-2'>
                        {[...products].map((item) => (
                          <Disclosure.Button
                            onClick={() => setMobileMenuOpen(false)}
                            key={item.name}
                            as='a'
                            href={item.href}
                            className='text-brand-text-primary block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:bg-gray-50'
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <HashLink
                  onClick={() => setMobileMenuOpen(false)}
                  to='/#pricing'
                  className='text-brand-text-primary -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'
                >
                  <T keyName='eleo-header-pricing'>Pricing</T>
                </HashLink>
                <HashLink
                  onClick={() => setMobileMenuOpen(false)}
                  to='/christmas-codes'
                  className='text-brand-text-primary -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'
                >
                  <T keyName='eleo-header-christmas-codes'>Christmas Codes</T>
                </HashLink>
              </div>
              <div className='py-6'>
                <a
                  href='/signin'
                  className='text-brand-text-primary -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:bg-gray-50'
                >
                  <T keyName='eleo-header-log-in-button'>Log in</T>
                </a>
                <a
                  href='/signup'
                  className='text-brand-text-primary -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:bg-gray-50'
                >
                  <T keyName='eleo-header-sign-up-button'>Sign up</T>
                </a>
                {
                  <a
                    href={DEFAULT_PAGE}
                    className='text-brand-text-primary -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:bg-gray-50'
                  >
                    <T keyName='eleo-header-open-app-button'>Open App</T>
                  </a>
                }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
