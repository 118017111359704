import { toast } from 'react-toastify'
import axios from 'axios'
import { errorToast } from 'helpers'
import { T } from '@tolgee/react'

export async function imageToClipboard(imageUrl) {
  try {
    const getImage = async () => {
      const image = await fetch(imageUrl, { mode: 'cors', cache: 'no-cache' })

      return await image.blob()
    }

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    if (isFirefox)
      return toast(<T keyName='eleo-image-copy-warn'>Right click the image to copy</T>, {
        position: 'bottom-right',
        type: 'warning',
        theme: 'colored',
        icon: false,
        toastId: imageUrl,
        autoClose: 2000,
      })

    await navigator.clipboard.write([new ClipboardItem({ 'image/png': getImage() })])

    toast(<T keyName='eleo-image-copy'>Image copied to clipboard</T>, {
      position: 'bottom-right',
      type: 'success',
      theme: 'colored',
      icon: false,
      toastId: imageUrl,
      autoClose: 2000,
    })
  } catch (err) {
    toast(err.toString(), {
      position: 'bottom-right',
      type: 'error',
      theme: 'colored',
      icon: false,
    })
    console.log(err)
  }
}

export async function downloadImage(imageUrl, name = null) {
  try {
    const image = await fetch(imageUrl, {
      mode: 'cors',
      cache: 'no-cache',
    })
    const blob = await image.blob()
    const data = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = data
    link.download = name || 'your-image.png'

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )

    // Firefox support
    setTimeout(() => {
      window.URL.revokeObjectURL(data)
      link.remove()
    }, 100)
  } catch (err) {
    errorToast(<T keyName='image-download-error'>Couldn't download you image. Please try again</T>)
  }
}

export const downloadFile = (file) => {
  if (file) {
    const fileUrl = window.URL.createObjectURL(file)

    const downloadLink = document.createElement('a')
    downloadLink.href = fileUrl
    downloadLink.download = file.name

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    window.URL.revokeObjectURL(fileUrl)
  }
}

// Expects a list of links
export async function saveToGallery(imageUrl, name, imageData = null) {
  try {
    const image = await fetch(imageUrl, {
      mode: 'cors',
      cache: 'no-cache',
    })
    const imgData = await image.blob()
    let uploadData = new FormData()
    uploadData.append('file', imgData, `${name}.png`)
    uploadData.append('dir', 'gallery/')
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const res = await axios.post('/api/utility/upload', uploadData, config)

    const url = '/api/gallery/update'
    const data = { image: res.data.items[0], imageData, name }

    await axios.post(url, data)

    toast('Image saved', {
      position: 'bottom-right',
      type: 'success',
      theme: 'colored',
      icon: false,
      toastId: 'Image saved',
      autoClose: 2000,
    })
  } catch (err) {
    console.log(err)
    toast('Could not save you image', {
      position: 'bottom-right',
      type: 'error',
      theme: 'colored',
      icon: false,
      toastId: 'Could not save you image',
      autoClose: 2000,
    })
  }
}

export async function uploadBase64Image(base64) {
  try {
    const res = await axios.post('/api/utility/upload/base64', { base64: base64, dir: 'masks/' })
    return res.data
  } catch (err) {
    console.error(err)
  }
}

export const LANDING_PAGE_URL = 'https://images.eleo.ai/image?url='
