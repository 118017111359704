import { T } from '@tolgee/react'

export const Author = [
  { value: 'expert', label: <T keyName='eleo-select-option-expert'>Expert</T> },
  { value: 'lawyer', label: <T keyName='eleo-select-option-lawyer'>Lawyer</T> },
  {
    value: 'accountant',
    label: <T keyName='eleo-select-option-accountant'>Accountant</T>,
  },
  {
    value: 'salesman',
    label: <T keyName='eleo-select-option-salesman'>Salesman</T>,
  },
  {
    value: 'businessman',
    label: <T keyName='eleo-select-option-businessman'>Businessman</T>,
  },
  {
    value: 'IT specialist',
    label: <T keyName='eleo-select-option-it-specialist'>IT specialist</T>,
  },
  {
    value: 'medicine expert',
    label: <T keyName='eleo-select-option-medicine-expert'>Medicine expert</T>,
  },
  {
    value: 'teacher',
    label: <T keyName='eleo-select-option-teacher'>Teacher</T>,
  },
  { value: 'writer', label: <T keyName='eleo-select-option-writer'>Writer</T> },
  {
    value: 'scientist',
    label: <T keyName='eleo-select-option-scientist'>Scientist</T>,
  },
  {
    value: 'teenager',
    label: <T keyName='eleo-select-option-teenager'>Teenager</T>,
  },
]
