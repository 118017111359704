import { Close } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Story } from './modalContent/story'
import { Website } from './modalContent/website'

const contentMap = {
  website: Website,
  story: Story,
}

export function MessageContextModal({ modal, setModal, messageContext }) {
  const { t } = useTranslate()
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseModal = (e) => {
    e.stopPropagation()
    e.target === e.currentTarget && setModal({ visible: false, content: '' })
  }

  const Content = contentMap[modal.content]

  return (
    <div
      className='fixed bottom-0 left-0 right-0 top-0 z-[9999] bg-white/80'
      onClick={handleCloseModal}
    >
      <div className='xs:border absolute left-1/2 top-1/2 flex max-h-[90dvh] w-[min(500px,100vw)] -translate-x-1/2 -translate-y-1/2 flex-col rounded-[4px] border-[#D9D9D9] bg-white shadow-[0_0_20px_#00000020]'>
        {/* Header */}
        <div className='bg-brand-violet space-y-1 rounded-t-[3px] pb-[50px] pl-[24px] pr-[16px] pt-[14px] text-white'>
          <div className='flex items-center justify-between'>
            <div className='text-[14px] font-medium'>
              {t(`eleo-chat-message-context-modal-title-${modal.content}`)}
            </div>
            <button
              type='button'
              className='size-[28px] cursor-pointer text-[18px]'
              onClick={() => setModal({ visible: false, content: '' })}
            >
              <Close fontSize='inherit' />
            </button>
          </div>

          <div className='text-[12px] leading-[1.3em] text-white/70'>
            {t(`eleo-chat-message-context-modal-info-${modal.content}`)}
          </div>
        </div>

        {/* Content */}
        <div className='overflow-y-auto'>
          <Content
            items={items}
            setItems={setItems}
            messageContext={messageContext}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>

        {/* Footer */}
        <div className='border-b' />
        <div className='flex gap-[14px] px-[24px] py-[14px] text-[13px] capitalize'>
          <button
            type='button'
            className='bg-brand-violet/10 hover:bg-brand-violet/20 hover:text-brand-violet text-brand-violet-light h-[40px] w-full rounded-[4px] font-bold uppercase transition-colors'
            onClick={() => setModal({ visible: false, content: '' })}
          >
            {t('eleo-modal-cancel-button', 'Cancel')}
          </button>
          <button
            type='button'
            disabled={isLoading || items.some((item) => !item.isValid)}
            className={classNames(
              'bg-brand-violet hover:bg-brand-violet-light h-[40px] w-full rounded-[4px] font-bold text-white transition-colors',
              items.some((item) => !item.isValid) && 'opacity-70'
            )}
            onClick={() => {
              items.map((item) =>
                messageContext.addDocument({
                  label: item.label,
                  type: modal.content,
                  content: item.content,
                  id: item.id ?? uuidv4(),
                })
              )
              setModal({ visible: false, content: '' })
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}
