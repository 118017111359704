import React from 'react'
import { Animate } from 'components/lib'
import AdvisorLayout from 'toolComponents/advisor/AdvisorLayout'

export function Advisor(props) {
  return (
    <Animate type='pop'>
      <AdvisorLayout />
    </Animate>
  )
}
