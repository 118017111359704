import links from '../docsLinks'

export const greatIdeas = {
  describe: {
    title: 'Describe what',
    content: 'Write down what ideas you want Eleo to generate for you.',
    tolgee_id: 'eleo-tool-great-ideas-help-tooltip-1',
  },
  details: {
    title: 'Details',
    content: 'Provide additional details that will help Eleo generate the ideas you expect.',
    tolgee_id: 'eleo-tool-great-ideas-help-tooltip-2',
  },

  negative: {
    title: 'Describe what you don’t want',
    content:
      "Write down what you don't want to read and what ideas you don't want Eleo to generate.",
    tolgee_id: 'eleo-tool-great-ideas-help-tooltip-3',
  },

  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },

  ideaFor: {
    title: 'Idea For',
    content: 'You can choose from a list of predefined topics for your ideas.',
    tolgee_id: 'eleo-tool-great-ideas-help-tooltip-4',
  },

  language: {
    title: 'Language',
    content: "Select the desired language for Eleo's response.",
    tolgee_id: 'eleo-tooltip-language',
  },

  tone: {
    title: 'Tone',
    content:
      "Define the ambiance of Eleo's response. Whether it's friendly, professional, adventurous, or persuasive, select a tone that fits your need, allowing Eleo to tailor her reply.",
    tolgee_id: 'eleo-tooltip-tone',
  },

  length: {
    title: 'Length',
    content: "Determine how wordy or concise you want Eleo's response to be.",
    tolgee_id: 'eleo-tooltip-length',
  },

  creativity: {
    title: 'Creativity',
    content:
      "Unleash Eleo's creative power. Setting a higher creativity level encourages Eleo to provide unique and engaging responses, not just repeat known facts.",
    tolgee_id: 'eleo-tooltip-creativity',
  },

  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },

  generateIdea: {
    title: 'Generate Idea',
    content: 'Click GENERATE IDEA to produce text from your current commands and settings.',
    tolgee_id: 'eleo-tool-great-ideas-help-tooltip-5',
  },

  share: {
    title: 'Share',
    content: 'Use this button to post your generated text on social media platforms.',
    tolgee_id: 'eleo-tooltip-share',
  },
  copy: {
    title: 'Copy',
    content: 'This button copies your text, ready to be pasted anywhere using commands like Ctrl-V',
    tolgee_id: 'eleo-tooltip-copy',
  },
  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of the text with existing instructions.',
    tolgee_id: 'eleo-tooltip-regenerate',
  },
  responseArea: {
    title: 'Response Area',
    content:
      'The generated text shows up here. Select text for options: regenerate a section, copy, forward, or remove it.',
    actions: [links.textEditing],
    tolgee_id: 'eleo-tooltip-response-area',
  },

  history: {
    title: 'History',
    content: `The 'History' icon takes you to previously generated texts.`,
    tolgee_id: 'eleo-tooltip-history',
  },

  selectFromList: {
    title: 'Select from List',
    content: 'Choose previous texts by clicking the circle beside them.',
    tolgee_id: 'eleo-tooltip-select-history',
  },
  trash: {
    title: 'Deleting history items',
    content: 'The trash icon deletes unwanted texts from your history.',
    tolgee_id: 'eleo-tooltip-trash',
  },
  clearHistory: {
    title: 'Clearing history',
    content: "'Clear' wipes your history clean.",
    tolgee_id: 'eleo-tooltip-clear-history',
  },
  confirmSelection: {
    title: 'Confirm Selection',
    content: 'CONFIRM opens the chosen text along with the original creation settings.',
    tolgee_id: 'eleo-tooltip-confirm-history',
  },
  myStory: {
    title: 'Your Story',
    content:
      'The "Your Story" feature allows you to save text in your profile, enabling you to utilize it as an additional context when generating documents. You have the capability to save multiple stories for varied purposes and convenience.',
    tolgee_id: 'eleo-tooltip-my-story',
  },
}
