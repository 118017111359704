import React from 'react'

import classNames from 'classnames'

import { Save } from '@mui/icons-material'

import { Button } from 'ui'
import { T } from '@tolgee/react'
import { useSelector } from 'react-redux'
import { SelectChosenTemplate, setClearFields } from 'store/account/yourStory'
import { useDispatch } from 'react-redux'

const StoryActions = ({ handleShowSaveTemplateModal }) => {
  const dispatch = useDispatch()
  const selectedStory = useSelector(SelectChosenTemplate)

  const handleResetFields = () => {
    dispatch(setClearFields())
  }

  return (
    <div className='flex gap-[10px]'>
      <Button
        color='gray'
        className='h-[45px]'
        onClick={handleResetFields}
        data-tooltip-id='yourStory-yourStoryClear'
      >
        <p className='text-[15px] font-medium uppercase leading-none'>
          <T keyName='eleo-history-action-clear'>clear</T>
        </p>
      </Button>
      <Button
        id='save'
        color='dark'
        className='h-[45px] w-full max-w-[300px] px-[18px]'
        onClick={handleShowSaveTemplateModal}
      >
        <div className={classNames('flex items-center gap-[6px]')}>
          <Save className='text-[#ffffff]' />
          <p className='text-[15px] font-medium uppercase leading-none'>
            {selectedStory.id ? (
              <T keyName='eleo-your-story-update-action'>update story</T>
            ) : (
              <T keyName='eleo-your-story-save-action'>save story</T>
            )}
          </p>
        </div>
      </Button>
    </div>
  )
}

export default StoryActions
