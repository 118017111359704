import links from '../../docsLinks'

export const checkText = {
  myText: {
    title: 'My Text',
    content: 'Paste the text you need reviewed here.',
    tolgee_id: 'eleo-tool-writer-check-text-tooltip-1',
  },

  checkFor: {
    title: 'Check For',
    content:
      "Choose a specific task from the 'Check For' list, such as grammar review or emotion analysis.",
    tolgee_id: 'eleo-tool-writer-check-text-tooltip-2',
  },
  guru: {
    title: 'Guru',
    content:
      'Guru is a special feature that employs advanced artificial intelligence to deliver improved answers. These responses are not only more precise and detailed but also demand significantly more computing power. Consequently, here, a single word counts as much as 10 words in less complex modes.',
    tolgee_id: 'eleo-tool-advisor-help-tooltip-4',
  },

  creativity: {
    title: 'Creativity',
    content:
      "Unleash Eleo's creative power. Setting a higher creativity level encourages Eleo to provide unique and engaging responses, not just repeat known facts.",
    tolgee_id: 'eleo-tooltip-creativity',
  },

  language: {
    title: 'Language',
    content: "Select the desired language for Eleo's response.",
    tolgee_id: 'eleo-tooltip-language',
  },

  clear: {
    title: 'Clear',
    content: 'Click CLEAR to erase all current form settings, starting fresh.',
    tolgee_id: 'eleo-tooltip-clear',
  },
  checkText: {
    title: 'Check Text',
    content: 'Click CHECK TEXT to produce text from your current commands and settings.',
    tolgee_id: 'eleo-tool-writer-check-text-tooltip-3',
  },

  // Right window functions

  share: {
    title: 'Share',
    content: 'Use this button to post your generated text on social media platforms.',
    tolgee_id: 'eleo-tooltip-share',
  },
  copy: {
    title: 'Copy',
    content: 'This button copies your text, ready to be pasted anywhere using commands like Ctrl-V',
    tolgee_id: 'eleo-tooltip-copy',
  },
  regenerate: {
    title: 'Regenerate',
    content: 'Click REGENERATE if you want a new version of the text with existing instructions.',
    tolgee_id: 'eleo-tooltip-regenerate',
  },
  responseArea: {
    title: 'Response Area',
    content:
      'The generated text shows up here. Select text for options: regenerate a section, copy, forward, or remove it.',
    actions: [links.textEditing],
    tolgee_id: 'eleo-tooltip-response-area',
  },
}
