import { useState } from 'react'
import { Label, Error, Icon, ClassHelper } from 'components/lib'
import Style from './input.tailwind.js'
import { useTranslate } from '@tolgee/react'

export function PasswordInput(props) {
  const [error, setError] = useState(props.errorMessage || 'Please enter a password')
  const { t } = useTranslate()
  function validate(e) {
    let value = e ? e.target.value : ''
    let valid = undefined

    // input is required and value is blank
    if (props.required && value === '') valid = false

    // input isn't required and value is blank
    if (!props.required && value === '') valid = true

    if (props.required && value !== '') valid = true

    if (!props.required) valid = true

    // complexity rules
    if (props.complexPassword) {
      if (value.length < 5) {
        valid = false
        setError(
          t(
            'eleo-password-validation-min-length-error',
            'Password must be a minimum of 5 characters'
          )
        )
      } else if (!/[A-Z]/.test(value)) {
        valid = false
        setError(
          t(
            'eleo-password-validation-uppercase-letter-error',
            'Password must have at least one upper case letter'
          )
        )
      } else if (!/[a-z]/.test(value)) {
        valid = false
        setError(
          t(
            'eleo-password-validation-lovercase-letter-error',
            'Password must have at least one lower case letter'
          )
        )
      }
    }

    // update the parent form
    props.onChange?.(props.name, value, valid)
  }

  const passwordStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  })

  return (
    <div className={Style.input}>
      {props.label && (
        <Label
          text={props.label}
          required={props.required}
          inputLabelClass={props.inputLabelClass}
          for={props.name}
        />
      )}

      <input
        type='password'
        id={props.for}
        name={props.name}
        className={passwordStyle}
        disabled={props.disabled}
        onChange={(e) => props.onChange?.(props.name, e.target.value, undefined)}
        onBlur={(e) => validate(e)}
      />

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon image='check' color='#8CC57D' className={Style.successIcon} size={16} />
      )}
    </div>
  )
}
