import classNames from 'classnames'
import { Button } from 'ui'

export default function Header(props) {
  const title = props.title
  const subtitle = props.subtitle
  const description = props.description

  return (
    <div className='mb-7 border-y bg-white'>
      <div className='relative isolate py-14'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto max-w-2xl text-center'>
            <h1 className='text-brand-text-primary text-4xl font-bold tracking-tight sm:text-6xl'>
              {title}
            </h1>
            <p className='text-md text-brand-violet mt-2 font-bold'>{subtitle}</p>
            {props.link && (
              <Button
                className={classNames('mt-6', props.link.className)}
                href={props.link.href}
                color='violet'
                target={props.link.target}
              >
                {props.link.text}
              </Button>
            )}
            {description && (
              <h2 className='text-brand-text-primary mt-6 text-xl font-bold tracking-tight sm:text-2xl'>
                {description}
              </h2>
            )}
          </div>
          {props.videoLink && (
            <div className='relative mx-auto mt-6 w-[1216px] max-w-[90vw] pb-[56.25%]'>
              <iframe
                title={props.title}
                src={props.videoLink}
                className='absolute inset-0 h-full w-full rounded-xl shadow-2xl ring-1 ring-gray-900/10'
                allow='full'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
