import React from 'react'
import { T } from '@tolgee/react'
const MoreOptions = ({ isShowAdvanced }) => {
  return (
    <>
      {isShowAdvanced ? (
        <T keyName='eleo-tool-hide-options'>Hide Options</T>
      ) : (
        <T keyName='eleo-tool-more-options'>More Options</T>
      )}
    </>
  )
}

export default MoreOptions
