import { T } from '@tolgee/react'
import classNames from 'classnames'
import WriterHeader from 'toolComponents/writer/WriterHeader'
import { Button } from 'ui'
import ChatSettings from './chatSettings'
import GeneratedLinks from './generatedLinks'

const ChatResult = ({ handleGenerateChatBot, hidden, handleFileUpload }) => {
  return (
    <>
      {!hidden && (
        <div id='output' className={classNames('relative h-full')}>
          <div className={classNames('flex h-full flex-col justify-between')}>
            <div className='overflow-x-clip overflow-y-scroll'>
              <GeneratedLinks />
              <hr />
              <div>
                <div className='flex h-full flex-col'>
                  <WriterHeader
                    historyIsVisible={false}
                    showHistoryIcon={false}
                    showHelpButton={false}
                    headerTitle='Your Chat Bot For Your Website'
                    toolName='profile-chat-bot-result-for-website'
                    headerContainerClasses='!py-[20px]'
                  />
                  <ChatSettings handleFileUpload={handleFileUpload} />
                </div>
              </div>
            </div>
            <div className='p-[16px]'>
              <div className='flex w-full justify-end'>
                <Button
                  color='dark'
                  className='h-[45px] w-full max-w-[260px]'
                  data-tooltip-id='chatBotPanel-applyYourSettings'
                  onClick={() => handleGenerateChatBot()}
                >
                  <p className='text-[15px] font-medium uppercase leading-none'>
                    <T keyName='eleo-chat-bot-apply-custom-settings'>Apply Custom Settings</T>
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChatResult
