import { Chat } from 'views/tools/chat'
import { Translator } from 'views/tools/translator'
import { Image } from 'views/tools/image'
import { ImageModify } from 'views/tools/imageModify'
import { Writer } from 'views/tools/writer'
import { WriterModifyText } from 'views/tools/writer-modify-text'
import { WriterCheckText } from 'views/tools/writer-check-text'
import { Gallery } from 'views/tools/gallery'
import { GreatIdeas } from 'views/tools/great-ideas'
import { Advisor } from 'views/tools/advisor'
import { Reply } from 'views/tools/reply'

const Routes = [
  {
    path: '/chat',
    view: Chat,
    layout: 'app',
    permission: 'user',
    title: 'Chat',
    tokens: 'words',
  },
  {
    path: '/translator',
    view: Translator,
    layout: 'app',
    permission: 'user',
    title: 'Translator',
    tokens: 'words',
  },
  {
    path: '/image',
    view: Image,
    layout: 'app',
    permission: 'user',
    title: 'Image',
    tokens: 'images',
  },
  {
    path: '/image/modify',
    view: ImageModify,
    layout: 'app',
    permission: 'user',
    title: 'Image',
    tokens: 'images',
  },
  {
    path: '/image/gallery',
    view: Gallery,
    layout: 'app',
    permission: 'user',
    title: 'Gallery',
    tokens: 'images',
  },
  {
    path: '/writer',
    view: Writer,
    layout: 'app',
    permission: 'user',
    title: 'Writer',
    tokens: 'words',
  },
  {
    path: '/writer/reply',
    view: Reply,
    layout: 'app',
    permission: 'user',
    title: 'Reply',
    tokens: 'words',
  },
  {
    path: '/writer/modify',
    view: WriterModifyText,
    layout: 'app',
    permission: 'user',
    title: 'Modify Text',
    tokens: 'words',
  },
  {
    path: '/writer/check',
    view: WriterCheckText,
    layout: 'app',
    permission: 'user',
    title: 'Check Text',
    tokens: 'words',
  },
  {
    path: '/great-ideas',
    view: GreatIdeas,
    layout: 'app',
    permission: 'user',
    title: 'Great Ideas',
    tokens: 'words',
  },
  {
    path: '/advisor',
    view: Advisor,
    layout: 'app',
    permission: 'user',
    title: 'Advisor',
    tokens: 'words',
  },
]

export default Routes
