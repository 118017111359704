import { streamingRequest } from 'helpers/streamingRequest'
import { useState, useContext } from 'react'
import { ViewContext } from 'components/lib'
import axios from 'axios'

export function useStream(useErrorHandling = true) {
  const [state, setState] = useState({ message: null, command: null, isLoading: false })
  const [abortController, setAbortController] = useState(null)
  const context = useContext(ViewContext)

  async function fetch(url, data) {
    url = axios.defaults.baseURL + url
    let message = ''
    try {
      setState({ message: null, command: null, isLoading: true })

      const controller = new AbortController()
      setAbortController(controller)

      const res = await streamingRequest(url, data, { signal: controller?.signal }, false)

      const reader = res.body.getReader()
      const decoder = new TextDecoder('utf-8')
      let commandString = ''
      let contentEnded = false

      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        let textChunk = decoder.decode(value)

        // Handle custom messages from the backend
        const flagIndex = textChunk.indexOf('-ENDSTREAM-')
        if (flagIndex !== -1) {
          // If flag found
          contentEnded = true
          if (flagIndex !== 0) {
            const textBeforeFlag = textChunk.slice(0, flagIndex)
            message = message + textBeforeFlag
            const newMsg = message
            setState((prev) => ({ ...prev, message: newMsg }))

            textChunk = textChunk.slice(flagIndex + 11)
          } else textChunk = textChunk.slice(11)
        }

        if (contentEnded) {
          commandString += textChunk
          continue
        }
        message += textChunk
        const newMsg = message
        setState((prev) => ({ ...prev, message: newMsg }))

        if (abortController?.signal?.aborted) {
          // Abort the request if the signal is aborted
          reader.cancel()
          break
        }
      }

      try {
        const obj = await JSON.parse(commandString)
        setState((prev) => ({ ...prev, command: obj }))
      } catch (err) {
        console.error(err)
      }
      return message
    } catch (err) {
      if (err.name === 'AbortError') return message
      else if (useErrorHandling) context.handleError(err)

      if (err.response?.status === 402) {
        err.name = 'LimitError'
        throw err
      }
      throw err
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }))
      setAbortController(null)
    }
  }

  return { state: state, abortController, fetch }
}
