import ExtensionIcon from '@mui/icons-material/Extension'
import DevicesIcon from '@mui/icons-material/Devices'
import LanguageIcon from '@mui/icons-material/Language'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import HistoryIcon from '@mui/icons-material/History'
import PsychologyIcon from '@mui/icons-material/Psychology'

import image from 'images/features/chat.png'
import { T } from '@tolgee/react'

const features = [
  {
    name: 'Browser plugin.',
    description: 'With the Eleo Chat browser plugin, assistance is always a click away.',
    icon: ExtensionIcon,
    id: 'eleo-features-chat-content-feature-browser-plugin',
  },
  {
    name: 'Use anywhere.',
    description:
      'Enjoy the flexibility of using Eleo Chat on any device - be it a computer, tablet, or phone.',
    icon: DevicesIcon,
    id: 'eleo-features-chat-content-feature-use-anywhere',
  },
  {
    name: 'Multilingual.',
    description: 'Break language barriers and converse in any language with ease.',
    icon: LanguageIcon,
    id: 'eleo-features-chat-content-feature-multilingual',
  },
  {
    name: 'Personalized assistant.',
    description: `Expect precise, tailored responses - whether it's information, advice, translations, creative ideas, or messages.`,
    icon: FingerprintIcon,
    id: 'eleo-features-chat-content-feature-personalized-assistant',
  },
  {
    name: 'Memorable Chat Experiences.',
    description:
      'Engage in memorable, inspiring conversations with Eleo, benefiting from its ability to recall past interactions.',
    icon: PsychologyIcon,
    id: 'eleo-features-chat-content-feature-memorable-chat-experiences',
  },
  {
    name: 'Chat history.',
    description:
      'Plus, with access to your chat history, you can revisit and continue past conversations anytime.',
    icon: HistoryIcon,
    id: 'eleo-features-chat-content-feature-chat-history',
  },
]

export default function Content() {
  return (
    <div className='bg-white pb-24 pt-5 sm:pb-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl sm:text-center'>
          <h2 className='text-brand-violet text-base font-semibold leading-7'>
            <T keyName='eleo-features-chat-content-subtitle'>Everything you need</T>
          </h2>
          <p className='text-brand-text-primary mt-2 text-3xl font-bold tracking-tight sm:text-4xl'>
            <T keyName='eleo-features-chat-content-title'>Discover the Power of Eleo Chat</T>
          </p>
          <p className='text-brand-text-secondary mt-6 text-lg leading-8'>
            <T keyName='eleo-features-chat-content-description'>
              Imagine having a conversation partner that’s always there for you, ready to answer any
              question, swiftly verify information, or translate in a snap. That's Eleo Chat -
              conveniently within your reach as an app or browser plugin, streamlining your day with
              its ease and efficiency.
            </T>
          </p>
        </div>
      </div>
      <div className='relative overflow-hidden pt-16'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <img
            src={image}
            alt='Chat screenshot'
            className='mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10'
            width={1216}
            height={821}
          />
          <div className='relative' aria-hidden='true'>
            <div className='absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]' />
          </div>
        </div>
      </div>
      <div className='mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8'>
        <dl className='text-brand-text-secondary mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16'>
          {features.map((feature) => (
            <div key={feature.name} className='relative pl-9'>
              <dt className='text-brand-text-primary inline font-semibold'>
                <feature.icon
                  className='text-brand-violet absolute left-1 top-1 h-5 w-5'
                  aria-hidden='true'
                />
                <T keyName={`${feature.id}-name`}>{feature.name}</T>
              </dt>{' '}
              <dd className='inline'>
                <T keyName={`${feature.id}-description`}>{feature.description}</T>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
