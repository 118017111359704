import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'

const { useState, useEffect } = require('react')

export const useGetTemplateList = (name, segment) => {
  const context = useContext(ViewContext)

  const [loading, setLoading] = useState(false)
  const [templatesRecordId, setTemplatesRecordId] = useState(null)
  const [templateList, setTemplateList] = useState([])
  const [templateOptions, setTemplateOptions] = useState([])
  // const [templateListLoading, setTemplateListLoading] = useState(false)

  const getTemplateList = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${axios.defaults.baseURL}/api${segment}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })
      const { data } = await response.json()

      if (data) {
        const templateList = data[`${name}`]
        const templateListAsOptions = templateList.map((template) => {
          return {
            value: template.id,
            label: template.templateName,
          }
        })
        setTemplatesRecordId(data.id)
        setTemplateList(templateList)
        setTemplateOptions(templateListAsOptions)
      }
    } catch (err) {
      context.handleError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTemplateList()
  }, [])

  return {
    templateList,
    setTemplateList,
    templateOptions,
    templatesRecordId,
    reload: getTemplateList,
    templateListLoading: loading,
  }
}
