import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectContext, setContext } from '../../store/context'
import { useYourStory } from './yourStory'

export function useDocumentContext(tool, pullFrom) {
  const dispatchAction = useDispatch()
  const contextData = useSelector(selectContext)
  const [docContext, setDocContext] = useState(contextData[pullFrom ?? tool] ?? [])
  const [stories, setStories] = useState({ isLoading: true, data: [] })
  const { data: storyData, isLoading: storyLoading } = useYourStory()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [savedState, setSavedState] = useState(docContext)

  // Update store
  useEffect(() => {
    if (docContext) dispatchAction(setContext({ tool: tool, data: docContext }))
  }, [docContext, dispatchAction, tool])

  useEffect(() => {
    const data = storyData.map((story) => ({
      id: story.id,
      label: story.templateName,
      content: story.storyText,
    }))

    setStories({ isLoading: false, data: data })
  }, [storyLoading, storyData])

  function addDocument({ label, type, content, id, invalid = false, metadata }) {
    setDocContext((prev) => {
      if (prev.some((item) => item.id === id)) return prev
      return [...prev, { label, type, content, id, invalid, metadata }]
    })
  }

  function removeDocument(id) {
    setDocContext((prev) => prev.filter((doc) => doc.id !== id))
  }

  function toggleEditDocument(id) {
    setDocContext((prev) =>
      prev.map((item) => (item.id === id ? { ...item, edit: !item.edit } : item))
    )
  }

  function removeEmptyDocuments() {
    setDocContext((prev) => prev.filter((doc) => doc.content))
  }

  function openModal() {
    setSavedState(docContext)
    setIsModalVisible(true)
  }

  function closeModal({ saveChanges = true }) {
    if (!saveChanges) setDocContext(savedState)
    setIsModalVisible(false)
  }

  function clearContext() {
    setDocContext([])
  }

  function removeDocumentsOfType(type) {
    setDocContext((prev) => prev.filter((doc) => doc.type !== type))
  }

  return {
    docContext,
    stories,
    setDocContext,
    addDocument,
    removeDocument,
    toggleEditDocument,
    removeEmptyDocuments,
    removeDocumentsOfType,
    clearContext,
    isModalVisible,
    openModal,
    closeModal,
  }
}
