/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React from 'react'
// import BoostYoutCapabilities from 'tailwindComponents/BoostYourCapabilities'

// import DontGetOvertaken from 'tailwindComponents/DontGetOvertaken'
// // import { Faqs } from 'tailwindComponents/Faqs'
// import { Hero } from 'tailwindComponents/Hero'
// import PotentialImageTilesCTA from 'tailwindComponents/PotentialImageTilesCTA'
// import { PoweredBy } from 'tailwindComponents/PoweredBy'
// // import { Pricing } from 'tailwindComponents/Pricing'
// import { PrimaryFeatures } from 'tailwindComponents/PrimaryFeatures'
// // import { Testimonials } from 'tailwindComponents/Testimonials'
// import UseEleoToday from 'tailwindComponents/UseEleoTodayCTA'
// import VideoCTA from 'tailwindComponents/VideoCTA'
// import PackPricing from '../../tailwindComponents/PackPricing'
// import SimplePricing from 'tailwindComponents/SimplePricing'
// import { EleoOutro } from 'components/eleo-outro'
// import ImageCarousel from 'components/carousel/ImageCarousel'
import { Navigate } from 'react-router-dom'
import { DEFAULT_PAGE } from 'constants'

export function Home(props) {
  return <Navigate to={DEFAULT_PAGE} />

  // return (
  //   <>
  //     <main>
  //       <Hero />
  //       <PoweredBy />
  //       <BoostYoutCapabilities />
  //       <UseEleoToday />
  //       <DontGetOvertaken />
  //       <VideoCTA />
  //       <PotentialImageTilesCTA />
  //       <ImageCarousel showLink />
  //       <PrimaryFeatures />
  //       {/* <Testimonials /> */}
  //       <SimplePricing />
  //       {/* <Pricing /> */}
  //       <PackPricing />
  //       {/* <Faqs /> */}
  //       <EleoOutro />
  //     </main>
  //   </>
  // )
}
