import { T } from '@tolgee/react'
import { useWindowDimensions } from 'components/hooks/window'
import Slider from 'react-slick'
import { Button } from 'ui'
import cat from './images/cat.png'
import deer from './images/deer.png'
import dog from './images/dog.png'
import expresionism from './images/expresionism.png'
import hiker from './images/hiker.png'
import investor from './images/investor.png'
import people from './images/people.png'
import robot from './images/robot.png'
import squirrel from './images/squirrel.png'
import woman from './images/woman.png'
import woman2 from './images/woman2.png'
import woman3 from './images/woman3.png'

export default function ImageCarousel({ showLink }) {
  const { width } = useWindowDimensions()
  const slidesToShow = width >= 1024 ? 3 : width >= 640 ? 2 : 1
  const images = [
    cat,
    dog,
    squirrel,
    // santa,
    woman3,
    investor,
    woman2,
    expresionism,
    deer,
    woman,
    people,
    hiker,
    robot,
  ]

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    centerMode: slidesToShow === 1,
  }

  return (
    <div className='mx-auto mb-10 max-w-7xl overflow-clip'>
      <h2 className='text-brand-text-primary my-4 text-center text-3xl font-bold tracking-tight sm:text-4xl'>
        <T keyName='eleo-images-by'>Images generated by</T>
        <a href='/image' className='text-brand-violet'>
          {' '}
          Eleo.ai
        </a>
      </h2>

      <Slider {...settings}>
        {images.map((item) => (
          <div key={item} className='px-2'>
            <img src={item} alt='Creative men thinking about Eleo' className='rounded-2xl' />
          </div>
        ))}
      </Slider>

      {showLink && (
        <div className='mt-10 flex justify-center'>
          <Button href='/features/images' color='violet'>
            <T keyName='eleo-see-more'>See more</T>
          </Button>
        </div>
      )}
    </div>
  )
}
